import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { InputMask } from '@react-input/mask';
import styled from 'styled-components';
import { useUserContext } from '../../context/UserContext';

enum InputErrorType {
  None,
  NoValue,
  WrongFormat,
}

interface StyledInputProps {
  onBlur: () => void;
  onClick?: () => void;
  $hasBeenFocused: boolean;
  $invalidOnEmpty: boolean;
  $isValid: boolean;
}

type PhoneInputProps = {
  labelText: string;
  name?: string;
  inputPlaceholderText?: string;
  invalidOnEmpty?: boolean;
  isRequired?: boolean;
  includeLabel?: boolean;
  showErrorMessage?: boolean;
  isDisabled?: boolean;
  setInputIsValid?: (newValue: boolean) => void;
  setErrorMessage?: (newValue: string) => void;
  setValue?: (newValue: string) => void;
  valueOverride?: string;
  onClick?: () => void;
};

const InputErrorText = styled.p`
  font-family: Brandon;
  font-size: 16px;
  color: ${props => props.theme.colors.mangoTango};
  font-weight: 400;
  padding: 0px 15px;
  margin: auto auto 16px 0;
`;

const LabeledInputContainer = styled.div`
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 9.6px 0 9.6px 0;
`;

const Label = styled.label`
  font-weight: 700;
  text-transform: uppercase;
  color: ${props => props.theme.colors.charcoal};
  text-align: left;
  width: 85%;
  margin: auto;
`;

const StyledInputMask = styled(InputMask)<StyledInputProps>`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-family: 'Brandon';
  font-size: 16px;
  height: 24px;
  padding: 10px;
  margin: auto;
  width: 80%;

  &.invalid {
    border: 1px solid ${props => props.theme.colors.mangoTango};
  }

  &:focus {
    outline: none !important;
    border: 1px solid ${props => props.theme.colors.curiousBlue};
  }

  ${props =>
    props.$hasBeenFocused &&
    props.$invalidOnEmpty &&
    `
      &:invalid {
        border: 1px solid ${props.theme.colors.mangoTango};
      }
    `}

  ${props =>
    props.$isValid &&
    `
      &:focus {
        outline: none !important;
        border: 1px solid ${props.theme.colors.mangoTango};
      }
    `}
`;

const OptionalLabel = styled.label`
  font-weight: 700;
  text-transform: uppercase;
  color: ${props => props.theme.colors.loblolly};
  text-align: right;
  width: fit-content;
  float: right;
`;

const LabelContainer = styled.div`
  width: 85%;
  align-self: center;
`;

const PhoneInput = ({
  labelText,
  name,
  inputPlaceholderText,
  invalidOnEmpty = true,
  isRequired = true,
  includeLabel = true,
  showErrorMessage = true,
  setInputIsValid,
  setErrorMessage,
  onClick,
  valueOverride,
  isDisabled = false,
}: PhoneInputProps) => {
  const [hasBeenFocused, setHasBeenFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(InputErrorType.None);
  const { getString } = useUserContext();

  const getErrorString = useCallback(
    (errorType: InputErrorType) => {
      switch (errorType) {
        case InputErrorType.NoValue:
          return getString('controlMessageComponent.message.required');
        case InputErrorType.WrongFormat:
          return getString(
            'controlMessageComponent.message.invalidPhoneNumber'
          );
        default:
          return '';
      }
    },
    [getString]
  );

  const handleOnBlur = () => {
    // The first time this is clicked off, enable the invalid selector
    if (!hasBeenFocused) {
      setHasBeenFocused(true);
    }
    const updatedValue = inputValue.replace(/\D/g, '');
    //since autofill doesn't trigger onChange, we need to check here as well for when the element is unselected
    if (updatedValue.length === 0 && isRequired) {
      setInputError(InputErrorType.NoValue);
    } else if (updatedValue.length > 0 && updatedValue.length < 10) {
      setInputError(InputErrorType.WrongFormat);
    } else {
      setInputError(InputErrorType.None);
    }
  };
  // load initial value

  useEffect(() => {
    if (valueOverride && valueOverride?.length === 0 && isRequired) {
      setInputError(InputErrorType.NoValue);
    } else if (valueOverride && valueOverride.replace(/\D/g, '').length < 10) {
      setInputError(InputErrorType.WrongFormat);
    } else {
      setInputError(InputErrorType.None);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueOverride, isRequired]);

  useEffect(() => {
    const cleaned = ('' + valueOverride).replace(/\D/g, '');

    // If the cleaned number has more than 10 digits, take only the first 10 digits
    const truncated = cleaned.substring(0, 10);

    // Format the truncated number based on its length
    if (truncated.length > 7) {
      setInputValue(
        `(${truncated.substring(0, 3)}) ${truncated.substring(
          3,
          7
        )}-${truncated.substring(7)}`
      );
    } else if (truncated.length > 3) {
      setInputValue(`(${truncated.substring(0, 3)}) ${truncated.substring(3)}`);
    } else if (truncated.length > 0) {
      setInputValue(`(${truncated.substring(0, truncated.length)}`);
    } else {
      setInputValue('');
    }
  }, [valueOverride]);

  useEffect(() => {
    setErrorMessage?.(getErrorString(inputError));
  }, [getErrorString, inputError, setErrorMessage]);

  useEffect(() => {
    setInputIsValid?.(inputError === InputErrorType.None);
  }, [inputError, setInputIsValid]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const inputName = 'input-' + labelText.toLowerCase().replace(' ', '-');
  return (
    <LabeledInputContainer>
      {includeLabel && (
        <LabelContainer>
          <Label htmlFor={inputName}>{labelText}</Label>
          {!isRequired && (
            <OptionalLabel htmlFor={inputName} className={inputName}>
              {getString('form.label.optional')}
            </OptionalLabel>
          )}
        </LabelContainer>
      )}
      <StyledInputMask
        mask='(___) ___-____'
        replacement={{ _: /\d/ }}
        className={
          inputError == InputErrorType.None || !hasBeenFocused
            ? 'valid'
            : 'invalid'
        }
        name={name}
        onBlur={() => handleOnBlur()}
        onClick={onClick}
        onChange={handleChange}
        $hasBeenFocused={hasBeenFocused}
        $invalidOnEmpty={invalidOnEmpty}
        $isValid={inputError !== InputErrorType.None}
        type={'text'}
        id={inputName}
        value={inputValue}
        placeholder={inputPlaceholderText}
        data-testid={labelText}
        disabled={isDisabled}></StyledInputMask>
      {showErrorMessage &&
      inputError !== InputErrorType.None &&
      hasBeenFocused ? (
        <InputErrorText id='error-text'>
          {getErrorString(inputError)}
        </InputErrorText>
      ) : null}
    </LabeledInputContainer>
  );
};

export default PhoneInput;
