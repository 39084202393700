export const getEnumCaseName = (
  value: string,
  enumType: Record<string, string>
): string | undefined => {
  for (const key in enumType) {
    if (enumType[key] === value) {
      return key;
    }
  }
  return undefined;
};
