import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { AuthFormPage } from './AuthFormPage';
import { LabeledInput } from '../shared/LabeledInput';
import { useUserContext } from '../../context/UserContext';
import { HCPUser } from '../../models';
import { useAPI } from '../../context/APIContext';
import { Endpoints } from '../../api/endpoints';
import { ErrorToast } from '../shared/ToastrNotifications';
import {
  logGAEvent,
  sendPageView,
} from '../../shared/services/googleAnalyticsService';

const Button = styled.button`
  background-color: ${props => props.theme.colors.pacificBlue};
  border: 1px solid ${props => props.theme.colors.pacificBlue};
  border-radius: 21px;
  color: ${props => props.theme.colors.white};
  display: block;
  font-weight: 900;
  font-size: medium;
  height: 42px;
  margin: auto;
  margin-bottom: 16px;
  width: 67%;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    background-color: ${props => props.theme.colors.cerulean};
  }
  &:disabled {
    opacity: 0.5;
  }
`;

const AuthForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const ForgotPassword = styled(Link)`
  color: ${props => props.theme.colors.echoBlue};
  text-align: right;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  max-width: 90%;
  margin-left: auto;
  margin-right: 7.5%;
  margin-top: -8px;
  margin-bottom: 24px;
  &:hover {
    color: ${props => props.theme.colors.curiousBlue};
  }
`;

export const Login = () => {
  const { user, setUser, getString } = useUserContext();
  const { isLoading } = useAPI();
  const navigate = useNavigate();

  useEffect(() => {
    sendPageView('home');
    if (user) {
      if (user.superAdmin) {
        navigate('/hcp/users');
      } else {
        navigate('/patient');
      }
    }
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      email: HTMLInputElement;
      password: HTMLInputElement;
    };

    const body = {
      email: formElements.email.value.trim(),
      password: formElements.password.value,
    };

    axios
      .post(Endpoints.staffLogin, body)
      .then(response => {
        const user: HCPUser = response.data;
        setUser(user);
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'viewPatient',
          eventAct: 'view',
          uuid: user.uuid,
        });
        const passwordExpirationDays = user.passwordExpirationDays;
        if (passwordExpirationDays !== -1) {
          localStorage.setItem(
            'passwordExpirationDays',
            '' + passwordExpirationDays
          );
        }
        if (user.superAdmin) {
          navigate('/hcp');
        } else {
          navigate('/patient');
        }
      })
      .catch(error => {
        setUser(undefined);
        if ('passwordExpired' in error && error.passwordExpired) {
          ErrorToast(
            getString('toastrMessages.loginComponent.pleaseChangePassword')
          );
          setTimeout(() => navigate('/forgotPassword/true'), 2000);
        } else {
          ErrorToast(error);
        }
      });
  };

  return (
    <AuthFormPage>
      <AuthForm
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}>
        <LabeledInput
          name='email'
          labelText={getString('form.label.email')}
          matchPattern={true}
          showErrorMessage={false}
          isRequired={true}
          onClick={() =>
            logGAEvent({
              event: 'eventTracker',
              eventCat: 'login',
              eventAct: 'click',
              eventLbl: 'email',
            })
          }
        />
        <LabeledInput
          name='password'
          labelText={getString('form.label.password')}
          inputType='password'
          matchPattern={true}
          showErrorMessage={false}
          isRequired={true}
          onClick={() =>
            logGAEvent({
              event: 'eventTracker',
              eventCat: 'login',
              eventAct: 'click',
              eventLbl: 'password',
            })
          }
        />
        <ForgotPassword
          to={'/forgotpassword/false'}
          data-testid='forgot-password-link'>
          {getString('loginComponent.forgotPasswordLink')}
        </ForgotPassword>
        <Button
          type='submit'
          disabled={isLoading}
          data-testid='login-button'
          onClick={() =>
            logGAEvent({
              event: 'eventTracker',
              eventCat: 'login',
              eventAct: 'click',
              eventLbl: 'password',
            })
          }>
          {getString('form.button.login')}
        </Button>
      </AuthForm>
    </AuthFormPage>
  );
};

export default Login;
