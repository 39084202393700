import styled from 'styled-components';
import BaseModal from '../../shared/modals/BaseModal';
import theme from '../../../assets/theme';
import { useUserContext } from '../../../context/UserContext';
import LabeledInput from '../../shared/LabeledInput';
import DatePicker from 'react-datepicker';
import { TimeInput } from '../../shared/TimeInput';
import 'react-datepicker/dist/react-datepicker.css';
import { format, formatISO, parse } from 'date-fns';
import { useEffect, useState, useRef } from 'react';
import { RRuleUtility } from '../../../util/rruleUtil';
import { ErrorToast, SuccessToast } from '../../shared/ToastrNotifications';
import moment from 'moment';
import { useMomentUtility } from '../../../util/MomentUtility';
import {
  logGAEvent,
  sendPageView,
} from '../../../shared/services/googleAnalyticsService';
import axios from 'axios';
import { Endpoints } from '../../../api/endpoints';
import { Appointment } from '../../../models';

interface AddAppointmentProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  patientId: number;
  onCloseButtonClicked?: () => void;
  prefillData?: Appointment;
  refreshTable?: () => void;
}

interface ReturnBody {
  title: string;
  location: string;
  notes: string;
  startDateTime: string; // start time of appt in ISO
  endRecurrence: string | null; // end time of appt in ISO
  remindersActive: boolean; // bool for if at least one reminder is active
  endDateTime?: string; // end date for recurrence
  rrule?: string; // recurrence rule
  reminders?: number[]; //reminders
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const enum Frequency {
  never,
  daily,
  weekly,
  twoweekly,
  monthly,
  yearly,
  weekdays,
}

const enum Reminders {
  none = -1,
  atTimeOfEvent = 0,
  fiveMinutesBefore = 5,
  tenMinutesBefore = 10,
  fifteenMinutesBefore = 15,
  thirtyMinutesBefore = 30,
  oneHourBefore = 60,
  twoHoursBefore = 120,
  oneDayBefore = 1440,
  twoDaysBefore = 2880,
  oneWeekBefore = 10080,
}

const enum DatePickerErrors {
  none = '',
  changeApppointmentStartDate = 'changeAppointmentStartDate',
  appointmentStartDateInThePast = 'appointmentStartDateInThePast',
  invalidAppointmentEndDate = 'invalidAppointmentEndDate',
  appointmentEndDateInThePast = 'appointmentEndDateInThePast',
  required = 'required',
}

const ModalTitle = styled.h1`
  text-transform: uppercase;
  color: ${props => props.theme.colors.charcoal};

  font-weight: 900;
  font-size: 20px;
  letter-spacing: 2.73px;
  margin: 0;
  margin-top: -20px;
  padding-bottom: 16px;
`;

const InputForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0;

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    flex-direction: column;
  }
`;

const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-right: 0;
`;

const InputContainer = styled.div`
  width: 80%;
  margin-left: 0;
  display: flex;
  align-items: end;
  margin-left: -20px;

  input.datepicker {
    border: 1px solid ${props => props.theme.colors.mystic};
    border-radius: 4px;
    color: ${props => props.theme.colors.charcoal};
    font-weight: 600;
    font-size: 14px;
    height: 26px;
    padding: 10px;

    &#end-date {
      margin-left: -20px;
    }
  }
  .react-datepicker__day--outside-month {
    visibility: hidden !important;
  }

  input.invalid {
    border-color: ${props => props.theme.colors.mangoTango};
  }

  #error-text {
    margin-left: 20px;
  }

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    width: 100%;
    margin-left: auto;
    input {
      width: 85%;
    }
    #error-text {
      margin-left: 0;
    }
  }
`;

const InputLabel = styled.label`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  margin: auto;
  margin-right: 0%;

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    font-size: 16px;
  }
`;
const LabelSubheading = styled.p`
  color: ${props => props.theme.colors.loblolly};
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  margin: auto;
  margin-right: 0%;
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 35px;

  :last-child {
    margin-left: -2px;
  }

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    margin-left: 15px;
  }
`;

const RecurringSelect = styled.select`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px 0 0 4px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-size: 14px;
  height: 46px;
  margin-left: 32px;
  width: calc(50% - 35px);
  padding: 10px;
  background-color: ${props => props.theme.colors.aliceBlue};
  align-self: flex-start;

  &.never {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: ${props => props.theme.colors.loblolly};
    opacity: 0.4;
  }

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    width: 50%;
    margin-left: 15px;
  }
`;
const RemindersComponent = styled.select`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px 0 0 4px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-size: 14px;
  height: 46px;
  margin-left: 32px;
  width: calc(50% - 35px);
  padding: 10px;
  background-color: ${props => props.theme.colors.aliceBlue};

  &.none {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: ${props => props.theme.colors.loblolly};
    opacity: 0.4;
  }

  &#reminder-2 {
    margin-left: 0;
  }
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    margin-left: 15px;
    width: 50%;
  }
`;

const SubmitButton = styled.button`
  background-color: ${props => props.theme.colors.pacificBlue};
  border: 1px solid ${props => props.theme.colors.pacificBlue};
  border-radius: 21px;
  color: ${props => props.theme.colors.white};
  display: block;
  font-weight: 600;
  font-size: medium;
  height: 42px;
  margin: auto;
  min-width: 250px;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    background-color: ${props => props.theme.colors.cerulean};
  }

  &:disabled {
    opacity: 0.4;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 35px;

  p {
    margin: 0;
  }
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    margin-left: 15px;
  }
`;

const ErrorText = styled.p`
  color: ${props => props.theme.colors.mangoTango};
  text-align: left;
  font-weight: 500;

  &#end-date {
    margin-left: -20px;
    max-width: 202px;
  }
`;

const AddAppointmentModal = (props: AddAppointmentProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    gaEvent('close');
    props.setModalIsOpen(false);
  };

  sendPageView(
    'editAppointment',
    props.prefillData ? 'Edit Appointment' : 'Add Appointment'
  );

  const [titleIsValid, setTitleIsValid] = useState<boolean>();
  const [startDate, setStartDate] = useState<Date>();
  const [minSelectableDate, setMinSelectableDate] = useState<Date>();
  const [startDateHasBeenTouched, setStartDateHasBeenTouched] = useState(false);
  const [startDateError, setStartDateError] = useState(DatePickerErrors.none);
  const [startTimeIsValid, setStartTimeIsValid] = useState(false);
  const [startTimeValue, setStartTimeValue] = useState<string>();
  const [endTimeIsValid, setEndTimeIsValid] = useState(false);
  const [endTimeValue, setEndTimeValue] = useState<string>();
  const [rrule, setRRule] = useState('');
  const [recurring, setRecurring] = useState(Frequency.never);
  const [endRepeatDate, setEndRepeatDate] = useState<Date>();
  const [endRepeatDateError, setEndRepeatDateError] = useState(
    DatePickerErrors.none
  );
  const [reminders, setReminders] = useState([
    Reminders.oneDayBefore,
    Reminders.none,
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { getString } = useUserContext();
  const MomentUtility = useRef(useMomentUtility());

  const gaEvent = (type: string) => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'appointments',
      eventAct: 'click',
      eventLbl: props.prefillData ? '_edit' : '_add' + type,
    });
  };

  // Sets the default values if the modal is in edit mode
  useEffect(() => {
    if (props.prefillData) {
      setStartDate(new Date(props.prefillData.startDateTime));
      setMinSelectableDate(
        MomentUtility.current.minDate(
          props.prefillData.startDateTime
            ? moment(props.prefillData.startDateTime).format()
            : moment().format()
        )
      );

      setStartTimeValue(
        moment(props.prefillData.startDateTime).format('hh:mm a')
      );

      if (props.prefillData.endRecurrence) {
        setEndTimeValue(
          moment(props.prefillData.endRecurrence).format('hh:mm a')
        );
      } else {
        setEndTimeValue(undefined);
      }
      setRecurring(
        props.prefillData.rrule
          ? RRuleUtility.getRuleType(props.prefillData.rrule)
          : Frequency.never
      );

      if (
        props.prefillData.endRecurrence == null ||
        props.prefillData.endRecurrence == undefined
      ) {
        setEndRepeatDate(undefined);
      } else {
        setEndRepeatDate(new Date(props.prefillData.endRecurrence));
      }

      if (props.prefillData.reminders == undefined) {
        setReminders([Reminders.oneDayBefore, Reminders.none]);
      } else {
        props.prefillData.reminders == undefined
          ? setReminders([Reminders.oneDayBefore, Reminders.none])
          : setReminders(props.prefillData.reminders as unknown as Reminders[]);
      }
    } else {
      // set the default values of the add appointment form
      setStartDate(undefined);
      setMinSelectableDate(new Date());
      setStartDateHasBeenTouched(false);
      setStartTimeValue(undefined);
      setEndTimeValue(undefined);
      setEndRepeatDate(undefined);
      setRecurring(Frequency.never);
      setReminders([Reminders.oneDayBefore, Reminders.none]);
      setTitleIsValid(false);
      setStartTimeIsValid(false);
      setEndTimeIsValid(true);
    }
  }, [props.prefillData]);

  // update the min selectable date of the end recurrence calendar
  useEffect(() => {
    setMinSelectableDate(startDate);
  }, [startDate]);

  const convertInISO = (dateString: any, timeString: any) => {
      const dateTimeString = `${dateString} ${timeString}`;
      const isoDate = formatISO(new Date(dateTimeString))
      
      return isoDate;
  }
  
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    //validate the required fields
    if (
      titleIsValid &&
      startDateError == DatePickerErrors.none &&
      startTimeIsValid &&
      endTimeIsValid &&
      endRepeatDateError == DatePickerErrors.none
    ) {
      e.preventDefault();
      const form = e.currentTarget;
      const formElements = form.elements as typeof form.elements & {
        title: HTMLInputElement;
        location: HTMLInputElement;
        notes: HTMLInputElement;
        startDate: HTMLInputElement;
        startTime: HTMLInputElement;
        endTime: HTMLInputElement;
        repeat: HTMLInputElement;
        endDate: HTMLInputElement;
        reminder1: HTMLInputElement;
        reminder2: HTMLInputElement;
      };
      const filterdReminders = reminders.filter(value => value >= 0); //remove all none entries (-1)
      const body: ReturnBody = {
        title: formElements.title.value.trim(),
        location: formElements.title.value ? formElements.location.value : '',
        notes: formElements.notes.value ? formElements.notes.value : '',
        // startDateTime: moment(
        //   formElements.startDate.value + ' ' + formElements.startTime.value,
        //   'ddd MMM D gggg hh:mm a'
        // ).toISOString(),

        /* endRecurrence references the end of the appointment time, if specified, and endDateTime
           references the end of the recurrence period if the appointment repeats.
        */
        // endRecurrence: endTimeValue
        //   ? moment(
        //       formElements: any.startDate.value + ' ' + formElements.endTime.value,
        //       'ddd MMM D gggg hh:mm a'
        //     ).toISOString()
        //   : null,

        startDateTime: convertInISO(formElements.startDate.value, formElements.startTime.value),
        endRecurrence: endTimeValue
          ? convertInISO(formElements.startDate.value, formElements.endTime.value) : null,
        remindersActive: filterdReminders.length > 0,
      };

      if (rrule !== '') {
        body.rrule = rrule;
      }
      if (endRepeatDate) {
        body.endDateTime = moment(endRepeatDate).toISOString();
      }
      if (filterdReminders.length > 0) {
        body.reminders = filterdReminders;
      }
      if (props.prefillData) {
        body.id = props.prefillData.id;
        body.version = props.prefillData.version++;
      }

      setIsSubmitting(true);
      // if this is the edit version, use the update call
      if (props.prefillData) {
        axios
          .put(Endpoints.appointment(props.patientId), body)
          .then(() => {
            SuccessToast('', getString('toastrMessages.types.success'));
            props.refreshTable?.();
            props.setModalIsOpen(false);
          })
          .catch(error => {
            ErrorToast(error);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } else {
        axios
          .post(Endpoints.appointment(props.patientId), body)
          .then(() => {
            SuccessToast('', getString('toastrMessages.types.success'));
            props.refreshTable?.();
            props.setModalIsOpen(false);
          })
          .catch(error => {
            ErrorToast(error);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    } else {
      ErrorToast(getString('toastrMessages.common.pleaseFillRequiredFields'));
    }
    return false;
  };

  /**
   * Custom validator for appointment start date
   *
   * - required
   * - must be after the current time.
   * - must be before the appointment end recurrence date.
   * - In edit mode: appointment start date has to be before the original.
   *
   */
  useEffect(() => {
    if (startDate?.toString() == '') {
      setStartDateError(DatePickerErrors.required);
    } else if (
      props.prefillData &&
      moment(startDate).isBefore(
        moment(props.prefillData?.startDateTime).startOf('day')
      )
    ) {
      setStartDateError(DatePickerErrors.changeApppointmentStartDate);
    } else if (
      !props.prefillData &&
      moment(startDate).isBefore(moment().startOf('day'))
    ) {
      setStartDateError(DatePickerErrors.appointmentStartDateInThePast);
    } else {
      setStartDateError(DatePickerErrors.none);
    }
  }, [startDate, props.prefillData]);

  /**
   * Customer validator for appointment end recurrence date
   * - must be on or after appointment start date
   * - In edit mode: must be before current
   */
  useEffect(() => {
    if (endRepeatDate == undefined) {
      return;
    }

    if (
      moment(endRepeatDate)
        .startOf('D')
        .isBefore(moment(startDate).startOf('D'))
    ) {
      setEndRepeatDateError(DatePickerErrors.invalidAppointmentEndDate);
      return;
    }
    if (props.prefillData) {
      if (moment(endRepeatDate).isBefore(moment().startOf('D'))) {
        setEndRepeatDateError(DatePickerErrors.appointmentEndDateInThePast);
        return;
      }
    }
    setEndRepeatDateError(DatePickerErrors.none);
  }, [startDate, endRepeatDate, props.prefillData]);

  // Validate the startDate picker if it was prefilled
  useEffect(() => {
    if (
      (startDate?.toString() == '' || startDate == undefined) &&
      startDateHasBeenTouched
    ) {
      setStartDateError(DatePickerErrors.required);
    } else if (
      props.prefillData &&
      moment(startDate).isBefore(
        moment(props.prefillData?.startDateTime).startOf('D')
      )
    ) {
      setStartDateError(DatePickerErrors.changeApppointmentStartDate);
    } else if (
      !props.prefillData &&
      moment(startDate).isBefore(moment().startOf('D'))
    ) {
      setStartDateError(DatePickerErrors.appointmentStartDateInThePast);
    } else {
      setStartDateError(DatePickerErrors.none);
    }
  }, [startDate, props.prefillData, startDateHasBeenTouched]);

  //update the reminder rule when the reminder changes
  useEffect(() => {
    switch (recurring) {
      case Frequency.never:
        setRRule('');
        break;
      case Frequency.daily:
        setRRule(RRuleUtility.rruleDaily());
        break;
      case Frequency.weekly:
        setRRule(RRuleUtility.rruleWeekly());
        break;
      case Frequency.twoweekly:
        setRRule(RRuleUtility.rruleTwoWeekly());
        break;
      case Frequency.monthly:
        setRRule(RRuleUtility.rruleMonthly());
        break;
      case Frequency.yearly:
        setRRule(RRuleUtility.rruleYearly());
        break;
      case Frequency.weekdays:
        setRRule(RRuleUtility.rruleWeekdays());
        break;
      default:
        setRRule('');
    }
  }, [recurring]);

  const onStartDateBlur = () => {
    setStartDateHasBeenTouched(true);
  };

  return (
    <BaseModal
      onCloseButtonClicked={() => props.onCloseButtonClicked?.()}
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      modalWidth={theme.modalWidths.lg}>
      <ModalTitle>
        {getString(
          `appointmentModalComponent.title.${
            props.prefillData ? 'editAppointment' : 'addAppointment'
          }`
        )}
      </ModalTitle>
      <InputForm onSubmit={handleSubmit}>
        <InputRow>
          <InputLabelContainer>
            <InputLabel htmlFor='input-title'>
              {getString('form.label.title')}
            </InputLabel>
          </InputLabelContainer>
          <InputContainer>
            <LabeledInput
              setInputIsValid={(isValid: boolean) => setTitleIsValid(isValid)}
              valueOverride={props.prefillData ? props.prefillData.title : ''}
              includeLabel={false}
              name='title'
              labelText='title'
              isRequired={true}
              matchPattern={true}
              inputPlaceholderText={getString(
                'appointmentModalComponent.placeholders.title'
              )}
              onClick={() => gaEvent('title')}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputLabelContainer>
            <InputLabel htmlFor='input-location'>
              {getString('form.label.location')}
            </InputLabel>
            <LabelSubheading>
              {getString('form.label.optional')}
            </LabelSubheading>
          </InputLabelContainer>
          <InputContainer>
            <LabeledInput
              includeLabel={false}
              name='location'
              labelText='location'
              isRequired={false}
              inputPlaceholderText={getString(
                'appointmentModalComponent.placeholders.location'
              )}
              valueOverride={
                props.prefillData ? props.prefillData.location : ''
              }
              invalidOnEmpty={false}
              onClick={() => gaEvent('location')}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputLabelContainer>
            <InputLabel htmlFor='input-notes'>
              {getString('form.label.notes')}
            </InputLabel>
            <LabelSubheading>
              {getString('form.label.optional')}
            </LabelSubheading>
          </InputLabelContainer>
          <InputContainer>
            <LabeledInput
              includeLabel={false}
              name='notes'
              labelText='notes'
              isRequired={false}
              inputPlaceholderText={getString(
                'appointmentModalComponent.placeholders.notes'
              )}
              invalidOnEmpty={false}
              valueOverride={props.prefillData ? props.prefillData.notes : ''}
              onClick={() => gaEvent('notes')}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputLabelContainer>
            <InputLabel htmlFor='date'>
              {getString('form.label.date')}
            </InputLabel>
          </InputLabelContainer>
          <InputContainer>
            <ErrorContainer>
              <DatePicker
                id='date'
                name='startDate'
                className={`datepicker ${
                  startDateError == DatePickerErrors.none ? '' : 'invalid'
                }`}
                value={
                  startDate ? format(startDate, 'E LLL d y') : 'MM/DD/YYYY'
                }
                selected={startDate}
                onChange={date => setStartDate(date ?? minSelectableDate)}
                onBlur={onStartDateBlur}
                minDate={moment().toDate()} // min date is always today
                onInputClick={() => gaEvent('appointment_start_date')}
              />
              {startDateError == DatePickerErrors.none ? null : (
                <ErrorText>
                  {getString(
                    `controlMessageComponent.message.${startDateError.valueOf()}`
                  )}
                </ErrorText>
              )}
            </ErrorContainer>
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputLabelContainer>
            <InputLabel htmlFor='startTime'>
              {getString('form.label.time')}
            </InputLabel>
          </InputLabelContainer>
          <InputContainer>
            <TimeContainer>
              <TimeInput
                id='startTime'
                name='startTime'
                startDateValue={startDate}
                isEndtime={false}
                defaultValue={
                  props.prefillData
                    ? moment(props.prefillData.startDateTime).format('hh:mm a')
                    : ''
                }
                setIsValid={isValid => setStartTimeIsValid(isValid)}
                valueCallback={value => setStartTimeValue(value)}
                onClick={() => gaEvent('time_picker_start')}
              />
              <TimeInput
                name='endTime'
                startDateValue={startDate}
                setIsValid={isValid => setEndTimeIsValid(isValid)}
                isEndtime={true}
                defaultValue={
                  props.prefillData?.endRecurrence
                    ? moment(props.prefillData.endRecurrence).format('hh:mm a')
                    : undefined
                }
                startTimeValue={startTimeValue}
                valueCallback={value => setEndTimeValue(value)}
                onClick={() => gaEvent('time_picker_end')}
              />
            </TimeContainer>
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputLabelContainer>
            <InputLabel htmlFor='recurring'>
              {getString('form.label.repeat')}
            </InputLabel>
          </InputLabelContainer>
          <InputContainer>
            <RecurringSelect
              id='recurring'
              name='recurring'
              data-testid='repeat-occurence'
              value={recurring}
              onChange={e => setRecurring(+e.target.value as Frequency)}
              className={recurring === Frequency.never ? 'never' : ''}
              onClick={() => gaEvent('repeat')}>
              <option value={Frequency.never}>
                {getString('form.dropdown.never')}
              </option>
              <option value={Frequency.daily}>
                {getString('form.dropdown.everyDay')}
              </option>
              <option value={Frequency.weekly}>
                {getString('form.dropdown.everyWeek')}
              </option>
              <option value={Frequency.twoweekly}>
                {getString('form.dropdown.every2Weeks')}
              </option>
              <option value={Frequency.monthly}>
                {getString('form.dropdown.everyMonth')}
              </option>
              <option value={Frequency.yearly}>
                {getString('form.dropdown.everyYear')}
              </option>
              <option value={Frequency.weekdays}>
                {getString('form.dropdown.everyWeekday')}
              </option>
            </RecurringSelect>
            {recurring !== Frequency.never ? (
              <ErrorContainer>
                <DatePicker
                  name='recurringDate'
                  id='end-date'
                  className={`datepicker ${
                    endRepeatDateError == DatePickerErrors.none ? '' : 'invalid'
                  }`}
                  value={
                    endRepeatDate
                      ? format(endRepeatDate, 'E LLL d y')
                      : 'MM/DD/YYYY'
                  }
                  selected={endRepeatDate}
                  placeholderText={getString(
                    'appointmentModalComponent.placeholder.endRepeatDate'
                  )}
                  onChange={date => setEndRepeatDate(date ?? minSelectableDate)}
                  minDate={minSelectableDate}
                  onInputClick={() => gaEvent('end_appointment_date')}
                />
                {endRepeatDateError == DatePickerErrors.none ? null : (
                  <ErrorText id='end-date-error'>
                    {getString(
                      `controlMessageComponent.message.${endRepeatDateError.valueOf()}`
                    )}
                  </ErrorText>
                )}
              </ErrorContainer>
            ) : null}
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputLabelContainer>
            <InputLabel htmlFor='reminder-1'>
              {getString('form.label.reminders')}
            </InputLabel>
          </InputLabelContainer>
          <InputContainer>
            <RemindersComponent
              id='reminder-1'
              data-testid='reminder-1'
              name='firstReminder'
              className={reminders[0] == Reminders.none ? 'none' : ''}
              value={reminders[0]}
              onChange={e => setReminders([+e.target.value, reminders[1]])}
              onClick={() => gaEvent('reminder-1')}>
              <option value={Reminders.none}>
                {getString(
                  'appointmentModalComponent.dropdownValues.noReminder'
                )}
              </option>
              <option value={Reminders.atTimeOfEvent}>
                {getString('appointmentModalComponent.dropdownValues.atTimeOf')}
              </option>
              <option value={Reminders.fiveMinutesBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder5Mins'
                )}
              </option>
              <option value={Reminders.tenMinutesBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder10Mins'
                )}
              </option>
              <option value={Reminders.fifteenMinutesBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder15Mins'
                )}
              </option>
              <option value={Reminders.thirtyMinutesBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder30Mins'
                )}
              </option>
              <option value={Reminders.oneHourBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder1Hour'
                )}
              </option>
              <option value={Reminders.twoHoursBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder2Hours'
                )}
              </option>
              <option value={Reminders.oneDayBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder1Day'
                )}
              </option>
              <option value={Reminders.twoDaysBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder2Days'
                )}
              </option>
              <option value={Reminders.oneWeekBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder1Week'
                )}
              </option>
            </RemindersComponent>
            <RemindersComponent
              id='reminder-2'
              data-testid='reminder-2'
              name='secondReminder'
              className={reminders[1] == Reminders.none ? 'none' : ''}
              value={reminders[1]}
              placeholder='Second Reminder'
              onChange={e => setReminders([reminders[0], +e.target.value])}
              onClick={() => gaEvent('second_reminder')}>
              <option value={Reminders.none}>
                {getString(
                  'appointmentModalComponent.dropdownValues.noReminder'
                )}
              </option>
              <option value={Reminders.atTimeOfEvent}>
                {getString('appointmentModalComponent.dropdownValues.atTimeOf')}
              </option>
              <option value={Reminders.fiveMinutesBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder5Mins'
                )}
              </option>
              <option value={Reminders.tenMinutesBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder10Mins'
                )}
              </option>
              <option value={Reminders.fifteenMinutesBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder15Mins'
                )}
              </option>
              <option value={Reminders.thirtyMinutesBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder30Mins'
                )}
              </option>
              <option value={Reminders.oneHourBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder1Hour'
                )}
              </option>
              <option value={Reminders.twoHoursBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder2Hours'
                )}
              </option>
              <option value={Reminders.oneDayBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder1Day'
                )}
              </option>
              <option value={Reminders.twoDaysBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder2Days'
                )}
              </option>
              <option value={Reminders.oneWeekBefore}>
                {getString(
                  'appointmentModalComponent.dropdownValues.reminder1Week'
                )}
              </option>
            </RemindersComponent>
          </InputContainer>
        </InputRow>
        <InputRow>
          <SubmitButton
            data-testid='submit'
            type='submit'
            disabled={isSubmitting}
            onClick={() => gaEvent('save')}>
            {getString('form.button.save')}
          </SubmitButton>
        </InputRow>
      </InputForm>
    </BaseModal>
  );
};

export { AddAppointmentModal };
