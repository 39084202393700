import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Endpoints } from '../../api/endpoints';
import { getString } from '../../context/UserContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorToast } from '../shared/ToastrNotifications';
import { AuthFormPage } from './../auth/AuthFormPage';
import LegalDocsModal from './modals/LegalDocsModal';
import { TextField } from '@mui/material';

const AuthForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 250px;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 700;
  text-transform: uppercase;
  margin: 0px;
  .email {
    font-size: 18px;
    font-weight: 500;
  }
  .not-you {
    cursor: pointer;
  }
`;

const InstructionsText = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 400;
  padding: 0px 15px;
  line-height: 1.5;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  border-radius: 21px;
  display: block;
  font-weight: 900;
  font-size: medium;
  height: 42px;
  width: 'auto';
  margin: auto;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;
  &:disabled {
    opacity: 0.5;
  }
`;

const SubmitButton = styled(Button)`
  background-color: ${props => props.theme.colors.pacificBlue};
  border: 1px solid ${props => props.theme.colors.pacificBlue};
  color: ${props => props.theme.colors.white};
  &:hover {
    background-color: ${props => props.theme.colors.cerulean};
  }
`;

const Label = styled.label`
  font-weight: 700;
  text-transform: uppercase;
  color: ${props => props.theme.colors.charcoal};
  text-align: left;
  width: 100%;
  margin: auto;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 25px;
  width: 90%;
  margin: 4px 0 20px;

  .MuiFormControl-root {
    width: 100%;
  }
`;

const Input = styled(TextField)`
  && {
    .MuiInputBase-root {
      border: 1px solid ${props => props.theme.colors.mystic};
      border-radius: 4px;
      color: ${props => props.theme.colors.charcoal};
      font-weight: 600;
      font-family: 'Brandon';
      font-size: 16px;
      height: 45px;
      padding: 10px;
      margin: auto;
      width: 100%;
      transition: border-color 0.3s;
      input {
        padding: 0;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border: none;
    }

    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${props => props.theme.colors.curiousBlue};
        box-shadow: none;
      }
    }

    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${props => props.theme.colors.mangoTango};
        border: 1px solid ${props => props.theme.colors.mangoTango};
      }
    }
  }
`;

const InputErrorText = styled.p`
  font-family: Brandon;
  font-size: 16px;
  color: ${props => props.theme.colors.mangoTango};
  font-weight: 400;
  padding: 0px 15px;
  margin: auto;
  margin-bottom: 16px;
  text-align: center;
`;

interface Document {
  docType: number;
  version: string;
  dateAccepted: Date;
}

const HCPActivation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email] = useState(searchParams.get('email'));
  const [activationKey] = useState(searchParams.get('activationKey'));
  const [isValidKey, setIsValidKey] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentsAccepted, setDocumentsAccepted] = useState<Document[]>([]);
  const [incorrectUser, setIncorrectUser] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  useEffect(() => {
    if (activationKey) {
      axios
        .post(Endpoints.verifyStaff, { activationKey: activationKey })
        .then(result => {
          const docInfo = result.data.documentInfo;
          const dateAccepted = new Date();
          for (const document in docInfo) {
            if (document) {
              const documentAccepted = {
                docType: docInfo[document].docType,
                version: docInfo[document].version,
                dateAccepted: dateAccepted,
              };
              setDocumentsAccepted(prev => [...prev, documentAccepted]);
            }
          }
          setIsValidKey(true);
          setIsOpen(true);
        })
        .catch(() => {
          ErrorToast(
            getString(
              'toastrMessages.hcpActivationComponent.passwordLinkExpired'
            )
          );
        });
    }
  }, [activationKey]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      ErrorToast(
        getString(
          'toastrMessages.hcpActivationComponent.fillAndConfirmPassword'
        )
      );
      return;
    }

    const form = e.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      password: HTMLInputElement;
      confirmPassword: HTMLInputElement;
    };

    if (
      formElements.password.value.trim() !==
      formElements.confirmPassword.value.trim()
    ) {
      ErrorToast(
        getString(
          'toastrMessages.hcpActivationComponent.fillAndConfirmPassword'
        )
      );
      return;
    }
    setIsLoading(true);
    const body = {
      password: formElements.password.value.trim(),
      activationKey: activationKey,
      documentsAccepted: documentsAccepted,
    };

    axios
      .post(Endpoints.setPassword, body)
      .then(() => navigate('/login'))
      .catch(error => ErrorToast(error))
      .finally(() => setIsLoading(false));
  };

  const nullifyActivationKey = () => {
    axios
      .post(Endpoints.nullifyActivationKey, {
        activationKey: activationKey,
      })
      .then(() => setIncorrectUser(true))
      .catch(error => ErrorToast(error));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateInput(e);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    validateInput(e);
  };

  const validateInput = (
    e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value.trim();

    switch (name) {
      case 'password':
        setPassword(value);
        if (!value) {
          setPasswordError(
            getString('controlMessageComponent.message.required')
          );
        } else if (
          !/(?!^[0-9]*$)(?!^[a-z]*$)(?!^[A-Z]*$)(?!^[\W]*$)(^[a-zA-Z0-9\W]).{7,}/.test(
            value
          )
        ) {
          setPasswordError(
            getString('controlMessageComponent.message.invalidPassword')
          );
        } else {
          setPasswordError('');
        }
        break;

      case 'confirmPassword':
        setConfirmPassword(value);
        if (!value) {
          setConfirmPasswordError(
            getString('controlMessageComponent.message.required')
          );
        } else if (password !== value) {
          setConfirmPasswordError(
            getString(
              'controlMessageComponent.message.incorrectConfirmPassword'
            )
          );
        } else {
          setConfirmPasswordError('');
        }
        break;

      default:
        break;
    }
  };

  return (
    <AuthFormPage>
      {!isValidKey && !incorrectUser && (
        <AuthForm>
          <Title>
            {getString('hcpActivationComponent.titles.linkExpired')}
          </Title>
        </AuthForm>
      )}
      {isValidKey && !incorrectUser && (
        <>
          <LegalDocsModal modalIsOpen={isOpen} setModalIsOpen={setIsOpen} />
          <AuthForm
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}>
            <Title>
              {getString('hcpActivationComponent.titles.validLink')}
              <div className='email'>{email}</div>
              <div
                className='not-you'
                role='button'
                aria-hidden='true'
                tabIndex={0}
                onClick={nullifyActivationKey}>
                {getString('hcpActivationComponent.titles.notYourEmail')}
              </div>
            </Title>
            <InstructionsText>
              {getString('hcpActivationComponent.instructions.newPassword')}
            </InstructionsText>
            <InputRow>
              <div>
                <Label htmlFor='password'>
                  {getString('form.label.createPassword')}
                </Label>
              </div>
              <div>
                <Input
                  name='password'
                  type='password'
                  error={passwordError ? true : false}
                  required
                  id='password'
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                />
                {passwordError && (
                  <InputErrorText>{passwordError}</InputErrorText>
                )}
              </div>
            </InputRow>
            <InputRow>
              <div>
                <Label htmlFor='confirmPassword'>
                  {getString('form.label.confirmPassword')}
                </Label>
              </div>
              <div>
                <Input
                  name='confirmPassword'
                  type='password'
                  error={confirmPasswordError ? true : false}
                  required
                  id='confirmPassword'
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                />
                {confirmPasswordError && (
                  <InputErrorText>{confirmPasswordError}</InputErrorText>
                )}
              </div>
            </InputRow>
            <ButtonContainer>
              <SubmitButton disabled={isLoading}>
                {getString('form.button.activate')}
              </SubmitButton>
            </ButtonContainer>
          </AuthForm>
        </>
      )}
    </AuthFormPage>
  );
};

export default HCPActivation;
