import axios from 'axios';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Endpoints from '../../api/endpoints';

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.charcoal};
  padding: 18px;
`;

const FooterContent = styled.div`
  margin: auto;
  width: 75%;
  p {
    margin-bottom: 10px;
    text-align: center;
  }
`;

const LegalLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
  div {
    display: flex;
    gap: 0 4px;
  }
  a {
    color: ${props => props.theme.colors.white};
    text-decoration: underline;
  }
  div:last-child > span {
    display: none;
  }
`;

interface LegalLink {
  title: string;
  url: string;
  key?: string;
  docType?: number;
}

export const Footer = () => {
  // Hard preset the values
  const [links, setLinks] = useState<LegalLink[]>([
    {
      title: 'C4T Portal Terms of Use',
      url: 'https://itx-bax-contenthub.s3.amazonaws.com/c4t-portal/test/C4T_Portal_Terms_of_Use_en.html',
    },
    {
      title: 'C4T Portal Privacy Policy',
      url: 'https://itx-bax-contenthub.s3.amazonaws.com/c4t-portal/test/C4T_Portal_Privacy_Policy_en.html',
    },
    {
      title: 'Important HCP Portal Information',
      url: 'https://itx-bax-contenthub.s3.amazonaws.com/c4t-portal/test/Important_HCP_Portal_Information_en.html',
    },
    {
      title: 'C4T Portal Support',
      url: 'https://itx-bax-contenthub.s3.amazonaws.com/c4t-portal/test/C4T_Portal_Support_en.html',
    },
  ]);

  useEffect(() => {
    axios.get(Endpoints.portalFooterLinks).then(response => {
      if (response.data) {
        setLinks(response.data.legalDocuments);
      }
    });
  }, []);

  const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  return (
    <FooterContainer>
      <FooterContent>
        <LegalLinksContainer>
          {links.map(item => (
            <div key={item.url}>
              <a href={item.url} target='_blank' rel='noreferrer'>
                {item.title}
              </a>
              <span>•</span>
            </div>
          ))}
        </LegalLinksContainer>
        <p>
          © Johnson & Johnson Services, Inc. {getCurrentYear()}. All Rights Reserved. This
          site is published by Johnson & Johnson Services, Inc. {getCurrentYear()}, which is
          solely responsible for its contents. Please read our Important HCP
          Portal Information, Privacy Policy and Terms of Use. This site is
          intended for use by residents of the United States. All trademarks
          used herein are registered trademarks of their respective owners. This
          page was last modified in November 2023.
        </p>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
