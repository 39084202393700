import { useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  IconButton,
  TextField,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import axios from 'axios';
import { format } from 'date-fns';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router';
import styled from 'styled-components';
import Endpoints from '../../../api/endpoints';
import infoImage from '../../../assets/images/info_med.svg';
import add from '../../../assets/images/medication/add.svg';
import placeholderImage from '../../../assets/images/medication/placeholder.svg';
import remove from '../../../assets/images/medication/remove.svg';
import { BuildParams } from '../../../context/BuildParams';
import { getString } from '../../../context/UserContext';
import { DrugDetail, Medication } from '../../../models';
import { logGAEvent } from '../../../shared/services/googleAnalyticsService';
import { RRuleUtility } from '../../../util/rruleUtil';
import DrugNamesAutoComplete from '../../shared/DrugNamesAutoComplete';
import LabeledInput from '../../shared/LabeledInput';
import { ErrorToast, SuccessToast } from '../../shared/ToastrNotifications';
import { GenericButton } from '../../shared/modals/BaseModalStyle';
import { CustomFrequencyModal } from './CustomFrequencyModal';

const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 640px;
  }
`;

const ModalTitle = styled(DialogTitle)`
  && {
    color: ${props => props.theme.colors.charcoal};
    font-weight: 900;
    text-transform: uppercase;
    font-size: 20px;
    margin: 20px 0 0;
    letter-spacing: 2.73px;
    font-family: 'Brandon';
    text-align: center;
  }
`;

const CustomIconButton = styled(IconButton)`
  && {
    position: absolute;
    top: 8px;
    right: 8px;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    font-weight: 700;
    font-family: inherit;
    opacity: 0.4;

    &:hover {
      background: none;
      opacity: 0.7;
    }
  }
`;

const ModalInstructions = styled.div`
  color: ${props => props.theme.colors.charcoal};
  font-size: 16px;
  margin: 0 0 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  padding: 10px 0 20px;
  gap: 16px;
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    flex-direction: column;
    text-align: right;
  }
  &.drug {
    @media (max-width: ${props => props.theme.breakPoints.sm}) {
      flex-direction: row;
    }
  }
  &.br-top {
    border-top: 1px solid ${props => props.theme.colors.mystic};
  }
`;

const RowLable = styled.div`
  color: ${props => props.theme.colors.stoneGray};
  font-family: Brandon;
  font-size: 10px;
  font-weight: 700;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    grid-template-columns: 1fr;
  }
  span {
    text-align: left;
  }
`;

const ColLeft = styled.div`
  flex: 0.7;
  text-transform: uppercase;
  font-weight: 700;
  text-align: right;
  margin: auto;
  line-height: 1.2;
  color: ${props => props.theme.colors.charcoal};
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    margin: 0;
  }
  p {
    color: ${props => props.theme.colors.loblolly};
    margin: 0;
  }
  span.icon {
    left: -60px;
    top: -95%;
  }
  span.icon::after {
    transform: rotate3d(1, 0, 12, 180deg);
    top: 100%;
  }
  &.flex-col {
    display: flex;
    gap: 40px;
    flex-direction: row;
    justify-content: end;
    div:first-child {
      display: flex;
      gap: 8px;
    }
  }
`;

const ColRight = styled.div`
  flex: 2;
  text-align: left;
  display: flex;
  gap: 32px;
  &.column {
    flex-direction: column;
    gap: 0;
  }
  &.dose-col {
    @media (max-width: ${props => props.theme.breakPoints.sm}) {
      gap: 0;
      display: block;
    }
  }
  &.block {
    display: block;
  }
  &.half-width {
    width: 50%;
    div {
      margin: 0;
      width: inherit;
      input {
        margin: 0;
      }
    }
  }
`;

const InfoIcon = styled.span`
  background: url(${infoImage}) no-repeat;
  z-index: 1;
  opacity: 1;
  margin: 40px 0 0 -16px;
  height: 20px;
  width: 24px;
`;

const StyledTooltip = muiStyled(({ className, ...props }: TooltipProps) => (
  <Tooltip placement={'top'} {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    font-size: 14px;
    font-family: Brandon;
    line-height: 1.5;
    letter-spacing: 1px;
    font-weight: 400;
  }
`;

const Input = styled(TextField)`
  border: 1px solid ${props => props.theme.colors.mystic};
  background-color: ${props => props.theme.colors.white};
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  font-family: 'Brandon';
  & .half {
    width: 50%;
  }
  & .MuiInputBase-input {
    height: 12px;
    color: ${props => props.theme.colors.charcoal};
    font-weight: 600;
    font-size: 16px;
    font-family: 'Brandon';
  }

  .MuiOutlinedInput-root {
    fieldset {
      border: 1px solid ${props => props.theme.colors.mystic};
      border-radius: 4px;
    }
    &:hover fieldset {
      border: 1px solid ${props => props.theme.colors.curiousBlue};
    }
    &.Mui-focused fieldset {
      border: 1px solid ${props => props.theme.colors.curiousBlue};
    }
    &.Mui-error fieldset {
      border-color: ${props => props.theme.colors.mangoTango};
      &:hover {
        border: 1px solid ${props => props.theme.colors.curiousBlue};
      }
    }
  }

  .MuiFormHelperText-root.Mui-error {
    color: ${props => props.theme.colors.mangoTango};
    background-color: ${props => props.theme.colors.concrete};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    font-family: 'Brandon';
  }

  &.half {
    width: 48%;
  }

  &.time {
    width: 49%;
    background: transparent;
    input {
      background-color: ${props => props.theme.colors.white};
      border-radius: 4px;
    }
    @media (max-width: ${props => props.theme.breakPoints.sm}) {
      width: 100%;
    }
  }
`;

const Separator = styled.div`
  background-color: ${props => props.theme.colors.concrete};
  margin: 0 -24px;
  ul {
    background-color: ${props => props.theme.colors.charcoal};
    color: ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => props.theme.colors.concrete};
    list-style: none;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'Brandon';
    font-weight: 700;
    li:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  div.content {
    margin: 0 24px;
  }
`;

const ButtonContainer = styled(DialogActions)`
  && {
    border-top: 1px solid ${props => props.theme.colors.mystic};
    background-color: ${props => props.theme.colors.concrete};
    text-align: center;
    margin-top: -20px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-size: 14px;
  height: 46px;
  width: 50%;
  padding: 10px;
  background-color: ${props => props.theme.colors.aliceBlue};
  align-self: flex-start;

  &.invalid,
  &.invalid:hover,
  &.invalid:focus {
    border: 1px solid ${props => props.theme.colors.mangoTango};
  }

  &:hover,
  &:focus {
    border: 1px solid ${props => props.theme.colors.curiousBlue};
  }

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    width: 100%;
  }

  &.half-size {
    width: 50%;
  }
`;

const HelperText = styled(FormHelperText)`
  && {
    color: ${props => props.theme.colors.mangoTango};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    font-family: 'Brandon';
  }
`;

const FormLabel = styled(FormControlLabel)`
  width: max-content;
  span.MuiFormControlLabel-label {
    font-family: 'Brandon';
    color: ${props => props.theme.colors.charcoal};
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const DateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  input {
    color: ${props => props.theme.colors.charcoal};
    border: 1px solid ${props => props.theme.colors.mystic};
    border-radius: 4px;
    font-weight: 500;
    height: 20px;
    padding: 10px;
    margin: 0 6px 0 0;
    &:focus,
    &:active,
    &:hover {
      border-radius: 4px;
      border: 1px solid ${props => props.theme.colors.curiousBlue};
    }
    &.error {
      border-radius: 4px;
      border: 1px solid ${props => props.theme.colors.mangoTango};
    }
  }

  .react-datepicker__day--outside-month {
    visibility: hidden !important;
  }
`;

const InputForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const enum Frequency {
  none = '',
  asneeded = 0,
  daily = 1,
  weekly = 2,
  twoweekly = 3,
  monthly = 4,
  yearly = 5,
  weekdays = 6,
  custom = 7,
}

interface ManageMedicationProps {
  setModalIsOpen: (isOpen: boolean) => void;
  refreshTable?: () => void;
  modalIsOpen: boolean;
  medication?: Medication;
}

interface DoseProps {
  time: string | undefined;
  quantity: string | undefined;
  quantityPart: string | undefined;
}

interface ReturnDoseBody {
  quantity: number;
  rrule: string;
  startTime: string;
  unit: string;
}

interface ReturnBody {
  id?: string;
  version?: number | string;
  name: string;
  asNeeded: boolean;
  nickName?: string;
  genericName?: string;
  reminderMessage?: string;
  remindUser: boolean;
  image?: string;
  drugID?: number;
  inUse: boolean;
  route?: string;
  strength?: string;
  form?: string;
  rrule: string; // recurrence rule
  specialInstructions?: string;
  dose?: ReturnDoseBody[];
  startTime: string; // start time of appt in ISO
  endTime?: string | null; // end time of appt in ISO
  // [key: string]: any;
}

const ManagemedicationModal = (props: ManageMedicationProps) => {
  const { id } = useParams();
  const [patientId] = useState(() => parseInt(id || ''));
  const [nickName, setNickName] = useState(props?.medication?.nickName || '');
  const [rrule, setRRule] = useState(props?.medication?.rrule || '');
  const [frequency, setFrequency] = useState(Frequency.none);
  const [specialInstructions, setSpecialInstructions] = useState(
    props?.medication?.specialInstructions || ''
  );
  const [startTime, setStartTime] = useState(
    props?.medication?.startTime
      ? new Date(props.medication.startTime)
      : undefined
  );
  const [endTime, setEndTime] = useState(
    props?.medication?.endTime ? new Date(props.medication.endTime) : undefined
  );
  const [enableEndTime, setEnableEndTime] = useState(
    props?.medication?.endTime ? true : false
  );
  const [occurance, setOccurance] = useState('');
  const [occuranceType, setOccuranceType] = useState('');
  const [occuranceDays, setOccuranceDays] = useState<string[]>([]);
  const [rruleText, setRRuleText] = useState('');
  const [reminderMessage, setReminderMessage] = useState(
    getString('medicationModalComponent.placeholder.medicationReminderMessage')
  );
  const [doses, setDoses] = useState<DoseProps[]>([
    { time: undefined, quantity: undefined, quantityPart: undefined },
  ]);
  const [invalidStartDate, setInvalidStartDate] = useState(false);
  const [invalidEndDate, setInvalidEndDate] = useState(false);
  const [invalidFrequecy, setInvalidFrequecy] = useState(false);
  const [openCustomFrequecyModel, setOpenCustomFrequecyModel] = useState(false);
  const [drugDetail, setDrugDetail] = useState<DrugDetail | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const displayFormat = 'EEE MMM dd yyyy';

  useEffect(() => {
    setNickName(props?.medication?.nickName || '');
    setRRule(props?.medication?.rrule || '');
    setSpecialInstructions(props?.medication?.specialInstructions || '');
    setStartTime(
      props?.medication?.startTime
        ? new Date(props.medication.startTime)
        : undefined
    );
    setEndTime(
      props?.medication?.endTime
        ? new Date(props.medication.endTime)
        : undefined
    );
    setEnableEndTime(props?.medication?.endTime ? true : false);
    setOccurance('');
    setOccuranceType('');
    setOccuranceDays([]);
    setRRuleText(
      props?.medication?.rrule
        ? RRuleUtility.getText(props?.medication?.rrule)
        : ''
    );
    setReminderMessage(
      props?.medication?.reminderMessage ||
        getString(
          'medicationModalComponent.placeholder.medicationReminderMessage'
        )
    );

    const frequency = props?.medication?.asNeeded
      ? Frequency.asneeded
      : props?.medication?.rrule
        ? RRuleUtility.getRuleType(props.medication.rrule)
        : Frequency.none;
    setFrequency(frequency);

    let doseFrequency: DoseProps[] = [
      { time: undefined, quantity: undefined, quantityPart: undefined },
    ];
    if (props?.medication?.dose.length) {
      doseFrequency = props?.medication?.dose.map(dose => {
        const quantity = dose.quantity?.toString()?.split('.');
        return {
          time: moment(dose.startTime).format('HH:mm') || undefined,
          quantity: quantity && quantity.length > 0 ? quantity[0] : undefined,
          quantityPart:
            quantity && quantity.length > 1 ? `.${quantity[1]}` : undefined,
        };
      });
    }
    setDoses(doseFrequency);
  }, [props.medication]);

  useEffect(() => {
    setRRule(
      RRuleUtility.rruleCustom(
        Number(occurance),
        occuranceType || '',
        occuranceDays || []
      )
    );
  }, [occurance, occuranceDays, occuranceType]);

  useEffect(() => {
    setRRuleText('');
    if (rrule && Frequency.custom === frequency) {
      setRRuleText(RRuleUtility.getText(rrule));
    }

    if (!rrule && Frequency.custom === frequency) {
      setFrequency(Frequency.none);
      setInvalidFrequecy(true);
    }
  }, [rrule, frequency]);

  useEffect(() => {
    switch (frequency) {
      case Frequency.none:
        setRRule('');
        break;
      case Frequency.daily:
        setRRule(RRuleUtility.rruleDaily());
        break;
      case Frequency.weekly:
        setRRule(RRuleUtility.rruleWeekly());
        break;
      case Frequency.twoweekly:
        setRRule(RRuleUtility.rruleTwoWeekly());
        break;
      case Frequency.monthly:
        setRRule(RRuleUtility.rruleMonthly());
        break;
      case Frequency.yearly:
        setRRule(RRuleUtility.rruleYearly());
        break;
      case Frequency.weekdays:
        setRRule(RRuleUtility.rruleWeekdays());
        break;
      case Frequency.custom:
        setOpenCustomFrequecyModel(true);
        break;
      default:
        setRRule('');
    }
  }, [frequency]);

  const handleDoseInputChange = (
    e: {
      target: {
        value: string;
        name: string;
        required?: boolean;
        checked?: boolean;
      };
    },
    index: number
  ) => {
    setDoses(prevFormValue => {
      const updatedFormValue = [...prevFormValue];
      updatedFormValue[index] = {
        ...updatedFormValue[index],
        [e.target.name]: e.target.value,
      };
      return updatedFormValue;
    });
  };

  const handleRemoveDose = (index: number) => {
    setDoses(prevDoses => {
      const newDoses = [...prevDoses];
      newDoses.splice(index, 1);
      return newDoses;
    });
  };

  const handleAddDose = () => {
    setDoses(prevDoses => [
      ...prevDoses,
      { time: undefined, quantity: undefined, quantityPart: undefined },
    ]);
  };

  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    setDrugDetail(null);
    props.setModalIsOpen(false);
  };

  const googleAnalytics = (type: string, number?: number) => {
    switch (type) {
      case 'add_dose':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'medications',
          eventAct: 'click',
          eventLbl: 'add_dose_' + String(number),
        });
        break;
      case 'submit':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'medications',
          eventAct: 'click',
          eventLbl: 'save',
        });
        break;
      case 'close':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'medications',
          eventAct: 'click',
          eventLbl: 'close',
        });
        break;
      default:
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'medications',
          eventAct: 'click',
          eventLbl: 'add_' + type,
        });
        break;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hasEmptyValue = (array: any[], keys: any[]) => {
    return array.some(obj =>
      keys.some(key => obj[key] === '' || (key === 'quantity' && obj[key] < 1))
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      drugNames: HTMLInputElement;
      drugDetailsList: HTMLInputElement;
      nickname: HTMLInputElement;
      specialInstructions: HTMLInputElement;
      startDate: HTMLInputElement;
      endDate: HTMLInputElement;
      frequency: HTMLInputElement;
      reminderMessage: HTMLInputElement;
      time: HTMLInputElement;
      quantity: HTMLInputElement;
      quantityPart: HTMLInputElement;
      form: HTMLSelectElement;
      strength: HTMLInputElement;
      strengthUnits: HTMLSelectElement;
    };

    if (
      !formElements.drugNames.value ||
      (!formElements?.drugDetailsList?.value &&
        (!formElements?.form?.value ||
          !formElements?.strength?.value ||
          !formElements?.strengthUnits?.value)) ||
      !formElements.startDate.value ||
      (enableEndTime && !formElements?.endDate?.value) ||
      formElements.frequency.value === Frequency.none ||
      (frequency === Frequency.asneeded &&
        (!doses[0].quantity || +doses[0].quantity < 1)) ||
      (frequency !== Frequency.asneeded &&
        hasEmptyValue(doses, ['time', 'quantity', 'quantitypart']))
    ) {
      ErrorToast(
        getString(
          'toastrMessages.medicationModalComponent.pleaseCompleteTheForm'
        )
      );
      return false;
    }

    setIsDisabled(true);
    const dosesArr = doses.map(dose => {
      const quantity = Number(dose?.quantity) + Number(dose?.quantityPart || 0);
      let unitOrForm = '';
      if (!drugDetail && formElements?.form?.value) {
        if (
          [
            getString('medicationModalComponent.dropdown.capsule'),
            getString('medicationModalComponent.dropdown.tablet'),
          ].includes(formElements.form.value)
        ) {
          unitOrForm =
            quantity > 1
              ? formElements.form.value + 's'
              : formElements.form.value;
        } else {
          unitOrForm = quantity > 1 ? 'doses' : 'dose';
        }
      }
      return {
        quantity: quantity,
        rrule: rrule,
        startTime: moment(
          moment().format('YYYY-MM-DD') + ' ' + dose?.time,
          'YYYY-MM-DD hh:mm a'
        ).toISOString(),
        unit: unitOrForm,
      };
    });

    const body: ReturnBody = {
      name: formElements.drugNames.value.trim(),
      asNeeded:
        Number(formElements.frequency.value) === Frequency.asneeded
          ? true
          : false,
      nickName: formElements.nickname.value.trim(),
      genericName: drugDetail?.generic_name,
      reminderMessage: formElements?.reminderMessage?.value?.trim(),
      remindUser: true,
      image: drugDetail?.image?.url,
      drugID: drugDetail?.id,
      inUse: true,
      route: drugDetail?.route,
      strength: drugDetail
        ? drugDetail.strength
        : [
            formElements?.strength?.value,
            formElements?.strengthUnits?.value,
          ].join(''),
      form: drugDetail ? drugDetail.form_name : formElements?.form?.value,
      rrule: rrule,
      specialInstructions: formElements.specialInstructions.value.trim(),
      dose: dosesArr,
      startTime: moment(
        formElements.startDate.value,
        'ddd MMM D gggg hh:mm a'
      ).toISOString(),
    };

    if (formElements?.endDate?.value) {
      body.endTime = moment(
        formElements.endDate.value,
        'ddd MMM D gggg hh:mm a'
      ).toISOString();
    }

    if (props?.medication?.id) {
      const editBody = {
        id: props?.medication?.id,
        version: props?.medication?.version,
        rrule: rrule || props?.medication?.rrule,
      };

      axios
        .put(Endpoints.medication(patientId), { ...body, ...editBody })
        .then(() => {
          SuccessToast('', getString('toastrMessages.types.success'));
          props.refreshTable?.();
          handleClose();
        })
        .catch(error => {
          ErrorToast(error);
        })
        .finally(() => {
          setIsDisabled(false);
        });
    } else {
      axios
        .post(Endpoints.medication(patientId), body)
        .then(() => {
          SuccessToast('', getString('toastrMessages.types.success'));
          props.refreshTable?.();
          handleClose();
        })
        .catch(error => {
          ErrorToast(error);
        })
        .finally(() => {
          setIsDisabled(false);
        });
    }
  };

  const handleCustomFrequencyModel = (open: boolean) => {
    setOpenCustomFrequecyModel(open);
  };

  const updateCustomFrequency = (
    type: number,
    occurance: string,
    days?: string[]
  ) => {
    switch (type) {
      case 0:
        setOccurance(occurance);
        setOccuranceDays([]);
        setOccuranceType('daily');

        break;
      case 1:
        setOccurance(occurance);
        setOccuranceDays(days || []);
        setOccuranceType('weekly');
        break;
      default:
        return;
    }
  };

  return (
    <>
      <CustomFrequencyModal
        modalIsOpen={openCustomFrequecyModel}
        setModalIsOpen={handleCustomFrequencyModel}
        updateCustomFrequency={updateCustomFrequency}
      />
      <CustomDialog
        onClose={handleClose}
        aria-labelledby='manage-medication-dialog-title'
        open={props.modalIsOpen}
        disableEscapeKeyDown
        scroll={'body'}
        disablePortal
        fullWidth>
        <ModalTitle id='manage-medication-dialog-title'>
          {props?.medication?.id
            ? getString('medicationModalComponent.title.editMedication')
            : getString('medicationModalComponent.title.addMedication')}
        </ModalTitle>
        <CustomIconButton aria-label='close' onClick={handleClose}>
          x
        </CustomIconButton>
        <InputForm onSubmit={handleSubmit}>
          <DialogContent>
            <ModalInstructions>
              {getString('medicationModalComponent.title.disclaimer')}
            </ModalInstructions>
            <Row className='drug'>
              <ColLeft>
                <StyledTooltip
                  title={
                    <div>
                      {getString(
                        'medicationModalComponent.tooltip.medicationPhoto'
                      )}
                      <br />
                      {getString('medicationModalComponent.tooltip.care4Today')}
                      &reg;&nbsp;
                      {getString(
                        'medicationModalComponent.tooltip.doesNotValidate'
                      )}
                    </div>
                  }
                  arrow>
                  <IconButton
                    style={{
                      bottom: 0,
                      right: 0,
                      backgroundColor: 'transparent',
                    }}>
                    <img
                      src={drugDetail?.image?.url || placeholderImage}
                      alt='drug'
                      style={{
                        width: '90px',
                        height: '90px',
                        borderRadius: '50%',
                      }}
                    />
                    <InfoIcon />
                  </IconButton>
                </StyledTooltip>
              </ColLeft>
              <ColRight>
                <DrugNamesAutoComplete
                  placeholder={getString(
                    'medicationModalComponent.placeholder.medication'
                  )}
                  prefilledData={{
                    inputValue: props?.medication?.name,
                    title: props?.medication?.name || '',
                    genericName: props?.medication?.genericName,
                    form: props?.medication?.form,
                    strength: props?.medication?.strength,
                    drugID: props?.medication?.drugID,
                  }}
                  updateDrugDetail={setDrugDetail}
                />
              </ColRight>
            </Row>
            <Row>
              <ColLeft>
                <label htmlFor='nickname'>
                  {getString('medicationModalComponent.label.nickname')}
                </label>
                <p>{getString('form.label.optional')}</p>
              </ColLeft>
              <ColRight className='half-width'>
                <LabeledInput
                  name='nickname'
                  labelText='nickname'
                  includeLabel={false}
                  inputPlaceholderText={getString(
                    'medicationModalComponent.placeholder.nickname'
                  )}
                  valueOverride={nickName}
                  onClick={() => googleAnalytics('nick_name')}
                  isRequired={false}
                />
              </ColRight>
            </Row>

            <Row>
              <ColLeft>
                <label htmlFor='instructions'>
                  {getString('medicationModalComponent.label.instructions')}
                </label>
                <p>{getString('form.label.optional')}</p>
              </ColLeft>
              <ColRight>
                <Input
                  id='instructions'
                  name='specialInstructions'
                  variant='outlined'
                  placeholder={getString(
                    'medicationModalComponent.placeholder.specialInstructions'
                  )}
                  value={specialInstructions}
                  onClick={() => googleAnalytics('special_instructions')}
                  onChange={e => setSpecialInstructions(e.target.value)}
                />
              </ColRight>
            </Row>
            <Separator>
              <ul>
                <li>{getString('medicationModalComponent.label.schedule')}</li>
                {BuildParams.refill_alert_enabled && (
                  <li>
                    {getString('medicationModalComponent.label.refillAlert')}
                  </li>
                )}
              </ul>
              <div className='content'>
                <Row>
                  <ColLeft>
                    <label htmlFor='startDate'>
                      {getString('form.label.date')}
                    </label>
                  </ColLeft>
                  <ColRight className={enableEndTime ? 'column' : ''}>
                    <DateContainer>
                      <div>
                        <DatePicker
                          id='start-date'
                          name='startDate'
                          value={startTime && format(startTime, displayFormat)}
                          placeholderText='MM/DD/YYYY'
                          selected={startTime}
                          onChange={date => setStartTime(date ?? undefined)}
                          maxDate={endTime || undefined}
                          onBlur={() =>
                            setInvalidStartDate(!startTime ? true : false)
                          }
                          onFocus={() =>
                            googleAnalytics('medication_start_date')
                          }
                          className={invalidStartDate ? 'error' : ''}
                          autoComplete='off'
                        />
                        {(invalidStartDate || invalidEndDate) && (
                          <HelperText>
                            {getString(
                              'controlMessageComponent.message.required'
                            )}
                          </HelperText>
                        )}
                      </div>
                      {enableEndTime && frequency !== Frequency.asneeded && (
                        <DatePicker
                          id='end-date'
                          name='endDate'
                          value={endTime && format(endTime, displayFormat)}
                          placeholderText='MM/DD/YYYY'
                          selected={endTime}
                          onChange={date => setEndTime(date ?? undefined)}
                          minDate={startTime || undefined}
                          onFocus={() => googleAnalytics('medication_end_date')}
                          onBlur={() =>
                            setInvalidEndDate(!endTime ? true : false)
                          }
                          dateFormat={displayFormat}
                          className={invalidEndDate ? 'error' : ''}
                          autoComplete='off'
                        />
                      )}
                    </DateContainer>

                    <FormLabel
                      value={1}
                      control={
                        <Checkbox
                          name='enableEndDate'
                          checked={enableEndTime}
                          onChange={e => setEnableEndTime(e.target.checked)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={getString(
                        'medicationModalComponent.label.endDate'
                      )}
                      labelPlacement='end'
                    />
                  </ColRight>
                </Row>
                <Row>
                  <ColLeft>
                    <label htmlFor='frequency'>
                      {getString('medicationModalComponent.label.frequency')}
                    </label>
                  </ColLeft>
                  <ColRight className='block'>
                    <div>
                      <StyledSelect
                        id='frequency'
                        name='frequency'
                        value={frequency}
                        onChange={e =>
                          setFrequency(+e.target.value as Frequency)
                        }
                        onBlur={e =>
                          setInvalidFrequecy(
                            e.target.value === Frequency.none ? true : false
                          )
                        }
                        className={invalidFrequecy ? 'invalid' : ''}
                        onClick={() => googleAnalytics('frequency')}>
                        <option value={Frequency.none} hidden disabled>
                          {getString(
                            'medicationModalComponent.dropdown.selectFrequency'
                          )}
                        </option>
                        <option value={Frequency.asneeded}>
                          {getString(
                            'medicationModalComponent.dropdown.asNeeded'
                          )}
                        </option>
                        <option value={Frequency.daily}>
                          {getString('form.dropdown.everyDay')}
                        </option>
                        <option value={Frequency.weekly}>
                          {getString('form.dropdown.everyWeek')}
                        </option>
                        <option value={Frequency.twoweekly}>
                          {getString('form.dropdown.every2Weeks')}
                        </option>
                        <option value={Frequency.monthly}>
                          {getString('form.dropdown.everyMonth')}
                        </option>
                        <option value={Frequency.yearly}>
                          {getString('form.dropdown.everyYear')}
                        </option>
                        <option value={Frequency.weekdays}>
                          {getString('form.dropdown.everyWeekday')}
                        </option>
                        <option value={Frequency.custom}>
                          {getString(
                            'medicationModalComponent.dropdown.customSchedule'
                          )}
                        </option>
                      </StyledSelect>
                      <div>{rruleText}</div>
                      {invalidFrequecy && (
                        <HelperText>
                          {getString(
                            'controlMessageComponent.message.required'
                          )}
                        </HelperText>
                      )}
                    </div>
                  </ColRight>
                </Row>
                {frequency !== Frequency.asneeded && (
                  <Row>
                    <ColLeft>
                      <label htmlFor='reminder'>
                        {getString('medicationModalComponent.label.reminder')}
                      </label>
                      <p>{getString('form.label.optional')}</p>
                    </ColLeft>
                    <ColRight>
                      <Input
                        id='reminder'
                        value={reminderMessage}
                        name='reminderMessage'
                        onChange={e => setReminderMessage(e.target.value)}
                        onClick={() => googleAnalytics('reminder')}
                      />
                    </ColRight>
                  </Row>
                )}
                <RowLable>
                  <span></span>
                  {frequency !== Frequency.asneeded && (
                    <span>{getString('form.label.time')}</span>
                  )}
                  <span>
                    {getString('medicationModalComponent.label.quantity')}
                  </span>
                </RowLable>

                {doses.map((_, i) => (
                  <Row className='br-top' key={`dose-row-${i}`}>
                    <ColLeft className='flex-col'>
                      {frequency !== Frequency.asneeded && (
                        <div>
                          {doses.length !== 1 && (
                            <button
                              type='button'
                              id={`remove-dose-img${i}`}
                              onClick={() => {
                                handleRemoveDose(i);
                              }}>
                              <img src={remove} alt='remove dose' />
                            </button>
                          )}
                          {(doses.length === 1 ||
                            (doses.length > 1 && i !== 0)) && (
                            <button
                              type='button'
                              id={`add-dose-img${i}`}
                              onClick={() => {
                                handleAddDose();
                                googleAnalytics('add_dose', i + 1);
                              }}>
                              <img src={add} alt='add dosage' />
                            </button>
                          )}
                        </div>
                      )}
                      <label htmlFor='dose'>
                        {`${getString('medicationModalComponent.label.dose')} ${
                          frequency !== Frequency.asneeded ? i + 1 : ''
                        }`}
                      </label>
                    </ColLeft>
                    <ColRight className='dose-col'>
                      {frequency !== Frequency.asneeded && (
                        <div className='time'>
                          <Input
                            required
                            id={`{dose-time-${i}}`}
                            type='time'
                            placeholder={getString(
                              'medicationModalComponent.placeholder.doseTime'
                            )}
                            onClick={() => googleAnalytics('dose_time')}
                            onChange={e => handleDoseInputChange(e, i)}
                            onBlur={e => handleDoseInputChange(e, i)}
                            name={`time`}
                            value={doses?.[i]?.time}
                            error={doses?.[i]?.time === '' ? true : false}
                          />
                          {doses?.[i]?.time === '' && (
                            <HelperText>
                              {getString(
                                'controlMessageComponent.message.required'
                              )}
                            </HelperText>
                          )}
                        </div>
                      )}
                      <div>
                        <Input
                          error={
                            doses?.[i]?.quantity === '' ||
                            Number(doses?.[i]?.quantity) < 1
                              ? true
                              : false
                          }
                          name={`quantity`}
                          value={doses?.[i]?.quantity}
                          id='medication-quantity-input'
                          type='number'
                          onClick={() => googleAnalytics('medication_quantity')}
                          className='half'
                          onChange={e => handleDoseInputChange(e, i)}
                          onBlur={e => handleDoseInputChange(e, i)}
                        />

                        <StyledSelect
                          className='half-size'
                          id={`medication-quantity-dropdown-${i}`}
                          value={doses?.[i]?.quantityPart || ''}
                          name={`quantityPart`}
                          onChange={e => handleDoseInputChange(e, i)}
                          onBlur={e => handleDoseInputChange(e, i)}
                          onClick={() =>
                            googleAnalytics('medication_quantity_dropdown')
                          }>
                          <option value='0'>0</option>
                          <option value='.25'>1/4</option>
                          <option value='.5'>1/2</option>
                          <option value='.75'>3/4</option>
                        </StyledSelect>
                        <br />
                        {(doses?.[i]?.quantity === '' ||
                          Number(doses?.[i]?.quantity) < 1) && (
                          <HelperText>
                            {getString(
                              'controlMessageComponent.message.invalidDoseAmount'
                            )}
                          </HelperText>
                        )}
                      </div>
                    </ColRight>
                  </Row>
                ))}
              </div>
            </Separator>
          </DialogContent>
          <ButtonContainer>
            <GenericButton
              disabled={isDisabled}
              type='submit'
              onClick={() => googleAnalytics('submit')}>
              {getString('form.button.save')}
            </GenericButton>
          </ButtonContainer>
        </InputForm>
      </CustomDialog>
    </>
  );
};

export default ManagemedicationModal;
