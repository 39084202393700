import { useState } from 'react';
import { getString } from '../../context/UserContext';
import { addDays } from 'date-fns';
import { Adherence, ScheduledAdherence } from '../../models';

type useAdherenceCalendarProps = {
  // keys are dates
  calendar: Record<string, Adherence | ScheduledAdherence>;
};

type AdherenceLevel =
  | 'no-data'
  | 'no-adherence'
  | 'some-adherence'
  | 'full-adherence';

export const useAdherenceCalendar = ({
  calendar = {},
}: useAdherenceCalendarProps) => {
  const daysOfWeek: string[] = JSON.parse(
    getString('medicationCalendarComponent.daysOfTheWeek') || '[]'
  );

  const dateKeys = Object.keys(calendar);
  const startDayOfWeek = addDays(new Date(), dateKeys.length + 1).getDay();

  const [weeks] = useState<string[][]>(() => {
    const result: string[][] = [];
    for (let i = 0; i < dateKeys.length; i += 7) {
      result.push(dateKeys.slice(i, i + 7));
    }
    return result;
  });

  const dayOfWeek = (day: number) => daysOfWeek[(day + startDayOfWeek) % 7];

  // user-defined type guard
  const isScheduledAdherence = (
    data: Adherence | ScheduledAdherence
  ): data is ScheduledAdherence => 'scheduledAdhered' in data;

  const getAdherenceLevel = (date: string): AdherenceLevel => {
    const data = calendar[date];
    if (!data) {
      return 'no-data';
    }
    if (!isScheduledAdherence(data)) {
      if (data.total === 0 || (data.percentage === 1 && data.adhered === 0))
        return 'no-data';
      if (data.percentage === 0) return 'no-adherence';
      if (data.percentage === 1) return 'full-adherence';
    } else {
      if (data.scheduledTotal === 0 && !data.asNeededAdhered) return 'no-data';
      if (data.scheduledPercentage === 0 && !data.asNeededAdhered)
        return 'no-adherence';
      if (data.scheduledPercentage === 1 || data.asNeededAdhered)
        return 'full-adherence';
    }
    return 'some-adherence';
  };

  return { weeks, dayOfWeek, getAdherenceLevel };
};

export default useAdherenceCalendar;
