import BaseModal from '../../shared/modals/BaseModal';
import styled from 'styled-components';
import { getString } from '../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Endpoints } from '../../../api/endpoints';
import parse from 'html-react-parser';
import {
  ModalTitle,
  ModalInstructions,
  ModalButtonContainer,
  GenericButton,
} from '../../shared/modals/BaseModalStyle';
import theme from '../../../assets/theme';
import downArrow from '../../../assets/images/down_arrow.svg';

const Title = styled(ModalTitle)`
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 2.73px;
`;

const Instructions = styled(ModalInstructions)`
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 50px;
`;

const Consent = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-top: 25px;
`;

const Confirmation = styled(Consent)`
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
  margin-top: 25px;
  padding: 30px 15px 50px;
`;

const ButtonContainer = styled(ModalButtonContainer)`
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  button {
    margin: 0;
    min-width: auto;
  }
`;

interface ModalProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  onCloseButtonClicked?: () => void;
}

export const DeclineModal = (props: ModalProps) => {
  const navigate = useNavigate();
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };

  const handleConfirmDecline = () => {
    handleClose();
    navigate('/login');
  };

  return (
    <BaseModal
      onCloseButtonClicked={() => props.onCloseButtonClicked?.()}
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      modalWidth={theme.modalWidths.xxl}
      showExitButton={false}>
      <Confirmation>
        {parse(
          getString('legalDocsModalComponent.instructions.confirmDecline')
        )}

        <ButtonContainer>
          <GenericButton type='button' onClick={handleConfirmDecline}>
            {getString('legalDocsModalComponent.button.confirmDecline')}
          </GenericButton>
          <GenericButton type='button' onClick={handleClose}>
            {getString('legalDocsModalComponent.button.goBack')}
          </GenericButton>
        </ButtonContainer>
      </Confirmation>
    </BaseModal>
  );
};

const LegalDocsModal = (props: ModalProps) => {
  const [docUrl, setDocUrl] = useState('');
  const [openDeclineModal, setOpenDeclineModal] = useState(false);

  useEffect(() => {
    axios
      .get(Endpoints.document, {
        params: {
          product: 'Care4Today',
          key: 'terms_of_use',
        },
      })
      .then(response => {
        setDocUrl(`${response.data?.[0].url}#onboarding-acceptance`);
      });
  });

  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };

  return (
    <>
      {openDeclineModal ? (
        <DeclineModal modalIsOpen={true} setModalIsOpen={setOpenDeclineModal} />
      ) : (
        <BaseModal
          onCloseButtonClicked={() => props.onCloseButtonClicked?.()}
          handleClose={handleClose}
          modalIsOpen={props.modalIsOpen}
          setModalIsOpen={props.setModalIsOpen}
          modalWidth={theme.modalWidths.xxl}
          showExitButton={false}>
          <Title>
            {getString('legalDocsModalComponent.title.hcpPortalTitle')}
          </Title>
          <Instructions>
            <p>
              {getString('legalDocsModalComponent.instructions.scrollDown')}
            </p>
            <p>
              <img src={downArrow} alt='down arrow' />
            </p>
          </Instructions>
          <iframe
            className='legal-docs-iframe'
            src={docUrl}
            title='Terms of use'
            width={'95%'}
            height={1000}></iframe>
          <Consent>
            {getString('legalDocsModalComponent.instructions.consent')}
          </Consent>
          <ButtonContainer>
            <GenericButton type='button' onClick={handleClose}>
              {getString('legalDocsModalComponent.button.accept')}
            </GenericButton>
            <GenericButton
              type='button'
              onClick={() => setOpenDeclineModal(true)}>
              {getString('legalDocsModalComponent.button.decline')}
            </GenericButton>
          </ButtonContainer>
        </BaseModal>
      )}
    </>
  );
};

export default LegalDocsModal;
