import styled from 'styled-components';
import BaseModal from '../../../shared/modals/BaseModal';
import { GenericButton } from '../../../shared/modals/BaseModalStyle';
import theme from '../../../../assets/theme';
import { useUserContext } from '../../../../context/UserContext';

interface ModalProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
}

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 16px;
  padding: 0 16px;
`;

const Title = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 28px;
  text-transform: uppercase;
  text-align: center;
`;
const Instructions = styled.span`
  text-align: left;
`;

const AgreeButton = styled(GenericButton)`
  min-width: 200px;
  width: 300px;
`;

const CalculateRiskScoreErrorModal = (props: ModalProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };

  const { getString } = useUserContext();

  return (
    <BaseModal
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      modalWidth={theme.modalWidths.lg}>
      <InputRow className='centered'>
        <Title>
          {getString('riskScoreModalComponent.riskScoreError.title')}
        </Title>
      </InputRow>
      <InputRow>
        <Instructions>
          {getString('riskScoreModalComponent.riskScoreError.body')}
        </Instructions>
      </InputRow>
      <InputRow>
        <AgreeButton onClick={() => props.setModalIsOpen(false)}>
          {getString('riskScoreModalComponent.riskScoreError.button')}
        </AgreeButton>
      </InputRow>
    </BaseModal>
  );
};
export { CalculateRiskScoreErrorModal };
