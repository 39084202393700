import styled from 'styled-components';
import { theme } from '../../../assets/theme';
import { useEffect, useRef, useState } from 'react';

interface BaseModalProps {
  setModalIsOpen?: (isOpen: boolean) => void;
  handleClose?: () => void;
  modalIsOpen: boolean;
  children: React.ReactNode;
  onCloseButtonClicked?: () => void;
  modalWidth?: string;
  modalPosition?: string;
  showExitButton?: boolean;
}

const Modal = styled.dialog<{ $modalWidth: string; $modalPosition: string }>`
  width: ${props => props.$modalWidth};
  border: none;
  background-color: unset;
  padding: 0;
  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: 3px 6px 6px 6px rgba(0, 0, 0, 0.2);
  &.bounce {
    animation: bounce 0.25s;
  }

  @media (min-width: ${props => props.theme.breakPoints.sm}) {
    translate: 0 ${props => props.$modalPosition};
  }

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    margin: 0px auto;
  }

  @keyframes bounce {
    50% {
      transform: scale(1.0125);
    }
  }

  @media print {
    display: none !important;
  }
`;

const ExitButton = styled.button`
  width: 15px;
  height: 15px;
  position: relative;
  float: right;
  padding: 12px;
  outline: none;
`;
const ModalContent = styled.div`
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
`;

const ModalHeader = styled.div`
  padding: 20px 16px;
`;

const ExitStyle = styled.span`
  font-size: 24px;
  vertical-align: middle;
  top: -20px;
  right: 5px;
  position: relative;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  font-weight: 700;
  font-family: inherit;
  opacity: 0.4;
  &:hover {
    opacity: 0.7;
  }
`;

const BaseModal = (props: BaseModalProps) => {
  const modalRef = useRef<HTMLDialogElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [bounce, setBounce] = useState(false);
  const [showExit, setShowExit] = useState(true);

  useEffect(() => {
    setIsOpen(props.modalIsOpen);
  }, [props.modalIsOpen]);

  useEffect(() => {
    setShowExit(props?.showExitButton === false ? false : true);
  }, [props.showExitButton]);

  useEffect(() => {
    const modalElement = modalRef.current;

    if (modalElement) {
      if (isOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isOpen]);

  const handleClickOutside = (event: React.MouseEvent) => {
    if (event.target == event.currentTarget) {
      setBounce(true);
      return;
    }
  };

  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.onCloseButtonClicked?.();
    props.setModalIsOpen?.(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      setBounce(true);
    }
  };

  return (
    <Modal
      className={bounce ? 'bounce' : ''}
      $modalWidth={props.modalWidth ?? theme.modalWidths.md}
      $modalPosition={props.modalPosition ?? theme.modalPositions.center}
      ref={modalRef}
      onClickCapture={handleClickOutside}
      onKeyDown={handleKeyDown}
      onAnimationEnd={() => setBounce(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      {showExit && (
        <ModalHeader>
          <ExitButton onClick={handleClose}>
            <ExitStyle>x</ExitStyle>
          </ExitButton>
        </ModalHeader>
      )}
      <>
        <ModalContent>
          <div>{props.children}</div>
        </ModalContent>
      </>
    </Modal>
  );
};

export default BaseModal;
