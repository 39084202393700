import styled from 'styled-components';
import BaseModal from '../../../shared/modals/BaseModal';
import { MaskedInput } from '../../../shared/MaskedInput';
import { GenericButton } from '../../../shared/modals/BaseModalStyle';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useUserContext } from '../../../../context/UserContext';
import { Endpoints } from '../../../../api/endpoints';
import { SuccessToast, ErrorToast } from '../../../shared/ToastrNotifications';
import axios from 'axios';
import theme from '../../../../assets/theme';
import formatISO from 'date-fns/formatISO';
import { RiskScoreTracker, Tracker } from '../../../../api/models';
import Loader from '../../../shared/Loader';

interface ModalProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  patientId: number;
  trackerData: Tracker | undefined;
  adherenceData: RiskScoreTracker | undefined;
  refreshTable?: () => void;
}

const ModalTitle = styled.h1`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 900;
  letter-spacing: 2.73px;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: -20px;
  margin-bottom: 32px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  .input-field {
    width: 155px;
    margin: 0 15px;
    flex: 1;
  }
`;
const InputLabel = styled.label`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  text-align: left;
  flex: 1;
`;
const InputMeasurment = styled.span`
  color: ${props => props.theme.colors.shark};
  font-size: 14px;
  font-weight: 700;
  flex: 1;
  text-align: left;
`;

const SubmitButton = styled(GenericButton)`
  &.submit-button {
    margin-bottom: 16px;
  }
  &:disabled {
    cursor: unset;
    opacity: 0.5;
  }
`;

const HeartRateModal = (props: ModalProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };
  const [heartRate, setHeartRate] = useState('');
  const [heartRateTrackerData, setHeartRateTrackerData] = useState(
    props.trackerData
  );
  const [heartRateAdherenceData, setHeartRateAdherenceData] = useState(
    props.adherenceData
  );
  const requestOngoing = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getString } = useUserContext();
  const inputMask = /^\d{1,3}$|^$/;

  // handles the submission logic for the tracker
  const handleUpdateTracker = useCallback(() => {
    requestOngoing.current = false;

    const current = formatISO(new Date());
    const clientCreatedDateTime = current.replace('+', '-');

    const customTrackerData = heartRateTrackerData;
    if (customTrackerData == undefined) {
      setIsLoading(false);
      return;
    }

    // attach the input value to the fields data
    customTrackerData.fields[0].value = heartRate;

    const body = {
      clientCreatedDateTime: clientCreatedDateTime,
      asNeeded:
        customTrackerData['asNeeded'] != undefined
          ? customTrackerData['asNeeded']
          : true,
      dateTime: clientCreatedDateTime,
      didAdhere: true,
      fields: customTrackerData['fields'],
      recordType:
        customTrackerData['recordType'] != undefined
          ? customTrackerData['recordType']
          : 'HeartRate',
      responseVersion:
        customTrackerData['responseVersion'] != undefined
          ? customTrackerData['responseVersion']
          : 1,
      scheduledActivityId: customTrackerData['scheduledActivityId'],
      scheduledActivityVersion: customTrackerData['scheduledActivityVersion'],
    };
    axios
      .post(Endpoints.updateRiskTracker(props.patientId), body)
      .then(() => {
        SuccessToast('', getString('toastrMessages.types.success'));
        props.refreshTable?.();
        props.setModalIsOpen(false);
      })
      .catch(error => ErrorToast(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, [heartRateTrackerData, heartRate, getString, props]);

  useEffect(() => {
    setHeartRateTrackerData(props.trackerData);
  }, [props.trackerData]);
  useEffect(() => {
    setHeartRateAdherenceData(props.adherenceData);
  }, [props.adherenceData]);

  useEffect(() => {
    // wait for the bloodPressureTrackerData to be updated from network request
    if (requestOngoing.current) {
      handleUpdateTracker();
    }
  }, [heartRateTrackerData, handleUpdateTracker]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    if (heartRate.length > 0) {
      requestOngoing.current = true;
      // check if risk tracker is active
      if (
        heartRateAdherenceData?.suspended ||
        heartRateAdherenceData?.turnedOff ||
        heartRateAdherenceData?.adherence == null
      ) {
        // if the tracker is off, turn it on
        axios
          .post(Endpoints.turnOnTracker(props.patientId), {
            trackers: [
              {
                id: 'HeartRate',
                title: 'HeartRate',
                active: true,
              },
            ],
          })
          .then(response => {
            // then once it's activated, get the most updated tracker info
            if (response.data) {
              axios
                .get(Endpoints.customTracker(props.patientId))
                .then(response => {
                  const heartRateData = response.data.filter(
                    (tracker: Tracker) => tracker.recordType === 'HeartRate'
                  )[0];
                  setHeartRateTrackerData(heartRateData);
                })
                .catch(error => ErrorToast(error));
            }
          })
          .catch(error => ErrorToast(error));
      } else {
        // tracker was already active, simply call the update method
        handleUpdateTracker();
      }
    }
  };

  return (
    <BaseModal
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      modalWidth={theme.modalWidths.lg}>
      <ModalTitle>
        {getString('riskScoreModalComponent.heartRate.title')}
      </ModalTitle>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <InputRow>
            <InputLabel htmlFor='heart-rate'>
              {getString('riskScoreModalComponent.heartRate.label')}
            </InputLabel>
            <MaskedInput
              placeholder={getString('form.label.enterValue')}
              className='input-field'
              id='heart-rate'
              value={heartRate}
              setValue={setHeartRate}
              mask={inputMask}></MaskedInput>
            <InputMeasurment>
              {getString('riskScoreModalComponent.heartRate.measurement')}
            </InputMeasurment>
          </InputRow>
          <SubmitButton
            className='submit-button'
            disabled={!(heartRate.length > 0) || isLoading}
            onClick={handleSubmit}>
            {getString('form.button.save')}
          </SubmitButton>
        </>
      )}
    </BaseModal>
  );
};
export { HeartRateModal };
