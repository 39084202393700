import styled from 'styled-components';
import BaseModal from '../../shared/modals/BaseModal';
import { useState } from 'react';
import { GenericButton, EmptyButton } from '../../shared/modals/BaseModalStyle';
import { useUserContext } from '../../../context/UserContext';
import { Endpoints } from '../../../api/endpoints';
import { SuccessToast, ErrorToast } from '../../shared/ToastrNotifications';
import axios from 'axios';
import { logGAEvent } from '../../../shared/services/googleAnalyticsService';

interface DeleteAppointmentModalProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  patientId: number;
  appointmentId: string;
  onCloseButtonClicked?: () => void;
  refreshTable?: () => void;
}
const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  padding: 20px;
  padding-bottom: 0;
`;

const ModalTitle = styled.h1`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 900;
  letter-spacing: 2.73px;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: -20px;
  margin-bottom: 10px;
`;

const ModalInstructions = styled.p`
  color: ${props => props.theme.colors.echoBlue};
  font-size: 16px;
  margin: 0px;
  padding: 20px 14px 20px;
  font-weight: 500;
`;

const ConfimrationMessage = styled.p`
  color: ${props => props.theme.colors.charcoal};
  font-size: 16px;
  font-weight: 800;
  padding: 0 14px;
  margin-bottom: 0;
  margin-top: 0;
`;

const DeleteCancelButton = styled(EmptyButton)`
  min-width: 130px;
  width: 130px;
  margin: 0px 10px 0 10px;
  font-weight: 800;
`;
const DeleteContinueButton = styled(GenericButton)`
  min-width: 130px;
  width: 130px;
  margin: 0px 10px 0 10px;
  font-weight: 800;
`;

const DeleteAppointmentModal = (props: DeleteAppointmentModalProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    gaEvent('delete_close');
    props.setModalIsOpen(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const { getString } = useUserContext();

  const gaEvent = (value: string) => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'appointments',
      eventact: 'click',
      eventLbl: value,
    });
  };

  const handleCancelClicked = () => {
    gaEvent('cancel');
    props.setModalIsOpen(false);
  };

  const handleDeclineClicked = () => {
    gaEvent('yes_delete');

    setIsLoading(true);
    axios
      .delete(Endpoints.deleteAppointment(props.patientId, props.appointmentId))
      .then(() => {
        SuccessToast('', getString('toastrMessages.types.success'));
        props.refreshTable?.();
      })
      .catch(error => {
        ErrorToast(error);
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalIsOpen(false);
      });
  };
  return (
    <BaseModal
      onCloseButtonClicked={() => props.onCloseButtonClicked?.()}
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}>
      <ModalTitle>
        {getString(
          'confirmDeleteAppointmentModalComponent.title.deleteAppointment'
        )}
      </ModalTitle>
      <ModalInstructions>
        {getString(
          'confirmDeleteAppointmentModalComponent.instructions.deleteAppointment'
        ) +
          '® ' +
          getString(
            'confirmDeleteAppointmentModalComponent.instructions.connectApplication'
          )}
      </ModalInstructions>
      <ConfimrationMessage>
        {getString(
          'confirmDeleteAppointmentModalComponent.instructions.areYouSure'
        )}
      </ConfimrationMessage>
      <ModalButtonContainer>
        <DeleteCancelButton
          onClick={handleCancelClicked}
          disabled={isLoading}
          data-testid='cancel'>
          {getString('form.button.cancel')}
        </DeleteCancelButton>
        <DeleteContinueButton
          onClick={handleDeclineClicked}
          disabled={isLoading}
          data-testid='delete'>
          {getString('form.button.yesDelete')}
        </DeleteContinueButton>
      </ModalButtonContainer>
    </BaseModal>
  );
};
export { DeleteAppointmentModal };
