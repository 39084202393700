import BaseModal from '../../shared/modals/BaseModal';
import styled from 'styled-components';
import { useUserContext } from '../../../context/UserContext';
import { Patient } from '../../../models';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { logGAEvent } from '../../../shared/services/googleAnalyticsService';
import axios from 'axios';
import { Endpoints } from '../../../api/endpoints';
import { ErrorToast, SuccessToast } from '../../shared/ToastrNotifications';
import {
  ModalTitle,
  ModalInstructions,
  ModalButtonContainer,
  GenericButton,
  EmptyButton,
} from '../../shared/modals/BaseModalStyle';

interface ProfileModalProps {
  title?: string;
  instructions: string[];
  patient?: Patient;
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  children?: React.ReactNode;
  onCloseButtonClicked?: () => void;
}

interface PatientProps {
  patient: Patient;
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  refetchData?: () => void;
}

const CustomMotalTitle = styled(ModalTitle)`
  font-weight: 900;
  font-size: 20px;
  letter-spacing: 3px;
`;

const CustomGenericButton = styled(GenericButton)`
  &.medium {
    min-width: 175px;
  }
`;

const CustomEmptyButton = styled(EmptyButton)`
  &.medium {
    min-width: 175px;
  }
`;

const CancelButton = styled.button`
  color: ${props => props.theme.colors.pacificBlue};
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    color: ${props => props.theme.colors.charcoal};
  }
`;

const ConfirmationText = styled.p`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 900;
  padding: 0 30px;
  text-align: center;
`;

const FlexBox = styled.div`
  display: flex;
`;

const ProfileModal = (props: ProfileModalProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };

  return (
    <BaseModal
      onCloseButtonClicked={() => props.onCloseButtonClicked?.()}
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}>
      <CustomMotalTitle>{props.title}</CustomMotalTitle>
      <ModalInstructions>
        {props.instructions[0]}
        <ConfirmationText>{props.instructions[1]}</ConfirmationText>
      </ModalInstructions>
      <ModalButtonContainer>
        <div>{props.children}</div>
      </ModalButtonContainer>
    </BaseModal>
  );
};

const CareplanModal = (props: PatientProps) => {
  const { getString } = useUserContext();
  const navigate = useNavigate();
  const [hasCarePlan] = useState(
    !props?.patient?.carePlanTemplate ? false : true
  );

  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    googleAnalytics('assignment_close');
    props.setModalIsOpen(false);
  };

  const googleAnalytics = (type: string) => {
    switch (type) {
      case 'assignment_close':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'care_module',
          eventAct: 'click',
          eventLbl: hasCarePlan ? type : 'edit_close',
        });
        break;
      default:
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'care_module',
          eventAct: 'click',
          eventLbl: type,
        });
        break;
    }
  };

  const assignCareModule = () => {
    googleAnalytics('assign_care_module');
    navigate(`/patient/${props.patient?.id}/customizeModule`);
  };

  const assignLater = () => {
    googleAnalytics('assign_care_module_later');
    props.setModalIsOpen(false);
  };

  return (
    <ProfileModal
      title={
        !hasCarePlan
          ? getString('careModuleModalComponent.title.noCareModule')
          : [
              props.patient.carePlanTemplate,
              getString('careModuleModalComponent.title.assigned'),
            ].join(' ')
      }
      instructions={[
        [
          getString('careModuleModalComponent.instructions.selectedPatient'),
          props.patient.carePlanTemplate,
          getString('careModuleModalComponent.instructions.editCareModule'),
        ].join(' '),
      ]}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      patient={props.patient}
      onCloseButtonClicked={() => handleClose()}>
      <CustomGenericButton
        id='assign-care-module-button'
        onClick={assignCareModule}>
        {!hasCarePlan
          ? getString('careModuleModalComponent.button.assignCareModule')
          : getString('careModuleModalComponent.button.editCareModule')}
      </CustomGenericButton>
      <CancelButton id='assign-later-care-module-label' onClick={assignLater}>
        {!hasCarePlan
          ? getString('careModuleModalComponent.button.assignLater')
          : getString('careModuleModalComponent.button.editLater')}
      </CancelButton>
    </ProfileModal>
  );
};

const StatusModal = (props: PatientProps) => {
  const { getString } = useUserContext();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };

  const confirmDisconnect = () => {
    setIsDisabled(true);
    axios
      .put(
        Endpoints.disconnectPatient(
          props.patient.id,
          props.patient.sites[0].id
        ),
        {
          params: {
            fromHcp: true,
          },
        }
      )
      .then(() => {
        SuccessToast('', getString('toastrMessages.types.success'));
        handleClose();
      })
      .catch(error => {
        ErrorToast(error);
      })
      .finally(() => {
        setIsDisabled(false);
        props.setModalIsOpen(false);
        props.refetchData?.();
      });
  };

  return (
    <ProfileModal
      title={getString('confirmDisconnectModalComponent.title.disconnect')}
      instructions={[
        getString(
          'confirmDisconnectModalComponent.instructions.disconnectPatient'
        ),
        getString('confirmDisconnectModalComponent.instructions.areYouSure'),
      ]}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      patient={props.patient}
      onCloseButtonClicked={() => handleClose()}>
      <FlexBox>
        <CustomEmptyButton
          id='assign-later-care-module-label'
          onClick={handleClose}
          className='medium'>
          {getString('form.button.cancel')}
        </CustomEmptyButton>

        <CustomGenericButton
          id='assign-care-module-button'
          onClick={confirmDisconnect}
          disabled={isDisabled}
          className='medium'>
          {getString('form.button.yesDisconnect')}
        </CustomGenericButton>
      </FlexBox>
    </ProfileModal>
  );
};

export { CareplanModal, StatusModal };
