export const Endpoints = {
  userLogin: '/api/v1/user/login',
  staffLogin: '/api/v1/staff/login',
  staffLogout: '/api/v1/staff/logout',
  verifyStaff: '/api/v1/staff/verify',
  setPassword: '/api/v1/staff/setPassword',
  forgotPassword: '/api/v1/staff/forgotPassword',
  nullifyActivationKey: '/api/v1/staff/nullifyActivationKey',
  tags: '/api/v1/staff/tags',
  verifyPasswordResetKey: (userType: string) =>
    `/api/v1/${userType}/verifyPasswordResetKey`,
  forgotPasswordReset: (userType: string) =>
    `/api/v1/${userType}/forgotPasswordReset`,
  termsOfUse: '/api/v1/staff/termsOfUse',
  patients: (siteId?: number) => `/api/v1/staff/site/${siteId ?? '-'}/users`,
  sitesInOrganization: (orgId: number) => `/api/v1/organization/${orgId}/sites`,
  staff: (siteId?: number) => `/api/v1/staff/site/${siteId ?? '-'}/staff`,
  updateStaff: (staffId: number) => `/api/v1/staff/profileupdate/${staffId}`,
  staffInfo: (staffId?: number) => `/api/v1/staff/staff/${staffId}`,
  staffProfile: '/api/v1/staff/profile',
  resetPassword: '/api/v1/staff/resetPassword',
  allStaff: `/api/v1/staff/allstaff`,
  resendInvite: '/api/v1/staff/reinvite-staff',
  confirmPatientConnection: (userId: number, siteId: number) =>
    `/api/v1/staff/user/${userId}/site/${siteId}`,
  patientProfile: (userId: number) => `/api/v1/staff/user/${userId}`,
  patientTags: (userId: number) => `/api/v1/staff/user/${userId}/tags`,
  disconnectPatient: (userId: number, siteId: number) =>
    `/api/v1/user/${userId}/site/${siteId}/disconnect`,
  patientCustomTrackers: (userId: number) =>
    `/api/v1/user/${userId}/customTracker/dashboard`,
  medicationList: (userId: number) =>
    `/api/v1/staff/user/${userId}/medication/dashboard`,
  medicationAdherence: (userId: number, medicationId: string) =>
    `/api/v1/staff/user/${userId}/medication/${medicationId}/dashboard`,
  educationStatus: (userId: number) =>
    `/api/v1/user/${userId}/education/status`,
  appointment: (userId: number) => `/api/v1/user/${userId}/appointment`,
  appointmentMetric: (userId: number) =>
    `/api/v1/user/${userId}/appointmentMetric`,
  appointmentDetails: (userId: number) =>
    `/api/v1/user/${userId}/appointment/detailAdherence`,
  deleteAppointment: (userId: number, appointmentId: string) =>
    `/api/v1/user/${userId}/appointment/${appointmentId}`,
  customTrackers: (userId: number) =>
    `/api/v1/staff/user/${userId}/care-plan/trackers`,
  patientEducations: (userId: number) =>
    `/api/v1/staff/user/${userId}/care-plan/education`,
  getCurrentHPC: () => `/api/v1/staff/profile`,
  customTracker: (userId: number) => `/api/v1/user/${userId}/customTracker`,
  updateRiskTracker: (userId: number) =>
    `/api/v1/user/${userId}/customTracker/response`,
  updateSixMWDTracker: (userId: number) =>
    `/api/v1/staff/user/${userId}/riskScore/customTracker`,
  sendNotification: (userId: number) =>
    `/api/v1/staff/user/${userId}/riskScore/sendNotification`,
  riskScoreData: (userId: number) => `/api/v1/staff/user/${userId}/riskScore`,
  riskScoreTrackerData: (userId: number) =>
    `/api/v1/staff/user/${userId}/riskScore/customTrackerData`,
  sixMWDRiskScoreTrackerData: (userId: number) =>
    `/api/v1/staff/user/${userId}/riskScore/sixMwd`,
  turnOnTracker: (userId: number) =>
    `/api/v1/staff/user/${userId}/care-plan/trackers`,
  medication: (userId: number) => `/api/v1/user/${userId}/medication`,
  deleteMedication: (userId: number, medicationId: string) =>
    `/api/v1/user/${userId}/medication/${medicationId}`,
  drugNames: 'https://drugdb.jhiplatform.services/api/v1/us/drug_names.json',
  drugDetails: 'https://drugdb.jhiplatform.services/api/v1/us/drugs.json',
  site: '/api/v1/site',
  allsite: '/api/v1/allsite',
  allOrganization: '/api/v1/organization',
  allCarePlan: '/api/v1/care-plan-template',
  createOrgAdmin: '/api/v1/staff/orgadminstaff',
  createHcp: '/api/v1/staff/staff',
  updateHcp: (userId: number) => `/api/v1/staff/staff/${userId}`,
  updateOrgAdmin: (userId: number) =>
    `/api/v1/staff/updateadminstaff/${userId}`,
  document: '/api/v1/docs',
  portalFooterLinks: '/api/v1/docs/portal',
  pushPatientUpdateNotification: (userId: number) =>
    `/api/v1/user/${userId}/patientnotification`,
  fetchReinforcementMessage: '/api/v1/reinforcement/message',
  pushReinforcementNotification: (userId: number) =>
    `/api/v1/user/${userId}/reinforcement`,
  phoneType: '/api/v1/phoneType',
};

export default Endpoints;
