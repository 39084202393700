import React, { ReactNode, forwardRef } from 'react';
import styled from 'styled-components';
import ResponsiveContainer from './ResponsiveContainer';
import NavBar from './NavBar';
import Footer from './Footer';
import { StyledContainer } from '../shared/ToastrNotifications';

interface Props {
  children: ReactNode;
}
const LayoutContainer = styled.div`
  background-color: ${props => props.theme.colors.solitude};
`;

const Main = styled(ResponsiveContainer)`
  margin: 32px auto;

  @media print {
    margin: auto !important;
    max-width: none;
    width: 96.5% !important;
  }
`;

const Layout = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <LayoutContainer>
      <NavBar />
      <Main ref={ref}>
        <StyledContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
        {props.children}
      </Main>
      <Footer />
    </LayoutContainer>
  );
});

Layout.displayName = 'Layout';
export default Layout;
