import { useState } from 'react';
import styled from 'styled-components';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import { StandardGraph, Heatmap, isHeatmap } from '../../../models';
import {
  standardChartOptions,
  heatmapOptions,
} from '../../../util/chartOptions';

HighchartsHeatmap(Highcharts);

const Container = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: max-height 0.2s ease-in-out;
  max-height: ${props => (props.$isExpanded ? '600px' : 0)};
  overflow: ${props => (props.$isExpanded ? 'auto' : 'hidden')};
  &:first-of-type div.graph-header {
    border-top: none;
  }
  div.graph-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-top: 1px solid ${props => props.theme.colors.athensGray};
    p {
      margin: 0;
      white-space: pre-wrap;
    }
    p,
    span {
      text-transform: uppercase;
      font-weight: 600;
      color: ${props => props.theme.colors.slateGray};
    }
    div.averages {
      display: flex;
      flex-direction: column;
      flex-basis: 33%;
    }
  }
`;

type ActivityGraphProps = {
  graph: StandardGraph | Heatmap;
  isExpanded: boolean;
  indexPosition: number;
  startDate?: Date;
  endDate?: Date;
};

const ActivityGraph = ({
  graph,
  isExpanded,
  indexPosition,
  startDate,
  endDate,
}: ActivityGraphProps) => {
  const [options] = useState(
    !isHeatmap(graph)
      ? standardChartOptions(graph)
      : heatmapOptions(
          graph,
          indexPosition,
          startDate || new Date(),
          endDate || new Date()
        )
  );
  return (
    <Container className='graph' $isExpanded={isExpanded}>
      <div className='graph-header'>
        <p>{graph.title}</p>
        {!isHeatmap(graph) && (
          <div className='averages'>
            {graph.averages.map((average, aIndex) => (
              <span key={aIndex}>{`${average.label}: ${average.value ?? 0} ${
                average.unitsLabel || ''
              }`}</span>
            ))}
          </div>
        )}
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { width: '100%', height: '400px' } }}
        options={options}
      />
    </Container>
  );
};

export default ActivityGraph;
