import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import searchImage from '../../assets/images/search.svg';
import clearImage from '../../assets/images/clear.svg';
import { getString } from '../../context/UserContext';
const Input = styled.input`
  border: 1px solid ${props => props.theme.colors.athensGray};
  color: ${props => props.theme.colors.charcoal};
  font-family: Brandon;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  margin-right: 10px;
  background: ${props => props.theme.colors.white} url(${searchImage}) no-repeat
    4% 50%;
  border-radius: 25px;
  width: 220px;
  padding-left: 30px;

  &.tagSelect {
    background: none;
    padding-left: 0px;
    border-radius: 0px;
    width: 85%;
    padding-left: 10px;
    &.invalid {
      outline: none !important;
      border: 1px solid ${props => props.theme.colors.mangoTango};
    }
  }
`;

const DropDown = styled.div`
  z-index: 1;
  margin-top: 2px;
  overflow: hidden;
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  max-width: calc(100% - 1px);
  box-shadow: 0 8px 16px ${props => props.theme.colors.shadow};
  &.tagSelect {
    margin-left: 25px;
    width: auto;
    //overflow:hidden
  }

  button {
    &.tagSelect {
      font-size: 16px;
      text-transform: none;
      width: 100%;
      //overflow:hidden
    }
    text-align: left;
    text-transform: uppercase;
    padding: 4px 24px;
    color: ${props => props.theme.colors.black};
    width: 250px;
    display: block;
    font-weight: 500;
    &.active {
      border: solid 1px ${props => props.theme.colors.decemberDawn};
      background-color: ${props => props.theme.colors.pacificBlue};
      color: ${props => props.theme.colors.white};
      &.tagSelect {
        border: none;
      }
    }
  }
`;

const ClearSearch = styled.button`
  background: url(${clearImage}) no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 145px;
  right: 63px;
  z-index: 20;
`;

const InputErrorText = styled.p`
  font-family: Brandon;
  font-size: 16px;
  color: ${props => props.theme.colors.mangoTango};
  font-weight: 450;
  padding: 0px 15px;
  text-align: left;
  margin-bottom: 16px;
  margin-left: 2px;
`;

interface SearchFieldProps {
  placeholder: string;
  output: string[] | undefined;
  handleSearchCallback: (query: string) => void;
  clickEvent: (text: number) => void;
  inputType?: string;
  searchStyle?: string;
  hasClearField?: boolean;
}

export const SearchField = (props: SearchFieldProps) => {
  const [hasInput, setHasInput] = useState(false);
  const [hasResult, setHasResult] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [searchIndex, setSearchIndex] = useState(0);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState<string>();
  const [showClearButton, setShowClearButton] = useState(false);
  const handleSearchCallback = props.handleSearchCallback;
  const [results, setResults] = useState<string[]>();
  const [maxIndex, setMaxIndex] = useState(0);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowMessage(false);
    setHasInput(false);
    setHasResult(false);
    setResults(undefined);
    setDebouncedValue(undefined);
    setValue(e.target.value);
  };
  const hasClearField = props.hasClearField ?? false;
  useEffect(() => {
    if (value.length > 0) {
      setShowClearButton(true);
      const timer = setTimeout(() => setDebouncedValue(value), 400);
      return () => clearTimeout(timer);
    } else {
      setShowClearButton(false);
    }
  }, [value]);

  useEffect(() => {
    if (debouncedValue) {
      if (props.searchStyle == 'tagSelect' && debouncedValue.length > 50) {
        setShowMessage(true);
      } else {
        setHasInput(debouncedValue.length > 0);
        handleSearchCallback(debouncedValue);
      }
    }
  }, [debouncedValue, handleSearchCallback, props.searchStyle]);

  useEffect(() => {
    if (props.output)
      if (props.output.length > 0) {
        setResults(props.output);
        setMaxIndex(props.output.length - 1);
      } else {
        setResults([getString('searchComponent.tsMessages.noResultsFound')]);
      }
    setHasResult(true);
  }, [props.output]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropDownRef.current?.contains(event.target as Node)) {
        setDebouncedValue(undefined);
        setHasInput(false);
        setHasResult(false);
        setResults(undefined);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropDownRef]);
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === 'ArrowUp') {
      const newIndex = index - 1 < 0 ? maxIndex : index - 1;
      setSearchIndex(newIndex);
    } else if (e.key === 'ArrowDown') {
      const newIndex = index + 1 > maxIndex ? 0 : index + 1;
      setSearchIndex(newIndex);
    } else if (
      e.key === 'Enter' &&
      hasInput &&
      results?.length &&
      debouncedValue &&
      hasResult
    ) {
      !results?.includes(
        getString('searchComponent.tsMessages.noResultsFound')
      ) && props.clickEvent(index);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      setDebouncedValue(undefined);
      setHasInput(false);
      setHasResult(false);
      setResults(undefined);
    }
  };

  return (
    <div>
      <Input
        className={
          showMessage ? `${props.searchStyle} invalid` : `${props.searchStyle}`
        }
        data-testid='searchInput'
        placeholder={props.placeholder}
        onChange={onChange}
        ref={inputRef}
        onKeyDown={e => {
          handleKeyDown(e, searchIndex);
        }}
      />
      {hasClearField && showClearButton && (
        <ClearSearch
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.value = '';
              setValue('');
            }
            setShowClearButton(false);
            setDebouncedValue(undefined);
            setHasInput(false);
            setHasResult(false);
            setShowMessage(false);
            setResults(undefined);
          }}
        />
      )}
      {hasInput &&
        results?.length &&
        debouncedValue &&
        hasResult &&
        debouncedValue?.length < 50 && (
          <DropDown
            className={`${props.searchStyle}`}
            data-testid='dropdown'
            ref={dropDownRef}>
            {results?.map((fills, index) => {
              const isActive = searchIndex === index;
              const classNames = [];
              classNames.push(props.searchStyle);
              if (isActive) {
                classNames.push('active');
              }

              const searchOutput =
                props.searchStyle != 'tagSelect' ? fills.toLowerCase() : fills;
              const result = searchOutput.indexOf(debouncedValue.toLowerCase());
              const start = searchOutput.substring(0, result);
              const middle = searchOutput.substring(
                result,
                result + debouncedValue.length
              );
              const end = searchOutput.substring(
                result + debouncedValue.length
              );
              return (
                <button
                  id={'button: ' + index}
                  data-testid={'resultButton: ' + fills}
                  onClick={() => {
                    !results.includes(
                      getString('searchComponent.tsMessages.noResultsFound')
                    ) && props.clickEvent(index);
                    if (inputRef.current) {
                      inputRef.current.value = '';
                      setValue('');
                    }
                    setShowClearButton(false);
                    setDebouncedValue(undefined);
                    setHasInput(false);
                    setHasResult(false);
                    setResults(undefined);
                  }}
                  key={index}
                  onMouseEnter={() => {
                    setSearchIndex(index);
                  }}
                  className={classNames.join(' ')}>
                  {start}
                  <b>{middle}</b>
                  {end}
                </button>
              );
            })}
          </DropDown>
        )}
      {showMessage && (
        <InputErrorText>
          {getString('controlMessageComponent.message.invalidTagName')}
        </InputErrorText>
      )}
    </div>
  );
};

export default SearchField;
