import React, { useEffect, useState } from 'react';
import Layout from '../main/Layout';
import styled from 'styled-components';
import LabeledInput from '../shared/LabeledInput';
import { HCPUser } from './../../models/index';
import theme from '../../assets/theme';
import axios from 'axios';
import Endpoints from '../../api/endpoints';
import { ChangePasswordModal } from './ChangePasswordModal';
import { useUserContext } from '../../context/UserContext';
import { ErrorToast, SuccessToast } from '../shared/ToastrNotifications';
const Header = styled.h4`
  padding: 25px 0;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  text-transform: uppercase;
`;
const Container = styled.div<{ $containerWidth: string }>`
  margin: auto;
  top: -100px;
  max-width: 100%;
  min-width: 425px;
  width: 45%;
  border: none;
  background-color: unset;
  padding: 0;
  &::backdrop {
    background-color: ${props => props.theme.colors.shadow};
  }

  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    margin: 0px auto;
  }
`;
const Input = styled.input`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-family: 'Brandon';
  font-size: 16px;
  height: 24px;
  padding: 10px;
  margin: auto;
  width: 80%;
`;

const ChangePassword = styled.button`
  cursor: pointer;
  margin-top: auto;
  margin-right: 10%;
  margin-bottom: auto;
  margin-left: 5%;
  color: ${props => props.theme.colors.echoBlue};
  &:hover {
    color: ${props => props.theme.colors.curiousBlue};
  }
`;

const Content = styled.div`
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
`;

const InputForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    flex-direction: column;
  }
`;

const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-left: 0;
`;

const InputContainer = styled.div`
  width: 70%;
  display: flex;
  align-items: end;
  input.invalid {
    border-color: ${props => props.theme.colors.mangoTango};
  }

  #error-text {
    margin-left: 10px;
  }
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    width: 100%;
    margin-left: auto;
    input {
      width: 85%;
    }
    #error-text {
      margin-left: 0;
    }
  }

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    width: 100%;
    input {
      width: 85%;
    }
    #error-text {
      margin-left: 0;
    }
  }
`;

const InputLabel = styled.label`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 700;
  text-transform: uppercase;
  margin: auto;

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    font-size: 16px;
  }
`;

const SubmitButton = styled.button`
  background-color: ${props => props.theme.colors.pacificBlue};
  border: 1px solid ${props => props.theme.colors.pacificBlue};
  border-radius: 21px;
  color: ${props => props.theme.colors.white};
  display: block;
  font-weight: 600;
  font-size: medium;
  height: 40px;
  margin: auto;
  min-width: 275px;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    background-color: ${props => props.theme.colors.cerulean};
  }
  &:disabled {
    opacity: 0.4;
  }
`;
export const HCPProfile = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [firstNameIsValid, setFirstNameIsValid] = useState(false);
  const [lastNameIsValid, setlastNameIsValid] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [currentUser, setCurrentUser] = useState<HCPUser>();
  const { user, setUser, getString } = useUserContext();

  const changePassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setModalIsOpen(true);
  };
  useEffect(() => {
    if (!currentUser) {
      axios.get(Endpoints.staffProfile, {}).then(response => {
        setCurrentUser(response.data);
        setIsDisabled(false);
      });
    }
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (lastNameIsValid && firstNameIsValid) {
      const form = e.currentTarget;
      const formElements = form.elements as typeof form.elements & {
        firstName: HTMLInputElement;
        lastName: HTMLInputElement;
      };
      if (
        formElements.firstName.value.length &&
        formElements.lastName.value.length &&
        user
      ) {
        if (currentUser) {
          const updatedUser: HCPUser = {
            ...currentUser,
            firstName: formElements.firstName.value,
            lastName: formElements.lastName.value,
            roleId: currentUser.roles.map(role => role.id).join(', '),
            siteId: currentUser.sites[0].id,
          };

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const {
            // refreshToken,
            // passwordExpirationDays,
            // locale,
            ...passedUser
          } = updatedUser;

          axios
            .put(Endpoints.staffInfo(user?.id), {
              ...passedUser,
            })
            .then(() => {
              setUser(updatedUser);
              SuccessToast('', getString('toastrMessages.types.success'));
            })
            .catch(error => {
              ErrorToast(error);
            });
        }
      } else {
        ErrorToast(getString('toastrMessages.common.pleaseFillRequiredFields'));
      }
    } else {
      ErrorToast(getString('toastrMessages.common.pleaseFillRequiredFields'));
    }
  };

  return (
    <Layout>
      {modalIsOpen ? (
        <ChangePasswordModal
          setModalIsOpen={isOpen => setModalIsOpen(isOpen)}
          modalIsOpen={modalIsOpen}
        />
      ) : null}
      <Header>{getString('editProfileComponent.title.myProfile')}</Header>
      <Container $containerWidth={theme.modalWidths.lg}>
        <Content>
          <div>
            <InputForm
              onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
                handleSubmit(e)
              }>
              <InputRow>
                <InputLabelContainer>
                  <InputLabel htmlFor='firstName'>
                    {getString('form.label.firstName')}
                  </InputLabel>
                </InputLabelContainer>
                <InputContainer>
                  <LabeledInput
                    valueOverride={currentUser?.firstName}
                    includeLabel={false}
                    name='firstName'
                    inputType='name'
                    labelText='First Name'
                    isRequired={true}
                    matchPattern={true}
                    regexPattern={
                      /^\s*?[a-zA-Z]+(?:(?:['.,\-\s])[a-zA-Z]+)*\s*?$/
                    }
                    isDisabled={isDisabled}
                    setInputIsValid={(isValid: boolean) =>
                      setFirstNameIsValid(isValid)
                    }
                  />
                </InputContainer>
              </InputRow>
              <InputRow>
                <InputLabelContainer>
                  <InputLabel htmlFor='lastName'>
                    {getString('form.label.lastName')}
                  </InputLabel>
                </InputLabelContainer>
                <InputContainer>
                  <LabeledInput
                    valueOverride={currentUser?.lastName}
                    includeLabel={false}
                    name='lastName'
                    labelText='Last Name'
                    inputType='name'
                    isRequired={true}
                    matchPattern={true}
                    isDisabled={isDisabled}
                    regexPattern={
                      /^\s*?[a-zA-Z]+(?:(?:['.,\-\s])[a-zA-Z]+)*\s*?$/
                    }
                    setInputIsValid={(isValid: boolean) =>
                      setlastNameIsValid(isValid)
                    }
                  />
                </InputContainer>
              </InputRow>

              <InputRow>
                <InputLabelContainer>
                  <InputLabel htmlFor='email'>
                    {getString('form.label.email')}
                  </InputLabel>
                </InputLabelContainer>
                <InputContainer>
                  <Input
                    data-testid='Email'
                    value={currentUser?.email}
                    disabled
                  />
                </InputContainer>
              </InputRow>

              {!isDisabled && (
                <>
                  <InputRow>
                    <InputLabelContainer>
                      <InputLabel htmlFor='Password'>
                        {getString('form.label.password')}
                      </InputLabel>
                    </InputLabelContainer>
                    <InputContainer>
                      <ChangePassword
                        data-testid='changePassword'
                        onClick={changePassword}>
                        {getString('editProfileComponent.form.changePassword')}
                      </ChangePassword>
                    </InputContainer>
                  </InputRow>

                  <InputRow>
                    <SubmitButton data-testid='submit' type='submit'>
                      {getString('form.button.saveChanges')}
                    </SubmitButton>
                  </InputRow>
                </>
              )}
            </InputForm>
          </div>
        </Content>
      </Container>
    </Layout>
  );
};

export default HCPProfile;
