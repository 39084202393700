import React from 'react';
import styled from 'styled-components';
import LogoImage from '../../assets/images/logo.svg';
import Footer from '../main/Footer';
import { StyledContainer } from '../shared/ToastrNotifications';
import { useUserContext } from '../../context/UserContext';

const PageBody = styled.div`
  background-color: ${props => props.theme.colors.darkCerulean};
  position: relative;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  margin: auto;
  text-align: center;
  vertical-align: center;
`;

const Logo = styled.img`
  height: 200px;
  width: 200px;
  margin: 4% auto;
  vertical-align: center;
`;

const AuthContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.decemberDawn};
  border-radius: 10px;
  display: block;
  max-width: 448px;
  min-height: 80px;
  padding: 36px 16px;
  position: relative;
  margin: auto;
  margin-bottom: 16px;
`;

const AuthForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const WarningMessage = styled.p`
  color: ${props => props.theme.colors.white};
  text-align: center;
  font-weight: 500;
  margin: auto;
  max-width: 81%;
  margin-bottom: 24px;
`;

export declare interface AuthFormPageProps {
  children: React.ReactNode;
}
export const AuthFormPage = (props: AuthFormPageProps) => {
  const { getString } = useUserContext();
  return (
    <PageBody>
      <Content>
        <StyledContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
        <StyledContainer />
        <Logo src={LogoImage} alt='Care4Today Logo' />
        <AuthContainer>{props.children ?? null}</AuthContainer>
        <WarningMessage>{getString('footer.warning.notice')}</WarningMessage>
      </Content>
      <Footer />
    </PageBody>
  );
};

export default AuthForm;
