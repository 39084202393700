import { LabeledInput } from '../shared/LabeledInput';
import { AuthFormPage } from './AuthFormPage';
import {
  logGAEvent,
  sendPageView,
} from '../../shared/services/googleAnalyticsService';
import { useUserContext } from '../../context/UserContext';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAPI } from '../../context/APIContext';
import axios from 'axios';
import { Endpoints } from '../../api/endpoints';
import { ErrorToast } from '../shared/ToastrNotifications';

const AuthForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 250px;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 900;
  text-transform: uppercase;
  margin: 0px;
`;

const InstructionsText = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 450;
  padding: 0px 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const SubButtonContainerLeft = styled.div`
  text-align: left;
  padding-left: 15px;
  padding-right: 5px;
  width: 100%;
`;
const SubButtonContainerRight = styled.div`
  text-align: right;
  padding-left: 5px;
  padding-right: 15px;
  width: 100%;
`;

const Button = styled.button`
  border-radius: 21px;
  display: block;
  font-weight: 900;
  font-size: medium;
  height: 42px;
  width: 100%;
  margin: auto;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;
  &:disabled {
    opacity: 0.5;
  }
`;
const SubmitButton = styled(Button)`
  background-color: ${props => props.theme.colors.pacificBlue};
  border: 1px solid ${props => props.theme.colors.pacificBlue};
  color: ${props => props.theme.colors.white};
  &:hover {
    background-color: ${props => props.theme.colors.cerulean};
  }
`;

const CancelButton = styled(Button)`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.pacificBlue};
  color: ${props => props.theme.colors.pacificBlue};
  &:hover {
    color: ${props => props.theme.colors.cerulean};
  }
`;

const OkayButton = styled(SubmitButton)`
  margin: auto;
  max-width: 158px;
  margin-top: 20px;
  margin-bottom: 70px;
`;
export const ForgotPassword = () => {
  const { getString } = useUserContext();
  const navigate = useNavigate();
  const { isLoading } = useAPI();
  const { passwordExpired } = useParams();
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [currentInput, setCurrentInput] = useState<string>();

  const [resetSuccessful, setResetSuccessful] = useState(false);

  useEffect(() => {
    sendPageView('forgotPassword');
  });

  const handleOnClick = () => {
    navigate('/login');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!emailIsValid || !currentInput || currentInput.length == 0) {
      ErrorToast(
        getString('toastrMessages.forgotPasswordComponent.invalidEmail')
      );
      return;
    }

    const form = e.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      email: HTMLInputElement;
    };

    const body = {
      email: formElements.email.value.trim(),
    };

    axios
      .post(Endpoints.forgotPassword, body)
      .then(() => setResetSuccessful(true))
      .catch(error => ErrorToast(error));
  };

  return (
    <AuthFormPage>
      {!resetSuccessful ? (
        <AuthForm
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}>
          <Title>
            {passwordExpired === 'true'
              ? getString('forgotPasswordComponent.title.expiredPasswordReset')
              : getString('forgotPasswordComponent.title.forgotPassword')}
          </Title>
          <InstructionsText>
            {getString('forgotPasswordComponent.instructions.forgotPassword')}
          </InstructionsText>
          <LabeledInput
            name='email'
            labelText={getString('form.label.email')}
            onClick={() =>
              logGAEvent({
                event: 'eventTracker',
                eventCat: 'forgotPassword',
                eventAct: 'click',
                eventLbl: 'email',
              })
            }
            matchPattern={true}
            invalidOnEmpty={true}
            isRequired={true}
            regexPattern={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/}
            setInputIsValid={(isValid: boolean) => setEmailIsValid(isValid)}
            setValue={(val: string) => setCurrentInput(val)}
          />
          <ButtonContainer>
            <SubButtonContainerLeft>
              <CancelButton onClick={handleOnClick}>
                {getString('form.button.cancel')}
              </CancelButton>
            </SubButtonContainerLeft>
            <SubButtonContainerRight>
              <SubmitButton disabled={isLoading}>
                {getString('form.button.submit')}
              </SubmitButton>
            </SubButtonContainerRight>
          </ButtonContainer>
        </AuthForm>
      ) : (
        <AuthForm>
          <Title>{getString('forgotPasswordComponent.title.confirmed')}</Title>
          <InstructionsText>
            {getString('forgotPasswordComponent.instructions.confirmed')}
          </InstructionsText>
          <OkayButton onClick={handleOnClick} type='button'>
            {getString('form.button.okay')}
          </OkayButton>
        </AuthForm>
      )}
    </AuthFormPage>
  );
};
