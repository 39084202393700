import styled from 'styled-components';
import BaseModal from '../../../shared/modals/BaseModal';
import {
  EmptyButton,
  GenericButton,
} from '../../../shared/modals/BaseModalStyle';
import { useState } from 'react';
import { useUserContext } from '../../../../context/UserContext';
import { Endpoints } from '../../../../api/endpoints';
import axios from 'axios';
import theme from '../../../../assets/theme';
import { RiskScoreTrackers, SixMWDTracker } from '../../../../api/models';
import formatISO from 'date-fns/formatISO';
import { SuccessToast } from '../../../shared/ToastrNotifications';

interface ModalProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  triggerErrorModal: () => void;
  patientId: number;
  riskScoreData: RiskScoreTrackers | undefined;
  sixMwdRiskScoreData: SixMWDTracker | undefined;
  refreshTable?: () => void;
}

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 16px;
  padding: 0 16px;

  &.column {
    flex-direction: column;
  }

  &.button {
    min-width: 120px;
  }
`;

const Instructions = styled.span`
  text-align: left;
`;
const RenalCheckButton = styled.input`
  margin-left: 8px;
`;

const AgreeButton = styled(GenericButton)`
  min-width: 200px;
  width: 300px;

  :disabled {
    cursor: unset;
    opacity: 0.5;
  }
`;
const GoBackButton = styled(EmptyButton)`
  min-width: 110px;
`;

const CalculateRiskScoreModal = (props: ModalProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };

  const [hasRenalInsufficiency, sethasRenalInsufficiency] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { getString } = useUserContext();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setIsLoading(true);

    const dateTime = formatISO(new Date()).replace('+', '-');
    const data = {
      BloodPressure:
        props.riskScoreData?.customTrackerData.BloodPressure?.adherence,
      Bnp: props.riskScoreData?.customTrackerData.Bnp?.adherence,
      Egfr: props.riskScoreData?.customTrackerData.Egfr?.adherence,
      HeartRate: props.riskScoreData?.customTrackerData.HeartRate?.adherence,
      SixMwd: props.sixMwdRiskScoreData ? props.sixMwdRiskScoreData : null,
      WhoFunctionalClass:
        props.riskScoreData?.customTrackerData.WhoFunctionalClass?.adherence,
      dateTime: dateTime,
      renalInsufficiency: hasRenalInsufficiency,
      riskScoreType: 'RevealTwoLiteRiskScore',
    };

    axios
      .post(Endpoints.riskScoreData(props.patientId), data)
      .then(() => {
        SuccessToast('', getString('toastrMessages.types.success'));
        props.setModalIsOpen(false);
        props.refreshTable?.();
      })
      .catch(() => {
        props.triggerErrorModal();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BaseModal
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      modalWidth={theme.modalWidths.lg}>
      <InputRow>
        <Instructions>
          {getString('riskScoreModalComponent.riskScoreCalculation.step1')}
        </Instructions>
        <RenalCheckButton
          type='checkbox'
          checked={hasRenalInsufficiency}
          onChange={e => sethasRenalInsufficiency(e.target.checked)}
          data-testid='renal-insufficiency'
        />
      </InputRow>
      <InputRow className='column'>
        <Instructions>
          {getString('riskScoreModalComponent.riskScoreCalculation.step2')}
        </Instructions>
        <Instructions>
          {getString(
            'riskScoreModalComponent.riskScoreCalculation.step2Description'
          )}
        </Instructions>
      </InputRow>
      <InputRow>
        <GoBackButton onClick={() => props.setModalIsOpen(false)}>
          {getString('riskScoreModalComponent.riskScoreCalculation.goBack')}
        </GoBackButton>
        <AgreeButton disabled={isLoading} onClick={handleSubmit}>
          {getString('riskScoreModalComponent.riskScoreCalculation.agree')}
        </AgreeButton>
      </InputRow>
    </BaseModal>
  );
};
export { CalculateRiskScoreModal };
