import styled from 'styled-components';
import infoImage from '../../../assets/images/info.svg';
import { useState } from 'react';

const ToolTipBody = styled.div`
  width: auto !important;
  position: absolute;
  text-align: left;
  background-color: ${props => props.theme.colors.whisper};
  color: ${props => props.theme.colors.charcoal};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.charcoal};
  padding: 6px;
  line-height: 1.5;
  z-index: 13;
  font-size: 14px !important;
  opacity: 0.9;
  font-weight: 400 !important;
  letter-spacing: 1px;
  width: 12vw !important;

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 5px);
    left: -10px;
    z-index: -1;
    border-width: 5px;
    border-color: transparent ${props => props.theme.colors.charcoal}
      transparent transparent;
    border-style: solid;
  }

  h3,
  p {
    margin: 0;
    text-align: left;
  }
  h3 {
    font-weight: 600;
    font-size: 14px;
  }
`;
const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .info {
    cursor: pointer;
  }
`;

const RiskScoreTrackerHeader = (props: {
  title: string;
  toolTipText: string;
}) => {
  const [toolTipLeft, setToolTipLeft] = useState<number>();

  const setToolTipStyle = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const padding = 8;
    setToolTipLeft(rect.left + rect.width + padding);
  };

  return (
    <Container>
      {props.title}{' '}
      <img
        className='info'
        alt='info icon'
        src={infoImage}
        data-testid='icon'
        onMouseLeave={() => setToolTipLeft(undefined)}
        onMouseEnter={e => {
          setToolTipStyle(e);
        }}
      />
      {toolTipLeft && (
        <ToolTipBody style={{ left: toolTipLeft }}>
          <h3 data-testid='title' className='tooltip-title'>
            {props.title}
          </h3>
          <p data-testid='text'>{props.toolTipText}</p>
        </ToolTipBody>
      )}
    </Container>
  );
};

export { RiskScoreTrackerHeader };
