import styled from 'styled-components';
import BaseModal from '../../../shared/modals/BaseModal';
import { MaskedInput } from '../../../shared/MaskedInput';
import { GenericButton } from '../../../shared/modals/BaseModalStyle';
import { useState } from 'react';
import { useUserContext } from '../../../../context/UserContext';
import { Endpoints } from '../../../../api/endpoints';
import { SuccessToast, ErrorToast } from '../../../shared/ToastrNotifications';
import axios from 'axios';
import theme from '../../../../assets/theme';
import formatISO from 'date-fns/formatISO';
import Loader from '../../../shared/Loader';

interface ModalProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  patientId: number;
  refreshTable?: () => void;
}

const ModalTitle = styled.h1`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 900;
  letter-spacing: 2.73px;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: -20px;
  margin-bottom: 32px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 16px;

  .input-field {
    width: 155px;
    margin: 0 15px;
    flex: 1;
  }
`;
const InputLabel = styled.label`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  flex: 1;
`;
const InputMeasurment = styled.span`
  color: ${props => props.theme.colors.shark};
  font-size: 14px;
  font-weight: 700;
  flex: 1;
  text-align: left;
`;

const SubmitButton = styled(GenericButton)`
  &.submit-button {
    margin-bottom: 16px;
  }
  &:disabled {
    cursor: unset;
    opacity: 0.5;
  }
`;

const SixMinuteWalkModal = (props: ModalProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };
  const [distance, setDistance] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { getString } = useUserContext();
  const inputMask = /^\d{1,4}$|^$/;

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isLoading || distance.length === 0) {
      return;
    } else {
      setIsLoading(true);
      handleUpdateTracker();
    }
  };

  // handles the submission logic for the tracker
  const handleUpdateTracker = () => {
    const current = formatISO(new Date());
    const clientCreatedDateTime = current.replace('+', '-');

    const body = {
      dateTime: clientCreatedDateTime,
      fields: [
        {
          sixMwd: {
            value: distance,
            unit: 'm',
          },
        },
      ],
      recordType: 'SixMwd',
    };
    axios
      .post(Endpoints.updateSixMWDTracker(props.patientId), body)
      .then(() => {
        SuccessToast('', getString('toastrMessages.types.success'));
        props.refreshTable?.();
        props.setModalIsOpen(false);
      })
      .catch(error => ErrorToast(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BaseModal
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      modalWidth={theme.modalWidths.lg}>
      <ModalTitle>
        {getString('riskScoreModalComponent.6MinuteWalkDistance.title')}
      </ModalTitle>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <InputRow>
            <InputLabel htmlFor='distance-value'>
              {getString('riskScoreModalComponent.6MinuteWalkDistance.label')}
            </InputLabel>
            <MaskedInput
              placeholder={getString('form.label.enterValue')}
              className='input-field'
              id='distance-value'
              value={distance}
              setValue={setDistance}
              mask={inputMask}></MaskedInput>
            <InputMeasurment>
              {getString(
                'riskScoreModalComponent.6MinuteWalkDistance.measurement'
              )}
            </InputMeasurment>
          </InputRow>
          <SubmitButton
            className='submit-button'
            disabled={!(distance.length > 0) || isLoading}
            onClick={handleSubmit}>
            {getString('form.button.save')}
          </SubmitButton>
        </>
      )}
    </BaseModal>
  );
};
export { SixMinuteWalkModal };
