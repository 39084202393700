import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getString } from '../../../context/UserContext';
import { ErrorToast } from '../../shared/ToastrNotifications';
import { Card, CardHeader, NoInfoBaseContainer } from './Card';
import ProgressBar from './ProgressBar';
import Loader from '../../shared/Loader';
import EducationStatusModal from './modals/EducationStatusModal';
import axios from 'axios';
import Endpoints from '../../../api/endpoints';
import { EducationBundle } from '../../../models';
import { logGAEvent } from '../../../shared/services/googleAnalyticsService';
import noDataImage from '../../../assets/images/no_education_activity_icon.svg';

const Container = styled.div`
  display: flex;
  min-height: 255px;
`;

const NoInfo = styled(NoInfoBaseContainer)`
  flex-grow: 1;
  justify-content: center;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  padding: 0 15px;
`;

const BundleRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  gap: 8px;
  border-top: 1px solid ${props => props.theme.colors.athensGray};
  p,
  button {
    text-transform: uppercase;
    font-weight: 600;
  }
  p {
    margin: 0;
    color: ${props => props.theme.colors.charcoal};
  }
  button {
    align-self: flex-start;
    color: ${props => props.theme.colors.echoBlue};
    &:hover {
      color: ${props => props.theme.colors.curiousBlue};
    }
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    flex-direction: column;
    align-items: stretch;
    gap: 2px;
  }
  margin: 0 15px;
  & > div {
    flex-basis: 75%;
  }
`;

type EducationProps = {
  patientId: number;
  showOnPrint?: boolean;
};

const Education = ({ patientId, showOnPrint = true }: EducationProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bundles, setBundles] = useState<EducationBundle[]>();
  const [selectedBundle, setSelectedBundle] = useState<EducationBundle>();

  // load education bundles
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(Endpoints.educationStatus(patientId))
      .then(response => setBundles(response.data))
      .catch(error => ErrorToast(error))
      .finally(() => setIsLoading(false));
  }, [patientId]);

  return (
    <Card className={showOnPrint ? '' : 'do-not-print'}>
      <CardHeader>
        <h3>{getString('educationCardComponent.title.education')}</h3>
      </CardHeader>
      {selectedBundle && (
        <EducationStatusModal
          bundle={selectedBundle}
          onClose={() => setSelectedBundle(undefined)}
        />
      )}
      <Container>
        {isLoading ? (
          <Loader />
        ) : !bundles?.length ? (
          <NoInfo>
            <img src={noDataImage} alt='no education activities' />
            <h1>{getString('educationCardComponent.title.noEducation')}</h1>
          </NoInfo>
        ) : (
          <ContentContainer>
            {bundles.map(bundle => (
              <BundleRow key={bundle.id} data-testid='education-bundle-status'>
                <p>{bundle.title}</p>
                <ProgressContainer>
                  <ProgressBar
                    percentage={
                      bundle.totalArticles > 0
                        ? bundle.totalArticlesRead / bundle.totalArticles
                        : 0
                    }
                  />
                  <span>
                    {[bundle.totalArticlesRead, bundle.totalArticles].join(
                      ' / '
                    )}
                  </span>
                </ProgressContainer>
                <button
                  onClick={() => {
                    setSelectedBundle(bundle);
                    logGAEvent({
                      event: 'eventTracker',
                      eventCat: 'education',
                      eventAct: 'click',
                      eventLbl:
                        'view_progress_' +
                        bundle.title.toLowerCase().replace(/ /g, '_'),
                    });
                  }}>
                  {getString(
                    'educationCardComponent.instructions.viewProgress'
                  )}
                </button>
              </BundleRow>
            ))}
          </ContentContainer>
        )}
      </Container>
    </Card>
  );
};

export default Education;
