import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import parse from 'html-react-parser';
import arrow from '../../../assets/images/breadcrumb_arrow.svg';
import Layout from '../../main/Layout';
import { getString, useUserContext } from '../../../context/UserContext';
import { Card } from '../detail/Card';
import infoImage from '../../../assets/images/info.svg';
import selectedImage from '../../../assets/images/selected.svg';
import selectedLockedImage from '../../../assets/images/selected_locked.svg';
import MedicationModule from './MedicationModule';
import AppointmentModule from './AppointmentModule';
import { Education, Patient, Tracker } from '../../../models';
import axios from 'axios';
import Endpoints from '../../../api/endpoints';
import { useNavigate, useParams } from 'react-router';
import { ErrorToast, SuccessToast } from '../../shared/ToastrNotifications';
import { logGAEvent } from '../../../shared/services/googleAnalyticsService';
import Loader from '../../shared/Loader';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { styled as muiStyled } from '@mui/material/styles';
import EducationStatusModal from '../detail/modals/EducationStatusModal';

const Breadcrumb = styled.h1`
  color: ${props => props.theme.colors.charcoal};
  display: inline-block;
  word-wrap: normal;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 3px;
  font-size: 20px;
  font-weight: 900;
  padding: '0 15px';

  img {
    height: 15px;
    margin: 0 10px;
  }

  span {
    color: ${props => props.theme.colors.curiousBlue};
  }
`;

const Instructions = styled.div`
  color: ${props => props.theme.colors.stoneGray};
  margin-top: 37px;

  p {
    text-transform: uppercase;
    font-weight: 500;
    &.note {
      font-weight: 600;
      padding: 0 15px;
    }
    span.grey {
      color: ${props => props.theme.colors.loblolly};
    }
    span.normal {
      text-transform: none;
      font-weight: normal;
    }

    a {
      color: ${props => props.theme.colors.pacificBlue};
    }

    a:hover {
      text-decoration: underline;
    }
  }
`;

const FlexBox = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  @media (min-width: ${props => props.theme.breakPoints.md}) {
    flex-direction: row;
    gap: 30px;
  }
`;

const Icon = styled.span`
  background: url(${infoImage}) no-repeat;
  width: 20px !important;
  height: 20px;
  position: relative;
  bottom: -4px;
  margin: 0;
  cursor: pointer;
  float: right;
`;

const TrackerList = styled.div`
  padding: 15px 25px;
  div.tracker {
    width: 100%;
    float: left;
    display: flex;
    gap: 8px;
    padding: 15px 0px;
    color: ${props => props.theme.colors.lightGray};
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    width: 50%;
    label {
      cursor: pointer;
      align-self: end;
    }
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  button {
    background-color: ${props => props.theme.colors.pacificBlue};
    border: 1px solid ${props => props.theme.colors.pacificBlue};
    border-radius: 21px;
    color: ${props => props.theme.colors.white};
    display: inline;
    font-weight: 900;
    height: 42px;
    padding: 10px 15px;
    text-align: center;
    text-transform: uppercase;
    width: 30%;
    margin: 20px 0 45px 0;
  }
`;

const Disclaimer = styled.p`
  color: ${props => props.theme.colors.charcoal};
  font-size: 16px;
  text-align: center;
`;

const EducationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  margin: 0 auto;
  gap: 5px;
`;

const EducationBox = styled.div`
  height: 200px;
  margin: 0;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.athensGray};
  border-radius: 10px;
  padding: 10px 15px 0;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  flex: 0 0 100%;
  text-transform: uppercase;
  position: relative;
  @media (min-width: ${props => props.theme.breakPoints.md}) {
    flex: 0 0 calc(33.33% - 5px);
  }
  &.selected {
    border: 1px solid ${props => props.theme.colors.ceruleanBlue};
    color: ${props => props.theme.colors.ceruleanBlue};
  }
  h4 {
    font-size: 20px;
    font-weight: 900;
    margin: 12px 0;
    text-align: center;
    line-height: 1.2;
  }
  button {
    background-color: transparent;
    border: 1px solid ${props => props.theme.colors.ashGray};
    border-radius: 5px;
    color: ${props => props.theme.colors.slateGray};
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    padding: 1px 6px;
    text-transform: uppercase;
    width: 76px;
    margin: 0 auto;
  }
  p {
    img.hide {
      visibility: hidden;
    }
  }
`;

const StyledTooltip = muiStyled(({ className, ...props }: TooltipProps) => (
  <Tooltip placement={'right'} {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    font-size: 14px;
    font-family: Brandon;
    line-height: 1.5;
    letter-spacing: 1px;
    font-weight: 400;
  }
`;

const CustomizeCareModule = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [patientId] = useState(() => parseInt(id || ''));
  const [educations, setEducations] = useState<Education[]>([]);
  const [trackers, setTrackers] = useState<Tracker[]>([]);
  const [patient, setPatient] = useState<Patient>();
  const [selectedEducation, setSelectedEducation] = useState<Education>();
  const [checkedTrackers, setCheckedTrackers] = useState<{
    [key: number]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const { user, setUser } = useUserContext();

  useEffect(() => {
    if (user?.superAdmin) {
      navigate('/hcp/users');
      return;
    }
  }, [user, navigate]);

  useEffect(() => {
    axios
      .get(Endpoints.patientProfile(patientId))
      .then(response => {
        setPatient(response?.data);
      })
      .catch(error => {
        if (error.response?.status === 401) {
          // prevent displaying toast when
          // - request was cancelled
          // - response status is 401 unauthorized because user will be redirected to login
          setUser(undefined);
          navigate('/login');
        }
        ErrorToast(error);
      });

    axios
      .get(Endpoints.customTrackers(patientId))
      .then(response => {
        const trackers = response?.data;
        const sorted = [...trackers].sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        setTrackers(sorted);

        const initialCheckedState: { [key: string]: boolean } = sorted.reduce(
          (acc, item) => {
            acc[item.id] = item.default || item.previouslyActive;
            return acc;
          },
          {}
        );

        setCheckedTrackers(initialCheckedState);
      })
      .catch(error => {
        ErrorToast(error);
      });

    axios
      .get(Endpoints.patientEducations(patientId))
      .then(response => {
        setEducations(response.data);
      })
      .catch(error => {
        ErrorToast(error);
      });
  }, [patientId, navigate, setUser]);

  const handleEducationSelection = (index: number) => {
    googleAnalytics('select_education', educations[index].title);
    setEducations(prevData => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        previouslyActive: !newData[index].previouslyActive,
      };
      return newData;
    });
  };

  const openBundleModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    education: Education
  ) => {
    event.stopPropagation();
    setSelectedEducation(education);
    googleAnalytics(
      'select_education_items',
      education.title,
      education.articles.length
    );
  };

  const googleAnalytics = (
    type: string,
    label?: string,
    items?: string | number | boolean
  ) => {
    switch (type) {
      case 'select_education':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'selected_education_activities',
          eventAct: 'click',
          eventLbl: label,
        });
        break;
      case 'select_education_items':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'selected_education_activities',
          eventAct: 'click',
          eventLbl: label + '_' + items + '_items',
        });
        break;
      case 'education_hover':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'selected_education_activities',
          eventAct: 'click',
          eventLbl: 'education_activity_info',
        });
        break;
      case 'tracker_hover':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'selected_tracker_activities',
          eventAct: 'click',
          eventLbl: 'tracker_info',
        });
        break;
      case 'tracker':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'selected_tracker_activities',
          eventAct: items ? 'check' : 'uncheck',
          eventLbl: label,
        });
        break;
      case 'submit':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'care_module',
          eventAct: 'click',
          eventLbl: 'save',
        });
        break;
    }
  };

  const handleTrackerSelection = (id: number, title: string) => {
    googleAnalytics('tracker', title, !checkedTrackers[id]);
    setCheckedTrackers(prev => ({
      ...prev,
      [id]: !prev[id],
    }));

    const updatedData = trackers.map(item => ({
      ...item,
      previouslyActive:
        item.id === id ? !item.previouslyActive : item.previouslyActive,
    }));
    setTrackers(updatedData);
  };

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();

    setIsLoading(true);
    googleAnalytics('submit');

    const requests = [
      {
        url: Endpoints.customTrackers(patientId),
        body: {
          trackers: trackers.map(tracker => {
            return {
              id: tracker.id,
              title: tracker.title,
              active: tracker.previouslyActive,
            };
          }),
        },
      },
      {
        url: Endpoints.patientEducations(patientId),
        body: {
          education: educations.map(education => {
            return {
              id: education.id,
              title: education.title,
              active: education.previouslyActive || education.default,
            };
          }),
        },
      },
    ];

    setIsLoading(true);
    const axiosRequests = requests.map(request =>
      axios
        .post(request.url, request.body)
        .then(response => {
          SuccessToast(
            response?.data?.message,
            getString('toastrMessages.types.success')
          );
        })
        .catch(error => {
          ErrorToast(error);
        })
    );

    // Wait for all requests to complete
    Promise.all(axiosRequests)
      .then(() => {
        setTimeout(() => {
          navigate(`/patient/${patientId}`);
        }, 6000);
      })
      .catch(error => {
        // Handle errors if any of the requests fail
        ErrorToast(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Layout>
      {selectedEducation && (
        <EducationStatusModal
          education={selectedEducation}
          onClose={() => setSelectedEducation(undefined)}
        />
      )}

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumb>
            {patient?.firstName.concat(' ', patient.lastName)}
            <img alt='breadcrumb' src={arrow} />
            <span>{patient?.carePlanTemplate}</span>
          </Breadcrumb>

          <Instructions>
            <p>
              {getString(
                'moduleCustomizationComponent.instructions.addMedOrAppt'
              )}
            </p>
            <p>
              <span className='grey'>
                {getString(
                  'moduleCustomizationComponent.instructions.important'
                )}
              </span>{' '}
              <span className='normal'>
                {parse(
                  getString('moduleCustomizationComponent.instructions.asAHCP')
                )}
              </span>
            </p>
          </Instructions>
          <FlexBox>
            <MedicationModule />
            <AppointmentModule />
          </FlexBox>

          <div id='education-card'>
            <Instructions>
              <p>
                {getString('moduleCustomizationComponent.title.education')}
                <StyledTooltip
                  title={getString(
                    'moduleCustomizationComponent.tooltip.education'
                  )}
                  arrow
                  placement='left'
                  onMouseOver={() => googleAnalytics('education_hover')}>
                  <Icon />
                </StyledTooltip>
              </p>
            </Instructions>
            <EducationContainer>
              {educations.map((education, i) => (
                <EducationBox
                  role='button'
                  aria-hidden='true'
                  tabIndex={-1}
                  onClick={() => handleEducationSelection(i)}
                  key={education.id}
                  className={education.previouslyActive ? 'selected' : ''}>
                  <p>
                    <img
                      src={selectedImage}
                      alt='selected'
                      className={education.previouslyActive ? '' : 'hide'}
                    />
                  </p>
                  <p>
                    {education.required && (
                      <img src={selectedLockedImage} alt='locked' />
                    )}
                  </p>
                  <h4>{education.title}</h4>
                  <button onClick={event => openBundleModal(event, education)}>
                    {education.articles.length}
                    {education.articles.length > 1 ? ' Items' : ' Item'}
                  </button>
                </EducationBox>
              ))}
            </EducationContainer>
          </div>

          <Instructions>
            <p>{getString('moduleCustomizationComponent.title.tracker')}</p>
          </Instructions>
          <Card>
            <TrackerList>
              {trackers.map(tracker => (
                <div key={tracker.id} className='tracker'>
                  <input
                    type='checkbox'
                    id={`${tracker.id}`}
                    value={tracker.id}
                    checked={checkedTrackers[tracker.id] || false}
                    disabled={tracker.required}
                    onChange={() =>
                      handleTrackerSelection(tracker.id, tracker.title)
                    }
                  />
                  <label htmlFor={`${tracker.id}`}>{tracker.title}</label>
                  <StyledTooltip
                    title={tracker.description}
                    arrow
                    onMouseOver={() => googleAnalytics('tracker_hover')}>
                    <Icon />
                  </StyledTooltip>
                </div>
              ))}
            </TrackerList>
          </Card>

          <Instructions>
            <p className='note'>
              {getString('moduleCustomizationComponent.instructions.note')}{' '}
              <span className='normal'>
                {parse(
                  getString('moduleCustomizationComponent.instructions.toHCP')
                )}
              </span>
            </p>
          </Instructions>

          <ButtonContainer>
            <button onClick={handleSubmit} disabled={isLoading}>
              {getString('form.button.save')}
            </button>
          </ButtonContainer>

          <Disclaimer>{getString('disclaimer.footer')}</Disclaimer>
        </>
      )}
    </Layout>
  );
};

export default CustomizeCareModule;
