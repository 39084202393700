import styled from 'styled-components';
import { useUserContext } from '../../../context/UserContext';
import pencil from '../../../assets/images/pencil-blue.svg';
import clock from '../../../assets/images/clock.svg';
import magic from '../../../assets/images/magic.svg';
import chart from '../../../assets/images/chart-column.svg';
import table from '../../../assets/images/table.svg';
import chevronDown from '../../../assets/images/chevron_down.svg';
import chevronUp from '../../../assets/images/chevron_up.svg';
import { useEffect, useState, useRef } from 'react';
import { Card, CardHeader, Divider } from './Card';
import { BloodPressureModal } from '../modals/riskscore/BloodPressureModal';
import IconButton from '../../shared/IconButton';
import { HeartRateModal } from '../modals/riskscore/HeartRateModal';
import { SixMinuteWalkModal } from '../modals/riskscore/SixMinuteWalkModal';
import { BNPModal } from '../modals/riskscore/BNPModal';
import { WHOFCModal } from '../modals/riskscore/WHOFCModal';
import { EGFRModal } from '../modals/riskscore/EGFRModal';
import { CalculateRiskScoreModal } from '../modals/riskscore/CalculateRiskScoreModal';
import { RiskScoreTrackerHeader } from './RiskScoreTrackerHeader';
import axios from 'axios';
import Endpoints from '../../../api/endpoints';
import {
  Reveal2LiteRiskScore,
  RiskScoreTrackers,
  SixMWDTracker,
  Tracker,
} from '../../../api/models';
import { ErrorToast, SuccessToast } from '../../shared/ToastrNotifications';
import { CalculateRiskScoreErrorModal } from '../modals/riskscore/CalculateRiskScoreErrorModal';
import Loader from '../../shared/Loader';
import {
  format,
  formatISO,
  parseISO,
  sub,
  addDays,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { GraphDataPoint, Patient, StandardGraph } from '../../../models';
import DateRangePicker from '../../shared/DateRangePicker';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { riskScoreChartOptions } from '../../../util/chartOptions';
import { RiskScoreTable } from './RiskScoreTable';
import parser from 'html-react-parser';
import { enUS } from 'date-fns/locale';

interface RiskScoreProps {
  patientId: number;
  patient: Patient | undefined;
  parentReload: boolean;
  showOnPrint?: boolean;
  triggerParentReload: () => void;
}

enum ModalTypes {
  none,
  bloodPressure,
  heartRate,
  sixMinWalk,
  bnp,
  whoFC,
  egfr,
  calculateRiskScore,
  calculateError,
}

const RiskScoreCard = styled(Card)`
  padding: 10px 15px;
`;

const Container = styled.div``;

const Disclaimer = styled.div`
  font-size: 16px;
  color: ${props => props.theme.colors.shark};
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 8px;

  form {
    text-align: center;
  }

  &.padding {
    padding: 10px 15px;
  }
`;

const TrackerContainer = styled.div`
  padding: 10px 0 0;
`;

const TrackerHeader = styled.div`
  display: flex;

  div {
    flex-grow: 1;
    font-size: 20px;
    text-align: center;
    color: ${props => props.theme.colors.charcoal};
    font-weight: 700;
    width: 30px;
  }

  div:first-child,
  div:last-child {
    width: 0px;
  }

  div:nth-child(4) {
    width: 70px;
  }
`;

const TrackerContent = styled(TrackerHeader)`
  padding-top: 8px;

  div.loading-container {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 85px;

    img {
      width: 30px;
    }
  }
`;

const Row = styled.div`
  padding: 20px 0;

  &.flex {
    display: flex;
    gap: 16px;
    flex-direction: row;

    @media (min-width: ${props => props.theme.breakPoints.md}) {
      span:nth-child(1) {
        justify-content: flex-end;
      }
    }

    @media (max-width: ${props => props.theme.breakPoints.md}) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
`;

const Link = styled.span`
  width: 50%;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.pacificBlue};
  font-weight: 600;

  &.left-align {
    button {
      margin: 0;
      text-transform: capitalize;
    }
  }

  img {
    margin-right: 8px;
    vertical-align: middle;

    &.expand-icon {
      color: ${props => props.theme.colors.pacificBlue};
      width: 14px;
      margin: 0;
      margin-left: 10px;
    }
  }

  button {
    color: ${props => props.theme.colors.pacificBlue};
    font-weight: 600;
    display: flex;
    align-items: center;
    background-color: unset;
    border: none;

    img {
      width: 18px;
    }

    &.expand-text {
      font-size: 16px;
      font-weight: 800;
    }
  }
  &.centered {
    margin: auto;
    justify-content: center;
  }
`;

const TrackerItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const TrackerMetricContainer = styled.div`
  width: auto !important;
  max-height: 85px !important;
  display: flex;
  flex-direction: column;
  &.who-fc {
    justify-content: space-between;
  }
`;
const TrackerMetric = styled.h4`
  font-weight: 600;
  font-size: 20px;
  margin: 0;

  &.who-fc {
    font-size: 18px;
  }
`;
const TrackerMeasurement = styled.b`
  color: ${props => props.theme.colors.raven};
  font-weight: bolder;
  font-size: 14px;
  line-height: -5px;
`;
const TrackerDate = styled.p`
  margin: 0;
  text-transform: capitalize;
  color: ${props => props.theme.colors.raven};
  font-size: 14px;
  font-weight: 400;
`;

const MetricContainter = styled.div`
  width: auto !important;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 5px;

  .test-type {
    font-size: 14px;
    font-weight: 400;
  }

  &.bnp-container {
    display: flex;
    flex-direction: row;
  }
`;
const ButtonContainer = styled.p`
  margin: 20px 0;
`;

const VisualizationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const RiskScoreVisualizationHeader = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  flex-wrap: wrap;
  margin: 20px 0;
  align-items: center;

  .visualization-header {
    margin: 0;
    color: ${props => props.theme.colors.suvaGray};
    font-weight: 800;
    font-size: 20px;
  }
`;

const RiskScoreContainer = styled.div`
  padding: 0 30px;
`;

const RiskScore = ({
  patientId,
  patient,
  parentReload,
  showOnPrint = false,
  triggerParentReload,
}: RiskScoreProps) => {
  const [activeModal, setActiveModal] = useState(ModalTypes.none);
  const [trackerAdherenceData, setTrackerData] = useState<RiskScoreTrackers>();
  const [modalData, setModalData] = useState<Tracker[]>();
  const [sixMWTracker, setSixMWTracker] = useState<SixMWDTracker>();
  const [riskScoreData, setRiskScoreData] = useState<Reveal2LiteRiskScore[]>();
  const [riskScoreGraphData, setRiskScoreGraphData] = useState<StandardGraph>();

  const [trackerDataLoading, setTrackerDataLoading] = useState(true);
  const [sixMWTrackerDataLoading, setSixMWTrackerDataLoading] = useState(true);
  const [riskScoreDataLoading, setRiskScoreDataLoading] = useState(true);

  const [contentExpanded, setContentExpanded] = useState(false);
  const [graphView, setGraphView] = useState(true);

  const [startDate, setStartDate] = useState<Date>();
  const [minStartDate, setMinStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState(endOfDay(new Date()));

  const [hasAcknowledged, setHasAcknowledged] = useState<boolean>();
  const [hasAcknowledgedLoading, setHasAcknowledgedLoading] = useState(true);

  const { getString } = useUserContext();
  const hcpId = useRef<number>();
  const hcpRegistrationDate = useRef<string>();

  // set initial start date
  useEffect(() => {
    if (!patient || !!startDate) {
      return;
    }
    const registrationDate = startOfDay(parseISO(patient.registrationDate));
    setMinStartDate(registrationDate);
    setStartDate(
      new Date(
        Math.max(addDays(endDate, -90).getTime(), registrationDate.getTime())
      )
    );
  }, [patient, startDate, endDate]);

  // get hcp profile data
  useEffect(() => {
    setHasAcknowledgedLoading(true);

    axios
      .get(Endpoints.getCurrentHPC())
      .then(response => {
        setHasAcknowledged(response.data['pah_acknowledgement']);
        hcpId.current = response.data['id'];
      })
      .catch(error => ErrorToast(error))
      .finally(() => {
        setHasAcknowledgedLoading(false);
      });
  }, [getString]);

  // get patient registration date
  useEffect(() => {
    hcpRegistrationDate.current = patient?.registrationDate;
  }, [patient]);

  // load tracker data
  useEffect(() => {
    // Tracker data for Blood pressure, Heart Rate, BNP, WHO FC, and EGFR
    setTrackerDataLoading(true);
    axios
      .get(Endpoints.riskScoreTrackerData(patientId), {
        params: {
          dateTime: formatISO(new Date()).slice(0, -14) + '23:59:59+00:00',
          riskScoreType: 'reveal2lite',
        },
      })
      .then(response => {
        setTrackerData(response.data);
      })
      .catch(error => ErrorToast(error))
      .finally(() => {
        setTrackerDataLoading(false);
      });

    // Tracker data for Six MWD
    setSixMWTrackerDataLoading(true);

    // get start and end time
    const current = formatISO(new Date());
    const endDateTime = current.replace('+', '-');
    const startDateTime = formatISO(sub(new Date(), { days: 90 })).replace(
      '+',
      '-'
    );

    axios
      .get(Endpoints.sixMWDRiskScoreTrackerData(patientId), {
        params: {
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        },
      })
      .then(response => {
        if (response.data.length > 0) {
          setSixMWTracker(response.data.pop());
        }
      })
      .catch(error => ErrorToast(error))
      .finally(() => {
        setSixMWTrackerDataLoading(false);
      });
  }, [patientId, parentReload]);

  // load modal data
  useEffect(() => {
    axios
      .get(Endpoints.customTracker(patientId))
      .then(response => {
        setModalData(response.data);
      })
      .catch(error => {
        ErrorToast(error);
      });
  }, [patientId]);

  // load risk score data for graph
  useEffect(() => {
    if (!startDate) {
      return;
    }

    setRiskScoreDataLoading(true);

    axios
      .get(Endpoints.riskScoreData(patientId), {
        params: {
          startDateTime: formatISO(startDate).slice(0, -14) + '00:00:00+00:00',
          endDateTime: formatISO(endDate),
          riskScoreType: 'RevealTwoLiteRiskScore',
        },
      })
      .then(response => {
        setRiskScoreData(response.data);
      })
      .catch(error => {
        ErrorToast(error);
      })
      .finally(() => {
        setRiskScoreDataLoading(false);
      });
  }, [startDate, endDate, getString, patientId, parentReload]);

  // transform the risk score data into a graph-parsable format
  useEffect(() => {
    const scores: {
      label: string;
      value: string | number | null;
      units: string | null;
      unitsLabel?: string;
    }[] = [];

    const values: GraphDataPoint[] = [];

    riskScoreData?.map(riskScore => {
      const scoreAverage = {
        label: `Risk Score: ${riskScore.score}`,
        value: riskScore.score,
        units: '',
      };
      values.push({
        date: riskScore.dateTime,
        value: riskScore.score.toString(),
      });
      scores.push(scoreAverage);
    });

    const graphData: StandardGraph = {
      title: '',
      graphType: 'Scatter',
      dataType: 'Integer',
      mobileDisplay: true,
      values: { 'RISK SCORE': values },
      xLabel: '',
      yLabel: '',
      yMin: '0',
      yMax: '14',
      averages: scores,
    };

    setRiskScoreGraphData(graphData);
  }, [riskScoreData]);
  // send network request for accepting acknowledgement
  const confirmAcknowledge = (acknowledged: boolean) => {
    axios
      .put(Endpoints.updateStaff(hcpId.current ?? 0), {
        acknowledge: acknowledged,
      })
      .then(() => {
        SuccessToast('', getString('toastrMessages.types.success'));
        setHasAcknowledged(acknowledged);
      })
      .catch(error => {
        ErrorToast(error);
      });
  };

  // handles request update button press
  const handleRequestUpdate = () => {
    axios
      .post(Endpoints.sendNotification(patientId))
      .then(response => {
        SuccessToast(
          response.data.message,
          getString('toastrMessages.types.success')
        );
      })
      .catch(error => {
        ErrorToast(error);
      });
  };

  const ActiveModal = () => {
    switch (activeModal) {
      case ModalTypes.none:
        return null;
      case ModalTypes.bloodPressure:
        return (
          <BloodPressureModal
            modalIsOpen={true}
            setModalIsOpen={() => setActiveModal(ModalTypes.none)}
            trackerData={
              modalData?.filter(
                tracker => tracker.recordType === 'BloodPressure'
              )[0]
            }
            adherenceData={
              trackerAdherenceData?.customTrackerData.BloodPressure ?? undefined
            }
            patientId={patientId}
            refreshTable={() => {
              triggerParentReload();
            }}
          />
        );
      case ModalTypes.heartRate:
        return (
          <HeartRateModal
            modalIsOpen={true}
            setModalIsOpen={() => setActiveModal(ModalTypes.none)}
            patientId={patientId}
            trackerData={
              modalData?.filter(
                tracker => tracker.recordType === 'HeartRate'
              )[0]
            }
            adherenceData={
              trackerAdherenceData?.customTrackerData.HeartRate ?? undefined
            }
            refreshTable={() => {
              triggerParentReload();
            }}
          />
        );
      case ModalTypes.sixMinWalk:
        return (
          <SixMinuteWalkModal
            modalIsOpen={true}
            setModalIsOpen={() => setActiveModal(ModalTypes.none)}
            patientId={patientId}
            refreshTable={() => {
              triggerParentReload();
            }}
          />
        );
      case ModalTypes.bnp:
        return (
          <BNPModal
            modalIsOpen={true}
            setModalIsOpen={() => setActiveModal(ModalTypes.none)}
            patientId={patientId}
            trackerData={
              modalData?.filter(tracker => tracker.recordType === 'Bnp')[0]
            }
            adherenceData={
              trackerAdherenceData?.customTrackerData.Bnp ?? undefined
            }
            refreshTable={() => {
              triggerParentReload();
            }}
          />
        );
      case ModalTypes.whoFC:
        return (
          <WHOFCModal
            modalIsOpen={true}
            setModalIsOpen={() => setActiveModal(ModalTypes.none)}
            patientId={patientId}
            trackerData={
              modalData?.filter(
                tracker => tracker.recordType === 'WhoFunctionalClass'
              )[0]
            }
            adherenceData={
              trackerAdherenceData?.customTrackerData.WhoFunctionalClass ??
              undefined
            }
            refreshTable={() => {
              triggerParentReload();
            }}
          />
        );
      case ModalTypes.egfr:
        return (
          <EGFRModal
            modalIsOpen={true}
            setModalIsOpen={() => setActiveModal(ModalTypes.none)}
            patientId={patientId}
            trackerData={
              modalData?.filter(tracker => tracker.recordType === 'Egfr')[0]
            }
            adherenceData={
              trackerAdherenceData?.customTrackerData.Egfr ?? undefined
            }
            refreshTable={() => {
              triggerParentReload();
            }}
          />
        );
      case ModalTypes.calculateRiskScore:
        return (
          <CalculateRiskScoreModal
            modalIsOpen={true}
            setModalIsOpen={() => setActiveModal(ModalTypes.none)}
            triggerErrorModal={() => setActiveModal(ModalTypes.calculateError)}
            patientId={patientId}
            riskScoreData={trackerAdherenceData}
            sixMwdRiskScoreData={sixMWTracker}
            refreshTable={() => {
              triggerParentReload();
            }}
          />
        );
      case ModalTypes.calculateError:
        return (
          <CalculateRiskScoreErrorModal
            modalIsOpen={true}
            setModalIsOpen={() => {
              setActiveModal(ModalTypes.none);
            }}
          />
        );

      default:
        return null;
    }
  };

  const formattedDate = (dateTimeString: string | undefined | null) => {
    if (!dateTimeString) return;
    // Parse the ISO string
    const parsedDate = utcToZonedTime(parseISO(dateTimeString), 'EST');

    // Format the date using the 'LLL d yyyy' format and the 'en-US' locale
    return format(parsedDate, 'LLL d yyyy', { locale: enUS });
  };

  return (
    <RiskScoreCard className={showOnPrint ? '' : 'do-not-print'}>
      {ActiveModal()}
      <CardHeader>
        <h3>{getString('riskScoreCardComponent.title.riskScore')}</h3>
      </CardHeader>
      <Container>
        {hasAcknowledgedLoading ? (
          <Loader />
        ) : (
          <>
            <Disclaimer>
              {parser(
                getString(
                  `riskScoreCardComponent.disclaimer.${
                    contentExpanded || !hasAcknowledged ? 'long' : 'short'
                  }`
                )
              )}
              {!hasAcknowledged ? (
                <form>
                  <label htmlFor='acknowledge'>
                    <input
                      id='acknowledge'
                      type='checkbox'
                      name='acknowledge'
                      data-testid='acknowledge'
                      checked={hasAcknowledged ?? false}
                      onChange={e => confirmAcknowledge(e.target.checked)}
                    />
                    {getString(
                      'riskScoreCardComponent.label.acknowledge'
                    ).toUpperCase()}
                  </label>
                </form>
              ) : (
                <Link className='centered'>
                  <button
                    onClick={() => setContentExpanded(!contentExpanded)}
                    className='expand-text'
                    data-testid='expand-button'>
                    {getString(
                      `riskScoreCardComponent.button.see${
                        contentExpanded ? 'Less' : 'More'
                      }`
                    )}
                    <img
                      src={contentExpanded ? chevronUp : chevronDown}
                      alt='expand and contract icon'
                      className='expand-icon'
                    />
                  </button>
                </Link>
              )}
            </Disclaimer>
            <Row>
              <h3 className='secondary'>
                {getString('riskScoreCardComponent.title.associatedValue')}
              </h3>
              <Disclaimer className='padding'>
                {getString('riskScoreCardComponent.disclaimer.associatedValue')}
              </Disclaimer>
            </Row>
          </>
        )}
        <Divider />
        <TrackerContainer>
          <TrackerHeader>
            <RiskScoreTrackerHeader
              title={getString('riskScoreCardComponent.tracker.title.bp')}
              toolTipText={getString(
                'riskScoreCardComponent.tracker.tooltip.bp'
              )}
            />
            <RiskScoreTrackerHeader
              title={getString('riskScoreCardComponent.tracker.title.hr')}
              toolTipText={getString(
                'riskScoreCardComponent.tracker.tooltip.hr'
              )}
            />
            <RiskScoreTrackerHeader
              title={getString('riskScoreCardComponent.tracker.title.6mwd')}
              toolTipText={getString(
                'riskScoreCardComponent.tracker.tooltip.6mwd'
              )}
            />
            <RiskScoreTrackerHeader
              title={getString('riskScoreCardComponent.tracker.title.bnp')}
              toolTipText={getString(
                'riskScoreCardComponent.tracker.tooltip.bnp'
              )}
            />
            <RiskScoreTrackerHeader
              title={getString('riskScoreCardComponent.tracker.title.who')}
              toolTipText={getString(
                'riskScoreCardComponent.tracker.tooltip.who'
              )}
            />
            <RiskScoreTrackerHeader
              title={getString('riskScoreCardComponent.tracker.title.egfr')}
              toolTipText={getString(
                'riskScoreCardComponent.tracker.tooltip.egfr'
              )}
            />
          </TrackerHeader>
          <Divider className='dark' />
          <TrackerContent>
            <TrackerItem>
              {trackerDataLoading ? (
                <div className='loading-container'>
                  <Loader />
                </div>
              ) : trackerAdherenceData?.customTrackerData.BloodPressure
                  ?.adherence ? (
                <TrackerMetricContainer>
                  <TrackerMetric data-testid='blood-pressure'>
                    {' '}
                    {`${trackerAdherenceData?.customTrackerData.BloodPressure.adherence.fields[0].systolic}/${trackerAdherenceData?.customTrackerData.BloodPressure.adherence.fields[0].diastolic}`}
                  </TrackerMetric>
                  <TrackerMeasurement>
                    {
                      trackerAdherenceData?.customTrackerData.BloodPressure
                        .adherence.fields[0].unitsLabel
                    }
                  </TrackerMeasurement>
                  <TrackerDate>
                    {formattedDate(
                      trackerAdherenceData?.customTrackerData.BloodPressure
                        .adherence.dateTime
                    )}
                  </TrackerDate>
                </TrackerMetricContainer>
              ) : (
                <p>—</p>
              )}
              <ButtonContainer>
                <IconButton
                  alt='pencil'
                  imgSrc={pencil}
                  onClick={() => setActiveModal(ModalTypes.bloodPressure)}
                />
              </ButtonContainer>
            </TrackerItem>
            <TrackerItem>
              {trackerDataLoading ? (
                <div className='loading-container'>
                  <Loader />
                </div>
              ) : trackerAdherenceData?.customTrackerData.HeartRate
                  ?.adherence ? (
                <TrackerMetricContainer>
                  <TrackerMetric data-testid='heart-rate'>
                    {' '}
                    {
                      trackerAdherenceData?.customTrackerData.HeartRate
                        .adherence.fields[0].value
                    }
                  </TrackerMetric>
                  <TrackerMeasurement>
                    {
                      trackerAdherenceData?.customTrackerData.HeartRate
                        .adherence.fields[0].unitsLabel
                    }
                  </TrackerMeasurement>
                  <TrackerDate>
                    {formattedDate(
                      trackerAdherenceData?.customTrackerData.HeartRate
                        .adherence.dateTime
                    )}
                  </TrackerDate>
                </TrackerMetricContainer>
              ) : (
                <p>—</p>
              )}
              <p>
                <IconButton
                  alt='pencil'
                  imgSrc={pencil}
                  onClick={() => setActiveModal(ModalTypes.heartRate)}
                />
              </p>
            </TrackerItem>
            <TrackerItem>
              {sixMWTrackerDataLoading ? (
                <div className='loading-container'>
                  <Loader />
                </div>
              ) : sixMWTracker ? (
                <TrackerMetricContainer>
                  <TrackerMetric data-testid='six-mwd'>
                    {' '}
                    {sixMWTracker.sixMwd.value}
                  </TrackerMetric>
                  <TrackerMeasurement>
                    {sixMWTracker.sixMwd.unitsLabel}
                  </TrackerMeasurement>
                  <TrackerDate>
                    {formattedDate(sixMWTracker.dateTime)}
                  </TrackerDate>
                </TrackerMetricContainer>
              ) : (
                <p>—</p>
              )}
              <p>
                <IconButton
                  alt='pencil'
                  imgSrc={pencil}
                  onClick={() => setActiveModal(ModalTypes.sixMinWalk)}
                />
              </p>
            </TrackerItem>
            <TrackerItem>
              {trackerDataLoading ? (
                <div className='loading-container'>
                  <Loader />
                </div>
              ) : trackerAdherenceData?.customTrackerData.Bnp?.adherence ? (
                <TrackerMetricContainer>
                  <MetricContainter className='bnp-container'>
                    <TrackerMetric data-testid='bnp'>
                      {' '}
                      {
                        trackerAdherenceData?.customTrackerData.Bnp.adherence
                          .fields[0].value
                      }
                    </TrackerMetric>
                    <small className='test-type'>
                      {
                        trackerAdherenceData?.customTrackerData.Bnp.adherence
                          .fields[1].value
                      }
                    </small>
                  </MetricContainter>
                  <TrackerMeasurement>
                    {
                      trackerAdherenceData?.customTrackerData.Bnp.adherence
                        .fields[0].unitsLabel
                    }
                  </TrackerMeasurement>
                  <TrackerDate>
                    {formattedDate(
                      trackerAdherenceData?.customTrackerData.Bnp.adherence
                        .dateTime
                    )}
                  </TrackerDate>
                </TrackerMetricContainer>
              ) : (
                <p>—</p>
              )}
              <p>
                <IconButton
                  alt='pencil'
                  imgSrc={pencil}
                  onClick={() => setActiveModal(ModalTypes.bnp)}
                />
              </p>
            </TrackerItem>
            <TrackerItem>
              {trackerDataLoading ? (
                <div className='loading-container'>
                  <Loader />
                </div>
              ) : trackerAdherenceData?.customTrackerData.WhoFunctionalClass
                  ?.adherence ? (
                <TrackerMetricContainer className='who-fc'>
                  <TrackerMetric className='who-fc' data-testid='who-fc'>
                    {' '}
                    {
                      trackerAdherenceData?.customTrackerData.WhoFunctionalClass
                        .adherence?.fields[0]?.arrayText?.[
                        +(
                          trackerAdherenceData?.customTrackerData
                            .WhoFunctionalClass.adherence.fields[0].value ?? '0'
                        )
                      ]
                    }
                  </TrackerMetric>
                  <TrackerDate>
                    {formattedDate(
                      trackerAdherenceData?.customTrackerData.WhoFunctionalClass
                        .adherence.dateTime
                    )}
                  </TrackerDate>
                </TrackerMetricContainer>
              ) : (
                <p>—</p>
              )}
              <p>
                <IconButton
                  alt='pencil'
                  imgSrc={pencil}
                  onClick={() => setActiveModal(ModalTypes.whoFC)}
                />
              </p>
            </TrackerItem>
            <TrackerItem>
              {trackerDataLoading ? (
                <div className='loading-container'>
                  <Loader />
                </div>
              ) : trackerAdherenceData?.customTrackerData.Egfr?.adherence ? (
                <TrackerMetricContainer>
                  <TrackerMetric data-testid='egfr'>
                    {' '}
                    {
                      trackerAdherenceData?.customTrackerData.Egfr.adherence
                        .fields[0].value
                    }
                  </TrackerMetric>
                  <TrackerMeasurement>
                    {
                      trackerAdherenceData?.customTrackerData.Egfr.adherence
                        .fields[0].unitsLabel
                    }
                  </TrackerMeasurement>
                  <TrackerDate>
                    {formattedDate(
                      trackerAdherenceData?.customTrackerData.Egfr.adherence
                        .dateTime
                    )}
                  </TrackerDate>
                </TrackerMetricContainer>
              ) : (
                <p>—</p>
              )}
              <p>
                <IconButton
                  alt='pencil'
                  imgSrc={pencil}
                  onClick={() => setActiveModal(ModalTypes.egfr)}
                />
              </p>
            </TrackerItem>
          </TrackerContent>

          <Row className='flex'>
            <Link>
              <button
                onClick={() => setActiveModal(ModalTypes.calculateRiskScore)}>
                <img src={magic} alt='' />
                {getString('riskScoreCardComponent.tracker.estimate.score')}
              </button>
            </Link>
            <Link>
              <button onClick={handleRequestUpdate}>
                <img src={clock} alt='' />
                {getString('riskScoreCardComponent.tracker.request.update')}
              </button>
            </Link>
          </Row>
        </TrackerContainer>
        <Divider />
        <VisualizationContainer>
          <RiskScoreVisualizationHeader>
            <h3 className='visualization-header'>
              {getString('riskScoreCardComponent.title.previousResult')}{' '}
            </h3>
            <DateRangePicker
              startDate={startDate}
              minStartDate={minStartDate}
              endDate={endDate}
              onChange={(start, end) => {
                start && setStartDate(start);
                end && setEndDate(end);
              }}
            />
          </RiskScoreVisualizationHeader>
          <Link className='left-align'>
            <button onClick={() => setGraphView(!graphView)}>
              <img src={graphView ? table : chart} alt='' />
              {graphView ? 'Table View' : 'Chart View'}
            </button>
          </Link>
        </VisualizationContainer>
        {riskScoreDataLoading ? (
          <Loader />
        ) : riskScoreData && riskScoreGraphData != undefined ? (
          <RiskScoreContainer>
            {graphView ? (
              <div data-testid='risk-score-graph'>
                <HighchartsReact
                  highcharts={Highcharts}
                  containerProps={{ style: { width: '100%', height: '400px' } }}
                  options={riskScoreChartOptions(
                    riskScoreGraphData,
                    riskScoreData
                  )}
                />
              </div>
            ) : (
              <RiskScoreTable
                data={riskScoreData}
                data-testid='risk-score-table'
              />
            )}
            {riskScoreData.length === 0 ? (
              <p>{getString('riskScoreCardComponent.record.no')}</p>
            ) : null}
          </RiskScoreContainer>
        ) : null}
      </Container>
    </RiskScoreCard>
  );
};

export default RiskScore;
