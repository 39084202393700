import React from 'react';
import styled from 'styled-components';

const Bar = styled.div<{ $borderSize: number }>`
  display: flex;
  overflow: hidden;
  height: 14px;
  min-height: 14px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.athensGray};
  border: ${props => props.$borderSize}px solid
    ${props => props.theme.colors.charcoal};
`;

const Progress = styled.div<{ $percentage: number }>`
  background-color: ${props => props.theme.colors.charcoal};
  width: ${props => props.$percentage * 100}%;
`;

type ProgressBarProps = {
  percentage: number;
  borderSize?: number;
};

const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

export const ProgressBar = ({
  percentage = 0,
  borderSize = 0,
}: ProgressBarProps) => {
  return (
    <Bar $borderSize={borderSize}>
      <Progress $percentage={clamp(percentage, 0, 1)} />
    </Bar>
  );
};

export default ProgressBar;
