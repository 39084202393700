import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.athensGray};
  border-radius: 38px;
  height: 38px;
  padding: 0 12px;
  min-width: 126px;
  span {
    text-transform: uppercase;
    font-weight: 500;
    color: ${props => props.theme.colors.charcoal};
  }
`;

export declare interface RoundedButtonProps {
  title?: string;
  image?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const RoundedButton = (props: RoundedButtonProps) => (
  <Button onClick={props?.onClick}>
    {props.image ? <img src={props.image} alt={props.title} /> : null}
    <span>{props.title}</span>
  </Button>
);

export default RoundedButton;
