import styled from 'styled-components';
import { getString } from '../../../../context/UserContext';
import { Education, EducationBundle } from '../../../../models';
import BaseModal from '../../../shared/modals/BaseModal';
import theme from '../../../../assets/theme';
import checkedImage from '../../../../assets/images/education_check.svg';

const Title = styled.h2`
  color: ${props => props.theme.colors.charcoal};
  font-family: Brandon, Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 900;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px 50px;
  border-top: 1px solid ${props => props.theme.colors.athensGray};
  padding-top: 30px;
  gap: 30px;
  @media (max-width: ${props => props.theme.breakPoints.lg}) {
    flex-direction: column;
    gap: 0px;
  }
  div.article-count {
    p {
      margin: 0;
      margin-bottom: 15px;
      text-align: right;
      font-weight: 800;
      color: ${props => props.theme.colors.charcoal};
    }
    @media (min-width: ${props => props.theme.breakPoints.lg}) {
      flex-basis: 33%;
    }
  }
  div.list {
    ul {
      margin-top: 0;
      text-align: left;
      li {
        margin-bottom: 5px;
        font-weight: 500;
        &.read {
          list-style-image: url(${checkedImage});
          color: ${props => props.theme.colors.loblolly};
        }
      }
    }
  }
`;

type EducationStatusModalProps = {
  bundle?: EducationBundle;
  education?: Education;
  onClose: () => void;
};

export const EducationStatusModal = ({
  bundle,
  education,
  onClose,
}: EducationStatusModalProps) => (
  <BaseModal
    modalWidth={theme.modalWidths.lg}
    onCloseButtonClicked={() => onClose()}
    modalIsOpen={true}>
    <Title>{bundle?.title || education?.title}</Title>
    <Content>
      <div className='article-count'>
        <p>
          {`${
            bundle ? bundle.totalArticles : education?.articles?.length
          } ${getString('bundleModalComponent.title.articles')}`}
        </p>
      </div>
      <div className='list'>
        <ul>
          {bundle
            ? bundle.content.map(article => (
                <li key={article.id} className={article.lastRead ? 'read' : ''}>
                  {article.title}
                </li>
              ))
            : education?.articles.map(article => (
                <li key={article.id}>{article.title}</li>
              ))}
        </ul>
      </div>
    </Content>
  </BaseModal>
);

export default EducationStatusModal;
