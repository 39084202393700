import styled from 'styled-components';
import { useState } from 'react';
import theme from '../../assets/theme';

const Button = styled.button<{ $size: string }>`
  width: ${props => props.$size};
  height: ${props => props.$size};
`;
const Image = styled.img<{ $size: string }>`
  width: ${props => props.$size};
  height: ${props => props.$size};
`;
interface IconButtonProps {
  imgSrc: string;
  alt: string;
  onClick: () => void;
  onHoverImage?: string;
  size?: string;
}

export const IconButton = (props: IconButtonProps) => {
  const [activeImage, setActiveImage] = useState(props.imgSrc);

  return (
    <Button
      onClick={props.onClick}
      onMouseOver={() => setActiveImage(props.onHoverImage ?? props.imgSrc)}
      onFocus={() => setActiveImage(props.onHoverImage ?? props.imgSrc)}
      onMouseLeave={() => setActiveImage(props.imgSrc)}
      onBlur={() => setActiveImage(props.imgSrc)}
      $size={props.size || theme.iconButtonSizes.md}>
      <Image
        src={activeImage}
        alt={props.alt}
        $size={props.size || theme.iconButtonSizes.md}
      />
    </Button>
  );
};
export default IconButton;
