import React, { useEffect, useState, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import axios from 'axios';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { Endpoints } from '../../api/endpoints';
import add from '../../assets/images/medication/add.svg';
import remove from '../../assets/images/medication/remove.svg';
import { getString, useUserContext } from '../../context/UserContext';
import { logGAEvent } from '../../shared/services/googleAnalyticsService';
import LegalDoc from '../shared/LegalDoc';
import PrimaryButton from '../shared/PrimaryButton';
import { ErrorToast, SuccessToast } from '../shared/ToastrNotifications';
import { HCPUser } from '../../models';
import LabeledInput from '../shared/LabeledInput';

const UserForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const FullWidthInputContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  div {
    &.dropdown {
      padding: 0 25px;
      .site_container {
        display: flex;
        flex-direction: row;
        gap: 4px;
        margin-bottom: 4px;
      }
    }
    &.roles-input {
      padding-left: 30px;
    }
    &.MuiAutocomplete-root {
      padding: 0;
    }
    &.toggle {
      padding: 0 32px;
      .MuiToggleButtonGroup-grouped {
        border-color: ${props => props.theme.colors.echoBlue};
        border-radius: 17px;
        height: 34px;
        outline: 0;
        width: 120px;
      }
      .MuiToggleButtonGroup-grouped:not(:last-of-type) {
        border-radius: 17px 0 0 17px;
      }
      .MuiToggleButtonGroup-grouped:not(:first-of-type) {
        border-radius: 0 17px 17px 0;
      }
      .Mui-selected {
        background-color: ${props => props.theme.colors.echoBlue};
        color: ${props => props.theme.colors.white};
      }
    }
    #error-text {
      padding: 0px 27px;
      margin: 0;
    }
  }
  label {
    font-weight: 700;
    text-transform: uppercase;
    color: ${props => props.theme.colors.charcoal};
  }
`;

const CustomTextField = styled(TextField)`
  padding: 10px;
  width: 100%;

  &.required fieldset {
    border-radius: 4px;
    border: 1px solid ${props => props.theme.colors.mangoTango};
    &:focus,
    &:hover {
      border-radius: 4px;
      border: 1px solid ${props => props.theme.colors.mangoTango} !important;
    }
  }

  & .MuiInputBase-input {
    height: 12px;
    color: ${props => props.theme.colors.charcoal};
    font-size: 16px;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Brandon';
  }

  &.MuiOutlinedInput-root fieldset,
  &.MuiOutlinedInput-notchedOutline {
    border: 1px solid ${props => props.theme.colors.mystic};
    border-radius: 4px;
    &:focus,
    &:hover {
      border: 1px solid ${props => props.theme.colors.mystic};
      border-radius: 4px;
    }
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }
`;

const ErrorText = styled.p`
  color: ${props => props.theme.colors.mangoTango};
  text-align: left;
  font-weight: 400;
  margin: 0;
  font-family: 'Brandon';
`;

const SideWithInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  div {
    &.roles {
      padding: 0 31px;
    }
  }
  label {
    font-weight: 700;
    text-transform: uppercase;
    color: ${props => props.theme.colors.charcoal};
  }
`;
const HalfWidthInputContainer = styled.div`
  width: 55%;
  margin: 0 0 20px 20px;
  &.margin {
    margin: 23px 0 0;
  }
  #error-text {
    padding: 0px 8px;
    margin: 0;
  }
`;

const Title = styled.div`
  color: ${props => props.theme.colors.charcoal};
  font-family: 'Brandon', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 2.73px;
  padding-bottom: 0px;
  padding-top: 25px;
  text-transform: uppercase;
  text-align: center;
`;

const Select = styled.select`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-size: 14px;
  height: 46px;
  width: 60%;
  padding: 10px;
  background-color: ${props => props.theme.colors.aliceBlue};
  align-self: flex-start;

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    width: 50%;
    margin-left: 15px;
  }

  &.invalid,
  &.invalid:hover,
  &.invalid:focus {
    border: 1px solid ${props => props.theme.colors.mangoTango};
  }

  &:hover,
  &:focus {
    border: 1px solid ${props => props.theme.colors.curiousBlue};
  }
`;

const Disclaimer = styled.div`
  margin-bottom: 16px;
`;

interface Option {
  label: string;
  value: number;
}

export default function UserModal(props: {
  onClick: (isOpen: boolean) => void;
  isOpen: boolean;
  staffUser?: HCPUser;
  refetchData?: () => void;
}) {
  const { user } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [organizationOption, setOrganizationOption] = useState<Option[]>([]);
  const [siteOption, setSiteOption] = useState<Option[]>([]);
  const [isOptionDisable, setIsOptionDisable] = useState(false);
  const [isSiteDisable, setIsSiteDisable] = useState(false);
  const [userData, setUserData] = useState(props?.staffUser);
  const [selectedOrgOption, setSelectedOrgOption] = useState<Option[]>([
    { value: 0, label: '' },
  ]);
  const [siteCounter, setSiteCounter] = useState(1);
  const [selectOrgId, setSelectOrgId] = useState(0);
  const [invalidSites, setInvalidSites] = useState<number[]>([]);
  const [selectedSites, setSelectedSites] = useState<number[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState('inactive');
  const [firstNameIsValid, setFirstNameIsValid] = useState<boolean>();
  const [firstName, setFirstName] = useState<string>();
  const [lastNameIsValid, setLastNameIsValid] = useState<boolean>();
  const [lastName, setLastName] = useState<string>();
  const [emailIsValid, setEmailIsValid] = useState<boolean>();
  const [email, setEmail] = useState<string>();
  const [passwordIsValid, setPasswordIsValid] = useState<boolean>();
  const [password, setPassword] = useState<string>();
  const [orgIsValid, setOrgIsValid] = useState(true);

  const handleStatusChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: string
  ) => {
    setSelectedStatus(newStatus === 'active' ? 'active' : 'inactive');
  };

  const handleSiteChange = (index: number, value: number) => {
    if (value) {
      setSelectedSites(prevValues => {
        const newValues = Array.isArray(prevValues) ? [...prevValues] : [];
        newValues[index] = value;
        return newValues;
      });

      setInvalidSites(prevValues => {
        const newValues = Array.isArray(prevValues) ? [...prevValues] : [];
        // Remove the value from setInvalidSites array if it exists
        if (newValues[index] === index) {
          newValues.splice(index, 1);
        }
        return newValues;
      });
    } else {
      setInvalidSites(prevValues => {
        const newValues = Array.isArray(prevValues) ? [...prevValues] : [];
        newValues[index] = index;
        return newValues;
      });
    }
  };

  useEffect(() => {
    setUserData(props.staffUser);
    setSelectedRoles(
      props?.staffUser?.roles?.map(
        (role: { machineName: string }) => role.machineName
      ) || ['ROLE_HCP']
    );
    setSelectedStatus(
      props?.staffUser?.enabled === true ? 'active' : 'inactive'
    );
    setFirstName(props.staffUser?.firstName);
    setLastName(props.staffUser?.lastName);
    setEmail(props.staffUser?.email);
  }, [props]);

  const handleClose = () => {
    setOrgIsValid(true);
    props.onClick(false);
  };

  const fetchOrganizationSite = useCallback(
    (orgId: number) => {
      if (orgId) {
        axios
          .get(Endpoints.sitesInOrganization(user?.superAdmin ? orgId : orgId))
          .then(result => {
            const res = result.data;
            const siteOptionData = res.map(
              (option: { id: number; name: string }) => ({
                value: option.id,
                label: option.name,
              })
            );
            setSiteOption(siteOptionData);
          })
          .catch(error => {
            setIsLoading(false);
            ErrorToast(error);
          });
      }
    },
    [user]
  );

  const getSelectedSite = useCallback(() => {
    const siteId =
      props?.staffUser?.sites?.map((site: { id: number }) => site.id) || [];
    setSelectedSites(siteId);
    setInvalidSites([]);
    setSiteCounter(siteId.length || 1);
    if (user?.superAdmin && props.staffUser) {
      setIsSiteDisable(true);
    }
  }, [props, user]);

  const getSelectedOrganization = useCallback(() => {
    if (props.staffUser) {
      organizationOption.map(option => {
        if (option.value == props?.staffUser?.organization.id) {
          setSelectOrgId(props?.staffUser?.organization.id);
          setSelectedOrgOption([option]);
          fetchOrganizationSite(props?.staffUser?.organization.id);
          setIsOptionDisable(true);
        }
      });
    } else {
      setSelectedOrgOption([{ value: 0, label: '' }]);
    }
  }, [fetchOrganizationSite, props, organizationOption]);

  useEffect(() => {
    getSelectedOrganization();
    getSelectedSite();
  }, [getSelectedOrganization, getSelectedSite, props, user]);

  const fetchOrganization = useCallback(() => {
    if (user?.superAdmin) {
      axios
        .get(Endpoints.allOrganization)
        .then(result => {
          const res = result.data;
          const organizationData = res.map(
            (option: { id: number; name: string }) => ({
              value: option.id,
              label: option.name,
            })
          );
          setOrganizationOption(organizationData);
        })
        .catch(error => {
          setIsLoading(false);
          ErrorToast(error);
        });
    } else {
      fetchOrganizationSite(Number(user?.organization?.id));
    }
  }, [fetchOrganizationSite, user]);

  useEffect(() => {
    fetchOrganization();
  }, [fetchOrganization]);

  const handleAddSite = () => {
    if (siteCounter === siteOption.length) {
      return;
    }
    setSiteCounter(siteCounter + 1);
  };

  const handleRemoveSite = (index: number) => {
    const sites = [...selectedSites];
    sites.splice(index, 1);
    setSelectedSites(sites);

    const invalid = [...invalidSites];
    invalid.splice(index, 1);
    setInvalidSites(invalid);

    setSiteCounter(siteCounter - 1);
  };

  const handleRoleSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRoles(prev => {
      if (e.target.checked) {
        return [...prev, e.target.value];
      } else {
        // Remove the unchecked value from the array
        return prev.filter(role => role !== e.target.value);
      }
    });
  };

  const validForm = (body: any) => {
    if (
      (!firstName || !lastName) &&
      !email &&
      !selectedSites.length &&
      ((user?.superAdmin &&
        !props?.staffUser?.id &&
        !password &&
        !body.organizationId) ||
        true)
    ) {
      return {
        status: false,
        key: ['toastrMessages.common.pleaseFillRequiredFields'],
      };
    }

    const errorKeys = [];
    if (!firstName || !lastName || !firstNameIsValid || !lastNameIsValid) {
      errorKeys.push('controlMessageComponent.message.invalidName');
    }
    if (!email || !emailIsValid) {
      errorKeys.push('controlMessageComponent.message.invalidEmail');
    }
    if (
      user?.superAdmin &&
      !props?.staffUser?.id &&
      (!passwordIsValid || !password)
    ) {
      errorKeys.push('controlMessageComponent.message.invalidPassword');
    }
    if (user?.superAdmin && !body.organizationId) {
      errorKeys.push('controlMessageComponent.message.invalidOrganization');
    }
    if (!selectedSites.length || selectedSites.length !== siteCounter) {
      errorKeys.push('controlMessageComponent.message.invalidSite');
    }
    return { status: errorKeys.length > 0 ? false : true, key: errorKeys };
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    googleAnalytics('user_save');
    e.preventDefault(); // Prevent default form submission behavior

    const target = e.target as typeof e.target & {
      firstName: { value: string };
      lastName: { value: string };
      email: { value: string };
      password: { value: string };
      orgAdmin: HTMLInputElement;
      siteAdmin: HTMLInputElement;
      hcpAdmin: HTMLInputElement;
    };

    const roleId = ['ROLE_HCP'];
    if (!user?.superAdmin) {
      if (target.orgAdmin.checked) roleId.push(target.orgAdmin.value);
      if (target.siteAdmin.checked) roleId.push(target.siteAdmin.value);
    }

    const body = {
      id: props?.staffUser?.id || 0,
      firstName: target.firstName.value.trim(),
      lastName: target.lastName.value.trim(),
      email: target.email.value.trim(),
      roles: [],
      roleId: !user?.superAdmin ? roleId.join(',') : '',
      password:
        user?.superAdmin && !props?.staffUser?.id
          ? target.password.value.trim()
          : null,
      organizationId: user?.superAdmin ? selectOrgId : user?.organization?.id,
      siteId: selectedSites.join(','),
      sites: [],
      enabled: selectedStatus === 'active' ? true : false,
    };

    const response = validForm(body);
    if (!response.status) {
      const message = response.key?.map(val => getString(val));
      ErrorToast(message.join('<br/>'));
      return false;
    }

    setIsLoading(true);
    if (user?.superAdmin) {
      if (body.id) {
        axios
          .put(Endpoints.updateOrgAdmin(body.id), body)
          .then(() => {
            handleClose();
            SuccessToast('', getString('toastrMessages.types.success'));
            props?.refetchData?.();
          })
          .catch(error => {
            ErrorToast(error);
          })
          .finally(() => setIsLoading(false));
      } else {
        axios
          .post(Endpoints.createOrgAdmin, body)
          .then(() => {
            handleClose();
            SuccessToast('', getString('toastrMessages.types.success'));
          })
          .catch(error => {
            ErrorToast(error);
          })
          .finally(() => setIsLoading(false));
      }
    } else {
      if (body.id) {
        axios
          .put(Endpoints.updateHcp(body.id), body)
          .then(() => {
            handleClose();
            SuccessToast('', getString('toastrMessages.types.success'));
            props?.refetchData?.();
          })
          .catch(error => {
            ErrorToast(error);
          })
          .finally(() => setIsLoading(false));
      } else {
        axios
          .post(Endpoints.createHcp, body)
          .then(() => {
            handleClose();
            SuccessToast('', getString('toastrMessages.types.success'));
          })
          .catch(error => {
            ErrorToast(error);
          })
          .finally(() => setIsLoading(false));
      }
    }
  };

  const googleAnalytics = (type: string) => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'admin',
      eventAct: 'click',
      eventLbl: type === 'site_save' ? 'new_site_save' : 'add_' + type,
    });
  };

  return (
    <Dialog
      aria-labelledby='user-form'
      open={props.isOpen}
      disableEscapeKeyDown
      fullWidth
      maxWidth='xs'
      scroll='body'>
      <DialogTitle>
        <Title>
          {getString(
            `hcpModalComponent.title.${
              userData && userData.id ? 'editUser' : 'newUser'
            }`
          )}
        </Title>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          fontSize: 16,
        }}>
        <CloseIcon fontSize={'inherit'} />
      </IconButton>
      <UserForm onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
        <DialogContent sx={{ px: 5 }}>
          <Disclaimer>
            <span>{parse(getString('hcpModalComponent.form.consent'))}</span>{' '}
            <span>
              <LegalDoc docKey={'terms_of_use'} />
            </span>
          </Disclaimer>
          <SideWithInputContainer>
            <HalfWidthInputContainer>
              <LabeledInput
                setInputIsValid={(isValid: boolean) =>
                  setFirstNameIsValid(isValid)
                }
                setValue={(value: string) => setFirstName(value)}
                valueOverride={userData?.firstName || ''}
                includeLabel={true}
                labelText={getString('form.label.name')}
                name='firstName'
                inputPlaceholderText={getString(
                  'hcpModalComponent.placeholder.firstName'
                )}
                inputType='name'
                isRequired={true}
                matchPattern={true}
                regexPattern={/^\s*?[a-zA-Z]+(?:(?:['.,\-\s])[a-zA-Z]+)*\s*?$/}
                onClick={() => googleAnalytics('first_name')}
              />
            </HalfWidthInputContainer>
            <HalfWidthInputContainer className='margin'>
              <LabeledInput
                setInputIsValid={(isValid: boolean) =>
                  setLastNameIsValid(isValid)
                }
                setValue={(value: string) => setLastName(value)}
                includeLabel={false}
                labelText={getString('form.label.lastName')}
                name='lastName'
                inputPlaceholderText={getString(
                  'hcpModalComponent.placeholder.lastName'
                )}
                valueOverride={userData?.lastName || ''}
                inputType='name'
                isRequired={true}
                matchPattern={true}
                regexPattern={/^\s*?[a-zA-Z]+(?:(?:['.,\-\s])[a-zA-Z]+)*\s*?$/}
                onClick={() => googleAnalytics('last_name')}
              />
            </HalfWidthInputContainer>
          </SideWithInputContainer>
          <FullWidthInputContainer>
            <LabeledInput
              name='email'
              labelText={getString('form.label.email')}
              inputPlaceholderText={getString(
                'hcpModalComponent.placeholder.email'
              )}
              matchPattern={true}
              isRequired={true}
              onClick={() => googleAnalytics('email')}
              valueOverride={userData?.email || ''}
              invalidOnEmpty={true}
              setInputIsValid={(isValid: boolean) => setEmailIsValid(isValid)}
              setValue={(value: string) => setEmail(value)}
              regexPattern={
                /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9.-]+\.[a-z0-9]{2,}$/i
              }
            />
          </FullWidthInputContainer>
          {user?.superAdmin && !props?.staffUser && (
            <FullWidthInputContainer>
              <LabeledInput
                setInputIsValid={(isValid: boolean) =>
                  setPasswordIsValid(isValid)
                }
                setValue={(value: string) => setPassword(value)}
                includeLabel={true}
                name='password'
                labelText='password'
                inputType='password'
                isRequired={true}
                matchPattern={true}
                regexPattern={
                  /(?!^[0-9]*$)(?!^[a-z]*$)(?!^[A-Z]*$)(?!^[\W]*$)(^[a-zA-Z0-9\W]).{7,}/
                }
                onClick={() => googleAnalytics('password')}
              />
            </FullWidthInputContainer>
          )}
          {user?.superAdmin && (
            <SideWithInputContainer>
              <FullWidthInputContainer>
                <div className='dropdown'>
                  <label htmlFor={getString('form.label.organization')}>
                    {getString('form.label.organization')}
                  </label>
                  <Autocomplete
                    disablePortal
                    options={organizationOption}
                    disableClearable={true}
                    value={selectedOrgOption[0]}
                    getOptionDisabled={option => isOptionDisable}
                    onChange={(event, option) => {
                      const obj1: Option = option;
                      setSelectedOrgOption([obj1]);
                      setSelectOrgId(obj1.value);
                      fetchOrganizationSite(
                        obj1 ? obj1.value : user.organization.id
                      );
                    }}
                    onBlur={() =>
                      !selectedOrgOption?.[0]?.value
                        ? setOrgIsValid(false)
                        : setOrgIsValid(true)
                    }
                    id='org'
                    renderInput={params => (
                      <div>
                        <CustomTextField
                          {...params}
                          className={orgIsValid ? '' : 'required'}
                          placeholder={getString(
                            'hcpModalComponent.placeholder.organization'
                          )}
                        />
                        {!orgIsValid && (
                          <ErrorText>
                            {getString(
                              `controlMessageComponent.message.required`
                            )}
                          </ErrorText>
                        )}
                      </div>
                    )}
                  />
                </div>
              </FullWidthInputContainer>
            </SideWithInputContainer>
          )}

          <FullWidthInputContainer>
            <div className='dropdown'>
              <label htmlFor={getString('form.label.site')}>
                {getString('form.label.site')}
              </label>

              {Array.from({ length: siteCounter }, (_, i) => (
                <div key={i} id={`site-${i}`} className='site_container'>
                  <Select
                    name='site'
                    disabled={isSiteDisable}
                    onBlur={e => handleSiteChange(i, Number(e.target.value))}
                    className={invalidSites[i] !== undefined ? 'invalid' : ''}
                    onChange={e => handleSiteChange(i, Number(e.target.value))}
                    value={selectedSites[i] || ''}>
                    <option value='' hidden>
                      {getString('hcpModalComponent.form.selectSite')}
                    </option>
                    {siteOption.map(site => {
                      return (
                        <>
                          <option
                            value={site.value}
                            hidden={
                              selectedSites.includes(site.value) &&
                              selectedSites[i] !== site.value
                            }>
                            {site.label}
                          </option>
                        </>
                      );
                    })}
                  </Select>
                  {siteCounter > 1 && !isSiteDisable && (
                    <button
                      type='button'
                      onClick={() => {
                        handleRemoveSite(i);
                      }}>
                      <img src={remove} alt='remove' />
                    </button>
                  )}
                  {!user?.superAdmin && (
                    <button
                      type='button'
                      onClick={() => {
                        handleAddSite();
                      }}>
                      <img src={add} alt='add site' />
                    </button>
                  )}
                </div>
              ))}
            </div>
          </FullWidthInputContainer>
          {!user?.superAdmin && (
            <FullWidthInputContainer>
              <div className='dropdown'>
                <label htmlFor='roles'>Roles</label>
              </div>
              <div className='roles-input'>
                <label htmlFor='org'>
                  <input
                    type='checkbox'
                    id='orgAdmin'
                    value={'ROLE_ORG_ADMIN'}
                    name='orgAdmin'
                    checked={selectedRoles.includes('ROLE_ORG_ADMIN')}
                    onChange={handleRoleSelection}
                  />
                  Org Admin
                </label>
                <label htmlFor='site'>
                  <input
                    type='checkbox'
                    id='siteAdmin'
                    value={'ROLE_SITE_ADMIN'}
                    checked={selectedRoles.includes('ROLE_SITE_ADMIN')}
                    name='siteAdmin'
                    onChange={handleRoleSelection}
                  />
                  Site Admin
                </label>
                <label htmlFor='hcp'>
                  <input
                    type='checkbox'
                    id='hcpAdmin'
                    value={'ROLE_HCP'}
                    name='hcpAdmin'
                    disabled
                    checked
                  />
                  HCP{' '}
                </label>
              </div>
            </FullWidthInputContainer>
          )}
          {!user?.superAdmin && userData && userData.id && (
            <FullWidthInputContainer>
              <div className='toggle'>
                <ToggleButtonGroup
                  color='primary'
                  value={selectedStatus}
                  exclusive
                  onChange={handleStatusChange}
                  aria-label='Platform'>
                  <ToggleButton value='active'>
                    {getString('hcpModalComponent.form.active')}
                  </ToggleButton>
                  <ToggleButton value='inactive'>
                    {getString('hcpModalComponent.form.inactive')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </FullWidthInputContainer>
          )}
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            type='submit'
            disabled={isLoading}
            label={getString('form.button.save')}
          />
        </DialogActions>
      </UserForm>
    </Dialog>
  );
}
