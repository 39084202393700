import { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Endpoints } from '../../api/endpoints';
import pencil from '../../assets/images/hcp-list/pencil_sans_circle.svg';
import { useUserContext, getString } from '../../context/UserContext';
import {
  logGAEvent,
  sendPageView,
} from '../../shared/services/googleAnalyticsService';
import Loader from '../shared/Loader';
import {
  ErrorToast,
  SuccessToast,
  WarningToast,
} from '../shared/ToastrNotifications';
import { HCPUser } from './../../models/index';
import UserModal from './UserModal';

const Container = styled.div`
  padding: 0 16px;
`;

const SizeOfList = styled.p`
  color: ${props => props.theme.colors.echoBlue};
  margin: 0;
  padding: 20px 0;
  text-transform: uppercase;
`;

const Table = styled.table`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.athensGray};
  border-collapse: separate;
  border-radius: 15px;
  margin-bottom: 20px;
  overflow: hidden;
  width: 100%;
`;

const TableRow = styled.tr`
  &.user-table-row {
    td,
    th {
      border-bottom: 1px solid ${props => props.theme.colors.athensGray};
      border-right: 1px solid ${props => props.theme.colors.ghostWhite};
      vertical-align: middle;
      text-align: left;
      padding: 12px;
    }
  }

  th {
    color: ${props => props.theme.colors.echoBlue};
    font-family: Brandon, Helvetica, Arial, sans-serif;
    font-weight: 900;
    padding-bottom: 10px;
    padding-top: 10px;
    text-transform: uppercase;
    word-break: inherit;
  }
`;

const TH = styled.th`
  &.content-width-users {
    width: 230px;
  }
  &.content-width-button {
    width: 80px;
  }
`;

const TableRowCell = styled.td`
  max-width: 230px;
  &.user-table-row td {
    border-top: 0;
    height: 80px;
    padding: 10px;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.address {
    max-width: 120px;
  }
`;

const Padding = styled.div`
  padding-left: 20px;
`;

const EditButton = styled.button`
  img {
    filter: brightness(10%);
  }
`;

const OverflowCutoff = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.uppercase {
    text-transform: uppercase;
  }
  &.grey,
  &.inactive {
    color: ${props => props.theme.colors.loblolly};
  }
  &.expired {
    color: ${props => props.theme.colors.vividRed};
  }
  &.bold {
    font-weight: 600;
  }
`;

const Clickable = styled.span`
  cursor: pointer;
`;

const HCPUsers = () => {
  const [data, setData] = useState<HCPUser[]>([]);
  const [count, setCount] = useState('-');
  const [open, setOpen] = useState(false);
  const [reinviteStatus, setReinviteStatus] = useState<{
    [key: number]: boolean;
  }>([]);
  const [staffUser, setStaffUser] = useState<HCPUser>();
  const { user } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(user?.superAdmin ? Endpoints.allStaff : Endpoints.staff())
      .then(response => {
        setData(response.data);
        setCount(response.data.length);
      })
      .catch(error => {
        ErrorToast(error);
      })
      .finally(() => setIsLoading(false));
    setRefreshTable(false);
  }, [user, refreshTable]);

  const getResetLink = (user: HCPUser) => {
    return (
      <Clickable onClick={() => resendInvite(user)} aria-hidden='true'>
        {' '}
        (
        {reinviteStatus[user.id]
          ? getString('hcpListComponent.invites.resendInvite')
          : getString('hcpListComponent.invites.inviteSent')}
        )
      </Clickable>
    );
  };

  const handleClickOpen = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const resendInvite = (user: HCPUser) => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'admin',
      eventAct: 'click',
      eventLbl: 'resent_user_invite',
    });

    if (!reinviteStatus[user.id]) {
      axios
        .post(Endpoints.resendInvite, {
          email: user.email,
        })
        .then(() => {
          setReinviteStatus({
            ...reinviteStatus,
            [`${user.id}`]: true,
          });
          SuccessToast(
            getString('toastrMessages.hcpListComponent.invitationResent'),
            getString('toastrMessages.types.success')
          );
        })
        .catch(error => {
          ErrorToast(error);
        });
    } else {
      WarningToast(
        getString('toastrMessages.hcpListComponent.invitationAlreadySent'),
        getString('toastrMessages.types.warning')
      );
    }
  };

  const handleEditUser = (staffUser: HCPUser) => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'admin',
      eventAct: 'click',
      eventLbl: 'edit_user',
    });
    sendPageView('Edit User');
    setStaffUser(staffUser);
    setOpen(true);
  };

  return (
    <Container>
      <SizeOfList>
        {count} {getString('hcpListComponent.legend.totalUsers')}
      </SizeOfList>
      {isLoading && count === '-' ? (
        <Loader />
      ) : (
        <>
          <Table>
            <thead>
              <TableRow className='user-table-row'>
                <TH className='content-width-users'>
                  {getString('hcpListComponent.userTitles.user')}
                </TH>
                <TH className='content-width-users'>
                  {getString('hcpListComponent.userTitles.sites')}
                </TH>
                <TH className='content-width-users'>
                  {getString('hcpListComponent.userTitles.roles')}
                </TH>
                <TH className='content-width-button'></TH>
              </TableRow>
            </thead>
            <tbody>
              {data.length
                ? data.map(staffUser => (
                    <TableRow className='user-table-row' key={staffUser.id}>
                      <TableRowCell className='uppercase'>
                        <OverflowCutoff className='bold'>
                          {staffUser.lastName}, {staffUser.firstName}
                        </OverflowCutoff>
                        <Padding>
                          <OverflowCutoff className='grey'>
                            {staffUser.email}
                          </OverflowCutoff>
                        </Padding>
                        <Padding>
                          <OverflowCutoff
                            className={staffUser.status.toLowerCase()}>
                            {staffUser.status}
                          </OverflowCutoff>
                          {staffUser.status === 'Pending' ||
                          staffUser.status === 'Expired'
                            ? getResetLink(staffUser)
                            : null}
                        </Padding>
                      </TableRowCell>
                      <TableRowCell className='address'>
                        {staffUser.sites?.map(site => (
                          <div key={site.name}>
                            <OverflowCutoff>{site.name}</OverflowCutoff>
                          </div>
                        ))}
                      </TableRowCell>
                      <TableRowCell>
                        <div>
                          {staffUser.roles?.map((role, index) => (
                            <span key={role.name}>
                              {index > 0 && ', '}
                              {role.name}
                            </span>
                          ))}
                        </div>
                      </TableRowCell>
                      <TableRowCell>
                        <EditButton onClick={() => handleEditUser(staffUser)}>
                          <Clickable>
                            <img src={pencil} alt='logo' height='16' />
                          </Clickable>
                        </EditButton>
                      </TableRowCell>
                    </TableRow>
                  ))
                : null}
            </tbody>
          </Table>
        </>
      )}
      <UserModal
        isOpen={open}
        onClick={handleClickOpen}
        staffUser={staffUser}
        refetchData={() => setRefreshTable(true)}
      />
    </Container>
  );
};

export default HCPUsers;
