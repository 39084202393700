import { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Endpoints } from '../../api/endpoints';
import pencil from '../../assets/images/hcp-list/pencil_sans_circle.svg';
import { useUserContext } from '../../context/UserContext';
import { Site } from '../../models/index';
import {
  logGAEvent,
  sendPageView,
} from '../../shared/services/googleAnalyticsService';
import Loader from '../shared/Loader';
import { ErrorToast } from '../shared/ToastrNotifications';
import SiteModal from './SiteModal';

const Container = styled.div`
  padding: 0 16px;
`;

const SizeOfList = styled.p`
  color: ${props => props.theme.colors.echoBlue};
  margin: 0;
  padding: 20px 0;
  text-transform: uppercase;
`;

const Table = styled.table`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.athensGray};
  border-collapse: separate;
  border-radius: 15px;
  margin-bottom: 20px;
  overflow: hidden;
  width: 100%;
`;

const TableRow = styled.tr`
  &.table-row {
    td,
    th {
      border-bottom: 1px solid ${props => props.theme.colors.athensGray};
      border-right: 1px solid ${props => props.theme.colors.ghostWhite};
      vertical-align: middle;
      text-align: left;
      padding: 10px;
    }
  }

  th {
    color: ${props => props.theme.colors.echoBlue};
    font-family: Brandon, Helvetica, Arial, sans-serif;
    font-weight: 900;
    padding-bottom: 10px;
    padding-top: 10px;
    text-transform: uppercase;
    word-break: inherit;
  }
`;

const TH = styled.th`
  &.content-width-sites {
    width: 274px;
  }
  &.content-width-button {
    width: 80px;
  }
`;

const TableRowCell = styled.td`
  border-top: 0;
  height: 56px;
  padding: 10px;
  max-width: 230px;
  &.content-width-button {
    width: 80px;
  }
  &.uppercase {
    text-transform: uppercase;
  }
`;

const EditButton = styled.button`
  img {
    filter: brightness(10%);
  }
`;

const OverflowCutoff = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.uppercase {
    text-transform: uppercase;
  }
  &.bold {
    font-weight: 600;
  }
`;

const ClickableImage = styled.img`
  cursor: pointer;
`;

interface HCPSiteProps {
  refetchData: (setUpdateList: boolean) => void;
  updateList: boolean;
}
const HCPSites = (props: HCPSiteProps) => {
  const [data, setData] = useState<Site[]>([]);
  const [count, setCount] = useState('-');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [site, setSite] = useState<Site>();

  const { user, getString } = useUserContext();

  useEffect(() => {
    setIsLoading(true);
    if (user) {
      axios
        .get(
          user?.superAdmin
            ? Endpoints.allsite
            : Endpoints.sitesInOrganization(user?.organization?.id)
        )
        .then(response => {
          setData(response.data);
          setCount(response.data.length);
        })
        .catch(error => {
          ErrorToast(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    props.refetchData(false);
  }, [props, user]);

  const handleClickOpen = (isOpen: boolean) => {
    props.refetchData(false);
    setOpen(isOpen);
  };

  const handleEditSite = (site: Site) => {
    setSite(site);
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'admin',
      eventAct: 'click',
      eventLbl: 'edit_site',
    });
    sendPageView('Edit Site');
    handleClickOpen(true);
  };

  return (
    <Container>
      <SizeOfList>
        {count} {getString('hcpListComponent.legend.totalSites')}
      </SizeOfList>
      {isLoading ? (
        <Loader />
      ) : (
        <Table>
          <thead>
            <TableRow className='table-row'>
              <TH className='content-width-sites'>
                {getString('hcpListComponent.siteTitles.siteName')}
              </TH>
              <TH className='content-width-sites'>
                {getString('hcpListComponent.siteTitles.address')}
              </TH>
              <TH className='content-width-sites'>
                {getString('hcpListComponent.siteTitles.phoneNumber')}
              </TH>
              <TH className='content-width-sites'>
                {getString('hcpListComponent.siteTitles.siteCode')}
              </TH>
              <TH className='content-width-button'></TH>
            </TableRow>
          </thead>
          <tbody>
            {data.length
              ? data.map(site => (
                  <TableRow className='table-row' key={site.id}>
                    <TableRowCell className='uppercase'>
                      <OverflowCutoff className='bold'>
                        {site.name}
                      </OverflowCutoff>
                    </TableRowCell>
                    <TableRowCell>
                      <OverflowCutoff>{site.address}</OverflowCutoff>
                    </TableRowCell>
                    <TableRowCell>{site.phoneNumber}</TableRowCell>
                    <TableRowCell>{site.identifier}</TableRowCell>
                    <TableRowCell className='content-width-button'>
                      <EditButton onClick={() => handleEditSite(site)}>
                        <ClickableImage
                          src={pencil}
                          alt='logo'
                          height='16'
                          className='clickable'
                        />
                      </EditButton>
                    </TableRowCell>
                  </TableRow>
                ))
              : null}
          </tbody>
        </Table>
      )}
      <SiteModal
        isOpen={open}
        onClick={handleClickOpen}
        refetchData={props.refetchData}
        site={site}
      />
    </Container>
  );
};

export default HCPSites;
