import { useState, useEffect } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import axios from 'axios';
import { formatISO, parseISO, addDays, startOfDay, endOfDay } from 'date-fns';
import Endpoints from '../../../api/endpoints';
import { Patient, PatientTracker } from '../../../models';
import { getString } from '../../../context/UserContext';
import { Card, CardHeader, NoInfoBaseContainer } from './Card';
import { ErrorToast } from '../../shared/ToastrNotifications';
import ActivityTracker from './ActivityTracker';
import DateRangePicker from '../../shared/DateRangePicker';
import Loader from '../../shared/Loader';
import noTrackersImage from '../../../assets/images/no_trackers_icon.svg';
import { CarePlanTracker } from '../../../api/models';

const ActivityCard = styled(Card)`
  padding: 10px 15px;
  div.content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    div.disclaimer {
      font-size: 16px;
      color: ${props => props.theme.colors.shark};
      font-weight: 500;
      line-height: 1.2;
    }
    div.note {
      font-size: 16px;
      color: ${props => props.theme.colors.loblolly};
      border-top: 1px solid ${props => props.theme.colors.athensGray};
      padding: 15px 0;
      b {
        text-transform: uppercase;
      }
      a {
        color: ${props => props.theme.colors.pacificBlue};
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.do-not-print {
    @media print {
      display: none;
    }
  }
`;

const NoInfo = styled(NoInfoBaseContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 -15px;
  min-height: 220px;
`;

type ActivityProps = {
  patient?: Patient;
  parentReload?: boolean;
  printTrackers?: CarePlanTracker[];
};

const Activity = ({ patient, parentReload, printTrackers }: ActivityProps) => {
  const [isLoading, setIsLoading] = useState(() => !patient);
  const [trackers, setTrackers] = useState<PatientTracker[]>();
  const [minStartDate, setMinStartDate] = useState<Date>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState(addDays(startOfDay(new Date()), 1));

  // calculate initial start date which is the most recent of:
  // 90 days ago or patient registration date
  useEffect(() => {
    if (!patient || !!startDate) {
      return;
    }
    const registrationDate = startOfDay(parseISO(patient.registrationDate));
    setMinStartDate(registrationDate);
    setStartDate(
      new Date(
        Math.max(addDays(endDate, -90).getTime(), registrationDate.getTime())
      )
    );
  }, [patient, startDate, endDate]);

  // load tracker data when date range changes
  useEffect(() => {
    if (!patient || !startDate) {
      return;
    }
    setIsLoading(true);
    axios
      .get(Endpoints.patientCustomTrackers(patient.id), {
        params: {
          startDateTime: formatISO(startDate),
          endDateTime: formatISO(endOfDay(endDate)),
        },
        headers: {
          'App-Version': '5.1.0+1-Portal',
        },
      })
      .then(response => setTrackers(response.data))
      .catch(error => ErrorToast(error))
      .finally(() => setIsLoading(false));
  }, [patient, startDate, endDate, parentReload]);

  return (
    <ActivityCard
      className={printTrackers?.length ?? -1 > 0 ? '' : 'do-not-print'}>
      <CardHeader>
        <h3>{getString('activeCardComponent.title.trackers')}</h3>
      </CardHeader>
      <DateRangePicker
        minStartDate={minStartDate}
        startDate={startDate}
        endDate={endDate}
        onChange={(start, end) => {
          start && setStartDate(start);
          end && setEndDate(end);
        }}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div className='content'>
          {!trackers?.length && (
            <NoInfo>
              <img src={noTrackersImage} alt='no trackers' />
              <h1>{getString('activeCardComponent.title.noTrackers')}</h1>
            </NoInfo>
          )}
          <div className='disclaimer'>
            {getString('activeCardComponent.title.trackerDisclaimer')}
          </div>
          <div className='note'>
            <b>{getString('activeCardComponent.instructions.note')}</b>{' '}
            {parse(getString('activeCardComponent.instructions.toHCP'))}
          </div>
          {trackers?.map((tracker, index) => {
            return (
              <ActivityTracker
                key={index}
                tracker={tracker}
                startDate={startDate}
                endDate={endDate}
                showOnPrint={
                  printTrackers?.some(
                    e =>
                      e.id.toLowerCase().replace(/ /g, '-') ===
                      tracker.type.toLowerCase().split(' ').join('-')
                  ) ?? false
                }
              />
            );
          })}
        </div>
      )}
    </ActivityCard>
  );
};

export default Activity;
