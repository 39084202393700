import React, { useEffect, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import styled from 'styled-components';
import Endpoints from '../../api/endpoints';
import { getString } from '../../context/UserContext';
import { DrugDetail } from '../../models';
import LabeledInput from './LabeledInput';
import { ErrorToast } from './ToastrNotifications';

const filter = createFilterOptions<OptionType>();

const CustomTextField = styled(TextField)`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  font-family: 'Brandon';

  & .MuiInputBase-input {
    height: 12px;
    color: ${props => props.theme.colors.charcoal};
    font-size: 16px;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Brandon';
  }

  .MuiOutlinedInput-root {
    fieldset {
      border: 1px solid ${props => props.theme.colors.mystic};
      border-radius: 4px;
    }
    &:hover fieldset {
      border: 1px solid ${props => props.theme.colors.curiousBlue};
    }
    &.Mui-focused fieldset {
      border: 1px solid ${props => props.theme.colors.curiousBlue};
    }
    &.Mui-error fieldset {
      border: 1px solid ${props => props.theme.colors.mangoTango};
      &:hover {
        border: 1px solid ${props => props.theme.colors.curiousBlue};
      }
    }
  }

  .MuiFormHelperText-root.Mui-error {
    color: ${props => props.theme.colors.mangoTango};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    font-family: 'Brandon';
  }
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  gap: 24px;

  &.full-width {
    width: 100%;
  }

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    flex-direction: column;
  }

  .unit {
    div {
      width: 50%;
      margin: 0;
      float: left;
    }
  }
`;

const InputContainer = styled.div`
  width: 100%;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledSelect = styled.select`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-size: 14px;
  height: 46px;
  width: 50%;
  padding: 10px;
  background-color: ${props => props.theme.colors.aliceBlue};
  align-self: flex-start;

  &.invalid,
  &.invalid:hover {
    border: 1px solid ${props => props.theme.colors.mangoTango};
  }

  &:hover {
    border: 1px solid ${props => props.theme.colors.curiousBlue};
  }

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    width: 100%;
  }

  &.full-width {
    width: 100%;
  }
`;

const ErrorText = styled.p`
  color: ${props => props.theme.colors.mangoTango};
  text-align: left;
  font-weight: 500;
  margin: 0;
`;

const header = {
  Authorization: 'Token token="1487c87ad542bc3fd152026492a08852"',
};

interface OptionType {
  inputValue?: string;
  title: string;
  genericName?: string;
}

interface PrefilledDataProps extends OptionType {
  drugID?: number;
  form?: string;
  strength?: string;
}

export default function DrugNamesAutoComplete(props: {
  placeholder: string | undefined;
  updateDrugDetail?: (drugDetail: DrugDetail | null) => void;
  prefilledData?: PrefilledDataProps | null;
}) {
  const [value, setValue] = useState<OptionType | null>({
    inputValue: props?.prefilledData?.inputValue,
    title: props?.prefilledData?.title || '',
    genericName: props?.prefilledData?.genericName,
  });
  const [isBlurred, setIsBlurred] = useState(false);
  const [inputValue, setInputValue] = useState(
    props?.prefilledData?.title || ''
  );
  const [options, setOptions] = useState<OptionType[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isCustomMed, setIsCustomMed] = useState(
    props?.prefilledData?.drugID ? false : true
  );
  const [drugDetails, setDrugDetails] = useState<DrugDetail[]>([]);
  const [isDrugDetailsListInvalid, setIsDrugDetailsListInvalid] =
    useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isStrengthUnitInvalid, setIsStrengthUnitInvalid] = useState(false);
  const [strength, setStrength] = useState(
    props.prefilledData?.strength
      ? props.prefilledData.strength.match(/[a-zA-Z]+|[0-9]+/g)?.[0] ?? ''
      : ''
  );
  const [strengthUnit, setStrengthUnit] = useState(
    props.prefilledData?.strength
      ? props.prefilledData.strength.match(/[a-zA-Z]+|[0-9]+/g)?.[1] ?? ''
      : ''
  );
  const [form, setForm] = useState(props?.prefilledData?.form || '');
  const [strengthUnitList, setStrengthUnitList] = useState<string[]>([]);
  const [drugId, setDrugId] = useState(
    props?.prefilledData?.drugID ? String(props.prefilledData.drugID) : ''
  );
  const isRequired = isBlurred && !value;

  useEffect(() => {
    if (props?.prefilledData?.drugID && !isCustomMed) {
      const selectedObject = drugDetails.find(
        obj => obj.id === parseInt(String(props.prefilledData?.drugID), 10)
      );
      props?.updateDrugDetail?.(selectedObject || null);
    }
  }, [props, drugDetails, isCustomMed]);

  useEffect(() => {
    if (!isOpen || !inputValue) return;
    axios
      .get(Endpoints.drugNames, {
        params: {
          q: inputValue,
        },
        headers: header,
        withCredentials: false,
      })
      .then(response => {
        setOptions(
          response?.data?.drug_names.map(
            (res: { name: string; generic_name: string }) => ({
              title: res.name,
              genericName: res.generic_name,
            })
          )
        );
      })
      .catch(error => {
        ErrorToast(error.message);
      });
  }, [isOpen, inputValue]);

  useEffect(() => {
    if (!value?.title) return;

    axios
      .get(Endpoints.drugDetails, {
        params: {
          q: value?.title,
        },
        headers: header,
        withCredentials: false,
      })
      .then(response => {
        setDrugDetails(response?.data?.drugs);
      })
      .catch(error => {
        ErrorToast(error.message);
      });
  }, [value]);

  useEffect(() => {
    /**
     * Executes when the medication form is selected. Changes units to match selected form.
     */
    switch (form) {
      case getString('medicationModalComponent.dropdown.applicatorful'):
        setStrengthUnitList([
          getString('medicationModalComponent.dropdown.applicatorfuls'),
        ]);
        break;
      case getString('medicationModalComponent.dropdown.drop'):
        setStrengthUnitList([
          getString('medicationModalComponent.dropdown.drops'),
        ]);
        break;
      case getString('medicationModalComponent.dropdown.puff'):
        setStrengthUnitList([
          getString('medicationModalComponent.dropdown.puffs'),
        ]);
        break;
      case getString('medicationModalComponent.dropdown.scoop'):
        setStrengthUnitList([
          getString('medicationModalComponent.dropdown.scoops'),
        ]);
        break;
      case getString('medicationModalComponent.dropdown.spray'):
        setStrengthUnitList([
          getString('medicationModalComponent.dropdown.sprays'),
        ]);
        break;
      case getString('medicationModalComponent.dropdown.inhalant'):
        setStrengthUnitList([
          getString('medicationModalComponent.dropdown.inhalations'),
        ]);
        break;
      case getString('medicationModalComponent.dropdown.patch'):
        setStrengthUnitList([
          getString('medicationModalComponent.dropdown.patches'),
        ]);
        break;
      case getString('medicationModalComponent.dropdown.capsule'):
        setStrengthUnitList([
          getString('medicationModalComponent.dropdown.g'),
          getString('medicationModalComponent.dropdown.mg'),
          getString('medicationModalComponent.dropdown.mcg'),
          getString('medicationModalComponent.dropdown.strength'),
        ]);
        break;
      case getString('medicationModalComponent.dropdown.tablet'):
        setStrengthUnitList([
          getString('medicationModalComponent.dropdown.g'),
          getString('medicationModalComponent.dropdown.mg'),
          getString('medicationModalComponent.dropdown.mcg'),
          getString('medicationModalComponent.dropdown.strength'),
        ]);
        break;
      case getString('medicationModalComponent.dropdown.suppository'):
        setStrengthUnitList([
          getString('medicationModalComponent.dropdown.suppository'),
        ]);
        break;
      case getString('medicationModalComponent.dropdown.oil'):
      case getString('medicationModalComponent.dropdown.solution'):
      case getString('medicationModalComponent.dropdown.suspension'):
        setStrengthUnitList([
          getString('medicationModalComponent.dropdown.milliliters'),
          getString('medicationModalComponent.dropdown.tablespoons'),
          getString('medicationModalComponent.dropdown.teaspoons'),
          getString('medicationModalComponent.dropdown.applicatorfuls'),
          getString('medicationModalComponent.dropdown.drops'),
          getString('medicationModalComponent.dropdown.dropperfuls'),
          getString('medicationModalComponent.dropdown.percent'),
          getString('medicationModalComponent.dropdown.injections'),
          getString('medicationModalComponent.dropdown.syringes'),
        ]);
        break;
      case getString('medicationModalComponent.dropdown.cream'):
      case getString('medicationModalComponent.dropdown.ointment'):
      case getString('medicationModalComponent.dropdown.gel'):
      case getString('medicationModalComponent.dropdown.powder'):
        setStrengthUnitList([
          getString('medicationModalComponent.dropdown.grams'),
          getString('medicationModalComponent.dropdown.micrograms'),
          getString('medicationModalComponent.dropdown.milligrams'),
          getString('medicationModalComponent.dropdown.applicatorfuls'),
          getString('medicationModalComponent.dropdown.doses'),
          getString('medicationModalComponent.dropdown.units'),
        ]);
        break;
      case 'default':
        setStrengthUnitList([]);
    }
  }, [form]);

  const handleBlur = () => {
    setIsBlurred(true);
    if (!value) {
      setIsBlurred(true);
    } else {
      setIsBlurred(false);
    }
  };

  const handleInputChange = async (
    event: React.ChangeEvent<unknown>,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);

    if (newInputValue.trim().length >= 3) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setOptions([]);
    }
  };

  const handleChangeDrugDetails = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedId = event.target.value;
    setDrugId(selectedId);
    const selectedObject = drugDetails.find(
      obj => obj.id === parseInt(selectedId, 10)
    );
    props?.updateDrugDetail?.(selectedObject || null);
  };

  return (
    <InputContainer>
      <Autocomplete
        sx={{ width: { xs: '100%', sm: '80%' } }}
        value={value}
        open={isOpen}
        onBlur={handleBlur}
        onInputChange={handleInputChange}
        onChange={(event, newValue) => {
          setIsCustomMed(false);
          if (typeof newValue === 'string') {
            setValue({
              inputValue: newValue,
              title: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            setIsCustomMed(true);
            setValue({
              inputValue: newValue.inputValue,
              title: newValue.inputValue,
            });
          } else {
            setValue(newValue);
          }
          setIsOpen(false); // Close the dropdown when an option is selected
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          const isExisting = options.some(
            option => inputValue === option.title
          );
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              title: `${getString(
                'medicationModalComponent.tooltip.createCustomMeds'
              )} ${inputValue}`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id='drugNames'
        options={options}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        freeSolo
        inputValue={inputValue}
        renderInput={params => (
          <div>
            <CustomTextField
              {...params}
              className={isRequired ? 'required' : ''}
              placeholder={props.placeholder}
            />
            {isRequired && (
              <ErrorText>
                {getString(`controlMessageComponent.message.required`)}
              </ErrorText>
            )}
          </div>
        )}
      />

      {value && !isCustomMed && drugDetails ? (
        <div>
          <StyledSelect
            value={drugId}
            name='drugDetailsList'
            className={isDrugDetailsListInvalid ? 'invalid' : ''}
            onBlur={e =>
              setIsDrugDetailsListInvalid(e.target.value ? false : true)
            }
            onChange={e => handleChangeDrugDetails(e)}>
            <option value='' selected>
              {getString('medicationModalComponent.label.selectStrength')}
            </option>
            {drugDetails &&
              drugDetails.map((drug: DrugDetail) => {
                return (
                  <option key={drug.id} value={drug.id}>
                    {drug.strength} | {drug.form_name}
                  </option>
                );
              })}
          </StyledSelect>
          {isDrugDetailsListInvalid ? (
            <ErrorText>
              {getString(`controlMessageComponent.message.required`)}
            </ErrorText>
          ) : (
            ''
          )}
        </div>
      ) : (
        <InputRow className='full-width'>
          <div>
            <StyledSelect
              name='form'
              id='custom-medication-form-dropdown'
              className={`full-width ${isFormInvalid ? 'invalid' : ''}`}
              value={form}
              onBlur={e => setIsFormInvalid(e.target.value ? false : true)}
              onChange={e => setForm(e.target.value)}>
              <option value='' hidden>
                {getString('medicationModalComponent.dropdown.form')}
              </option>
              <option
                value={getString(
                  'medicationModalComponent.dropdown.applicatorful'
                )}>
                {getString('medicationModalComponent.dropdown.applicatorful')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.drop')}>
                {getString('medicationModalComponent.dropdown.drop')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.puff')}>
                {getString('medicationModalComponent.dropdown.puff')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.scoop')}>
                {getString('medicationModalComponent.dropdown.scoop')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.spray')}>
                {getString('medicationModalComponent.dropdown.spray')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.inhalant')}>
                {getString('medicationModalComponent.dropdown.inhalant')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.oil')}>
                {getString('medicationModalComponent.dropdown.oil')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.solution')}>
                {getString('medicationModalComponent.dropdown.solution')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.cream')}>
                {getString('medicationModalComponent.dropdown.cream')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.ointment')}>
                {getString('medicationModalComponent.dropdown.ointment')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.gel')}>
                {getString('medicationModalComponent.dropdown.gel')}
              </option>
              <option
                value={getString(
                  'medicationModalComponent.dropdown.suspension'
                )}>
                {getString('medicationModalComponent.dropdown.suspension')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.patch')}>
                {getString('medicationModalComponent.dropdown.patch')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.capsule')}>
                {getString('medicationModalComponent.dropdown.capsule')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.tablet')}>
                {getString('medicationModalComponent.dropdown.tablet')}
              </option>
              <option
                value={getString('medicationModalComponent.dropdown.powder')}>
                {getString('medicationModalComponent.dropdown.powder')}
              </option>
              <option
                value={getString(
                  'medicationModalComponent.dropdown.suppository'
                )}>
                {getString('medicationModalComponent.dropdown.suppository')}
              </option>
            </StyledSelect>
            {isFormInvalid ? (
              <ErrorText>
                {getString(`controlMessageComponent.message.required`)}
              </ErrorText>
            ) : (
              ''
            )}
          </div>
          <div className='unit'>
            <LabeledInput
              includeLabel={false}
              inputPlaceholderText={getString(
                'medicationModalComponent.placeholder.strength'
              )}
              name='strength'
              labelText='strength'
              isRequired={true}
              showErrorMessage={true}
              matchPattern={true}
              setValue={(value: string) => setStrength(value)}
              valueOverride={strength}
            />
            <div>
              <StyledSelect
                id='custom-medication-unit-dropdown'
                name='strengthUnits'
                onBlur={e =>
                  setIsStrengthUnitInvalid(e.target.value ? false : true)
                }
                onChange={e => setStrengthUnit(e.target.value)}
                className={`full-width ${
                  isStrengthUnitInvalid ? 'invalid' : ''
                }`}>
                <option value='' hidden>
                  {getString('medicationModalComponent.dropdown.unit')}
                </option>
                {form && strengthUnitList
                  ? strengthUnitList?.map((unit: string) => {
                      return (
                        <option
                          value={unit}
                          key={unit}
                          selected={unit === strengthUnit ? true : false}>
                          {unit}
                        </option>
                      );
                    })
                  : null}
              </StyledSelect>
              {isStrengthUnitInvalid && (
                <ErrorText>
                  {getString(`controlMessageComponent.message.required`)}
                </ErrorText>
              )}
            </div>
          </div>
        </InputRow>
      )}
    </InputContainer>
  );
}
