import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { APIError } from '../api/models';
import { useUserContext } from './UserContext';

axios.defaults.baseURL = process.env.REACT_APP_SYMFONY_API_URL;
axios.defaults.withCredentials = true;

export interface APIContextProps {
  isLoading: boolean;
}

export const APIContext = createContext<APIContextProps>({
  isLoading: false,
});

export const APIProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    // request interceptor adds headers and sets loading state
    axios.interceptors.request.use(
      config => {
        setIsLoading(true);
        config.headers['Content-Type'] = 'application/json';
        config.headers['locale'] = 'en_US';
        return config;
      },
      error => {
        setIsLoading(false);
        return Promise.reject(error);
      }
    );

    // response interceptor checks for unauthorized responses and redirects to login
    axios.interceptors.response.use(
      response => {
        // handle status codes < 400
        setIsLoading(false);
        return response;
      },
      error => {
        // handle status codes >= 400
        setIsLoading(false);

        if (error.response?.status === 401) {
          // check for 401 unauthorized, clear user and redirect to login
          setUser(undefined);
          navigate('/login');
          return Promise.reject(error);
        }

        if (error.response?.data) {
          const data = error.response.data;
          return Promise.reject(data as APIError);
        }

        return Promise.reject(error);
      }
    );
  }, [navigate, setUser, setIsLoading]);

  return (
    <APIContext.Provider value={{ isLoading }}>{children}</APIContext.Provider>
  );
};

export const useAPI = () => {
  const context = useContext(APIContext);
  if (!context) {
    throw new Error('useAPI must be used inside the APIProvider');
  }
  return context;
};
