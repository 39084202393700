import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Endpoints } from '../../api/endpoints';
import { useUserContext } from '../../context/UserContext';
import { logGAEvent } from '../../shared/services/googleAnalyticsService';
import { GlobalValidator } from '../shared/GlobalValidator';
import { FormInput } from '../shared/FormInput';
import PrimaryButton from '../shared/PrimaryButton';
import { ErrorToast, SuccessToast } from '../shared/ToastrNotifications';
import axios from 'axios';
import { CarePlanTemplate, Organization } from '../../models';

const OrganizationForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const FullWidthInputContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  div {
    &.dropdown {
      padding: 0 31px;
      display: flex;
      flex-direction: column;
    }
  }
  label {
    font-weight: 700;
    text-transform: uppercase;
    color: ${props => props.theme.colors.charcoal};
  }
`;

const HalfWidthInputContainer = styled.div`
  width: 55%;
  margin: 0 0 20px 20px;
  label {
    &.input-phone-number {
      margin-right: -77%;
    }
  }
`;

const Title = styled.div`
  color: ${props => props.theme.colors.charcoal};
  font-family: 'Brandon', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 2.73px;
  padding-bottom: 0px;
  padding-top: 25px;
  text-transform: uppercase;
  text-align: center;
`;

const Select = styled.select`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-size: 14px;
  height: 46px;
  width: 100%;
  padding: 10px;
  background-color: ${props => props.theme.colors.aliceBlue};
  align-self: flex-start;

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    width: 50%;
    margin-left: 15px;
  }

  &.invalid,
  &.invalid:hover,
  &.invalid:focus {
    border: 1px solid ${props => props.theme.colors.mangoTango};
  }

  &:hover,
  &:focus {
    border: 1px solid ${props => props.theme.colors.curiousBlue};
  }
`;

const InputErrorText = styled.p`
  font-family: Brandon;
  font-size: 16px;
  color: ${props => props.theme.colors.mangoTango};
  margin: auto 0 8px;
  text-align: left;
  width: 85%;
`;

export default function OrganizationModal(props: {
  onClick: (isOpen: boolean) => void;
  isOpen: boolean;
  organization?: Organization;
}) {
  const { getString } = useUserContext();
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState<{
    [s: string]: string | number;
  }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [organizationData, setOrganizationData] = useState(props.organization);
  const [carePlanOption, setCarePlanOption] = useState<CarePlanTemplate[]>([]);
  const [carePlanIsValid, setCarePlanIsValid] = useState(true);
  const [carePlan, setCarePlan] = useState(
    props.organization?.carePlanTemplates?.[0]?.id
  );

  useEffect(() => {
    axios
      .get(Endpoints.allCarePlan)
      .then(result => {
        setCarePlanOption(result.data);
      })
      .catch(error => {
        ErrorToast(error);
      });
  }, []);

  useEffect(() => {
    setOrganizationData(props.organization);
    setCarePlan(props.organization?.carePlanTemplates?.[0]?.id);
  }, [props.organization]);

  const handleClose = () => {
    setCarePlan(props.organization?.carePlanTemplates?.[0]?.id);
    props.onClick(false);
  };

  const getInputError = React.useCallback(
    (key: string, value: string | number) => {
      setDisableSubmit(prevState => ({
        ...prevState,
        [key]: value,
      }));
    },
    [setDisableSubmit]
  );

  const validForm = (body: any) => {
    if (
      !body.name &&
      !body.alertLevelDays &&
      !body.deletionDays &&
      !body.disconnectionDays &&
      !body.carePlan
    ) {
      return {
        status: false,
        key: ['toastrMessages.common.pleaseFillRequiredFields'],
      };
    }

    const errorKeys = [];
    const errorMsg = Object.values(disableSubmit);
    if (
      !body.name ||
      errorMsg.includes(
        getString('controlMessageComponent.message.invalidName')
      )
    ) {
      errorKeys.push('controlMessageComponent.message.invalidOrgName');
    }
    if (!body.carePlan) {
      errorKeys.push('controlMessageComponent.message.invalidCarePlan');
    }
    if (
      !body.alertLevelDays ||
      !body.deletionDays ||
      !body.disconnectionDays ||
      errorMsg.includes(
        getString('controlMessageComponent.message.invalidNumericString')
      ) ||
      errorMsg.includes(
        getString('controlMessageComponent.message.invalidNumber')
      )
    ) {
      errorKeys.push('controlMessageComponent.message.invalidNumerString');
    }
    return { status: errorKeys.length > 0 ? false : true, key: errorKeys };
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    googleAnalytics('organization_save');
    e.preventDefault(); // Prevent default form submission behavior
    const target = e.target as typeof e.target & {
      name: { value: string };
      alertLevelDays: { value: string };
      disconnectionDays: { value: string };
      deletionDays: { value: string };
      carePlan: { value: string };
    };

    const body = {
      name: target.name.value.trim(),
      alertLevelDays: target.alertLevelDays.value.trim(),
      disconnectionDays: target.disconnectionDays.value.trim(),
      deletionDays: target.deletionDays.value.trim(),
      carePlan: target.carePlan.value.trim(),
    };

    const response = validForm(body);
    if (!response.status) {
      const message = response.key?.map(val => getString(val));
      ErrorToast(message.join('<br/>'));
      return false;
    }

    setIsLoading(true);
    if (props.organization) {
      axios
        .put(`${Endpoints.allOrganization}/${props.organization.id}`, {
          ...body,
          id: props.organization.id,
        })
        .then(() => {
          handleClose();
          SuccessToast('', getString('toastrMessages.types.success'));
          navigate('/hcp/organizations');
        })
        .catch(error => {
          ErrorToast(error);
        })
        .finally(() => setIsLoading(false));
    } else {
      axios
        .post(Endpoints.allOrganization, body)
        .then(() => {
          handleClose();
          SuccessToast('', getString('toastrMessages.types.success'));
          navigate('/hcp/organizations');
        })
        .catch(error => {
          ErrorToast(error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const googleAnalytics = (type: string) => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'admin',
      eventAct: 'click',
      eventLbl:
        type === 'organization_save' ? 'new_organization_save' : 'add_' + type,
    });
  };

  return (
    <Dialog
      aria-labelledby='organization-form'
      open={props.isOpen}
      disableEscapeKeyDown
      fullWidth
      maxWidth='xs'
      scroll='body'>
      <DialogTitle>
        <Title>
          {getString(
            `organizationModalComponent.title.${
              !organizationData?.id ? 'newOrganization' : 'editOrganization'
            }`
          )}
        </Title>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          fontSize: 16,
        }}>
        <CloseIcon fontSize={'inherit'} />
      </IconButton>
      <OrganizationForm onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
        <DialogContent>
          <FullWidthInputContainer>
            <FormInput
              labelText={getString('organizationModalComponent.form.name')}
              name='name'
              inputPlaceholderText={getString(
                'organizationModalComponent.placeholder.name'
              )}
              inputType='text'
              value={organizationData?.name}
              invalidOnEmpty={true}
              isRequired={true}
              validationFunctions={[
                GlobalValidator.required,
                GlobalValidator.nameCheck,
              ]}
              onClick={() => googleAnalytics('organization_name')}
              getInputError={getInputError}
            />
          </FullWidthInputContainer>
          <FullWidthInputContainer>
            <div className='dropdown'>
              <label htmlFor={getString('form.label.carePlan')}>
                {getString('form.label.carePlan')}
              </label>
              <Select
                name='carePlan'
                disabled={props.organization ? true : false}
                onBlur={e => setCarePlanIsValid(e.target.value ? true : false)}
                className={carePlanIsValid ? '' : 'invalid'}
                onChange={e => setCarePlan(+e.target.value)}
                value={carePlan}>
                <option value='' hidden>
                  {getString('organizationModalComponent.form.selectCarePlan')}
                </option>
                {carePlanOption?.map(option => {
                  return (
                    <>
                      <option value={option.id}>{option.title}</option>
                    </>
                  );
                })}
              </Select>
              {!carePlanIsValid && (
                <InputErrorText>
                  {getString('controlMessageComponent.message.required')}
                </InputErrorText>
              )}
            </div>
          </FullWidthInputContainer>
          <HalfWidthInputContainer>
            <FormInput
              labelText={getString('form.label.alertLevelDays')}
              name='alertLevelDays'
              inputPlaceholderText={getString(
                'organizationModalComponent.placeholder.alertLevelDays'
              )}
              inputType='text'
              value={
                organizationData?.alertLevelDays === undefined
                  ? 3
                  : organizationData?.alertLevelDays
              }
              invalidOnEmpty={true}
              isRequired={true}
              validationFunctions={[
                GlobalValidator.required,
                GlobalValidator.nonnegativeNumber,
                GlobalValidator.numeric,
              ]}
              onClick={() => googleAnalytics('alert_level_days')}
              getInputError={getInputError}
              maxLength={3}
            />
          </HalfWidthInputContainer>
          <HalfWidthInputContainer>
            <FormInput
              labelText={getString('form.label.disconnectionDays')}
              name='disconnectionDays'
              inputPlaceholderText={getString(
                'organizationModalComponent.placeholder.disconnectionDays'
              )}
              inputType='text'
              value={
                organizationData?.disconnectionDays === undefined
                  ? 14
                  : organizationData?.disconnectionDays
              }
              invalidOnEmpty={true}
              isRequired={true}
              validationFunctions={[
                GlobalValidator.required,
                GlobalValidator.nonnegativeNumber,
                GlobalValidator.numeric,
              ]}
              onClick={() => googleAnalytics('disconnection_days')}
              getInputError={getInputError}
              maxLength={3}
            />
          </HalfWidthInputContainer>
          <HalfWidthInputContainer>
            <FormInput
              labelText={getString('form.label.deletionDays')}
              name='deletionDays'
              inputPlaceholderText={getString(
                'organizationModalComponent.placeholder.deletionDays'
              )}
              inputType='text'
              value={
                organizationData?.deletionDays === undefined
                  ? 30
                  : organizationData?.deletionDays
              }
              invalidOnEmpty={true}
              isRequired={true}
              validationFunctions={[
                GlobalValidator.required,
                GlobalValidator.nonnegativeNumber,
                GlobalValidator.numeric,
              ]}
              onClick={() => googleAnalytics('deletionDays')}
              getInputError={getInputError}
              maxLength={3}
            />
          </HalfWidthInputContainer>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            type='submit'
            disabled={isLoading}
            label={getString('form.button.save')}
          />
        </DialogActions>
      </OrganizationForm>
    </Dialog>
  );
}
