import { parseISO, format, eachDayOfInterval } from 'date-fns';
import { CSSObject } from 'highcharts';
import { StandardGraph, Heatmap } from '../models';
import theme from '../assets/theme';
import { Reveal2LiteRiskScore } from '../api/models';
import './chartTooltip.css';

export interface Series {
  name: string;
  data: (number | null)[][];
  connectNulls?: boolean;
  type: string;
}
export interface RiskSeries {
  name: string;
  data: ({ x: number; y: number; color: string } | undefined)[];
  connectNulls?: boolean;
  type: string;
}

export const getSeries = (graph: StandardGraph): Series[] => {
  const series: Series[] = [];
  for (const name in graph.values) {
    const data = graph.values[name].map(item => [
      parseISO(item.date).getTime(),
      parseFloat(item.value ?? ''),
    ]);
    series.push({
      name,
      data,
      connectNulls: graph.graphType === 'Line',
      type: (() => {
        switch (graph.graphType) {
          case 'Bar':
            return 'column';
          case 'Scatter':
            return 'scatter';
          default:
            return 'line';
        }
      })(),
    });
  }
  return series;
};

export const getRiskScoreSeries = (graph: StandardGraph): RiskSeries[] => {
  const series: RiskSeries[] = [];
  for (const name in graph.values) {
    const data = graph.values[name].map(item => {
      if (item.value == null) {
        return;
      } else {
        return {
          x: parseISO(item.date).getTime(),
          y: +item.value,
          color:
            +item.value < 6
              ? theme.colors.lowRisk
              : +item.value < 8
                ? theme.colors.mediumRisk
                : theme.colors.highRisk,
        };
      }
    });
    series.push({
      name,
      data,
      connectNulls: true,
      type: 'line',
    });
  }
  return series;
};

export const getYAxisRange = (graph: StandardGraph, series: Series[]) => {
  if (graph.yMin && graph.yMax) {
    return [+graph.yMin, +graph.yMax];
  }
  const yMax = Math.ceil(
    series.reduce((yMax, item) => {
      const itemMax = item.data.reduce(
        (itemYMax, [, y]) =>
          !!y && (!itemYMax || y > itemYMax) ? y : itemYMax,
        0
      );
      return itemMax > yMax ? itemMax : yMax;
    }, 0)
  );
  const yMaxPadding = 5;
  return [0, yMax ? null : yMaxPadding];
};

export const axisLabelStyle: CSSObject = {
  color: theme.highcharts.axisLabel,
  fontSize: '12px',
  textOverflow: 'none',
};

export const standardChartOptions = (graph: StandardGraph) => {
  const series = getSeries(graph);
  const yAxisRange = getYAxisRange(graph, series);
  const weeklyInterval = 7 * 24 * 3600 * 1000;

  const options: Highcharts.Options = {
    title: {
      text: '',
    },
    series: series as Highcharts.SeriesOptionsType[],
    xAxis: {
      tickInterval: weeklyInterval,
      allowDecimals: false,
      labels: {
        enabled: true,
        formatter: function () {
          return format(this.value as number, 'MMM.dd');
        },
        style: axisLabelStyle,
      },
      lineColor: theme.highcharts.gridLine,
      tickColor: theme.highcharts.gridLine,
      startOnTick: true,
      endOnTick: true,
      crosshair: graph.graphType === 'Line',
    },
    yAxis: {
      min: yAxisRange[0],
      max: yAxisRange[1],
      tickAmount: graph.yAmount,
      title: {
        text: graph.yLabel,
      },
      labels: {
        style: axisLabelStyle,
      },
    },
    colors: theme.highcharts.standard,
    legend: {
      itemStyle: {
        color: theme.highcharts.legendLabel,
        fontSize: '12px',
        fontWeight: 'bold',
      },
    },
    tooltip: {
      shared: true,
      formatter: function () {
        let markup = `<b>${format(this.x as number, 'MMM.dd')}</b>`;
        const points = this.points ? this.points : [this];
        for (const point of points) {
          markup += `<br/><span style="color:${point.color};">●&nbsp;</span>`;
          markup += `<b>${point.series.name}</b> ${point.y}`;
        }
        return markup;
      },
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        events: {
          legendItemClick: () => false,
        },
      },
    },
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
  return options;
};

export const riskScoreChartOptions = (
  graph: StandardGraph,
  trackersData: Reveal2LiteRiskScore[]
) => {
  const series = getRiskScoreSeries(graph);
  const yAxisRange = [0, 15];
  const weeklyInterval = 7 * 24 * 3600 * 1000;

  const options: Highcharts.Options = {
    title: {
      text: '',
    },
    series: series as Highcharts.SeriesOptionsType[],
    xAxis: {
      tickInterval: weeklyInterval,
      allowDecimals: false,
      labels: {
        enabled: true,
        formatter: function () {
          return format(this.value as number, 'MMM.dd');
        },
        style: axisLabelStyle,
      },
      lineColor: theme.highcharts.gridLine,
      tickColor: theme.highcharts.gridLine,
      startOnTick: true,
    },
    yAxis: {
      lineWidth: 1,
      lineColor: theme.highcharts.gridLine,
      tickInterval: 2,
      min: yAxisRange[0],
      max: yAxisRange[1],
      tickAmount: graph.yAmount,
      title: {
        text: '',
      },
      allowDecimals: false,
      endOnTick: false,
    },
    colors: theme.highcharts.standard,
    legend: {
      itemStyle: {
        color: theme.highcharts.legendLabel,
        fontSize: '12px',
        fontWeight: 'bold',
      },
    },
    tooltip: {
      outside: true,
      formatter: function () {
        const points = this.points ? this.points : [this];
        let tooltipText =
          '<div class="fixed-wrapper"><table class="tooltip-table"><tr><td colspan="3" class="tooltip-td0">' +
          '<b>' +
          format(this.x as number, 'LLLL do, yyyy') +
          '</b></td></tr>';

        if (!trackersData) {
          return;
        }

        const romanValues = ['-', 'I', 'II', 'III', 'IV'];
        points.forEach(point => {
          const index = this.series.data.indexOf(this.point);
          if (
            trackersData[index] === undefined ||
            trackersData[index] === null
          ) {
            return;
          }
          const whoFCValue =
            +(
              trackersData[index].referenceData.WhoFunctionalClass?.fields[0]
                ?.value ?? '0'
            ) || 0;
          tooltipText +=
            '<br/><tr ><td colspan="3" class="tooltip-td0"' +
            'style="color: #ffff; background-color:' +
            point.color +
            '">' +
            point.series.name +
            ': ' +
            point.y +
            (point.color === theme.colors.lowRisk
              ? ' (LOW) '
              : point.color === theme.colors.mediumRisk
                ? ' (INTERMEDIATE) '
                : ' (HIGH) ') +
            '</td></tr> ';
          tooltipText +=
            '<tr class="tooltip-tr"><td class="tooltip-td1">' +
            'BP</td> ' +
            (trackersData[index].referenceData.BloodPressure === null
              ? '<td> - </td><td> - </td>'
              : '<td class="tooltip-td2">' +
                trackersData[index].referenceData.BloodPressure?.fields[0]
                  .systolic +
                '/' +
                trackersData[index].referenceData.BloodPressure?.fields[0]
                  .diastolic +
                '<small> ' +
                trackersData[index].referenceData.BloodPressure?.fields[0]
                  .unitsLabel +
                '</small> </td><td class="tooltip-td3"> ' +
                format(
                  parseISO(
                    trackersData[index].referenceData.BloodPressure?.dateTime ??
                      ''
                  ),
                  'LLLL d'
                ) +
                ' </td>') +
            '</tr>' +
            '<tr class="tooltip-tr"><td class="tooltip-td1">' +
            ' HR </td> ' +
            (trackersData[index].referenceData.HeartRate === null
              ? '<td> - </td><td> - </td>'
              : '<td class="tooltip-td2">' +
                trackersData[index].referenceData.HeartRate?.fields[0].value +
                ' <small> ' +
                trackersData[index].referenceData.HeartRate?.fields[0]
                  .unitsLabel +
                ' </small> </td><td class="tooltip-td3"> ' +
                format(
                  parseISO(
                    trackersData[index].referenceData.HeartRate?.dateTime ?? ''
                  ),
                  'LLLL d'
                ) +
                ' </td>') +
            '</tr>' +
            '<tr class="tooltip-tr"><td class="tooltip-td1">' +
            ' 6 MWD</td> ' +
            (trackersData[index].referenceData.SixMwd === null
              ? '<td> - </td><td> - </td>'
              : '<td class="tooltip-td2">' +
                trackersData[index].referenceData.SixMwd?.sixMwd.value +
                '<small> ' +
                trackersData[index].referenceData.SixMwd?.sixMwd.unitsLabel +
                '</small> </td><td class="tooltip-td3"> ' +
                format(
                  parseISO(
                    trackersData[index].referenceData.SixMwd?.dateTime ?? ''
                  ),
                  'LLLL d'
                ) +
                ' </td>') +
            '</tr>' +
            '<tr class="tooltip-tr"><td class="tooltip-td1">' +
            'BNP/NT-</br>PROBNP </td> ' +
            (trackersData[index].referenceData.Bnp === null
              ? '<td> - </td><td> - </td>'
              : '<td class="tooltip-td2">' +
                trackersData[index].referenceData.Bnp?.fields[0].value +
                ' <small> ' +
                trackersData[index].referenceData.Bnp?.fields[0].unitsLabel +
                ' </small> </td><td class="tooltip-td3"> ' +
                format(
                  parseISO(
                    trackersData[index].referenceData.Bnp?.dateTime ?? ''
                  ),
                  'LLLL d'
                ) +
                ' </td>') +
            '</tr>' +
            '<tr class="tooltip-tr"><td class="tooltip-td1">' +
            'WHO FC</td> ' +
            (trackersData[index].referenceData.WhoFunctionalClass === null
              ? '<td> - </td><td> - </td>'
              : '<td  class="tooltip-td2">' +
                romanValues[whoFCValue] +
                ' <small>' +
                ' </small> </td><td  class="tooltip-td3">' +
                format(
                  parseISO(
                    trackersData[index].referenceData.WhoFunctionalClass
                      ?.dateTime ?? ''
                  ),
                  'LLLL d'
                ) +
                '</td>') +
            '</tr>' +
            '<tr class="tooltip-tr"><td class="tooltip-td1">' +
            'EGFR</td>' +
            (trackersData[index].referenceData.Egfr === null
              ? '<td> - </td><td> - </td>'
              : '<td  class="tooltip-td2">' +
                trackersData[index].referenceData.Egfr?.fields[0].value +
                ' <small>' +
                trackersData[index].referenceData.Egfr?.fields[0].unitsLabel +
                ' </small> </td><td  class="tooltip-td3">' +
                format(
                  parseISO(
                    trackersData[index].referenceData.Egfr?.dateTime ?? ''
                  ),
                  'LLLL d'
                ) +
                '</td>') +
            '</tr>' +
            '<tr class="tooltip-tr"><td class="tooltip-td1">' +
            'Renal Insufficiency</td> ' +
            (trackersData[index].renalInsufficiency === false
              ? ' <td class="tooltip-td2"> Not Indicated </td><td> - </td>'
              : '<td class="tooltip-td2"> Yes' +
                ' </td><td class="tooltip-td3"> - </td>') +
            '</tr></table></div>';
        });
        return tooltipText;
      },
      shared: true,
      useHTML: true,
      backgroundColor: '#FFFFFF',
      style: {
        fontFamily: 'Brandon',
        padding: '0',
      },
      positioner: function (_boxWidth, _boxHeight, point) {
        return {
          x: point.plotX - 350,
          y: point.plotY + 20,
        };
      },
    },
    plotOptions: {
      series: {
        color: '#7cb5ec',
        lineWidth: 1,
        marker: {
          enabled: true,
        },
        animation: false,
      },
    },
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
  return options;
};

export const heatmapOptions = (
  graph: Heatmap,
  indexPosition: number,
  startDate: Date,
  endDate: Date
) => {
  const dateRange = eachDayOfInterval({ start: startDate, end: endDate });
  const dateStrings = dateRange.map(date => date.toISOString());
  const parts = graph.valueAttributes.map(attribute => attribute.label);
  const attributekeys = graph.valueAttributes.map(attribute => attribute.id);

  const dataArray: number[][] = [];
  const dateValues: Record<string, any[]> = {};

  if (graph?.values) {
    Object.keys(graph.values).forEach((key: string) => {
      if (graph.values[key].length > 0) {
        dateValues[key] = graph.values[key];
      }
    });
  }

  Object.keys(dateValues).forEach((key: string) => {
    const keyIndex = attributekeys.indexOf(key);
    dataArray.push(
      ...dateValues[key].map(entry => {
        const dateIndex = dateStrings.findIndex(
          date =>
            format(new Date(date), 'yyyy-MM-dd') ===
            format(new Date(entry.date), 'yyyy-MM-dd')
        );
        return dateIndex !== -1
          ? [dateIndex, keyIndex, Number(entry.value)]
          : [];
      })
    );
  });

  const colorIndex =
    indexPosition <= theme.highcharts.heatmap.length - 1 ? indexPosition : 0;
  const [, , commonColor] = theme.highcharts.heatmap[colorIndex];

  const options: Highcharts.Options = {
    chart: {
      type: 'heatmap',
      height: '304px',
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      min: 0,
      max: dateStrings.length - 1,
      categories: dateStrings,
      type: 'category',
      crosshair: { color: theme.highcharts.crosshair },
      gridLineWidth: 1,
      gridLineColor: theme.highcharts.gridLine,
      lineColor: theme.highcharts.gridLine,
      gridZIndex: 4,
      labels: {
        style: axisLabelStyle,
        enabled: true,
        formatter: function () {
          return format(parseISO(this.value as string), 'MMM.dd');
        },
      },
    },
    yAxis: {
      categories: parts,
      min: 0,
      max: parts.length - 1,
      reversed: true,
      gridLineColor: theme.highcharts.gridLine,
      gridZIndex: 1,
      labels: {
        style: axisLabelStyle,
      },
    },
    colorAxis: {
      minColor: commonColor,
      maxColor: commonColor,
    },
    legend: { enabled: false },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
        },
      },
    },
    series: [
      {
        type: 'heatmap',
        data: dataArray,
      },
    ],
    tooltip: {
      formatter: function () {
        const p = this.point;
        const date = p.series.xAxis.categories[p.x];
        const name = p.series.yAxis.categories[p.y || 0];
        const times = p.value === 1 ? 'time' : 'times';
        return `<b>${name}</b> on <b>${format(
          parseISO(date),
          'MMM.dd'
        )}</b><br>${p.value} ${times}`;
      },
      shared: true,
      useHTML: true,
      backgroundColor: theme.colors.white,
      style: {
        fontFamily: 'Brandon',
        padding: '0',
      },
    },
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
  return options;
};
