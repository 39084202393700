import styled from 'styled-components';
import { getString } from '../../../context/UserContext';
import thumbsUp from '../../../assets/images/thumbs_up.svg';
import clock from '../../../assets/images/clock.svg';
import { Card } from './Card';
import axios from 'axios';
import Endpoints from '../../../api/endpoints';
import { ErrorToast, SuccessToast } from '../../shared/ToastrNotifications';
import { useEffect, useState } from 'react';
import BaseModal from '../../shared/modals/BaseModal';
import {
  GenericButton,
  ModalButtonContainer,
  ModalTitle,
} from '../../shared/modals/BaseModalStyle';

const NotificationsCard = styled(Card)`
  padding: 15px;
  color: ${props => props.theme.colors.pacificBlue};
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: row;

  @media (min-width: ${props => props.theme.breakPoints.md}) {
    span:nth-child(1) {
      justify-content: flex-end;
    }
  }

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    flex-direction: column;
    justify-content: flex-start;
  }

  @media print {
    &.do-not-print {
      display: none !important;
    }
  }
`;

const Link = styled.span`
  cursor: pointer;
  width: 50%;
  display: flex;

  @media (max-width: ${props => props.theme.breakPoints.md}) {
    width: 100%;
  }

  img {
    margin-right: 8px;
    vertical-align: middle;
  }
`;

const Title = styled(ModalTitle)`
  text-transform: none;
  font-size: 16px;
  margin: -39px 0px 16px;
  line-height: 1.5;
`;

const ModalBody = styled.div`
  border-top: 1px solid ${props => props.theme.colors.hawkesBlue};
  color: ${props => props.theme.colors.deepGray};
  margin: 0;
  padding: 25px 30px 0;
  text-align: center;
  text-transform: none;
  font-weight: 400;
`;

const ButtonContainer = styled(ModalButtonContainer)`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = styled(GenericButton)`
  margin: 0;
  min-width: auto;
`;

interface NotificationsProps {
  patientId: number;
}

interface ReinforcementModalProps extends NotificationsProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  onCloseButtonClicked?: () => void;
}

export const ReinforcementModal = (props: ReinforcementModalProps) => {
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(Endpoints.fetchReinforcementMessage)
      .then(response => {
        setMessage(response?.data?.message);
        setType(response?.data?.type);
      })
      .catch(error => ErrorToast(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };

  const sendReinforcementNotification = () => {
    setIsLoading(true);
    axios
      .post(Endpoints.pushReinforcementNotification(props.patientId), {
        message: message,
        type: type,
      })
      .then(() => SuccessToast('', getString('toastrMessages.types.success')))
      .catch(error => {
        ErrorToast(error);
      })
      .finally(() => {
        setIsLoading(false);
        handleClose();
      });
  };

  return (
    <BaseModal
      onCloseButtonClicked={() => props.onCloseButtonClicked?.()}
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}>
      <Title>{getString('reinforcementComponent.header.label')}</Title>
      <ModalBody>{message}</ModalBody>
      <ButtonContainer>
        <Button onClick={sendReinforcementNotification} disabled={isLoading}>
          {getString('reinforcementComponent.button.label')}
        </Button>
      </ButtonContainer>
    </BaseModal>
  );
};

const Notifications = ({ patientId }: NotificationsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const sendNotification = () => {
    axios
      .post(Endpoints.pushPatientUpdateNotification(patientId))
      .then(() => SuccessToast('', getString('toastrMessages.types.success')))
      .catch(error => {
        ErrorToast(error);
      });
  };

  return (
    <>
      {isOpen && (
        <ReinforcementModal
          patientId={patientId}
          setModalIsOpen={setIsOpen}
          modalIsOpen={isOpen}
        />
      )}
      <NotificationsCard className='do-not-print'>
        <Link role='button' aria-hidden='true' onClick={() => setIsOpen(true)}>
          <img src={thumbsUp} alt='' />
          {getString('reinforcementComponent.link.title')}
        </Link>
        <Link role='button' aria-hidden='true' onClick={sendNotification}>
          <img src={clock} alt='' />
          {getString('notificationComponent.link.title')}
        </Link>
      </NotificationsCard>
    </>
  );
};

export default Notifications;
