import React, { useState, ChangeEvent, FocusEvent, useEffect } from 'react';
import { useUserContext } from '../../context/UserContext';
import styled from 'styled-components';
import { ValidationResult } from './GlobalValidator';

type FormInputProps = {
  labelText: string;
  name?: string;
  inputPlaceholderText?: string;
  inputType?: string;
  invalidOnEmpty?: boolean;
  isRequired?: boolean;
  onClick?: () => void;
  helperText?: string;
  validationFunctions?: ((value: string) => ValidationResult)[];
  showInputError?: boolean;
  maxLength?: number;
  value?: string | number;
  getInputError?: (key: string, value: string | number) => void;
  includeLabel?: boolean;
};

const InputErrorText = styled.p`
  font-family: Brandon;
  font-size: 16px;
  color: ${props => props.theme.colors.mangoTango};
  margin: auto;
  margin-bottom: 8px;
  text-align: left;
  width: 85%;
`;

const FormInputContainer = styled.div`
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 9.6px 0 9.6px 0;
`;

const Label = styled.label`
  font-weight: 700;
  text-transform: uppercase;
  color: ${props => props.theme.colors.charcoal};
  text-align: left;
  margin: auto;
  width: fit-content;
  float: left;
`;

const OptionalLabel = styled.label`
  font-weight: 700;
  text-transform: uppercase;
  color: ${props => props.theme.colors.loblolly} !important;
  text-align: right;
  width: fit-content;
  float: right;
`;

const LabelContainer = styled.div`
  width: 85%;
  align-self: center;
`;

const Input = styled.input<{
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  getInputError?: (key: string, value: string | number) => void;
  $hasBeenFocused: boolean;
  $invalidOnEmpty: boolean;
}>`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-family: 'Brandon';
  font-size: 16px;
  height: 24px;
  padding: 10px;
  margin: auto;
  width: 80%;
  &.invalid {
    border: 1px solid ${props => props.theme.colors.mangoTango};
  }
  ${props =>
    props.$hasBeenFocused &&
    props.$invalidOnEmpty &&
    `
        &:invalid {
        border: 1px solid ${props.theme.colors.mangoTango};
        }
    `}

  ${props =>
    props.$hasBeenFocused &&
    props.$invalidOnEmpty &&
    `
        &:invalid {
        border: 1px solid ${props.theme.colors.mangoTango};
        }
    `}

  &:focus {
    outline: none !important;
    border: 1px solid ${props => props.theme.colors.curiousBlue};
  }
`;

export const FormInput = ({
  labelText,
  name,
  inputPlaceholderText,
  inputType,
  invalidOnEmpty = true,
  isRequired = true,
  onClick,
  validationFunctions = [],
  showInputError = true,
  maxLength,
  value,
  getInputError,
  includeLabel = true,
}: FormInputProps) => {
  const [hasBeenFocused, setHasBeenFocused] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [inputError, setInputError] = useState('');
  const [inputClass, setInputClass] = useState('');
  const { getString } = useUserContext();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (name) getInputError?.(name, inputError);
    if (!inputError) setInputClass('');
  }, [inputError, name, getInputError]);

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    // The first time this is clicked off, enable the invalid selector
    if (!hasBeenFocused) {
      setHasBeenFocused(true);
    }
    validateInput(e);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    validateInput(e);
  };

  const validateInput = (
    e: ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>
  ) => {
    const inputValue = e.target.value.toString();
    const isRequiredFieldEmpty = isRequired && !inputValue;
    const requiredErrorString = getString(
      'controlMessageComponent.message.required'
    );

    setInputError(isRequiredFieldEmpty ? requiredErrorString : '');
    setInputClass(isRequiredFieldEmpty ? 'invalid' : '');

    let validationResult: ValidationResult = {};
    for (const validationFunction of validationFunctions) {
      validationResult = validationFunction(inputValue);
      const firstKey = Object.keys(validationResult)[0];
      if (validationResult[firstKey]) {
        setInputError(getString(`controlMessageComponent.message.${firstKey}`));
        setInputClass('invalid');
        break;
      }
    }
  };

  const inputName = 'input-' + labelText.toLowerCase().replace(' ', '-');
  return (
    <FormInputContainer>
      {includeLabel && (
        <LabelContainer>
          <Label htmlFor={inputName}>{labelText}</Label>
          {!isRequired && (
            <OptionalLabel htmlFor={inputName} className={inputName}>
              {getString('form.label.optional')}
            </OptionalLabel>
          )}
        </LabelContainer>
      )}

      <Input
        // required={isRequired}
        className={inputClass}
        name={name}
        onBlur={e => handleOnBlur(e)}
        onChange={e => handleOnChange(e)}
        onClick={onClick}
        $hasBeenFocused={hasBeenFocused}
        $invalidOnEmpty={invalidOnEmpty}
        type={inputType}
        id={inputName}
        value={inputValue}
        maxLength={maxLength}
        placeholder={inputPlaceholderText}
        data-testid={labelText}></Input>
      {inputError.length !== 0 && showInputError ? (
        <InputErrorText>{inputError}</InputErrorText>
      ) : null}
    </FormInputContainer>
  );
};

export default FormInput;
