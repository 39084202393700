import { useEffect, useState, Dispatch, SetStateAction } from 'react';

const useLocalStorage = <T>(
  storageKey: string,
  initialState: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    // Retrieve state from local storage or use the initial state
    const storedState = localStorage.getItem(storageKey);
    return storedState ? JSON.parse(storedState) : initialState;
  });

  useEffect(() => {
    // When state changes update local storage
    if (!state) {
      localStorage.removeItem(storageKey);
    } else {
      localStorage.setItem(storageKey, JSON.stringify(state));
    }
  }, [state, storageKey]);

  return [state, setState];
};

export default useLocalStorage;
