import BaseModal from '../../shared/modals/BaseModal';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useUserContext } from '../../../context/UserContext';
import { GenericButton } from '../../shared/modals/BaseModalStyle';
import axios from 'axios';
import Endpoints from '../../../api/endpoints';
import { ErrorToast } from '../../shared/ToastrNotifications';
import { CarePlanTracker } from '../../../api/models';
import theme from '../../../assets/theme';
import { Patient } from '../../../models';
import { logGAEvent } from '../../../shared/services/googleAnalyticsService';

const ModalTitle = styled.h1`
  color: ${props => props.theme.colors.charcoal};
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: -40px;
  margin-bottom: 10px;
`;

const Divider = styled.hr`
  background-color: ${props => props.theme.colors.athensGray};
  border-width: 0px;
  height: 2px;
`;
const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
`;
const ModalInstructions = styled.p`
  font-size: 16px;
  margin: 0px;
  padding: 0 14px;
  font-weight: 400;
`;

const ControlsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Control = styled.button`
  text-transform: capitalize;
  text-decoration: underline;
  color: ${props => props.theme.colors.pacificBlue};
  padding: 0 10px;
`;

const SelectionBody = styled.div`
  display: flex;
  flex-direction: row;
`;

const SectionLabel = styled.label`
  margin: 0;
  font-size: 16px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 700;

  &.full-width {
    width: 100%;
  }
`;

const SelectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 33%;
  min-height: 20px;
  justify-content: left;
`;
const TrackerSelectionColumn = styled(SelectionColumn)`
  width: 378px;
  flex: 66%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  border-left: 1px solid ${props => props.theme.colors.athensGray};
  gap: 0 10px;
  padding: 0 15px;
`;

const TrackerLabel = styled.label`
  font-size: 15px;
  font-weight: 400;
  align-items: center;
`;

const InputItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  max-width: 160.5px;
  padding: 0 15px;
  text-align: left;

  &.half-width {
    width: 50%;
    padding: 0;
    align-content: start;
  }
`;

const ExportButton = styled(GenericButton)`
  min-width: auto;
`;
const defaultTrackersNoPAH = [
  {
    default: false,
    description: 'Medications',
    id: 'Medications',
    previouslyActive: true,
    required: false,
    title: 'MEDICATIONS',
  },
  {
    default: false,
    description: 'Appointments',
    id: 'Appointments',
    previouslyActive: true,
    required: false,
    title: 'APPOINTMENTS',
  },
  {
    default: false,
    description: 'Education',
    id: 'Education',
    previouslyActive: true,
    required: false,
    title: 'EDUCATION',
  },
];
const defaultTrackersWithPAH = [
  ...defaultTrackersNoPAH,
  {
    default: false,
    description: 'Reveal Lite 2 Risk Score',
    id: 'Risk Score',
    previouslyActive: true,
    required: false,
    title: 'RISK SCORE',
  },
];

const ExportProfileModal = (props: {
  patient: Patient | undefined;
  patientId: number;
  modalIsOpen: boolean;
  setModalIsOpen: (isOpen: boolean) => void;
  triggerPrint: (selectedTrackers: CarePlanTracker[]) => void;
}) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };

  const [defaultTrackers, setDefaultTrackers] = useState<CarePlanTracker[]>(
    props.patient?.carePlanTemplate ===
      'Pulmonary Arterial Hypertension Care Module'
      ? defaultTrackersWithPAH
      : defaultTrackersNoPAH
  );

  const [selfReportTrackers, setSelfReportTrackers] =
    useState<CarePlanTracker[]>();

  const { getString } = useUserContext();

  useEffect(() => {
    props.patient?.carePlanTemplate ===
    'Pulmonary Arterial Hypertension Care Module'
      ? setDefaultTrackers(defaultTrackersWithPAH)
      : setDefaultTrackers(defaultTrackersNoPAH);
  }, [props.patient]);
  // get care plan trackers
  useEffect(() => {
    axios
      .get(Endpoints.customTrackers(props.patientId))
      .then(response => {
        const activeTrackers: CarePlanTracker[] = response.data.filter(
          (tracker: CarePlanTracker) => tracker.previouslyActive === true
        );
        activeTrackers.map(tracker => {
          if (tracker.title.toLowerCase() === 'meal rater') {
            tracker.id = 'meal';
          } else if (tracker.title.toLowerCase() === 'who functional class') {
            tracker.id = 'who-functional-class-level';
          } else if (tracker.title.toLowerCase() === 'bnp/nt probnp') {
            tracker.id = 'bnp';
          } else if (tracker.title.toLowerCase() === 'daily routine') {
            tracker.id = 'daily-routine-level';
          } else {
            tracker.id = tracker.title.toLowerCase().replace(/ /g, '-');
          }
        });
        setSelfReportTrackers(activeTrackers);
      })
      .catch(error => {
        ErrorToast(error);
      });
  }, [props.patientId]);

  const updateDefaultTracker = (index: number) => {
    const updatedCheckedValues = defaultTrackers?.map((item, i) => {
      if (index === i) {
        item.default = !item.default;
      }
      return item;
    });
    setDefaultTrackers(updatedCheckedValues);
  };

  const updateSelfReportTracker = (index: number) => {
    const updatedCheckedValues = selfReportTrackers?.map((item, i) => {
      if (index === i) {
        item.default = !item.default;
      }
      return item;
    });

    setSelfReportTrackers(updatedCheckedValues);
  };
  const setAll = (value: boolean) => {
    const updatedDefaultReportTrackers = defaultTrackers?.map(item => {
      item.default = value;
      return item;
    });
    setDefaultTrackers(updatedDefaultReportTrackers);

    const updatedSelfReportTrackers = selfReportTrackers?.map(item => {
      item.default = value;
      return item;
    });
    setSelfReportTrackers(updatedSelfReportTrackers);
  };

  const exportPage = () => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'exportprofile',
      eventAct: 'click',
      eventLbl: 'export-profile',
    });
    const trackersCopy = defaultTrackers
      .concat(selfReportTrackers ?? [])
      .filter(item => item?.default === true);
    if (
      trackersCopy == null ||
      trackersCopy == undefined ||
      trackersCopy.length === 0
    ) {
      return;
    }

    props.triggerPrint(trackersCopy);
    props.setModalIsOpen(false);
  };

  return (
    <BaseModal
      modalWidth={theme.modalWidths.lg}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      handleClose={handleClose}>
      <ModalTitle>
        {getString('exportProfileModalComponent.title.exportProfile')}
      </ModalTitle>
      <Divider />
      <ModalBody>
        <ModalInstructions>
          {getString('exportProfileModalComponent.label.exportText')}
        </ModalInstructions>
        <ControlsRow>
          <Control onClick={() => setAll(true)}>
            {getString('exportProfileModalComponent.button.selectAll')}
          </Control>
          <Control onClick={() => setAll(false)}>
            {getString('exportProfileModalComponent.button.clear')}
          </Control>
        </ControlsRow>
        <SelectionBody>
          <SelectionColumn>
            {defaultTrackers.map((tracker, i) => {
              return (
                <InputItem key={tracker.title}>
                  <SectionLabel htmlFor={tracker.title}>
                    <input
                      id={tracker.title}
                      type='checkbox'
                      checked={defaultTrackers?.[i].default}
                      onChange={() => updateDefaultTracker(i)}
                    />
                    {tracker.title}
                  </SectionLabel>
                </InputItem>
              );
            })}
          </SelectionColumn>
          <TrackerSelectionColumn>
            <SectionLabel className='full-width'>
              {getString('exportProfileModalComponent.label.selfTracker')}
            </SectionLabel>
            {selfReportTrackers?.map((tracker, i) => {
              return (
                <InputItem className='half-width' key={tracker.title}>
                  <TrackerLabel htmlFor={tracker.title}>
                    <input
                      id={tracker.title}
                      type='checkbox'
                      checked={selfReportTrackers[i]?.default}
                      onChange={() => updateSelfReportTracker(i)}
                    />
                    {tracker.title}
                  </TrackerLabel>
                </InputItem>
              );
            })}
          </TrackerSelectionColumn>
        </SelectionBody>
        <ExportButton onClick={() => exportPage()}>
          {getString('exportProfileModalComponent.button.export')}
        </ExportButton>
      </ModalBody>
    </BaseModal>
  );
};

export default ExportProfileModal;
