import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  background-color: ${props => props.theme.colors.pacificBlue};
  border: 1px solid ${props => props.theme.colors.pacificBlue};
  border-radius: 21px;
  color: ${props => props.theme.colors.white};
  display: block;
  font-weight: 900;
  font-size: medium;
  height: 42px;
  margin: auto;
  margin-bottom: 16px;
  width: 67%;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.cerulean};
    border: 1px solid ${props => props.theme.colors.cerulean};
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export declare interface PrimaryButtonProps {
  label?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

export const PrimaryButton = (props: PrimaryButtonProps) => (
  <Button onClick={props?.onClick} type={props.type} disabled={props.disabled}>
    <span>{props.label}</span>
  </Button>
);

export default PrimaryButton;
