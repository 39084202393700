import { useState, useRef } from 'react';
import styled from 'styled-components';
import ActivityGraph from './ActivityGraph';
import ActivityEncouragement from './ActivityEncouragement';
import { PatientTracker } from '../../../models';
import arrowImage from '../../../assets/images/arrow_expanded.svg';
import infoImage from '../../../assets/images/info.svg';
import { logGAEvent } from '../../../shared/services/googleAnalyticsService';

const Container = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${props => props.theme.colors.athensGray};
  margin: 0;
  padding: 15px;
  button.header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    h4 {
      margin: 0;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 20px;
    }
    .toggle {
      width: 10px;
      height: 10px;
      transform: ${props => (props.$isExpanded ? 'none' : 'rotate(-90deg)')};
    }
    .tooltip {
      position: absolute;
      text-align: left;
      background-color: ${props => props.theme.colors.charcoal};
      color: ${props => props.theme.colors.white};
      border-radius: 8px;
      padding: 6px;
      line-height: 1.5;
      z-index: 13;
      font-size: 14px;
      opacity: 0.9;
      font-weight: 400;
      letter-spacing: 1px;
      &::after {
        content: '';
        position: absolute;
        top: calc(50% - 5px);
        left: -10px;
        z-index: -1;
        border-width: 5px;
        border-color: transparent ${props => props.theme.colors.charcoal}
          transparent transparent;
        border-style: solid;
      }
    }
  }
  @media print {
    &.do-not-print {
      display: none !important;
    }
  }
`;

type ActivityGraphProps = {
  tracker: PatientTracker;
  startDate?: Date;
  endDate?: Date;
  showOnPrint?: boolean;
};

const ActivityTracker = ({
  tracker,
  startDate,
  endDate,
  showOnPrint = false,
}: ActivityGraphProps) => {
  const headerRef = useRef<HTMLButtonElement>(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [toolTipLeft, setToolTipLeft] = useState<number>();
  return (
    <Container
      $isExpanded={isExpanded}
      className={showOnPrint ? '' : 'do-not-print'}>
      <button
        ref={headerRef}
        className='header'
        onClick={() => {
          const expanded = !isExpanded;
          setIsExpanded(expanded);
          logGAEvent({
            event: 'eventTracker',
            eventCat: 'self_reported_patient_trackers',
            eventAct: 'click',
            eventLbl: `${
              expanded ? 'expand' : 'collapse'
            }_accordion_${tracker.type.toLowerCase().replace(/ /g, '_')}`,
          });
        }}>
        <img alt='toggle' className='toggle' src={arrowImage} />
        <h4>{tracker.type}</h4>
        <img
          className='info'
          alt='tracker description'
          src={infoImage}
          onMouseLeave={() => setToolTipLeft(undefined)}
          onMouseEnter={e => {
            if (headerRef.current) {
              const headerRect = headerRef.current.getBoundingClientRect();
              const rect = e.currentTarget.getBoundingClientRect();
              const padding = 8;
              setToolTipLeft(
                rect.left + rect.width + padding - headerRect.left
              );
            }
            logGAEvent({
              event: 'eventTracker',
              eventCat: 'self_reported_patient_trackers',
              eventAct: 'hover',
              eventLbl: tracker.type.toLowerCase().replace(/ /g, '_'),
            });
          }}
        />
        {toolTipLeft && (
          <div className='tooltip' style={{ left: toolTipLeft }}>
            {tracker.description}
          </div>
        )}
      </button>
      {tracker.records && (
        <ActivityEncouragement
          records={tracker.records}
          isExpanded={isExpanded}
        />
      )}
      <div className='graphs'>
        {tracker.graphs?.map((graph, index) => (
          <ActivityGraph
            key={index}
            indexPosition={index}
            graph={graph}
            isExpanded={isExpanded}
            startDate={startDate}
            endDate={endDate}
          />
        ))}
      </div>
    </Container>
  );
};

export default ActivityTracker;
