import React, { useState, useEffect, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styled from 'styled-components';
import { Endpoints } from '../../api/endpoints';
import { useUserContext } from '../../context/UserContext';
import { logGAEvent } from '../../shared/services/googleAnalyticsService';
import { GlobalValidator } from '../shared/GlobalValidator';
import { FormInput } from '../shared/FormInput';
import PrimaryButton from '../shared/PrimaryButton';
import { ErrorToast, SuccessToast } from '../shared/ToastrNotifications';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Site } from '../../models';
import PhoneInput from '../shared/PhoneInput';

const SiteForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const FullWidthInputContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  div {
    &.dropdown {
      padding: 0 31px;
    }
  }
  label {
    font-weight: 700;
    text-transform: uppercase;
    color: ${props => props.theme.colors.charcoal};
  }
`;

const HalfWidthInputContainer = styled.div`
  width: 55%;
  margin: 0 0 20px 20px;
  label {
    &.input-phone-number {
      margin-right: -77%;
    }
  }
`;

const Title = styled.div`
  color: ${props => props.theme.colors.charcoal};
  font-family: 'Brandon', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 2.73px;
  padding-bottom: 0px;
  padding-top: 25px;
  text-transform: uppercase;
  text-align: center;
`;

const CustomTextField = styled(TextField)`
  padding: 10px;
  width: 100%;

  &.required fieldset {
    border-radius: 4px;
    border: 1px solid ${props => props.theme.colors.mangoTango};
    &:focus,
    &:hover {
      border-radius: 4px;
      border: 1px solid ${props => props.theme.colors.mangoTango} !important;
    }
  }

  & .MuiInputBase-input {
    height: 12px;
    color: ${props => props.theme.colors.charcoal};
    font-size: 16px;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Brandon';
  }

  &.MuiOutlinedInput-root fieldset,
  &.MuiOutlinedInput-notchedOutline {
    border: 1px solid ${props => props.theme.colors.mystic};
    border-radius: 4px;
    &:focus,
    &:hover {
      border: 1px solid ${props => props.theme.colors.mystic};
      border-radius: 4px;
    }
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }
`;

const ErrorText = styled.p`
  color: ${props => props.theme.colors.mangoTango};
  text-align: left;
  font-weight: 400;
  margin: 0;
  font-family: 'Brandon';
`;

interface Option {
  label: string;
  value: number;
}

export default function SiteModal(props: {
  onClick: (isOpen: boolean) => void;
  isOpen: boolean;
  refetchData?: (setUpdateList: boolean) => void;
  site?: Site;
}) {
  const { user, getString } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [siteData, setSiteData] = useState(props.site);
  const [organizationOption, setOrganizationOption] = useState<Option[]>([]);
  const [isOptionDisable, setIsOptionDisable] = useState(false);
  const [selectedOrgOption, setSelectedOrgOption] = useState<Option[]>([
    { value: 0, label: '' },
  ]);
  const [selectOrgId, setSelectOrgId] = useState(0);
  const [orgIsValid, setOrgIsValid] = useState(true);

  useEffect(() => {
    setSiteData(props.site);
    if (props.site) {
      const selectedValue = organizationOption.filter(
        option => option.value == props?.site?.organizationId
      );
      setSelectedOrgOption(selectedValue);
      setSelectOrgId(props?.site?.organizationId || 0);
      setIsOptionDisable(true);
    }
  }, [props.site, organizationOption]);

  const handleClose = () => {
    if (!props.site) {
      setSelectedOrgOption([{ value: 0, label: '' }]);
    }
    setOrgIsValid(true);
    props.onClick(false);
  };

  const fetchOrganization = useCallback(() => {
    axios
      .get(Endpoints.allOrganization)
      .then(result => {
        const res = result.data;
        const organizationData = res.map(
          (option: { id: number; name: string }) => ({
            value: option.id,
            label: option.name,
          })
        );
        setOrganizationOption(organizationData);
      })
      .catch(error => {
        setIsLoading(false);
        ErrorToast(error);
      });
  }, []);

  useEffect(() => {
    fetchOrganization();
  }, [fetchOrganization]);

  const invalidForm = (body: {
    siteName: string;
    siteCode: string;
    emrId: string;
    address: string;
    phoneNumber: string;
    alertLevelDays: string;
    disconnectionDays: string;
  }) => {
    const nameResponse = GlobalValidator.siteNameCheck(body.siteName);
    const codeResponse = GlobalValidator.siteCodeCheck(body.siteCode);
    const emrIdResponse = user?.superAdmin
      ? GlobalValidator.emrIdCheck(body.emrId)
      : {};
    const phoneResponse = GlobalValidator.phone(body.phoneNumber);
    const negativeAlertDaysResponse = GlobalValidator.nonnegativeNumber(
      body.alertLevelDays
    );
    const invalidAlertDaysResponse = GlobalValidator.numeric(
      body.alertLevelDays
    );
    const negativeDisconnectionDaysResponse = GlobalValidator.nonnegativeNumber(
      body.disconnectionDays
    );
    const invalidDisconnectionDaysResponse = GlobalValidator.numeric(
      body.disconnectionDays
    );

    if (
      user?.superAdmin &&
      !selectOrgId &&
      nameResponse?.invalidName &&
      codeResponse?.invalidSiteCode &&
      user?.superAdmin &&
      emrIdResponse?.invalidEmrId &&
      phoneResponse?.invalidPhoneNumber &&
      (negativeAlertDaysResponse?.invalidNumber ||
        invalidAlertDaysResponse?.invalidNumericString) &&
      (negativeDisconnectionDaysResponse?.invalidNumber ||
        invalidDisconnectionDaysResponse?.invalidNumericString)
    ) {
      return {
        status: true,
        key: ['toastrMessages.common.pleaseFillRequiredFields'],
      };
    }

    const errorKeys = [];

    if (user?.superAdmin && !selectOrgId) {
      errorKeys.push('controlMessageComponent.message.invalidOrganization');
    }

    if (nameResponse?.invalidName) {
      errorKeys.push('controlMessageComponent.message.invalidSiteName');
    }

    if (codeResponse?.invalidSiteCode) {
      errorKeys.push('controlMessageComponent.message.invalidSiteCode');
    }

    if (emrIdResponse?.invalidEmrId) {
      errorKeys.push('controlMessageComponent.message.invalidEmrId');
    }

    if (phoneResponse?.invalidPhoneNumber) {
      errorKeys.push('controlMessageComponent.message.invalidPhoneNumber');
    }

    if (
      negativeAlertDaysResponse?.invalidNumber ||
      negativeDisconnectionDaysResponse?.invalidNumber ||
      invalidAlertDaysResponse?.invalidNumericString ||
      invalidDisconnectionDaysResponse?.invalidNumericString
    ) {
      errorKeys.push('controlMessageComponent.message.invalidNumerString');
    }

    return { status: errorKeys.length > 0 ? true : false, key: errorKeys };
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    googleAnalytics('site_save');
    e.preventDefault(); // Prevent default form submission behavior
    const target = e.target as typeof e.target & {
      siteName: { value: string };
      siteCode: { value: string };
      emrId: { value: string };
      address: { value: string };
      phoneNumber: { value: string };
      alertLevelDays: { value: string };
      disconnectionDays: { value: string };
    };

    const body = {
      siteName: target.siteName.value.trim(),
      siteCode: target.siteCode.value.trim(),
      emrId: target?.emrId?.value?.trim() || '',
      address: target.address.value.trim(),
      phoneNumber: target.phoneNumber.value.replace(/\D/g, '').trim(),
      alertLevelDays: target.alertLevelDays.value.trim(),
      disconnectionDays: target.disconnectionDays.value.trim(),
      organizationId: user?.superAdmin ? selectOrgId : user?.organization?.id,
    };

    const response = invalidForm(body);
    if (response.status) {
      const message = response.key?.map(val => getString(val));
      ErrorToast(message.join('<br/>'));
      return false;
    }

    setIsLoading(true);
    if (props.site) {
      axios
        .put(`${Endpoints.site}/${props.site.id}`, {
          ...body,
          id: props.site.id,
        })
        .then(() => {
          handleClose();
          SuccessToast('', getString('toastrMessages.types.success'));
          props?.refetchData?.(true);
        })
        .catch(error => ErrorToast(error))
        .finally(() => setIsLoading(false));
    } else {
      axios
        .post(Endpoints.site, body)
        .then(() => {
          handleClose();
          SuccessToast('', getString('toastrMessages.types.success'));
          props?.refetchData?.(true);
        })
        .catch(error => ErrorToast(error))
        .finally(() => setIsLoading(false));
    }
  };

  const googleAnalytics = (type: string) => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'admin',
      eventAct: 'click',
      eventLbl: type === 'site_save' ? 'new_site_save' : 'add_' + type,
    });
  };

  return (
    <Dialog
      aria-labelledby='site-form'
      open={props.isOpen}
      disableEscapeKeyDown
      fullWidth
      maxWidth='xs'
      scroll='body'>
      <DialogTitle>
        <Title>
          {getString(
            `siteModalComponent.title.${!siteData?.id ? 'newSite' : 'editSite'}`
          )}
        </Title>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          fontSize: 16,
        }}>
        <CloseIcon fontSize={'inherit'} />
      </IconButton>
      <SiteForm onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
        <DialogContent>
          {user?.superAdmin && (
            <FullWidthInputContainer>
              <div className='dropdown'>
                <label htmlFor={getString('form.label.organization')}>
                  {getString('form.label.organization')}
                </label>
                <Autocomplete
                  disablePortal
                  options={organizationOption}
                  disableClearable={true}
                  getOptionDisabled={option => isOptionDisable}
                  value={selectedOrgOption[0]}
                  onChange={(event, option) => {
                    const obj1: Option = option;
                    setSelectedOrgOption([obj1]);
                    setSelectOrgId(obj1.value);
                  }}
                  onBlur={() =>
                    !selectedOrgOption?.[0]?.value
                      ? setOrgIsValid(false)
                      : setOrgIsValid(true)
                  }
                  id='site-org'
                  renderInput={params => (
                    <div>
                      <CustomTextField
                        {...params}
                        className={orgIsValid ? '' : 'required'}
                        placeholder={getString(
                          'siteModalComponent.placeholder.organization'
                        )}
                      />
                      {!orgIsValid && (
                        <ErrorText>
                          {getString(
                            `controlMessageComponent.message.required`
                          )}
                        </ErrorText>
                      )}
                    </div>
                  )}
                />
              </div>
            </FullWidthInputContainer>
          )}
          <FullWidthInputContainer>
            <FormInput
              labelText={getString('siteModalComponent.form.siteName')}
              name='siteName'
              inputPlaceholderText={getString(
                'siteModalComponent.placeholder.siteName'
              )}
              value={siteData?.name || ''}
              inputType='text'
              invalidOnEmpty={true}
              isRequired={true}
              validationFunctions={[
                GlobalValidator.required,
                GlobalValidator.siteNameCheck,
              ]}
              onClick={() => googleAnalytics('site_name')}
            />
          </FullWidthInputContainer>
          <FullWidthInputContainer>
            <FormInput
              labelText={getString('siteModalComponent.form.siteCode')}
              name='siteCode'
              inputPlaceholderText={getString(
                'siteModalComponent.placeholder.siteCode'
              )}
              inputType='text'
              maxLength={8}
              value={siteData?.identifier || ''}
              invalidOnEmpty={false}
              isRequired={false}
              validationFunctions={[GlobalValidator.siteCodeCheck]}
              onClick={() => googleAnalytics('site_code')}
            />
          </FullWidthInputContainer>
          {user?.superAdmin && (
            <FullWidthInputContainer>
              <FormInput
                labelText={getString('siteModalComponent.form.emrId')}
                name='emrId'
                inputPlaceholderText={getString(
                  'siteModalComponent.placeholder.emrId'
                )}
                inputType='text'
                value={siteData?.emrId || ''}
                invalidOnEmpty={false}
                isRequired={false}
                validationFunctions={[GlobalValidator.emrIdCheck]}
                onClick={() => googleAnalytics('emr_id')}
              />
            </FullWidthInputContainer>
          )}
          <FullWidthInputContainer>
            <FormInput
              labelText={getString('form.label.address')}
              name='address'
              inputPlaceholderText={getString(
                'siteModalComponent.placeholder.address'
              )}
              value={siteData?.address || ''}
              inputType='text'
              invalidOnEmpty={false}
              isRequired={false}
              onClick={() => googleAnalytics('address')}
            />
          </FullWidthInputContainer>
          <HalfWidthInputContainer>
            <PhoneInput
              labelText={getString('form.label.phoneNumber')}
              name='phoneNumber'
              inputPlaceholderText={getString(
                'siteModalComponent.placeholder.phoneNumber'
              )}
              valueOverride={siteData?.phoneNumber || ''}
              invalidOnEmpty={false}
              isRequired={false}
              onClick={() => googleAnalytics('phone_number')}
            />
          </HalfWidthInputContainer>
          <HalfWidthInputContainer>
            <FormInput
              labelText={getString('form.label.alertLevelDays')}
              name='alertLevelDays'
              inputPlaceholderText={getString(
                'siteModalComponent.placeholder.alertLevelDays'
              )}
              inputType='text'
              value={
                siteData?.alertLevelDays === undefined
                  ? 3
                  : siteData?.alertLevelDays
              }
              invalidOnEmpty={true}
              isRequired={true}
              validationFunctions={[
                GlobalValidator.required,
                GlobalValidator.nonnegativeNumber,
                GlobalValidator.numeric,
              ]}
              onClick={() => googleAnalytics('alert_level_days')}
              maxLength={3}
            />
          </HalfWidthInputContainer>
          <HalfWidthInputContainer>
            <FormInput
              labelText={getString('form.label.disconnectionDays')}
              name='disconnectionDays'
              inputPlaceholderText={getString(
                'siteModalComponent.placeholder.disconnectionDays'
              )}
              inputType='text'
              value={
                siteData?.disconnectionDays === undefined
                  ? 14
                  : siteData?.disconnectionDays
              }
              invalidOnEmpty={true}
              isRequired={true}
              validationFunctions={[
                GlobalValidator.required,
                GlobalValidator.nonnegativeNumber,
                GlobalValidator.numeric,
              ]}
              onClick={() => googleAnalytics('disconnection_days')}
              maxLength={3}
            />
          </HalfWidthInputContainer>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            type='submit'
            disabled={isLoading}
            label={getString('form.button.save')}
          />
        </DialogActions>
      </SiteForm>
    </Dialog>
  );
}
