import { ReactNode } from 'react';
import { styled } from 'styled-components';

export const Card = styled.div`
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.athensGray};
  border-radius: 10px;
  margin-bottom: 10px;

  @media print {
    &.do-not-print {
      display: none;
    }
  }
`;

export const CardHeader = styled.div`
  position: relative;
  display: flex;
  height: 58px;
  padding: 0 16px;
  align-items: center;
  h3 {
    color: ${props => props.theme.colors.charcoal};
    font-weight: 900;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    width: 100%;
    &.secondary {
      margin: 0;
      color: ${props => props.theme.colors.suvaGray};
      letter-spacing: normal;
    }
  }
  div.actions {
    display: flex;
    gap: 16px;
    position: absolute;
    right: 16px;
    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    div.actions {
      position: absolute;
      top: 10px;
      right: 10px;
      gap: 10px;
    }
  }
`;

export const Divider = styled.hr`
  background-color: ${props => props.theme.colors.athensGray};
  height: 1px;
  border: 0;
  margin: 0;
  &.dark {
    background-color: ${props => props.theme.colors.shark};
  }
`;

export const NoInfoBaseContainer = styled.div`
  background-color: ${props => props.theme.colors.lightGrayishBlue};
  color: ${props => props.theme.colors.stoneGray};
  padding: 15px 0;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  background-position: 50%;
  background-repeat: no-repeat;
  min-height: 90px;
  h1 {
    font-weight: 700;
    padding-top: 20px;
    font-size: 20px;
    margin: 0;
  }
  p {
    padding: 0;
    margin: 0;
  }
`;

export const DateFilterContainer = styled.div`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;

  &.secondary {
    text-align: left;
    margin-top: 35px;
  }

  input {
    color: ${props => props.theme.colors.charcoal};
    border: 1px solid ${props => props.theme.colors.mystic};
    border-radius: 4px;
    font-weight: 500;
    height: 20px;
    padding: 10px;
    margin: 0 10px;

    &.secondary {
      text-align: left;
      width: 120px;
    }
  }

  button {
    background-color: ${props => props.theme.colors.pacificBlue};
    border: 1px solid ${props => props.theme.colors.pacificBlue};
    border-radius: 21px;
    color: ${props => props.theme.colors.white};
    display: inline;
    font-weight: 900;
    height: 42px;
    margin: auto;
    padding: 10px 15px;
    text-align: center;
    text-transform: uppercase;
  }
`;

export const DateFilterForm = (props: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <DateFilterContainer className={props?.className}>
      {props.children}
    </DateFilterContainer>
  );
};
