import moment from 'moment';
import { RRule } from 'rrule';

/**
 * Utility for generating a parsing RRule using the RRule.js library
 * https://github.com/jkbrzt/rrule
 */
export class RRuleUtility {
  /**
   * Generates an rrule with daily recurrence
   */
  public static rruleDaily(endDate?: string): string {
    const rrule = new RRule({
      freq: RRule.DAILY,
    });
    if (endDate) {
      rrule.options.until = new Date(endDate);
      rrule.origOptions.until = new Date(endDate);
    }

    return rrule.toString();
  }

  /**
   * Generates and rrule with weekly recurrence, based on startdate
   */
  public static rruleWeekly(endDate?: string): string {
    const rrule = new RRule({
      freq: RRule.WEEKLY,
    });
    if (endDate) {
      rrule.options.until = new Date(endDate);
      rrule.origOptions.until = new Date(endDate);
    }

    return rrule.toString();
  }

  /**
   * Generates and rrule with bi-weekly recurrence, based on startdate
   */
  public static rruleTwoWeekly(endDate?: string): string {
    const rrule = new RRule({
      freq: RRule.WEEKLY,
      interval: 2,
    });
    if (endDate) {
      rrule.options.until = new Date(endDate);
      rrule.origOptions.until = new Date(endDate);
    }

    return rrule.toString();
  }

  /**
   * Generates and rrule with monthly recurrence, based on startdate
   */
  public static rruleMonthly(endDate?: string): string {
    const rrule = new RRule({
      freq: RRule.MONTHLY,
    });
    if (endDate) {
      rrule.options.until = new Date(endDate);
      rrule.origOptions.until = new Date(endDate);
    }

    return rrule.toString();
  }

  /**
   * Generates and rrule with yearly recurrence, based on startdate
   */
  public static rruleYearly(endDate?: string): string {
    const rrule = new RRule({
      freq: RRule.YEARLY,
    });
    if (endDate) {
      rrule.options.until = new Date(endDate);
      rrule.origOptions.until = new Date(endDate);
    }

    return rrule.toString();
  }

  /**
   * Generates and rrule with M,T,W,R,F recurrence
   */
  public static rruleWeekdays(endDate?: string): string {
    const rrule = new RRule({
      freq: RRule.DAILY,
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
    });
    if (endDate) {
      rrule.options.until = new Date(endDate);
      rrule.origOptions.until = new Date(endDate);
    }

    return rrule.toString();
  }

  /**
   * Generates and rrule with custom recurrence
   */
  public static rruleCustom(
    occurrences: number,
    frequency: string,
    weekdays: Array<any>,
    endDate?: string
  ): string {
    const rrule = new RRule({
      freq: RRule.DAILY,
      interval: occurrences,
    });

    if (frequency === 'daily') {
      rrule.options.freq = RRule.DAILY;
      rrule.origOptions.freq = RRule.DAILY;
    } else {
      rrule.options.freq = RRule.WEEKLY;
      rrule.origOptions.freq = RRule.WEEKLY;
      let byWeekDay: Array<number>;
      //eslint-disable-next-line
      byWeekDay = [];
      const origByWeekDay = [];
      // eslint-disable-next-line prefer-const
      for (let day in weekdays) {
        if (day) {
          switch (weekdays[day]) {
            case 'mo':
              byWeekDay.push(0);
              origByWeekDay.push(RRule.MO);
              break;
            case 'tu':
              byWeekDay.push(1);
              origByWeekDay.push(RRule.TU);
              break;
            case 'we':
              byWeekDay.push(2);
              origByWeekDay.push(RRule.WE);
              break;
            case 'th':
              byWeekDay.push(3);
              origByWeekDay.push(RRule.TH);
              break;
            case 'fr':
              byWeekDay.push(4);
              origByWeekDay.push(RRule.FR);
              break;
            case 'sa':
              byWeekDay.push(5);
              origByWeekDay.push(RRule.SA);
              break;
            case 'su':
              byWeekDay.push(6);
              origByWeekDay.push(RRule.SU);
              break;
          }
        }
      }

      rrule.options.byweekday = byWeekDay;
      rrule.origOptions.byweekday = origByWeekDay;
    }
    if (endDate) {
      rrule.options.until = new Date(endDate);
      rrule.origOptions.until = new Date(endDate);
    }
    return rrule.toString();
  }

  /**
   * Parse given string to get the RRule and returns it in readable text
   */
  public static getText(rule: string): string {
    const str = RRule.fromString(rule.substr(rule.indexOf(':') + 1)).toText();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * Parse given RRULE to determine which type of recurrence it is
   */
  public static getRuleType(rule: string): number {
    if (!rule) {
      return 0;
    } else if (
      rule.includes('INTERVAL') &&
      (rule.includes('DAILY') ||
        (rule.includes('WEEKLY') && rule.includes('BYDAY')))
    ) {
      return 7;
    } else if (rule.includes('DAILY') && rule.includes('BYDAY')) {
      return 6;
    } else if (rule.includes('DAILY')) {
      return 1;
    } else if (rule.includes('WEEKLY') && rule.includes('INTERVAL')) {
      return 3;
    } else if (rule.includes('WEEKLY')) {
      return 2;
    } else if (rule.includes('MONTHLY')) {
      return 4;
    } else if (rule.includes('YEARLY')) {
      return 5;
    } else {
      return 0;
    }
  }

  /**
   * Find the next upcoming appointment date based on rrule, start date and end date.
   */
  public static nextAppointmentDate(
    startDate: string,
    rrule: string,
    endDate: string
  ) {
    if (rrule) {
      // Build RRule options
      const options = RRule.parseString(rrule.replace('RRULE:', ''));
      options.dtstart = moment(startDate).toDate();
      if (endDate) {
        options.until = moment(endDate).toDate();
      }
      // Build RRule object
      const rule = new RRule(options);
      // Return the first occurrence of the appointment that's in the future
      return rule.after(moment().toDate());
    }
  }
}

export enum Frequency {
  NEVER,
  DAILY,
  WEEKLY,
  TWOWEEKLY,
  MONTHLY,
  YEARLY,
  WEEKDAYS,
  CUSTOM,
}
