import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../main/Layout';
import HCPUsers from './HCPUsers';
import HCPSites from './HCPSites';
import HCPOrganizations from './HCPOrganizations';
import RoundedButton from '../shared/RoundedButton';
import { NavLink, useLocation } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';
import addImage from '../../assets/images/add_admin.svg';
import {
  logGAEvent,
  sendPageView,
} from '../../shared/services/googleAnalyticsService';
import SiteModal from './SiteModal';
import UserModal from './UserModal';
import OrganizationModal from './OrganizationModal';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const Menu = styled.div``;

const MenuLink = styled(NavLink)`
  text-transform: uppercase;
  padding: 14px 16px;
  font-size: 20px;
  font-weight: 900;
  color: ${props => props.theme.colors.loblolly};
  background: none;
  border: none;
  &.active {
    color: ${props => props.theme.colors.charcoal};
    border-bottom: solid 2px ${props => props.theme.colors.pacificBlue};
  }
`;

enum MenuSelection {
  users = 'users',
  sites = 'sites',
  organizations = 'organizations',
}

export const HCPList = () => {
  const { user, getString } = useUserContext();
  const location = useLocation();
  const [menuSelection, setMenuSelection] = useState<MenuSelection>();
  const [open, setOpen] = useState(false);
  const [updateList, setUpdateList] = useState(false);

  useEffect(() => {
    sendPageView('Admin');

    const lastSegment = location.pathname
      .replace(/\/+$/, '')
      .toLowerCase()
      .split('/')
      .pop();
    if (lastSegment === 'sites') {
      setMenuSelection(MenuSelection.sites);
    } else if (lastSegment === 'organizations') {
      setMenuSelection(MenuSelection.organizations);
    } else {
      setMenuSelection(MenuSelection.users);
    }
  }, [location]);

  const Content = () => {
    switch (menuSelection) {
      case MenuSelection.users:
        return <HCPUsers />;
      case MenuSelection.sites:
        return <HCPSites refetchData={setUpdateList} updateList={updateList} />;
      case MenuSelection.organizations:
        return <HCPOrganizations />;
      default:
        return null;
    }
  };

  const googleAnalytics = () => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'admin',
      eventAct: 'click',
      eventLbl: menuSelection,
    });
  };

  const handleClickOpen = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  return (
    <Layout>
      <Header>
        <Menu>
          <MenuLink to={{ pathname: '/hcp/users' }} onClick={googleAnalytics}>
            {getString('hcpListComponent.title.users')}
          </MenuLink>
          <MenuLink to={{ pathname: '/hcp/sites' }} onClick={googleAnalytics}>
            {getString('hcpListComponent.title.sites')}
          </MenuLink>
          {user?.superAdmin && (
            <MenuLink
              to={{ pathname: '/hcp/organizations' }}
              onClick={googleAnalytics}>
              {getString('hcpListComponent.title.organizations')}
            </MenuLink>
          )}
        </Menu>
        <RoundedButton
          image={addImage}
          title={(() => {
            switch (menuSelection) {
              case MenuSelection.users:
                return getString('hcpListComponent.button.newUser');
              case MenuSelection.sites:
                return getString('hcpListComponent.button.newSite');
              case MenuSelection.organizations:
                return getString('hcpListComponent.button.newOrganization');
            }
          })()}
          onClick={() => handleClickOpen(true)}
        />
      </Header>
      <Content />
      {menuSelection === MenuSelection.users ? (
        <UserModal isOpen={open} onClick={handleClickOpen} />
      ) : null}
      {menuSelection === MenuSelection.sites ? (
        <SiteModal isOpen={open} onClick={handleClickOpen} />
      ) : null}
      {menuSelection === MenuSelection.organizations ? (
        <OrganizationModal isOpen={open} onClick={handleClickOpen} />
      ) : null}
    </Layout>
  );
};

export default HCPList;
