import styled from 'styled-components';
import BaseModal from '../../../shared/modals/BaseModal';
import { GenericButton } from '../../../shared/modals/BaseModalStyle';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useUserContext } from '../../../../context/UserContext';
import { Endpoints } from '../../../../api/endpoints';
import { SuccessToast, ErrorToast } from '../../../shared/ToastrNotifications';
import axios from 'axios';
import { RiskScoreTracker, Tracker } from '../../../../api/models';
import theme from '../../../../assets/theme';
import formatISO from 'date-fns/formatISO';
import Loader from '../../../shared/Loader';

interface ModalProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  patientId: number;
  trackerData: Tracker | undefined;
  adherenceData: RiskScoreTracker | undefined;
  refreshTable?: () => void;
}

const ModalTitle = styled.h1`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 900;
  letter-spacing: 2.73px;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: -20px;
  margin-bottom: 32px;
`;

const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const InputLabel = styled.label`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0;
`;

const Subcontainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
`;

const SubmitButton = styled(GenericButton)`
  &.submit-button {
    margin-bottom: 16px;
  }
  &:disabled {
    cursor: unset;
    opacity: 0.5;
  }
`;

const WHOFCModal = (props: ModalProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };
  const [fcClass, setFcClass] = useState<string>();
  const [fcClassTrackerData, setFcClassTrackerData] = useState(
    props.trackerData
  );
  const [fcClassAdherenceData, setFcClassAdherenceData] = useState(
    props.adherenceData
  );
  const requestOngoing = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getString } = useUserContext();

  // handles the submission logic for the tracker
  const handleUpdateTracker = useCallback(() => {
    requestOngoing.current = false;

    const current = formatISO(new Date());
    const clientCreatedDateTime = current.replace('+', '-');

    const customTrackerData = fcClassTrackerData;
    if (customTrackerData == undefined) {
      setIsLoading(false);
      return;
    }

    // attach the input value to the fields data
    customTrackerData.fields[0].value = fcClass;

    const body = {
      clientCreatedDateTime: clientCreatedDateTime,
      asNeeded:
        customTrackerData['asNeeded'] != undefined
          ? customTrackerData['asNeeded']
          : true,
      dateTime: clientCreatedDateTime,
      didAdhere: true,
      fields: customTrackerData['fields'],
      recordType:
        customTrackerData['recordType'] != undefined
          ? customTrackerData['recordType']
          : 'WhoFunctionalClass',
      responseVersion:
        customTrackerData['responseVersion'] != undefined
          ? customTrackerData['responseVersion']
          : 1,
      scheduledActivityId: customTrackerData['scheduledActivityId'],
      scheduledActivityVersion: customTrackerData['scheduledActivityVersion'],
    };
    axios
      .post(Endpoints.updateRiskTracker(props.patientId), body)
      .then(() => {
        SuccessToast('', getString('toastrMessages.types.success'));
        props.refreshTable?.();
        props.setModalIsOpen(false);
      })
      .catch(error => ErrorToast(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, [fcClassTrackerData, fcClass, getString, props]);

  useEffect(() => {
    setFcClassTrackerData(props.trackerData);
  }, [props.trackerData]);

  useEffect(() => {
    setFcClassAdherenceData(props.adherenceData);
  }, [props.adherenceData]);

  useEffect(() => {
    // wait for the fcClassTrackerData to be updated from network request
    if (requestOngoing.current) {
      handleUpdateTracker();
    }
  }, [fcClassTrackerData, handleUpdateTracker]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }

    if (fcClass != null && fcClass != undefined) {
      setIsLoading(true);
      requestOngoing.current = true;
      // if the tracker is off, turn it on
      if (
        fcClassAdherenceData?.suspended ||
        fcClassAdherenceData?.turnedOff ||
        fcClassAdherenceData?.adherence == null
      ) {
        axios
          .post(Endpoints.turnOnTracker(props.patientId), {
            trackers: [
              {
                id: 'WhoFunctionalClass',
                title: 'WhoFunctionalClass',
                active: true,
              },
            ],
          })
          .then(response => {
            // then once it's activated, get the most updated tracker data
            if (response.data) {
              axios
                .get(Endpoints.customTracker(props.patientId))
                .then(response => {
                  const fcClassData = response.data.filter(
                    (tracker: Tracker) =>
                      tracker.recordType === 'WhoFunctionalClass'
                  )[0];
                  setFcClassTrackerData(fcClassData);
                })
                .catch(error => ErrorToast(error));
            }
          })
          .catch(error => ErrorToast(error));
      } else {
        // tracker was already active, simply call the update method
        handleUpdateTracker();
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFcClass(e.target.value);
  };

  return (
    <BaseModal
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      modalWidth={theme.modalWidths.lg}>
      <ModalTitle>{getString('riskScoreModalComponent.who.title')}</ModalTitle>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <InputColumn>
            <InputLabel>
              {getString('riskScoreModalComponent.who.subtitle')}
            </InputLabel>
            <Container>
              <Subcontainer>
                <InputRow>
                  <input
                    id='fc-1'
                    type='radio'
                    value={'1'}
                    checked={fcClass === '1'}
                    onChange={handleChange}
                  />
                  <InputLabel htmlFor='fc-1'>WHO FC I</InputLabel>
                </InputRow>
                <InputRow>
                  <input
                    id='fc-2'
                    type='radio'
                    value={'2'}
                    checked={fcClass === '2'}
                    onChange={handleChange}
                  />{' '}
                  <InputLabel htmlFor='fc-2'>WHO FC II</InputLabel>
                </InputRow>
              </Subcontainer>
              <Subcontainer>
                <InputRow>
                  <input
                    id='fc-3'
                    type='radio'
                    value={'3'}
                    checked={fcClass === '3'}
                    onChange={handleChange}
                  />{' '}
                  <InputLabel htmlFor='fc-3'>WHO FC III</InputLabel>
                </InputRow>
                <InputRow>
                  <input
                    id='fc-4'
                    type='radio'
                    value={'4'}
                    checked={fcClass === '4'}
                    onChange={handleChange}
                  />{' '}
                  <InputLabel htmlFor='fc-4'>WHO FC IV</InputLabel>
                </InputRow>
              </Subcontainer>
            </Container>
          </InputColumn>
          <SubmitButton
            className='submit-button'
            disabled={!(fcClass != null) || isLoading}
            onClick={handleSubmit}>
            {getString('form.button.save')}
          </SubmitButton>
        </>
      )}
    </BaseModal>
  );
};
export { WHOFCModal };
