export const BuildParams = {
  // Global Components
  alert_level_days_enabled: true,
  disclaimer_enabled: true,
  legal_docs_modal_enabled: true,

  //  Admin Components
  assign_multiple_sites_enabled: false,
  edit_hcp_profile_enabled: true,
  hcp_modal_enabled: true,
  password_reset_modal_enabled: true,
  site_modal_enabled: true,

  // Care Plan Components
  care_module_modal_enabled: true,
  care_plan_add_medication_enabled: true,
  confirm_save_modal_enabled: true,
  customize_care_module_enabled: true,

  // Appointment Components
  appointment_modal_enabled: true,
  confirm_delete_appointment_modal_enabled: true,
  delete_appointment_enabled: true,
  edit_appointment_enabled: true,

  // Medication Components
  confirm_delete_medication_modal_enabled: true,
  medication_custom_schedule_modal_enabled: true,
  medication_modal_enabled: true,
  patient_detail_add_medication_enabled: false,
  patient_detail_edit_medication_enabled: false,
  refill_alert_enabled: false,

  // Education Components
  add_education_enabled: false,
  bundle_modal_enabled: true,
  edit_education_enabled: false,
  education_detail_enabled: true,

  // Patient Reigistration Components
  deescalation_tier_enabled: false,
  print_instructions_enabled: false,
  regenerate_code_modal_enabled: false,
  register_patient_enabled: false,

  // Tracker Components
  add_tracker_enabled: false,
  graph_averages_enabled: true,

  // Patient Detail Components
  activity_detail_enabled: true,
  appointment_detail_enabled: true,
  edit_patient_modal_enabled: true,
  medication_detail_enabled: true,
  patient_detail_appointments_list_modal_enabled: true,
  profile_detail_enabled: true,
  patient_tags_enabled: true,

  // Patient List Components
  connection_status_filter_enabled: false,
  patient_connection_request_enabled: true,
  patients_pending_disconnect_enabled: true,
  patients_with_alerts_enabled: true,
  profile_picture_enabled: false,
  show_activity_enabled: false,
  show_connection_status_enabled: false,

  // Phone Number Validation
  phone_validator_regex: /^[2-9]\d{2}[2-9]\d{6}$/,
  phone_validator_mask: [
    '(',
    /[2-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /[2-9]/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};
