import styled from 'styled-components';
import BaseModal from '../../../shared/modals/BaseModal';
import { MaskedInput } from '../../../shared/MaskedInput';
import { GenericButton } from '../../../shared/modals/BaseModalStyle';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useUserContext } from '../../../../context/UserContext';
import { Endpoints } from '../../../../api/endpoints';
import { SuccessToast, ErrorToast } from '../../../shared/ToastrNotifications';
import axios from 'axios';
import theme from '../../../../assets/theme';
import { Tracker, RiskScoreTracker } from '../../../../api/models';
import { formatISO } from 'date-fns';
import Loader from '../../../shared/Loader';

interface ModalProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  patientId: number;
  trackerData: Tracker | undefined; // tracker data holds most of the body for the update call
  adherenceData: RiskScoreTracker | undefined;
  refreshTable?: () => void;
}

const ModalTitle = styled.h1`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 900;
  letter-spacing: 2.73px;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: -20px;
  margin-bottom: 10px;
`;

const ModalInstructions = styled.p`
  color: ${props => props.theme.colors.charcoal};
  font-size: 14px;
  margin: 0px;
  padding: 0 14px 20px;
  font-weight: 800;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  .input-field {
    width: 155px;
    margin: 0 15px;
    flex: 1;
  }
`;
const InputLabel = styled.label`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
  text-align: right;
  flex: 1;
`;
const InputMeasurment = styled.span`
  color: ${props => props.theme.colors.shark};
  font-size: 14px;
  font-weight: 700;
  flex: 1;
  text-align: left;
`;

const SubmitButton = styled(GenericButton)`
  &:disabled {
    cursor: unset;
    opacity: 0.5;
  }
`;

const BloodPressureModal = (props: ModalProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };
  const [systolicInputValue, setSystolicInputValue] = useState('');
  const [diastolicInputValue, setDiastolicInputValue] = useState('');
  const [bloodPressureTrackerData, setBloodPressureTrackerData] = useState(
    props.trackerData
  );
  const [bloodPressureAdherenceData, setBloodPressureAdherenceData] = useState(
    props.adherenceData
  );

  const requestOngoing = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getString } = useUserContext();
  const inputMask = /^\d{1,3}$|^$/;

  // handles the submission logic for the tracker
  const handleUpdateTracker = useCallback(() => {
    requestOngoing.current = false;

    const current = formatISO(new Date());
    const clientCreatedDateTime = current.replace('+', '-');

    const customTrackerData = bloodPressureTrackerData;
    if (customTrackerData == undefined) {
      setIsLoading(false);
      return;
    }

    // attach the input value to the fields data
    customTrackerData.fields[0].diastolic = +diastolicInputValue;
    customTrackerData.fields[0].systolic = +systolicInputValue;

    const body = {
      clientCreatedDateTime: clientCreatedDateTime,
      asNeeded:
        customTrackerData['asNeeded'] != undefined
          ? customTrackerData['asNeeded']
          : true,
      dateTime: clientCreatedDateTime,
      didAdhere: true,
      fields: customTrackerData['fields'],
      recordType:
        customTrackerData['recordType'] != undefined
          ? customTrackerData['recordType']
          : 'BloodPressure',
      responseVersion:
        customTrackerData['responseVersion'] != undefined
          ? customTrackerData['responseVersion']
          : 1,
      scheduledActivityId: customTrackerData['scheduledActivityId'],
      scheduledActivityVersion: customTrackerData['scheduledActivityVersion'],
    };
    axios
      .post(Endpoints.updateRiskTracker(props.patientId), body)
      .then(() => {
        SuccessToast('', getString('toastrMessages.types.success'));
        props.refreshTable?.();
        props.setModalIsOpen(false);
      })
      .catch(error => {
        ErrorToast(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    bloodPressureTrackerData,
    diastolicInputValue,
    systolicInputValue,
    getString,
    props,
  ]);

  useEffect(() => {
    setBloodPressureTrackerData(props.trackerData);
  }, [props.trackerData]);

  useEffect(() => {
    setBloodPressureAdherenceData(props.adherenceData);
  }, [props.adherenceData]);

  useEffect(() => {
    // wait for the bloodPressureTrackerData to be updated from network request
    if (requestOngoing.current) {
      handleUpdateTracker();
    }
  }, [bloodPressureTrackerData, handleUpdateTracker]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    if (systolicInputValue.length > 0 && diastolicInputValue.length > 0) {
      requestOngoing.current = true;
      // check if risk tracker is active
      if (
        bloodPressureAdherenceData?.suspended ||
        bloodPressureAdherenceData?.turnedOff ||
        bloodPressureAdherenceData?.adherence == null
      ) {
        // if the tracker is off, turn it on
        axios
          .post(Endpoints.turnOnTracker(props.patientId), {
            trackers: [
              {
                id: 'BloodPressure',
                title: 'BloodPressure',
                active: true,
              },
            ],
          })
          .then(response => {
            // then once it's activated, get the most updated tracker info
            if (response.data) {
              axios
                .get(Endpoints.customTracker(props.patientId))
                .then(response => {
                  const bloodPressureData = response.data.filter(
                    (tracker: Tracker) => tracker.recordType === 'BloodPressure'
                  )[0];

                  setBloodPressureTrackerData(bloodPressureData);
                })
                .catch(error => ErrorToast(error));
            }
          })
          .catch(error => ErrorToast(error));
      } else {
        // tracker was already active, simply call the update method
        handleUpdateTracker();
      }
    }
  };

  return (
    <BaseModal
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      modalWidth={theme.modalWidths.lg}>
      <ModalTitle>
        {getString('riskScoreModalComponent.bloodPressure.title')}
      </ModalTitle>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ModalInstructions>
            {getString('riskScoreModalComponent.bloodPressure.subtitle')}
          </ModalInstructions>
          <InputRow>
            <InputLabel htmlFor='systolic'>
              {getString('riskScoreModalComponent.bloodPressure.systolic')}
            </InputLabel>
            <MaskedInput
              placeholder={getString('form.label.enterValue')}
              className='input-field'
              id='systolic'
              value={systolicInputValue}
              setValue={setSystolicInputValue}
              mask={inputMask}></MaskedInput>
            <InputMeasurment>
              {getString('riskScoreModalComponent.bloodPressure.measurement')}
            </InputMeasurment>
          </InputRow>
          <InputRow>
            <InputLabel htmlFor='diastolic'>
              {getString('riskScoreModalComponent.bloodPressure.diastolic')}
            </InputLabel>
            <MaskedInput
              placeholder={getString('form.label.enterValue')}
              className='input-field'
              id='diastolic'
              value={diastolicInputValue}
              setValue={setDiastolicInputValue}
              mask={inputMask}></MaskedInput>
            <InputMeasurment>
              {getString('riskScoreModalComponent.bloodPressure.measurement')}
            </InputMeasurment>
          </InputRow>
          <SubmitButton
            disabled={
              !(
                systolicInputValue.length > 0 && diastolicInputValue.length > 0
              ) || isLoading
            }
            onClick={handleSubmit}>
            {getString('form.button.save')}
          </SubmitButton>
        </>
      )}
    </BaseModal>
  );
};
export { BloodPressureModal };
