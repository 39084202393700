import React from 'react';
import styled from 'styled-components';
import { Adherence } from '../../models';
import useAdherenceCalendar from './useAdherenceCalendar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

const Week = styled.div`
  display: flex;
`;

const Day = styled.div`
  width: 24px;
  height: 24px;
  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &.no-data {
    background-color: ${props => props.theme.colors.solitude};
    color: ${props => props.theme.colors.loblolly};
  }
  &.full-adherence {
    background-color: ${props => props.theme.colors.mossGreen};
    color: ${props => props.theme.colors.mossGreenDark};
  }
  &.some-adherence {
    background-color: ${props => props.theme.colors.texasRose};
    color: ${props => props.theme.colors.texasRoseDark};
  }
  &.no-adherence {
    background-color: ${props => props.theme.colors.amaranth};
    color: ${props => props.theme.colors.amaranthDark};
  }
`;

type DailyAdherenceProps = {
  calendar?: Record<string, Adherence>;
};

export const DailyAdherence = ({ calendar = {} }: DailyAdherenceProps) => {
  const { weeks, getAdherenceLevel, dayOfWeek } = useAdherenceCalendar({
    calendar,
  });

  return (
    <Container>
      {weeks.map((week, weekIndex) => (
        <Week key={weekIndex} data-testid='week'>
          {week.map((date, dayIndex) => (
            <Day
              key={date}
              className={getAdherenceLevel(date)}
              data-testid='day'>
              {dayOfWeek(dayIndex)}
            </Day>
          ))}
        </Week>
      ))}
    </Container>
  );
};

export default DailyAdherence;
