import { useParams } from 'react-router';
import add from '../../../assets/images/add.svg';
import trash from '../../../assets/images/delete.svg';
import pencil from '../../../assets/images/hcp-list/pencil_sans_circle.svg';
import { getString } from '../../../context/UserContext';
import { CardHeader } from '../detail/Card';
import { Content, FlexCard, SubTitle, Title } from './ModuleStyle';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Endpoints from '../../../api/endpoints';
import { ErrorToast } from '../../shared/ToastrNotifications';
import { Appointment } from '../../../models';
import format from 'date-fns/format';
import { logGAEvent } from '../../../shared/services/googleAnalyticsService';
import { DeleteAppointmentModal } from '../modals/DeleteAppointmentModal';
import { AddAppointmentModal } from '../modals/AddAppointmentModal';

enum ModalType {
  none,
  manageModal,
  deleteModal,
}

const AppointmentModule = () => {
  const { id } = useParams();
  const [patientId] = useState(() => parseInt(id || ''));
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [modalType, setModalType] = useState<ModalType>(ModalType.none);
  const [showModal, setShowModal] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [deletingId, setDeletingId] = useState('');
  const [currentAppointment, setCurrentAppointment] = useState<Appointment>();

  useEffect(() => {
    axios
      .get(Endpoints.appointment(patientId))
      .then(response => setAppointments(response.data))
      .catch(error => ErrorToast(error));
    setRefreshList(false);
  }, [refreshList, patientId]);

  const googleAnalytics = (type: string) => {
    switch (type) {
      case 'add_appointment':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'appointments',
          eventAct: 'click',
          eventLbl: 'add_appointment',
        });
        break;
      case 'delete_appointment':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'appointments',
          eventAct: 'click',
          eventLbl: 'appointment_delete',
        });
        break;
      case 'edit_appointment':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'appointments',
          eventAct: 'click',
          eventLbl: 'edit_appointment',
        });
        break;
    }
  };

  const activeModal = () => {
    switch (modalType) {
      case ModalType.manageModal:
        return (
          <AddAppointmentModal
            setModalIsOpen={setShowModal}
            modalIsOpen={showModal}
            patientId={patientId}
            refreshTable={() => setRefreshList(true)}
            prefillData={currentAppointment}
          />
        );
      case ModalType.deleteModal:
        return (
          <DeleteAppointmentModal
            patientId={patientId}
            setModalIsOpen={setShowModal}
            modalIsOpen={showModal}
            refreshTable={() => setRefreshList(true)}
            appointmentId={deletingId}
          />
        );
      default:
        return null;
    }
  };

  const handleDelete = (id: string) => {
    if (!id) {
      return;
    }
    googleAnalytics('delete_appointment');
    setModalType(ModalType.deleteModal);
    setDeletingId(id);
    setShowModal(true);
  };

  const manageAppointment = (appointment?: Appointment) => {
    googleAnalytics(appointment ? 'edit_appointment' : 'add_appointment');
    setModalType(ModalType.manageModal);
    setCurrentAppointment(appointment);
    setShowModal(true);
  };

  return (
    <>
      {activeModal()}
      <FlexCard>
        <CardHeader>
          <h3>
            {getString('moduleCustomizationComponent.title.appointments')}
          </h3>
          <div className='actions'>
            <button onClick={() => manageAppointment()}>
              <img
                alt='add appointment'
                id='add-appointment-customization-img'
                src={add}
              />
            </button>
          </div>
        </CardHeader>
        <div>
          {appointments?.map((appointment, index) => {
            return (
              <Content key={index}>
                <div>
                  <Title>{appointment.title}</Title>
                  <SubTitle>
                    {format(
                      new Date(appointment?.startDateTime),
                      'M/d | hh:mm a'
                    )}
                  </SubTitle>
                </div>
                <div className='actions'>
                  <button onClick={() => manageAppointment(appointment)}>
                    <img
                      alt='edit appointment'
                      id={`edit-appointment-${appointment.id}`}
                      src={pencil}
                    />
                  </button>
                  <button onClick={() => handleDelete(appointment.id)}>
                    <img
                      alt='delete appointment'
                      id={`delete-appointment-${appointment.id}`}
                      src={trash}
                    />
                  </button>
                </div>
              </Content>
            );
          })}
        </div>
      </FlexCard>
    </>
  );
};

export default AppointmentModule;
