import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const ToolTipText = styled.span`
  visibility: visible;
  font-size: 14px;
  position: absolute;
  background-color: ${props => props.theme.colors.charcoal};
  color: ${props => props.theme.colors.white};
  border-radius: 8px;
  text-transform: none;
  display: block;
  line-height: 1.5;
  z-index: 13;
  font-weight: 400;
  letter-spacing: 1px;
  left: calc(50% - (19.5vw / 2));
  opacity: 0.9;
  transition: opacity 0.3s;
  &.icon {
    text-align: center;
    width: 350px;
    top: 78%;
    padding: 10px;
    left: -100px;

    &::after {
      content: '';
      position: absolute;
      left: 40%;
      margin-left: -42px;
      bottom: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent ${props => props.theme.colors.black}
        transparent;
    }
  }

  &.table {
    padding: 4px;
    width: 19.5vw;
    left: calc(50% - (19.5vw / 2));
    bottom: 119%;
    border: solid;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -5px;
      z-index: -1;
      border-width: 5px;
      top: 100%;
      border-color: ${props => props.theme.colors.black} transparent transparent
        transparent;
      border-style: solid;
    }
  }

  &.modal {
    position: absolute;
    padding: 4px 8px;
    left: calc(33%);
    bottom: 100%;
    border: solid;
    z-index: 15;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -5px;
      z-index: -1;
      border-width: 5px;
      top: 100%;
      border-color: ${props => props.theme.colors.black} transparent transparent
        transparent;
      border-style: solid;
    }
  }
`;

export interface ToolTipProps {
  text?: string;
  toolType?: string;
  anchorEl: React.ReactNode;
}

const ToolTip = (props: ToolTipProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <Container>
        {isHovered && (
          <ToolTipText className={`${props.toolType}`}>
            {props.text}
          </ToolTipText>
        )}
        <span
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          {props.anchorEl}
        </span>
      </Container>
    </>
  );
};

export default ToolTip;
