import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import arrow from '../../assets/images/breadcrumb_arrow.svg';
import { getString, useUserContext } from '../../context/UserContext';
import Endpoints from '../../api/endpoints';
import { Patient } from '../../models';
import Layout from '../main/Layout';
import Activity from './detail/Activity';
import Appointments from './detail/Appointments';
import Education from './detail/Education';
import Medications from './detail/Medications';
import Notifications from './detail/Notifications';
import Profile from './detail/Profile';
import RiskScore from './detail/RiskScore';
import { ErrorToast } from '../shared/ToastrNotifications';
import Loader from '../shared/Loader';
import ExportProfileModal from './detail/ExportProfile';
import { useReactToPrint } from 'react-to-print';
import { CarePlanTracker } from '../../api/models';
import { logGAEvent } from '../../shared/services/googleAnalyticsService';
import { format } from 'date-fns';

const FlexBox = styled.div`
  display: flex;
  color: ${props => props.theme.colors.charcoal};
  gap: 10px;
  &.details {
    flex-direction: column;
    @media (min-width: ${props => props.theme.breakPoints.md}) {
      flex-direction: row;
    }
  }
  &.padding {
    padding: 25px 0 0 0;
  }
  &.header {
    @media print {
      justify-content: space-between;
    }
  }
`;
const PrintMessage = styled.p`
  display: none;
  @media print {
    &.only-print {
      display: inline;
      color: ${props => props.theme.colors.shark};
      font-size: 10px;
      font-weight: 500;
      text-align: right;
    }
  }
`;
const Container = styled.div`
  &.left {
    flex: 0 0 33.33%;
  }
  &.right {
    flex: 0 0 65.67%;
    overflow: hidden;
  }

  @media print {
    &.half-width-print {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      gap: 8px;
      :first-child {
        flex: 40%;
      }
      :first-child > * {
        flex: initial;
      }
      :nth-child(2) {
        flex: 60%;
      }
    }
    &.full-width-print {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      flex: 1;
    }
  }
`;

const Breadcrumb = styled.div`
  display: flex;
  flex: 0 0 65%;
  word-wrap: normal;
  text-transform: uppercase;

  h3 {
    margin: 0;
    margin-right: 5px;
    font-weight: 900;
  }

  img {
    height: 15px;
    padding: 0 5px;
    vertical-align: middle;
    margin-top: 5px;
  }

  span {
    color: ${props => props.theme.colors.curiousBlue};
    letter-spacing: 3px;
    font-size: 20px;
    font-weight: 900;
  }
`;

const ExportProfile = styled.div`
  flex: 0 0 33.33%;
  max-width: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  button {
    color: ${props => props.theme.colors.curiousBlue};
    font-weight: 900;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 3px;
  }

  p {
    margin-top: 5px;
    font-size: 12px;
  }

  @media print {
    display: none !important;
  }
`;

const Disclaimer = styled.div`
  color: ${props => props.theme.colors.charcoal};
  margin-bottom: 16px;
  margin-top: 0;
  text-align: center;
`;

const isDefaultTracker = (id: string) => {
  if (
    id === 'Medications' ||
    id === 'Appointments' ||
    id === 'Education' ||
    id === 'Risk Score'
  ) {
    return true;
  } else {
    return false;
  }
};

const PatientDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [patientId] = useState(() => parseInt(id || ''));
  const [reload, setReload] = useState(false);
  const [patient, setPatient] = useState<Patient>();
  const [patientLoading, setPatientLoading] = useState(true);
  const [showExportModal, setShowExportModal] = useState(false);
  const [selectedPrintTrackers, setSelectedPrintTrackers] =
    useState<CarePlanTracker[]>();
  const [isCadPadPatient, setIsCadPadPatient] = useState(false);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setSelectedPrintTrackers(undefined);
    },
  });
  const { user, setUser } = useUserContext();

  useEffect(() => {
    if (user?.superAdmin) {
      navigate('/hcp/users');
      return;
    }
    if (isNaN(patientId) || patientId < 1) {
      navigate('/patient/page/1');
      return;
    }
    setPatientLoading(true);
    axios
      .get(Endpoints.patientProfile(patientId))
      .then(response => {
        setPatient(response.data);
        if (['cad', 'pad', 'cadpad'].includes(response.data.accessCode.code)) {
          setIsCadPadPatient(true);
        }
      })
      .catch(error => {
        if (error.response?.status === 401) {
          // prevent displaying toast when
          // - request was cancelled
          // - response status is 401 unauthorized because user will be redirected to login
          setUser(undefined);
          navigate('/login');
        }
        ErrorToast(error);
      })
      .finally(() => setPatientLoading(false));
  }, [patientId, navigate, setUser, user]);

  useEffect(() => {
    // TODO: fix this getting called on each refresh once data set
    if (selectedPrintTrackers !== null && selectedPrintTrackers !== undefined) {
      handlePrint();
    }
  }, [selectedPrintTrackers, handlePrint]);

  const handlePrintPage = (activeTrackers: CarePlanTracker[]) => {
    setSelectedPrintTrackers(activeTrackers);
  };

  return (
    <Layout ref={componentRef}>
      <ExportProfileModal
        patient={patient}
        patientId={patientId}
        modalIsOpen={showExportModal}
        setModalIsOpen={(isOpen: boolean) => setShowExportModal(isOpen)}
        triggerPrint={handlePrintPage}
      />
      <FlexBox className='padding header'>
        <Breadcrumb>
          <h3>{getString('patientDetailComponent.title.patients')}</h3>
          <img alt='breadcrumb' src={arrow} />
          <span>{patient && `${patient.firstName} ${patient.lastName}`}</span>
        </Breadcrumb>
        <ExportProfile>
          <button
            onClick={() => {
              setShowExportModal(true);
              logGAEvent({
                event: 'eventTracker',
                eventCat: 'exportprofile',
                eventAct: 'click',
                eventLbl: 'export',
              });
            }}>
            {getString('patientDetailComponent.export.title')}
          </button>
          <p>{getString('patientDetailComponent.export.note')}</p>
        </ExportProfile>
        <PrintMessage className='only-print'>{`${getString(
          'exportProfileModalComponent.label.generatedOn'
        )} ${format(new Date(), 'MM/dd/yyyy')} ${getString(
          'exportProfileModalComponent.label.generatedBy'
        )}`}</PrintMessage>
      </FlexBox>
      <FlexBox className='details' ref={componentRef}>
        <Container
          className={
            selectedPrintTrackers &&
            selectedPrintTrackers.length === 1 &&
            selectedPrintTrackers?.some(e => e.id === 'Education')
              ? 'left half-width-print'
              : 'left full-width-print'
          }>
          <Profile />
          <Education
            patientId={patientId}
            showOnPrint={selectedPrintTrackers?.some(e => e.id === 'Education')}
          />
        </Container>
        <Container className='right'>
          <Medications
            patientId={patientId}
            showOnPrint={selectedPrintTrackers?.some(
              e => e.id === 'Medications'
            )}
          />
          <Appointments
            patientId={patientId}
            showOnPrint={selectedPrintTrackers?.some(
              e => e.id === 'Appointments'
            )}
          />
          {isCadPadPatient && <Notifications patientId={patientId} />}
          {patientLoading ? (
            <Loader />
          ) : patient?.accessCode?.code === 'pah' ? (
            <RiskScore
              patientId={patientId}
              patient={patient}
              parentReload={reload}
              triggerParentReload={() => setReload(!reload)}
              showOnPrint={selectedPrintTrackers?.some(
                e => e.id === 'Risk Score'
              )}
            />
          ) : null}
          <Activity
            patient={patient}
            parentReload={reload}
            printTrackers={selectedPrintTrackers?.filter(
              tracker => !isDefaultTracker(tracker.id)
            )}
          />
        </Container>
      </FlexBox>
      <Disclaimer>{getString('disclaimer.footer')}</Disclaimer>
    </Layout>
  );
};

export default PatientDetail;
