/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useLocalStorage from './useLocalStorage';
import { HCPUser } from '../models';
import strings from './en_US.json';

interface UserContextProps {
  user?: HCPUser;
  setUser: (user?: HCPUser) => void;
  getString: (path: string) => string;
}

export const UserContext = createContext<UserContextProps>({
  setUser: () => null,
  getString: () => '',
});

// Look up a string value by providing a json key path
// For example the path 'hcpListComponent.title.users' returns 'Users'
export const getString = (path: string): string => {
  let value = strings as Record<string, any>;
  for (const key of path.split('.')) {
    if (value && typeof value === 'object' && key in value) {
      value = value[key];
    } else {
      return path;
    }
  }
  return String(value);
};

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useLocalStorage<HCPUser | undefined>(
    'user',
    undefined
  );

  const location = useLocation();

  useEffect(() => {
    const titleMap = new Map([
      ['/login', 'Login'],
      ['/setpassword', 'Login'],
      ['/patient', 'Patient List'],
      ['/hcp', 'HCP'],
    ]);
    const segments = location.pathname.split('/').filter(Boolean);
    const title = titleMap.get(
      segments.length ? `/${segments[0].toLowerCase()}` : ''
    );
    document.title = ['Care 4 Today', title].filter(Boolean).join(' | ');
  }, [location]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        getString,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used inside the UserProvider');
  }
  return context;
};
