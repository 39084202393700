export class GlobalValidator {
  /**
   * Check for valid email format
   *
   * Valid examples: test@example.com, test93@example.io, test@exam-ple.
   * Invalid examples: testexample.com, test@example, test@example.c
   */
  public static email(value: string): ValidationResult {
    /* eslint-disable no-useless-escape */
    const EMAIL_REGEXP =
      /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9.-]+\.[a-z0-9]{2,}$/i;
    /* eslint-enable no-useless-escape */

    if (value !== '' && (value.length <= 5 || !EMAIL_REGEXP.test(value))) {
      return { invalidEmail: true };
    }

    return {};
  }

  /**
   * Check characters are all alphabetic
   *
   * Valid examples: test, TEST, Test, TeSt
   * Invalid examples: test89, te$t, T3$t
   */
  public static alpha(value: string): ValidationResult {
    const ALPHA_REGEXP = /^[a-zA-Z]+$/;

    if (value !== '' && !ALPHA_REGEXP.test(value)) {
      return { invalidAlphaString: true };
    }

    return {};
  }

  /**
   * Check for first/last name with the following qualifications:
   * 1. The characters can only be alphabetic, comma, single quote, period, hyphen,
   *    and space
   * 2. The leading character can be unlimited spaces or alphabetic
   *
   *
   * Valid examples: "testname", "    Test Name ", "   Jane-Doe"
   * Invalid examples: "92Cameron", "   -TestName---", "Jane9Doe"
   */
  public static nameCheck(value: string): ValidationResult {
    const NAME_REGEXP = /^\s*?[a-zA-Z]+(?:(?:['.,\-\s])[a-zA-Z]+)*\s*?$/;

    if (value !== '' && !NAME_REGEXP.test(value)) {
      return { invalidName: true };
    }

    return {};
  }

  /**
   * Check characters are only numeric
   *
   * Valid examples: 1234, 5, 9349499292993, 0
   * Invalid Examples: 2.1, 1/2, "1,245", a34, 0x123
   */
  public static numeric(value: string): ValidationResult {
    const NUMERIC_REGEXP = /^[0-9]+$/;

    if (value !== '' && !NUMERIC_REGEXP.test(value)) {
      return { invalidNumericString: true };
    }

    return {};
  }

  /**
   * Check if the characters are all digits and is length 10
   */
  public static phone(value: string): ValidationResult {
    const PHONE_REGEXP = /^[0-9]\d{2}[0-9]\d{6}$/;

    if (value !== '' && value !== null && !PHONE_REGEXP.test(value)) {
      return { invalidPhoneNumber: true };
    }

    return {};
  }

  /**
   * Check for passwords to meet the following qualifications:
   * 1. At least 8 characters long
   * 2. At least 1 character from 2 of the following character classes
   *    a. Lowercase letters
   *    b. Uppercase letters
   *    c. Numbers
   *    d. Special Characters
   */
  public static passwordCheck(value: string): ValidationResult {
    const PASSWORD_REGEXP =
      /(?!^[0-9]*$)(?!^[a-z]*$)(?!^[A-Z]*$)(?!^[\W]*$)(^[a-zA-Z0-9\W]).{7,}/;

    if (value !== '' && !PASSWORD_REGEXP.test(value)) {
      return { invalidPassword: true };
    }

    return {};
  }

  /**
   * Check for site name with the following qualifications:
   * 1. The name cannot be just whitespaces
   * 2. The leading character cannot be unlimited spaces
   *
   *
   * Valid examples: "test&name", "Test Name ", "Jane-Doe", "92Cameron"
   * Invalid examples: "   -TestName---", " Jane9Doe", "      "
   */
  public static siteNameCheck(value: string): ValidationResult {
    const NAME_REGEXP = /^(\S)+.*/;

    if (!value || value.trim() === '' || !NAME_REGEXP.test(value)) {
      return { invalidName: true };
    }

    return {};
  }

  /**
   * Check for site code with the following qualifications:
   * 1. 8 alphanumeric characters
   * 2. The leading character cannot be unlimited spaces
   *
   *
   * Valid examples: "abcd1234", "DRGUY123"
   * Invalid examples: "DRGUY", "DRGUY32!", "       "
   */
  public static siteCodeCheck(value: string): ValidationResult {
    const CODE_REGEXP = /^[a-zA-Z0-9]{1,8}$/;

    if (value !== null && value !== '' && !CODE_REGEXP.test(value)) {
      return { invalidSiteCode: true };
    }

    return {};
  }

  public static emrIdCheck(value: string): ValidationResult {
    const CODE_REGEXP = /^[a-zA-Z0-9]+$/;

    if (value !== null && value !== '' && !CODE_REGEXP.test(value)) {
      return { invalidEmrId: true };
    }

    return {};
  }

  /**
   * Check to make sure that a value is nonnegative
   *
   * Valid examples: 1, 99, 13
   * Invalid examples: -1, -100, -21, 0
   */
  public static nonnegativeNumber(value: string | number): ValidationResult {
    if (Number(value) < 1) {
      return { invalidNumber: true };
    }
    return {};
  }

  /**
   * Check to make sure that a value is present
   */
  public static required(value: string): ValidationResult {
    if (!value) {
      return { required: true };
    }
    return {};
  }
}

export interface ValidationResult {
  [key: string]: boolean;
}
