export interface Organization {
  id: number;
  name: string;
  alertLevelDays: number;
  disconnectionDays: number;
  deletionDays: number;
  carePlanTemplates: CarePlanTemplate[];
}

export interface CarePlanTemplate {
  id: number;
  version: number;
  title: string;
  description: string;
  importantNotice: string;
  dateCreated: string;
  lastModified: string;
}

export enum ConnectionStatus {
  pendingDisconnection = 'Pending Disconnection',
  pendingDeletion = 'Pending Deletion',
  pendingApproval = 'Pending Approval',
  pending = 'Pending',
  connected = 'Connected',
  expired = 'Expired',
}

export interface Site {
  id: number;
  identifier: string;
  name: string;
  address: string;
  emrId: string;
  phoneNumber: string;
  organizationId: number;
  alertLevelDays: number;
  disconnectionDays: number;
  connectionStatus?: {
    id: number;
    status: ConnectionStatus;
  };
  dateRequested?: string;
  dateProcessed?: string | null;
}

export interface Tracker {
  id: number;
  enabled: boolean;
  recordType: string;
  trackerKey: string;
  description?: string;
  title: string;
  previouslyActive?: boolean;
  default?: boolean;
  required?: boolean;
}

export interface Role {
  id: number;
  name: string;
  machineName: string;
}

export interface User {
  id: number;
  uuid?: string;
  email: string;
  firstName: string;
  lastName: string;
  status: string;
  sites: Site[];
  superAdmin: boolean;
}

export interface HCPUser extends User {
  refreshToken: string;
  passwordExpirationDays: number;
  locale: string;
  status: string;
  enabled: boolean;
  roleId?: string;
  siteId?: number;
  ssoUser: boolean;
  pah_acknowledgement: boolean;
  organization: Organization;
  roles: Role[];
}

export interface Adherence {
  adhered: number;
  percentage: number;
  total: number;
}

export interface ScheduledAdherence {
  asNeededAdhered: number;
  scheduledAdhered: number;
  scheduledTotal: number;
  scheduledPercentage: number;
}

export interface AccessCode {
  id: number;
  code: string;
  enable: boolean;
}

export interface Patient extends User {
  dateOfBirth: string;
  lastActive: string;
  revealLite2RiskScore: string;
  organizations: {
    id: number;
    userId: number;
    organizationId: number;
  }[];
  daysUntilDisconnection?: number;
  appointmentAdherence: Adherence;
  medicationAdherence: {
    id: number;
    isNonAdherent: boolean;
    percentage: number;
    siteId: number;
  };
  dailyAdherence?: Record<string, Adherence>;
  isNonAdherent: boolean;
  patientId: string;
  mobilePhoneNumber: string;
  carePlanTemplate: string;
  registrationDate: string;
  lastModifiedDate: string;
  lastModifiedName?: string;
  gamificationPoints: number;
  accessCode?: AccessCode;
  deescalationTier?: string;
  mobilePhoneType?: MobilePhoneType;
  connectStatus?: string;
}

export interface Medication {
  id: string;
  version: number;
  name: string;
  nickName: string | null;
  genericName?: string;
  reminderMessage: string;
  remindUser: boolean;
  drugID: number;
  route: string;
  strength: string;
  rrule: string;
  form: string;
  startTime?: string;
  endTime?: string;
  specialInstructions: string;
  suspended: boolean;
  asNeeded: boolean;
  lastModifiedDateTime: string;
  dose: Dose[];
  versionEffectiveEndDateTime?: string;
}

export interface Dose {
  quantity: number;
  rrule: string;
  startTime: string;
  unit: string;
}

export interface Article {
  id: string;
  title: string;
  version: string;
  dateModified: string;
  audioURL: string | null;
  order: number;
  url: string;
  lastRead?: string | null;
}

export interface EducationBundle {
  id: string;
  title: string;
  description: string;
  totalArticlesRead: number;
  totalArticles: number;
  content: Article[];
}

export interface Education {
  title: string;
  description?: string;
  id: string;
  default: boolean;
  required: boolean;
  previouslyActive: boolean;
  articles: Article[];
}

export interface Appointment {
  id: string;
  version: number;
  title: string;
  location?: string;
  notes?: string;
  startDateTime: string;
  endDateTime?: string;
  occurrence?: string;
  occurrenceDateTime?: string;
  rrule?: string;
  endRecurrence?: string;
  remindersActive?: boolean;
  reminders?: Array<number>;
  date?: Date;
}

export interface AppointmentAdherence {
  adherenceDateTime?: Date;
  appointmentId: string;
  dateTime: Date;
  didAdhere: boolean;
  version: number;
}

export interface AppointmentAdherencePair {
  adherence: AppointmentAdherence[];
  appointment: Appointment;
}

export interface AppointmentDetails {
  attended: number;
  skipped: number;
  next30Days: number;
  mostRecent?: AppointmentAdherencePair;
  upcoming?: Appointment;
}
export interface PatientTracker {
  type: string;
  description: string;
  graphs?: (StandardGraph | Heatmap)[];
  records?: Encouragement[];
}

export interface Encouragement {
  date: string;
  selection: EncouragementSelection[] | null;
}

export interface EncouragementSelection {
  name: string;
  label: string;
}

export interface Graph {
  title: string;
  dataType: 'Integer' | 'Decimal' | 'Time';
  graphType: 'Line' | 'Scatter' | 'Bar' | 'Heatmap';
  mobileDisplay: boolean;
  yAmount?: number;
  values: Record<string, GraphDataPoint[]>;
}

export interface GraphDataPoint {
  date: string;
  value: string | null;
}

// Line, Bar, Scatter
export interface StandardGraph extends Graph {
  xLabel: string;
  yLabel: string;
  yMin: string | null;
  yMax: string | null;
  averages: {
    label: string;
    value: string | number | null;
    units: string | null;
    unitsLabel?: string;
  }[];
}

export interface Heatmap extends Graph {
  valuesHeatmap: {
    parts: string[];
    dates: string[];
    dataArray: number[][];
    indexPosition: number;
  };
  valueAttributes: {
    id: string;
    label: string;
  }[];
}

export const isHeatmap = (data: StandardGraph | Heatmap): data is Heatmap =>
  'graphType' in data && data['graphType'] === 'Heatmap';

export interface DrugDetail {
  brand_name: string;
  form_category_name: string;
  form_id: number;
  form_name: string;
  generic_name: string;
  id: number;
  image: { url: string };
  route: string;
  short_name: string;
  status: string;
  strength: string;
}

export interface MobilePhoneType {
  id: number;
  name: string;
}
