import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Endpoints } from '../../../api/endpoints';
import alert from '../../../assets/images/alert-1.svg';
import pencil from '../../../assets/images/pencil.svg';
import { useUserContext } from '../../../context/UserContext';
import { Patient } from '../../../models';
import { logGAEvent } from '../../../shared/services/googleAnalyticsService';
import { useMomentUtility } from '../../../util/MomentUtility';
import Loader from '../../shared/Loader';
import { ErrorToast } from '../../shared/ToastrNotifications';
import { Tooltip } from '@mui/material';
import { CareplanModal, StatusModal } from '../modals/ProfileModal';
import { Card, CardHeader, Divider } from './Card';
import { BuildParams } from '../../../context/BuildParams';
import TagModal from './modals/TagModal';
import UpdateProfileModal from './modals/UpdateProfileModal';

enum ModalType {
  none,
  careplanModal,
  statusModal,
  tagModal,
  updateModal,
}

const Content = styled.div`
  padding: 0 15px;
`;

const Row = styled.div`
  &.padding {
    padding: 15px 0;
  }

  &.overflow-cutoff {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .row-margin {
    margin: 15px 0 0 0;
  }

  &.name-detail {
    display: flex;
    justify-content: center;

    div {
      display: inline-block;
      margin: auto 0;
    }

    h1 {
      color: ${props => props.theme.colors.charcoal};
      display: inline-block;
      font-size: 16px;
      font-weight: 900;
      max-width: 150px;
      padding: 0;
      margin: 0;
      margin-right: 8px;
      text-transform: uppercase;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  @media print {
    &.do-not-print {
      display: none !important;
    }
  }
`;

const Col = styled.div`
  display: flex;
  gap: 30px;

  span.label {
    text-align: right;
    text-transform: uppercase;
    color: ${props => props.theme.colors.charcoal};
    font-weight: 700;
    width: 45%;
  }

  span.content {
    color: ${props => props.theme.colors.charcoal};
    text-align: left;
    width: 55%;
    word-break: break-word;
  }

  span.uppercase {
    text-transform: uppercase;
  }

  span.clickable {
    color: ${props => props.theme.colors.pacificBlue};
    font-weight: 600;
    cursor: pointer;
    margin-left: 5px;
  }

  span.grey {
    color: ${props => props.theme.colors.loblolly};
  }

  span.red {
    color: ${props => props.theme.colors.amaranth};
  }

  span.orange {
    color: ${props => props.theme.colors.amber};
  }
`;

const TagContainer = styled.div`
  padding: 15px 15px 30px;
`;

const Tag = styled.span`
  background: ${props => props.theme.colors.athensGray};
  border-radius: 5px;
  display: inline-block;
  margin: 0 4px 8px 0;
  padding: 4px 8px;
  word-break: break-word;
`;

const Icon = styled.span`
  background: url(${alert}) no-repeat;
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  bottom: -4px;
  margin: 0;
  cursor: pointer;
`;

const Profile = () => {
  const { getString } = useUserContext();
  const { parseDate, fromNow } = useMomentUtility();
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [isTagLoading, setIsTagLoading] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [updateTag, setUpdateTag] = useState(false);
  const [data, setData] = useState<Patient | null>(null);
  const [tags, setTags] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.none);
  const { id } = useParams();
  const [patientId] = useState(() => parseInt(id || ''));

  useEffect(() => {
    setIsProfileLoading(true);
    axios
      .get(Endpoints.patientProfile(patientId))
      .then(response => {
        setData(response?.data);
      })
      .catch(error => {
        ErrorToast(error);
      })
      .finally(() => {
        setIsProfileLoading(false);
      });

    setUpdateProfile(false);
  }, [updateProfile, patientId]);

  useEffect(() => {
    setIsTagLoading(true);
    axios
      .get(Endpoints.patientTags(patientId))
      .then(response => {
        setTags(
          response?.data?.tags?.sort((a: string, b: string) =>
            a.localeCompare(b, undefined, { sensitivity: 'case' })
          )
        );
      })
      .catch(error => {
        ErrorToast(error);
      })
      .finally(() => {
        setIsTagLoading(false);
      });

    setUpdateTag(false);
  }, [updateTag, getString, patientId]);

  const googleAnalytics = (type: string) => {
    switch (type) {
      case 'patient_warning':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'profile',
          eventAct: 'hover',
          eventLbl: type,
        });
        break;
      case 'edit_patient_info':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'profile',
          eventAct: 'click',
          eventLbl: type,
        });
        break;
      case 'edit_patient_tags':
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'tags',
          eventAct: 'click',
          eventLbl: type,
        });
        break;
      default:
        logGAEvent({
          event: 'eventTracker',
          eventCat: 'care_module',
          eventAct: 'click',
          eventLbl: type,
        });
        break;
    }
  };

  const openPatientEditModal = () => {
    googleAnalytics('edit_patient_info');
    setModalType(ModalType.updateModal);
    setShowModal(true);
  };

  const openCareModuleModal = () => {
    googleAnalytics(data?.carePlanTemplate ? 'edit' : 'assign');
    setModalType(ModalType.careplanModal);
    setShowModal(true);
  };

  const openTagEditModal = () => {
    googleAnalytics('edit_patient_tags');
    setModalType(ModalType.tagModal);
    setShowModal(true);
  };

  const disconnectConfirmation = () => {
    setModalType(ModalType.statusModal);
    setShowModal(true);
  };

  const activeModal = () => {
    if (data === null) return null;

    switch (modalType) {
      case ModalType.careplanModal:
        return (
          <CareplanModal
            patient={data}
            setModalIsOpen={setShowModal}
            modalIsOpen={showModal}
          />
        );
      case ModalType.statusModal:
        return (
          <StatusModal
            patient={data}
            setModalIsOpen={setShowModal}
            modalIsOpen={showModal}
            refetchData={() => setUpdateProfile(true)}
          />
        );

      case ModalType.tagModal:
        if (showModal) {
          return (
            <TagModal
              updateTags={setTags}
              patientTags={tags}
              setModalIsOpen={setShowModal}
              modalIsOpen={showModal}
              patientId={patientId}
            />
          );
        } else {
          break;
        }
      case ModalType.updateModal:
        return (
          <UpdateProfileModal
            prefillData={data}
            setModalIsOpen={setShowModal}
            modalIsOpen={showModal}
            refetchData={() => setUpdateProfile(true)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Card>
      {activeModal()}
      <CardHeader>
        <h3>{getString('profileCardComponent.title.profile')}</h3>
        {!isProfileLoading && BuildParams.edit_patient_modal_enabled && (
          <div className='actions'>
            <button onClick={openPatientEditModal}>
              <img
                alt='edit patient'
                id='edit-patient-profile-img'
                src={pencil}
              />
            </button>
          </div>
        )}
      </CardHeader>
      <Content>
        <Divider />
        {isProfileLoading ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Row className='name-detail padding'>
                <div>
                  <h1 className='overflow-cutoff'>
                    {data?.firstName.concat(' ', data.lastName)}
                  </h1>
                  {BuildParams.patients_with_alerts_enabled &&
                    data?.isNonAdherent && (
                      <span
                        onMouseOver={() => googleAnalytics('patient_warning')}
                        onFocus={() => googleAnalytics('patient_warning')}>
                        <Tooltip
                          title={`${getString(
                            'profileCardComponent.tooltip.missedMedication'
                          )} ${data?.sites[0]?.alertLevelDays} ${getString(
                            'profileCardComponent.tooltip.daysInARow'
                          )}`}
                          arrow
                          placement='top'>
                          <Icon />
                        </Tooltip>
                      </span>
                    )}
                  <p>{parseDate(data?.dateOfBirth, 'll')}</p>
                </div>
              </Row>
              <Row>
                <Col>
                  <span className='label'>
                    {getString('form.label.patientID')}
                  </span>
                  <span className='content'>{data?.patientId || '—'} </span>
                </Col>
              </Row>
              <Row className='row-margin'>
                <Col>
                  <span className='label'>{getString('form.label.phone')}</span>
                  <span className='content'>
                    {data?.mobilePhoneNumber || '—'}
                  </span>
                </Col>
              </Row>
              <Row className='row-margin'>
                <Col>
                  <span className='label'>{getString('form.label.email')}</span>
                  <span className='content'>{data?.email || '—'}</span>
                </Col>
              </Row>
              {BuildParams.deescalation_tier_enabled && (
                <Row className='row-margin'>
                  <Col>
                    <span className='label'>
                      {getString('form.label.deescalation')}
                    </span>
                    <span className='content'>
                      {data?.deescalationTier || '—'}
                    </span>
                  </Col>
                </Row>
              )}
              <Row className='row-margin'></Row>
              <Divider />
              <Row className='row-margin'>
                <Row className='row-margin'>
                  <Col>
                    <span className='label'>
                      {getString('profileCardComponent.label.careModule')}
                    </span>
                    <span className='content'>
                      {data?.carePlanTemplate ||
                        getString('profileCardComponent.value.unassigned')}

                      <span
                        className='clickable'
                        id='edit-profile-care-module-span'
                        onClick={openCareModuleModal}
                        aria-hidden='true'>
                        {data?.carePlanTemplate
                          ? getString('profileCardComponent.button.edit')
                          : getString('profileCardComponent.button.assign')}
                      </span>
                    </span>
                  </Col>
                </Row>
                <Row className='row-margin'>
                  <Col>
                    <span className='label'>
                      {getString('profileCardComponent.label.status')}
                    </span>
                    <span className='content uppercase'>
                      <span
                        className={
                          data?.sites[0]?.connectionStatus?.status === 'Expired'
                            ? 'orange'
                            : data?.sites[0]?.connectionStatus?.status ===
                                'Pending'
                              ? 'grey'
                              : data?.sites[0]?.connectionStatus?.status ===
                                    'Pending Disconnection' ||
                                  data?.sites[0]?.connectionStatus?.status ===
                                    'Pending Deletion'
                                ? 'red'
                                : ''
                        }>
                        {data?.sites[0]?.connectionStatus?.status}
                      </span>
                      {data?.sites[0]?.connectionStatus?.status ===
                        'Connected' && (
                        <span
                          className='clickable'
                          onClick={disconnectConfirmation}
                          aria-hidden='true'>
                          {getString(
                            'profileCardComponent.disconnection.disconnectButton'
                          )}
                        </span>
                      )}
                    </span>
                  </Col>
                </Row>
                <Row className='row-margin'>
                  <Col>
                    <span className='label'>
                      {getString('profileCardComponent.label.lastActive')}
                    </span>
                    <span
                      className={`content ${
                        data?.lastActive === null && 'grey'
                      }`}>
                      {fromNow(data?.lastActive) ||
                        getString('form.content.noActivity')}
                    </span>
                  </Col>
                </Row>
                <Row className='row-margin'>
                  <Col>
                    <span className='label'>
                      {getString('profileCardComponent.label.lastModified')}
                    </span>
                    <span
                      className={`content ${
                        data?.lastModifiedDate === null ? 'grey' : ''
                      }`}>
                      {fromNow(data?.lastModifiedDate) ||
                        getString('form.content.noActivity')}
                      {data?.lastModifiedName && (
                        <span>({data?.lastModifiedName})</span>
                      )}
                    </span>
                  </Col>
                </Row>
                {data?.accessCode?.code == 'cadpad' && (
                  <Row className='row-margin'>
                    <Col>
                      <span className='label'>
                        {getString('profileCardComponent.label.pointsEarned')}
                      </span>
                      <span className='content'>
                        {data?.gamificationPoints > 0
                          ? data?.gamificationPoints +
                            ' ' +
                            getString('profileCardComponent.label.points')
                          : getString('profileCardComponent.label.noActivity')}
                      </span>
                    </Col>
                  </Row>
                )}
                <Row className='row-margin'></Row>
              </Row>
            </Row>
          </>
        )}
        <Divider />
        <Row className='do-not-print'>
          <CardHeader>
            <h3>{getString('profileCardComponent.title.tags')}</h3>
            {!isTagLoading && (
              <div className='actions'>
                <button onClick={() => openTagEditModal()}>
                  <img
                    alt='edit patient tags'
                    className='clickable modal-trigger'
                    id='edit-patient-tag-img'
                    src={pencil}
                  />
                </button>
              </div>
            )}
          </CardHeader>
          <Divider />
          <Row className='do-not-print'>
            <Row>
              <TagContainer>
                {isTagLoading ? (
                  <Loader />
                ) : tags.length ? (
                  tags.map(tag => {
                    return <Tag key={tag}>{tag}</Tag>;
                  })
                ) : null}
              </TagContainer>
            </Row>
          </Row>
        </Row>
      </Content>
    </Card>
  );
};

export default Profile;
