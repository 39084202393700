import React from 'react';
import styled from 'styled-components';
import {
  toast,
  ToastContainer,
  ToastOptions,
  ToastContentProps,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AxiosError } from 'axios';
import { APIError } from '../../api/models';
import { getString } from '../../context/UserContext';
import parse from 'html-react-parser';

type ToastrProps = {
  message: string;
  title: string;
};

const toastSettings: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};

export const StyledContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    font-family: 'Brandon';
    text-align: left;
    h3 {
      margin: 0;
      font-size: 16px;
      font-weight: 700;
    }
    p {
      margin: 0;
      font-size: 14px;
    }
    z-index: 1000;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

const ToastFormat = ({ message, title }: ToastContentProps & ToastrProps) => (
  <div>
    <h3>{title}</h3>
    <p>{parse(message)}</p>
  </div>
);

export const ErrorToast = (error: string | Error | APIError) => {
  if (error instanceof AxiosError) {
    const axiosError: AxiosError = error;
    if (
      axiosError.code === 'ERR_CANCELED' ||
      axiosError.response?.status === 401
    ) {
      // prevent displaying toast when
      // - request was cancelled
      // - response status is 401 unauthorized because user will be redirected to login
      return;
    }
  }

  let message: string;
  if (typeof error === 'string') {
    message = error;
  } else if ('message' in error) {
    message = error.message;
  } else {
    message = 'An unknown error occurred.';
  }

  toast.error(
    (props: ToastContentProps) => (
      <ToastFormat
        title={getString('toastrMessages.types.error')}
        message={message}
        {...props}
      />
    ),
    toastSettings
  );
};

export const SuccessToast = (message: string, title: string) => {
  toast.success(
    (props: ToastContentProps) => (
      <ToastFormat message={message} title={title} {...props} />
    ),
    toastSettings
  );
};

export const WarningToast = (message: string, title: string) => {
  toast.warning(
    (props: ToastContentProps) => (
      <ToastFormat message={message} title={title} {...props} />
    ),
    toastSettings
  );
};

export default { ErrorToast, SuccessToast, WarningToast, StyledContainer };
