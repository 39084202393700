import moment, { MomentInput } from 'moment';
import { useState, useEffect } from 'react';

const DEFAULT_DATE_FORMAT: string[] = [moment.defaultFormat, 'MM/DD/YYYY'];

export const useMomentUtility = () => {
  const [locale, setLocale] = useState('en');

  useEffect(() => {
    moment.locale(locale);
  }, [locale]);

  const parseDate = (date: MomentInput, format: string | undefined): string => {
    return moment(date).format(format);
  };

  const minDate = (
    date: MomentInput,
    dateFormat = DEFAULT_DATE_FORMAT
  ): Date => {
    return moment(date, dateFormat).toDate();
  };

  const fromNow = (
    date: MomentInput,
    dateFormat: string | string[] = DEFAULT_DATE_FORMAT
  ): string => {
    if (!date) {
      return '';
    }
    if (moment().diff(moment(date, dateFormat)) < 0) {
      return moment().fromNow();
    }
    return moment(date, dateFormat).fromNow();
  };

  return {
    parseDate,
    minDate,
    fromNow,
    setLocale,
  };
};
