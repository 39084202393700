import BaseModal from '../../shared/modals/BaseModal';
import axios from 'axios';
import styled from 'styled-components';
import { useUserContext } from '../../../context/UserContext';
import { Medication, Patient } from '../../../models';
import { useParams } from 'react-router-dom';
import { Endpoints } from '../../../api/endpoints';
import { ErrorToast, SuccessToast } from '../../shared/ToastrNotifications';
import React, { useState } from 'react';
import parse from 'html-react-parser';
import { EmptyButton, GenericButton } from '../../shared/modals/BaseModalStyle';

const ModalButtonContainer = styled.div`
  display: flex;
  margin: auto;
  padding: 10px 20px;
  text-align: center;
  gap: 20px;
  &.column {
    flex-direction: column;
  }
`;

const ModalTitle = styled.h1`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 900;
  text-transform: uppercase;
  font-size: 20px;
  margin: -20px 0 10px;
  letter-spacing: 2.73px;
`;

const ModalInstructions = styled.div`
  color: ${props => props.theme.colors.charcoal};
  font-size: 16px;
  margin: 32px 0 16px;
  font-weight: 900;
  line-height: 1.5;
  padding: 0 10px;
`;

const WarningText = styled(ModalInstructions)`
  color: ${props => props.theme.colors.loblolly};
  font-weight: 400;
`;

const ModalContent = styled.div``;

const CustomGenericButton = styled(GenericButton)`
  &.medium {
    min-width: 160px;
  }
`;

const CustomEmptyButton = styled(EmptyButton)`
  &.medium {
    min-width: 160px;
  }
`;

interface MedicationeModalProps {
  title?: string;
  instructions: string[];
  patient?: Patient;
  medication?: Medication;
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  children?: React.ReactNode;
  onCloseButtonClicked?: () => void;
}

interface MedicationProps {
  medication?: Medication;
  Patient?: Patient;
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  refetchMedications?: () => void;
}

const MedicationModal = (props: MedicationeModalProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };

  return (
    <BaseModal
      onCloseButtonClicked={() => props.onCloseButtonClicked?.()}
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}>
      <ModalTitle>{props.title}</ModalTitle>
      <ModalInstructions>
        {props.instructions[0]}
        {props.instructions[1] && (
          <WarningText>{parse(props.instructions[1])}</WarningText>
        )}
        {props.instructions[2]}
      </ModalInstructions>
      <ModalContent>
        <div>{props.children}</div>
      </ModalContent>
    </BaseModal>
  );
};

const DeleteMedicationModal = (props: MedicationProps) => {
  const { getString } = useUserContext();
  const [isDisabled, setIsDisabled] = useState(false);
  const { id } = useParams();
  const [patientId] = useState(() => parseInt(id || ''));

  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };

  const confirmDelete = () => {
    setIsDisabled(true);
    axios
      .delete(
        Endpoints.deleteMedication(patientId, props?.medication?.id || '')
      )
      .then(() => {
        SuccessToast('', getString('toastrMessages.types.success'));
        handleClose();
        props?.refetchMedications?.();
      })
      .catch(error => {
        ErrorToast(error.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  const warningText =
    getString('confirmDeleteMedicationModalComponent.instructions.byDeleting') +
    `<sup>&reg;</sup> ` +
    getString(
      'confirmDeleteMedicationModalComponent.instructions.connectApplication'
    );
  return (
    <MedicationModal
      onCloseButtonClicked={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      instructions={[
        getString('confirmDeleteMedicationModalComponent.title.disclaimer'),
        warningText,
        getString(
          'confirmDeleteMedicationModalComponent.instructions.areYouSure'
        ),
      ]}
      title={getString(
        'confirmDeleteMedicationModalComponent.title.deleteMedication'
      )}>
      <ModalButtonContainer>
        <CustomEmptyButton
          id='cacel-delete-medication-button'
          onClick={handleClose}
          className='medium'>
          {getString('form.button.cancel')}
        </CustomEmptyButton>

        <CustomGenericButton
          id='confirm-delete-medication-button'
          onClick={confirmDelete}
          disabled={isDisabled}
          className='medium'>
          {getString('form.button.yesDelete')}
        </CustomGenericButton>
      </ModalButtonContainer>
    </MedicationModal>
  );
};

export { DeleteMedicationModal };
