import styled from 'styled-components';
import BaseModal from '../../../shared/modals/BaseModal';
import theme from '../../../../assets/theme';
import { getString } from '../../../../context/UserContext';
import LabeledInput from '../../../shared/LabeledInput';
import PhoneInput from '../../../shared/PhoneInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { ErrorToast, SuccessToast } from '../../../shared/ToastrNotifications';
import {
  logGAEvent,
  sendPageView,
} from '../../../../shared/services/googleAnalyticsService';
import { MobilePhoneType, Patient } from '../../../../models';
import React from 'react';
import axios from 'axios';
import Endpoints from '../../../../api/endpoints';

interface ProfileProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  onCloseButtonClicked?: () => void;
  prefillData: Patient;
  refetchData?: () => void;
}

interface ReturnBody {
  patientId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  mobilePhoneNumber?: string;
  mobilePhoneType?: string;
}

const ModalTitle = styled.h1`
  text-transform: uppercase;
  color: ${props => props.theme.colors.charcoal};

  font-weight: 900;
  font-size: 20px;
  letter-spacing: 2.73px;
  margin: 0;
  margin-top: -20px;
  padding-bottom: 16px;
`;

const InputForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  column-gap: 16px;

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    flex-direction: column;
  }
`;

const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-right: 0;
`;

const InputContainer = styled.div`
  width: 70%;
  margin-left: 0;
  display: flex;
  align-items: end;
  &.half-width {
    input {
      width: 45%;
    }
  }
  input {
    margin: 0;
    width: 100%;
  }

  &.name {
    display: inline-block;
    div {
      float: left;
      input[name='firstName'] {
        border-radius: 4px 0 0 4px;
      }
      input[name='lastName'] {
        border-radius: 0 4px 4px 0;
      }
      &.last-name {
        margin-left: 21px;
      }
    }
  }

  &.phone {
    display: inline-block;
    div {
      float: left;
    }
    input,
    select {
      width: 90%;
      float: left;
    }
    input {
      border-radius: 4px 0 0 4px;
    }
    select {
      border-radius: 0 4px 4px 0;
      margin-left: 2px;
      margin-top: 9px;
      width: 150%;
    }
  }

  input.datepicker {
    border: 1px solid ${props => props.theme.colors.mystic};
    border-radius: 4px;
    color: ${props => props.theme.colors.charcoal};
    font-weight: 600;
    font-size: 14px;
    height: 26px;
    padding: 10px;
  }
  .react-datepicker__day--outside-month {
    visibility: hidden !important;
  }

  input.invalid {
    border-color: ${props => props.theme.colors.mangoTango};
  }

  #error-text {
    margin-left: 20px;
  }

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    width: 100%;
    margin-left: auto;
    input {
      width: 85%;
    }
    #error-text {
      margin-left: 0;
    }
  }
`;

const InputLabel = styled.label`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  margin: auto;
  margin-right: 0%;

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    font-size: 16px;
  }
`;
const LabelSubheading = styled.p`
  color: ${props => props.theme.colors.loblolly};
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  margin: auto;
  margin-right: 0%;
`;

const RemindersComponent = styled.select`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px 0 0 4px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-size: 14px;
  height: 46px;
  margin-top: -8px;
  width: 100%;
  padding: 10px;
  background-color: ${props => props.theme.colors.aliceBlue};

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    margin-left: 15px;
    width: 50%;
  }
`;

const SubmitButton = styled.button`
  background-color: ${props => props.theme.colors.pacificBlue};
  border: 1px solid ${props => props.theme.colors.pacificBlue};
  border-radius: 21px;
  color: ${props => props.theme.colors.white};
  display: block;
  font-weight: 600;
  font-size: medium;
  height: 42px;
  margin: auto;
  min-width: 250px;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    background-color: ${props => props.theme.colors.cerulean};
  }

  &:disabled {
    opacity: 0.4;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  p {
    margin: 0;
  }
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    margin-left: 15px;
  }
`;

const ErrorText = styled.p`
  color: ${props => props.theme.colors.mangoTango};
  text-align: left;
  font-weight: 500;
`;

const UpdateProfileModal = (props: ProfileProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    gaEvent('close');
    props.setModalIsOpen(false);
  };

  const [firstNameIsValid, setFirstNameIsValid] = useState<boolean>();
  const [lastNameIsValid, setLastNameIsValid] = useState<boolean>();
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState<boolean>();
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState<Date>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneTypes, setPhoneTypes] = useState<MobilePhoneType[]>([]);
  const [type, setType] = useState<number | undefined>(undefined);

  sendPageView('editProfile', 'Edit Profile');

  const gaEvent = (type: string) => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'profile',
      eventAct: 'click',
      eventLbl: ['save', 'close'].includes(type) ? type : 'edit_' + type,
    });
  };

  useEffect(() => {
    axios.get(Endpoints.phoneType).then(data => {
      setPhoneTypes(data.data);
    });
  }, []);

  // Sets the default values if the modal is in edit mode
  useEffect(() => {
    setDateOfBirth(new Date(props.prefillData.dateOfBirth));
    setPhoneNumber(props.prefillData.mobilePhoneNumber);
    setType(props.prefillData.mobilePhoneType?.id || undefined);
  }, [props.prefillData]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      patientId: HTMLInputElement;
      firstName: HTMLInputElement;
      lastName: HTMLInputElement;
      dateOfBirth: HTMLInputElement;
      email: HTMLInputElement;
      phone: HTMLInputElement;
      phoneType: HTMLSelectElement;
    };
    const body: ReturnBody = {
      patientId: formElements.patientId.value.trim(),
      firstName: formElements.firstName.value.trim(),
      lastName: formElements.lastName.value.trim(),
      dateOfBirth: formElements.dateOfBirth.value,
      email: formElements.email.value.trim(),
      mobilePhoneNumber: formElements.phone.value.replace(/\D/g, '').trim(),
      mobilePhoneType: formElements.phoneType.value.trim(),
    };

    //validate the required fields
    const errorMessages = [];
    if (!body.firstName || !body.lastName) {
      errorMessages.push(
        getString('controlMessageComponent.message.invalidName')
      );
    }

    if (phoneNumberIsValid === false) {
      errorMessages.push(phoneNumberError);
    }

    if (errorMessages.length) {
      ErrorToast(errorMessages.join('<br/>'));
      return false;
    }

    setIsSubmitting(true);
    axios
      .put(Endpoints.patientProfile(props.prefillData.id), body)
      .then(() => {
        SuccessToast('', getString('toastrMessages.types.success'));
        props.refetchData?.();
        props.setModalIsOpen(false);
      })
      .catch(error => ErrorToast(error))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <BaseModal
      onCloseButtonClicked={() => props.onCloseButtonClicked?.()}
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      modalWidth={theme.modalWidths.lg}>
      <ModalTitle>
        {getString('editPatientModalComponent.title.editProfile')}
      </ModalTitle>
      <InputForm onSubmit={handleSubmit}>
        <InputRow>
          <InputLabelContainer>
            <InputLabel htmlFor='input-patientid'>
              {getString('form.label.patientID')}
            </InputLabel>
          </InputLabelContainer>
          <InputContainer className='half-width'>
            <LabeledInput
              valueOverride={props.prefillData?.patientId || ''}
              includeLabel={false}
              name='patientId'
              labelText='patientId'
              isRequired={false}
              onClick={() => gaEvent('patient_id')}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputLabelContainer>
            <InputLabel htmlFor='input-firstname'>
              {getString('form.label.name')}
            </InputLabel>
          </InputLabelContainer>
          <InputContainer className='name'>
            <div className='first-name'>
              <LabeledInput
                includeLabel={false}
                name='firstName'
                labelText='firstName'
                isRequired={true}
                valueOverride={props.prefillData?.firstName}
                matchPattern={true}
                onClick={() => gaEvent('first_name')}
                setInputIsValid={(isValid: boolean) =>
                  setFirstNameIsValid(isValid)
                }
                setErrorMessage={(errString: string) =>
                  setFirstNameError(errString)
                }
                showErrorMessage={false}
              />
              {!firstNameIsValid && <ErrorText>{firstNameError}</ErrorText>}
            </div>
            <div className='last-name'>
              <LabeledInput
                includeLabel={false}
                name='lastName'
                labelText='lastName'
                isRequired={true}
                valueOverride={props.prefillData?.lastName}
                matchPattern={true}
                onClick={() => gaEvent('lastName')}
                setInputIsValid={(isValid: boolean) =>
                  setLastNameIsValid(isValid)
                }
                showErrorMessage={false}
                setErrorMessage={(errString: string) =>
                  setLastNameError(errString)
                }
              />
              {!lastNameIsValid && <ErrorText>{lastNameError}</ErrorText>}
            </div>
          </InputContainer>
        </InputRow>

        <InputRow>
          <InputLabelContainer>
            <InputLabel htmlFor='date'>
              {getString('form.label.dateOfBirth')}
            </InputLabel>
          </InputLabelContainer>
          <InputContainer>
            <ErrorContainer>
              <DatePicker
                id='date'
                name='dateOfBirth'
                className='datepicker'
                value={
                  dateOfBirth ? format(dateOfBirth, 'E LLL d y') : 'MM/DD/YYYY'
                }
                selected={dateOfBirth}
                onChange={date => setDateOfBirth(date || undefined)}
                onInputClick={() => gaEvent('date_of_birth')}
              />
            </ErrorContainer>
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputLabelContainer>
            <InputLabel htmlFor='input-email'>
              {getString('form.label.email')}
            </InputLabel>
          </InputLabelContainer>
          <InputContainer>
            <LabeledInput
              includeLabel={false}
              name='email'
              labelText='email'
              isRequired={true}
              invalidOnEmpty={true}
              matchPattern={true}
              valueOverride={props.prefillData?.email}
              onClick={() => gaEvent('email')}
              isDisabled={
                props.prefillData?.connectStatus !== 'Expired' &&
                props.prefillData?.connectStatus !== 'Not Invited'
              }
            />
          </InputContainer>
        </InputRow>

        <InputRow>
          <InputLabelContainer>
            <InputLabel htmlFor='input-phone'>
              {getString('editPatientModalComponent.label.mobilePhone')}
            </InputLabel>
            <LabelSubheading>
              {getString('form.label.optional')}
            </LabelSubheading>
          </InputLabelContainer>
          <InputContainer className='phone'>
            <div>
              <PhoneInput
                includeLabel={false}
                name='phone'
                labelText='phone'
                isRequired={false}
                inputPlaceholderText={getString(
                  'editPatientModalComponent.placeholder.mobilePhoneNumber'
                )}
                invalidOnEmpty={false}
                valueOverride={phoneNumber}
                onClick={() => gaEvent('mobile_phone_number')}
                setInputIsValid={(isValid: boolean) =>
                  setPhoneNumberIsValid(isValid)
                }
                setErrorMessage={(errString: string) =>
                  setPhoneNumberError(errString)
                }
                showErrorMessage={false}
                setValue={(value: string) => setPhoneNumber(value)}
              />
              {!phoneNumberIsValid && <ErrorText>{phoneNumberError}</ErrorText>}
            </div>
            <div>
              <RemindersComponent
                id='phoneType'
                data-testid='phoneType'
                name='phoneType'
                value={type}
                disabled={!phoneNumber}
                onChange={e => setType(+e.target.value)}
                onClick={() => gaEvent('mobile_phone_type')}>
                <option value='' selected disabled>
                  {getString('editPatientModalComponent.dropdown.selectType')}
                </option>
                {phoneTypes.map(phoneType => {
                  return (
                    <>
                      <option value={phoneType.id}>{phoneType.name}</option>
                    </>
                  );
                })}
              </RemindersComponent>
            </div>
          </InputContainer>
        </InputRow>
        <InputRow>
          <SubmitButton
            data-testid='submit'
            type='submit'
            disabled={isSubmitting}
            onClick={() => gaEvent('save')}>
            {getString('form.button.save')}
          </SubmitButton>
        </InputRow>
      </InputForm>
    </BaseModal>
  );
};

export default UpdateProfileModal;
