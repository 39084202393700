import BaseModal from '../../shared/modals/BaseModal';
import axios from 'axios';
import styled from 'styled-components';
import { useUserContext } from '../../../context/UserContext';
import { Patient } from '../../../models';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Endpoints } from '../../../api/endpoints';
import { ErrorToast, SuccessToast } from '../../shared/ToastrNotifications';
import {
  ModalTitle,
  ModalButtonContainer,
  ModalInstructions,
  GenericButton,
  EmptyButton,
} from '../../shared/modals/BaseModalStyle';
interface ConnectionModalProps {
  title: string;
  instructions: string[];
  patient: Patient;
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  children: React.ReactNode;
  onCloseButtonClicked?: () => void;
}

interface AcceptConnectionModalProps {
  patient: Patient;
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  siteId: number;
  refreshTable?: () => void;
}

const PatientNameText = styled.span`
  font-weight: 700;
`;

const CustomEmptyButton = styled(EmptyButton)`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const CustomGenericButton = styled(GenericButton)`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const ConnectionModal = (props: ConnectionModalProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };

  return (
    <BaseModal
      onCloseButtonClicked={() => props.onCloseButtonClicked?.()}
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}>
      <ModalTitle>{props.title}</ModalTitle>
      <ModalInstructions>
        {props.instructions[0]}
        <PatientNameText>{` ${props.patient.firstName} ${props.patient.lastName}`}</PatientNameText>
        {props.instructions[1]}
      </ModalInstructions>
      <ModalButtonContainer>
        <div>{props.children}</div>
      </ModalButtonContainer>
    </BaseModal>
  );
};

const AcceptConnectionModal = (props: AcceptConnectionModalProps) => {
  const { getString } = useUserContext();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    props.setModalIsOpen(false);
  };

  const handleNewCareModuleClicked = () => {
    navigate({
      pathname: `/patient/${props.patient.id}/customizeModule`,
      search: createSearchParams({
        moduleTitle: props.patient.carePlanTemplate,
        editCareModule: 'true',
      }).toString(),
    });
    handleCloseModal();
  };

  const handleViewPatientDetail = () => {
    navigate(`/patient/${props.patient.id}`);
    handleCloseModal();
  };

  return (
    <ConnectionModal
      title={getString('connectionRequestModalComponent.title.patientAccepted')}
      instructions={[
        getString('connectionRequestModalComponent.instructions.accepted'),
        getString('connectionRequestModalComponent.instructions.nowInOrg'),
      ]}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      patient={props.patient}
      onCloseButtonClicked={() => props.refreshTable?.()}>
      <CustomEmptyButton onClick={handleNewCareModuleClicked}>
        {getString('connectionRequestModalComponent.button.assignCareModule')}
      </CustomEmptyButton>
      <CustomEmptyButton onClick={handleViewPatientDetail}>
        {getString('connectionRequestModalComponent.button.viewPatientDetail')}
      </CustomEmptyButton>
    </ConnectionModal>
  );
};

const DeclineConnectionModal = (props: AcceptConnectionModalProps) => {
  const { getString } = useUserContext();

  const handleCloseModal = () => props.setModalIsOpen(false);

  const handleCancelClicked = () => {
    handleCloseModal();
  };

  const handleDeclineClicked = () => {
    axios
      .post(
        Endpoints.confirmPatientConnection(props.patient.id, props.siteId),
        {
          confirm: false,
        }
      )
      .then(() => {
        SuccessToast(
          getString(
            'connectionRequestModalComponent.tsMessages.successfullyDeclinedPatient'
          ),
          getString('toastrMessages.types.success')
        );
      })
      .catch(error => {
        ErrorToast(error);
      })
      .finally(() => {
        props.refreshTable?.();
        handleCloseModal();
      });
  };

  return (
    <ConnectionModal
      title={getString('connectionRequestModalComponent.title.patientDeclined')}
      instructions={[
        getString('connectionRequestModalComponent.instructions.declined'),
        '?',
      ]}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      patient={props.patient}>
      <CustomGenericButton onClick={handleDeclineClicked}>
        {getString('connectionRequestModalComponent.button.yesDecline')}
      </CustomGenericButton>
      <EmptyButton onClick={handleCancelClicked}>
        {getString('form.button.cancel')}
      </EmptyButton>
    </ConnectionModal>
  );
};

export { AcceptConnectionModal, DeclineConnectionModal };
