import styled from 'styled-components';
import BaseModal from '../shared/modals/BaseModal';
import theme from '../../assets/theme';
import { useUserContext } from '../../context/UserContext';
import LabeledInput from '../shared/LabeledInput';
import { useState } from 'react';

import axios from 'axios';
import { Endpoints } from '../../api/endpoints';
import { ModalInstructions } from '../shared/modals/BaseModalStyle';
import { ErrorToast, SuccessToast } from '../shared/ToastrNotifications';

interface ChangePasswordProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  onCloseButtonClicked?: () => void;
  refreshTable?: () => void;
}

const ModalTitle = styled.h1`
  text-transform: uppercase;
  color: ${props => props.theme.colors.charcoal};

  font-weight: 900;
  font-size: 20px;
  letter-spacing: 2.73px;
  margin: 0;
  margin-top: -20px;
  padding-bottom: 16px;
`;

const InputForm = styled.form`
  display: block;
  padding: 16px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  margin-left: 30px;
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    flex-direction: column;
  }
`;

const SideBySideContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
`;

const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
`;

const InputErrorText = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colors.mangoTango};
  font-weight: 450;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    margin-left: 20px;
  }
`;

const InputContainer = styled.div`
  width: 40%;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  input.invalid {
    border-color: ${props => props.theme.colors.mangoTango};
  }
  #error-text {
    display: none;
  }
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    margin-left: 10px;
    input {
      width: 85%;
    }
    #error-text {
      visibility: none;

      margin-left: 0;
    }
  }
`;

const InputLabel = styled.label`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  margin-right: 0%;

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    font-size: 16px;
  }
`;

const InstructionText = styled(ModalInstructions)`
  margin-left: 5%;
  text-align: left;
`;

const SubmitButton = styled.button`
  background-color: ${props => props.theme.colors.pacificBlue};
  border: 1px solid ${props => props.theme.colors.pacificBlue};
  border-radius: 21px;
  color: ${props => props.theme.colors.white};
  display: block;
  font-weight: 600;
  font-size: medium;
  height: 42px;
  min-width: 158px;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    background-color: ${props => props.theme.colors.cerulean};
  }

  &:disabled {
    opacity: 0.4;
  }
`;
const SubmitContainer = styled.div`
  display: flex;
  margin: auto;
  gap: 10px;
  flex-direction: row;
`;

const ChangePasswordModal = (props: ChangePasswordProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState<boolean>();
  const [passwordError, setPasswordError] = useState<string>();
  const [newPasswordIsValid, setNewPasswordIsValid] = useState<boolean>();
  const [newPasswordError, setNewPasswordError] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();

  const [confirmPasswordIsValid, setConfirmPasswordIsValid] =
    useState<boolean>();
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>();
  const { getString } = useUserContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      password: HTMLInputElement;
      newPassword: HTMLInputElement;
      confirmNewPassword: HTMLInputElement;
    };

    if (
      passwordIsValid &&
      newPasswordIsValid &&
      formElements.newPassword.value == formElements.confirmNewPassword.value
    ) {
      setIsSubmitting(true);
      axios
        .post(Endpoints.resetPassword, {
          password: formElements.password.value,
          newPassword: formElements.newPassword.value,
        })
        .then(() => {
          SuccessToast(
            getString(
              'toastrMessages.passwordResetModalComponent.passwordChangedSuccessfully'
            ),
            getString('toastrMessages.types.success')
          );
          form.reset();
        })
        .catch(error => {
          ErrorToast(error);
        })
        .finally(() => {
          props.setModalIsOpen(false);
        });
    } else {
      ErrorToast(getString('toastrMessages.common.pleaseFillRequiredFields'));
    }
  };

  return (
    <BaseModal
      data-testid='changePasswordModal'
      onCloseButtonClicked={() => props.onCloseButtonClicked?.()}
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      modalWidth={theme.modalWidths.lg}>
      <ModalTitle>
        {getString('editProfileComponent.form.changePassword')}
      </ModalTitle>
      <InputForm onSubmit={handleSubmit}>
        <InputRow>
          <SideBySideContainer>
            <InputLabelContainer>
              <InputLabel htmlFor='password'>
                {getString('form.label.currentPassword')}
              </InputLabel>
            </InputLabelContainer>

            <InputContainer>
              <LabeledInput
                setInputIsValid={(isValid: boolean) =>
                  setPasswordIsValid(isValid)
                }
                setErrorMessage={(errString: string) =>
                  setPasswordError(errString)
                }
                includeLabel={false}
                name='password'
                labelText='password'
                inputType='password'
                isRequired={true}
                matchPattern={true}
              />
            </InputContainer>
          </SideBySideContainer>
          {!passwordIsValid && <InputErrorText>{passwordError}</InputErrorText>}
        </InputRow>

        <InputRow>
          <SideBySideContainer>
            <InputLabelContainer>
              <InputLabel htmlFor='NewPassword'>
                {getString('form.label.newPassword')}
              </InputLabel>
            </InputLabelContainer>

            <InputContainer>
              <LabeledInput
                setInputIsValid={(isValid: boolean) =>
                  setNewPasswordIsValid(isValid)
                }
                setErrorMessage={(errString: string) =>
                  setNewPasswordError(errString)
                }
                setValue={(value: string) => setNewPassword(value)}
                includeLabel={false}
                name='newPassword'
                labelText='newPassword'
                inputType='password'
                isRequired={true}
                matchPattern={true}
                regexPattern={
                  /(?!^[0-9]*$)(?!^[a-z]*$)(?!^[A-Z]*$)(?!^[\W]*$)(^[a-zA-Z0-9\W]).{7,}/
                }
              />
            </InputContainer>
          </SideBySideContainer>
          {!newPasswordIsValid && (
            <InputErrorText>{newPasswordError}</InputErrorText>
          )}
        </InputRow>
        <InstructionText>
          {getString(
            'passwordResetModalComponent.instruction.passwordComplexity'
          )}
        </InstructionText>
        <InputRow>
          <SideBySideContainer>
            <InputLabelContainer>
              <InputLabel htmlFor='confirmNewPassword'>
                {getString('form.label.confirmNewPassword')}
              </InputLabel>
            </InputLabelContainer>

            <InputContainer>
              <LabeledInput
                setInputIsValid={(isValid: boolean) =>
                  setConfirmPasswordIsValid(isValid)
                }
                setErrorMessage={(errString: string) =>
                  setConfirmPasswordError(errString)
                }
                setValue={(value: string) => setConfirmPassword(value)}
                includeLabel={false}
                name='confirmNewPassword'
                labelText='confirmNewPassword'
                inputType='password'
                isRequired={true}
                matchPattern={true}
                regexPattern={
                  /(?!^[0-9]*$)(?!^[a-z]*$)(?!^[A-Z]*$)(?!^[\W]*$)(^[a-zA-Z0-9\W]).{7,}/
                }
                notMatching={confirmPassword != newPassword}
              />
            </InputContainer>
          </SideBySideContainer>
          {!confirmPasswordIsValid && (
            <InputErrorText>{confirmPasswordError}</InputErrorText>
          )}
        </InputRow>

        <InputRow>
          <SubmitContainer>
            <SubmitButton
              data-testid='cancel'
              type='submit'
              disabled={isSubmitting}
              onClick={() => {
                props.setModalIsOpen(false);
              }}>
              {getString('form.button.cancel')}
            </SubmitButton>
            <SubmitButton
              data-testid='submit'
              type='submit'
              disabled={isSubmitting}>
              {getString('form.button.submit')}
            </SubmitButton>
          </SubmitContainer>
        </InputRow>
      </InputForm>
    </BaseModal>
  );
};

export { ChangePasswordModal };
