import { useState, useEffect } from 'react';
import { getString } from '../../context/UserContext';
import styled from 'styled-components';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  label {
    color: ${props => props.theme.colors.charcoal};
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
  input {
    color: ${props => props.theme.colors.charcoal};
    border: 1px solid ${props => props.theme.colors.mystic};
    border-radius: 4px;
    font-weight: 500;
    height: 20px;
    padding: 10px;
    margin: 0 6px;
  }
  & > button {
    background-color: ${props => props.theme.colors.pacificBlue};
    border-radius: 21px;
    color: ${props => props.theme.colors.white};
    display: inline;
    font-weight: 900;
    height: 42px;
    padding: 10px 15px;
    text-align: center;
    text-transform: uppercase;
  }
  .react-datepicker__day--outside-month {
    visibility: hidden !important;
  }
`;

const displayFormat = 'EEE MMM dd yyyy';

type DateRangePickerProps = {
  startDate?: Date;
  endDate?: Date;
  minStartDate?: Date;
  onChange?: (startDate?: Date, endDate?: Date) => void;
};

const DateRangePicker = (props: DateRangePickerProps) => {
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }, [props]);

  return (
    <Container>
      <label htmlFor='start-date'>{getString('form.label.from')}</label>
      <DatePicker
        id='start-date'
        value={startDate && format(startDate, displayFormat)}
        placeholderText='MM/DD/YYYY'
        selected={startDate}
        onChange={date => setStartDate(date ?? undefined)}
        minDate={props?.minStartDate}
      />
      <label htmlFor='end-date'>{getString('form.label.to')}</label>
      <DatePicker
        id='end-date'
        value={endDate && format(endDate, displayFormat)}
        placeholderText='MM/DD/YYYY'
        selected={endDate}
        onChange={date => setEndDate(date ?? undefined)}
        minDate={startDate}
      />
      <button onClick={() => props.onChange?.(startDate, endDate)}>
        {getString('form.button.apply')}
      </button>
    </Container>
  );
};

export default DateRangePicker;
