import React, { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { useUserContext } from '../../context/UserContext';
import styled from 'styled-components';

enum InputErrorType {
  None,
  NoValue,
  WrongFormat,
  NotMatch,
}

type LabeledInputProps = {
  labelText: string;
  name?: string;
  inputPlaceholderText?: string;
  inputType?: string;
  invalidOnEmpty?: boolean;
  isRequired?: boolean;
  matchPattern?: boolean;
  regexPattern?: RegExp;
  matchValue?: string;
  notMatching?: boolean;
  includeLabel?: boolean;
  showErrorMessage?: boolean;
  isDisabled?: boolean;
  setInputIsValid?: (newValue: boolean) => void;
  setErrorMessage?: (newValue: string) => void;
  setValue?: (newValue: string) => void;
  valueOverride?: string;
  onClick?: () => void;
};

const InputErrorText = styled.p`
  font-family: Brandon;
  font-size: 16px;
  color: ${props => props.theme.colors.mangoTango};
  font-weight: 450;
  padding: 0px 15px;
  margin: auto;
  margin-bottom: 16px;
`;

const LabeledInputContainer = styled.div`
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 9.6px 0 9.6px 0;
`;

const Label = styled.label`
  font-weight: 700;
  text-transform: uppercase;
  color: ${props => props.theme.colors.charcoal};
  text-align: left;
  width: 85%;
  margin: auto;
`;
const Input = styled.input<{
  onBlur: () => void;
  onClick?: () => void;
  $hasBeenFocused: boolean;
  $invalidOnEmpty: boolean;
  $strictEmailParsing: boolean;
  $emailIsValid: boolean;
}>`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-family: 'Brandon';
  font-size: 16px;
  height: 24px;
  padding: 10px;
  margin: auto;
  width: 80%;

  &:focus {
    outline: none !important;
    border: 1px solid ${props => props.theme.colors.curiousBlue};
  }

  ${props =>
    props.$hasBeenFocused &&
    props.$invalidOnEmpty &&
    `
        :invalid {
        border: 1px solid ${props.theme.colors.mangoTango};
        }
    `}

  ${props =>
    props.$hasBeenFocused &&
    props.$strictEmailParsing &&
    props.$emailIsValid &&
    `
        &&{
          border: 1px solid ${props.theme.colors.mangoTango};
        }
        &:focus {
          outline: none !important;
          border: 1px solid ${props.theme.colors.mangoTango};
        }
    `}
`;

export const LabeledInput = ({
  labelText,
  name,
  inputPlaceholderText,
  inputType,
  invalidOnEmpty = true,
  isRequired = true,
  matchPattern = false,
  regexPattern = undefined,

  notMatching = false, //Used to check if passwords are the same
  includeLabel = true,
  showErrorMessage = true,
  setInputIsValid,
  setErrorMessage,
  setValue,
  onClick,
  valueOverride,
  isDisabled = false,
}: LabeledInputProps) => {
  const [hasBeenFocused, setHasBeenFocused] = useState(false);
  const [inputValue, setInputValue] = useState(valueOverride ?? '');
  const [inputError, setInputError] = useState(InputErrorType.None);
  const { getString } = useUserContext();

  const getErrorString = useCallback(
    (errorType: InputErrorType) => {
      switch (errorType) {
        case InputErrorType.NoValue:
          return getString('controlMessageComponent.message.required');
        case InputErrorType.WrongFormat:
          if (inputType == 'password') {
            return getString('controlMessageComponent.message.invalidPassword');
          } else if (inputType == 'name') {
            return getString('controlMessageComponent.message.invalidName');
          } else if (inputType == 'phone') {
            return getString(
              'controlMessageComponent.message.invalidPhoneNumber'
            );
          } else {
            return getString('controlMessageComponent.message.invalidEmail');
          }
        case InputErrorType.NotMatch:
          return getString(
            'controlMessageComponent.message.incorrectConfirmPassword'
          );
        default:
          return '';
      }
    },
    [getString, inputType]
  );

  const handleOnBlur = () => {
    // The first time this is clicked off, enable the invalid selector
    if (!hasBeenFocused) {
      setHasBeenFocused(true);
    }
    //since autofill doesn't trigger onChange, we need to check here as well for when the element is unselected
    if (inputValue.length === 0 && isRequired) {
      setInputError(InputErrorType.NoValue);
    } else if (
      regexPattern &&
      !regexPattern?.test(inputValue) &&
      inputValue.length > 0
    ) {
      setInputError(InputErrorType.WrongFormat);
    } else if (notMatching) {
      setInputError(InputErrorType.NotMatch);
    } else {
      setInputError(InputErrorType.None);
    }
  };
  // load initial value

  useEffect(() => {
    if (valueOverride && valueOverride?.length === 0 && isRequired) {
      setInputError(InputErrorType.NoValue);
    } else if (
      regexPattern &&
      !regexPattern?.test(valueOverride ?? '') &&
      valueOverride?.length
    ) {
      setInputError(InputErrorType.WrongFormat);
    } else {
      setInputError(InputErrorType.None);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueOverride, isRequired]);

  useEffect(() => {
    setInputValue(valueOverride ?? '');
  }, [valueOverride]);

  useEffect(() => {
    setErrorMessage?.(getErrorString(inputError));
  }, [getErrorString, inputError, setErrorMessage]);

  useEffect(() => {
    setInputIsValid?.(inputError === InputErrorType.None);
  }, [inputError, setInputIsValid]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setValue?.(e.target.value);
    if (e.target.value.length === 0 && isRequired) {
      setInputError(InputErrorType.NoValue);
    } else if (
      regexPattern &&
      !regexPattern?.test(e.target.value) &&
      e.target.value.length > 0
    ) {
      setInputError(InputErrorType.WrongFormat);
    } else {
      setInputError(InputErrorType.None);
    }
    setInputIsValid?.(inputError === InputErrorType.None);
  };

  const inputName = 'input-' + labelText.toLowerCase().replace(' ', '-');
  return (
    <LabeledInputContainer>
      {includeLabel && <Label htmlFor={inputName}>{labelText}</Label>}
      <Input
        className={
          inputError == InputErrorType.None || !hasBeenFocused
            ? 'valid'
            : 'invalid'
        }
        name={name}
        onBlur={() => handleOnBlur()}
        onClick={onClick}
        onChange={handleChange}
        $hasBeenFocused={hasBeenFocused}
        $invalidOnEmpty={invalidOnEmpty}
        $strictEmailParsing={matchPattern}
        $emailIsValid={inputError !== InputErrorType.None}
        type={inputType}
        id={inputName}
        value={inputValue}
        placeholder={inputPlaceholderText}
        data-testid={labelText}
        disabled={isDisabled}></Input>
      {showErrorMessage &&
      inputError !== InputErrorType.None &&
      hasBeenFocused &&
      matchPattern ? (
        <InputErrorText id='error-text'>
          {getErrorString(inputError)}
        </InputErrorText>
      ) : null}
    </LabeledInputContainer>
  );
};

export default LabeledInput;
