import { styled } from 'styled-components';

export const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 20px;
`;

export const ModalTitle = styled.h1`
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  margin: 0px;
  margin-bottom: 10px;
`;

export const ModalInstructions = styled.div`
  color: ${props => props.theme.colors.echoBlue};
  font-size: 16px;
  margin: 16px 0;
`;

export const GenericButton = styled.button`
  background-color: ${props => props.theme.colors.pacificBlue};
  border: 1px solid ${props => props.theme.colors.pacificBlue};
  border-radius: 21px;
  color: ${props => props.theme.colors.white};
  display: block;
  font-weight: 600;
  font-size: medium;
  height: 42px;
  margin: auto;
  min-width: 300px;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    background-color: ${props => props.theme.colors.cerulean};
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export const EmptyButton = styled(GenericButton)`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.pacificBlue};
  border-color: ${props => props.theme.colors.pacificBlue};
  &:hover {
    background-color: ${props => props.theme.colors.white};
  }
`;
