import ReactGA from 'react-ga';

export const sendPageView = (trackerName?: string, path?: string) => {
  ReactGA.initialize(process.env.REACT_APP_GA_ID || '', {
    gaOptions: { name: trackerName },
    alwaysSendToDefaultTracker: false,
    testMode: process.env.NODE_ENV === 'test',
  });
  ReactGA.send({ hitType: 'pageView', title: path ?? document.title }, [
    trackerName || '',
  ]);
};

export const logGAEvent = (event: object) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};
