import React from 'react';
import styled from 'styled-components';
import { useUserContext } from '../../context/UserContext';
import { theme } from '../../assets/theme';
import alertImage from '../../assets/images/alert-1.svg';

const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 6px;
  column-gap: 24px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  span {
    display: block;
    color: ${props => props.theme.colors.loblolly};
    text-transform: uppercase;
    font-weight: 600;
  }
`;

const LegendIcon = styled.div<{ color: string }>`
  min-width: 14px;
  height: 14px;
  background-color: ${props => props.color};
  border: solid 1px
    ${props =>
      props.color === 'transparent'
        ? props.theme.colors.loblolly
        : 'transparent'};
  border-radius: 3px;
`;

export const AdherenceLegend = ({
  alertLevelDays = 3,
}: {
  alertLevelDays?: number;
}) => {
  const { getString } = useUserContext();
  return (
    <LegendContainer>
      <LegendItem>
        <img src={alertImage} alt='alert' />
        <span>
          {`${getString(
            'patientListComponent.footer.missingDoses'
          )} ${alertLevelDays} ${getString(
            'patientListComponent.footer.days'
          )}`}
        </span>
      </LegendItem>
      <LegendItem>
        <LegendIcon color='transparent' />
        <span>{getString('form.adherence.noData')}</span>
      </LegendItem>
      <LegendItem>
        <LegendIcon color={theme.colors.amaranth} />
        <span>{getString('form.adherence.missedAll')}</span>
      </LegendItem>
      <LegendItem>
        <LegendIcon color={theme.colors.texasRose} />
        <span>{getString('form.adherence.missedSome')}</span>
      </LegendItem>
      <LegendItem>
        <LegendIcon color={theme.colors.mossGreen} />
        <span>{getString('form.adherence.tookAll')}</span>
      </LegendItem>
    </LegendContainer>
  );
};

export default AdherenceLegend;
