import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import ResponsiveContainer from './ResponsiveContainer';
import { useUserContext } from '../../context/UserContext';
import SearchField from '../shared/SearchField';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logoImage from '../../assets/images/navbar/jnj_c4t_logo_color_rgb.png';
import updateProfileImage from '../../assets/images/navbar/update_profile.svg';
import hamburgerImage from '../../assets/images/navbar/hamburger.svg';
import triangleCollapsedImage from '../../assets/images/navbar/triangle_collapsed.svg';
import triangleExpandedImage from '../../assets/images/navbar/triangle_expanded.svg';
import { Popper, ClickAwayListener } from '@mui/base';
import { Endpoints } from '../../api/endpoints';
import { User } from '../../models';
import { ErrorToast } from '../shared/ToastrNotifications';
import axios from 'axios';
import { logGAEvent } from '../../shared/services/googleAnalyticsService';

const NavContainer = styled.nav`
  display: flex;
  background-color: ${props => props.theme.colors.white};
`;

const LogoLink = styled(Link)`
  display: flex;
  margin-right: 20px;
  img {
    height: 30px;
  }
`;

const NavContentContainer = styled(ResponsiveContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface INavCollapsable {
  $isCollapsed: boolean;
}

const NavCollapsable = styled.div<INavCollapsable>`
  display: flex;
  align-items: center;
  flex-grow: 1;
  transition: height 0.3s ease-out;
  @media (max-width: ${props => props.theme.breakPoints.lg}) {
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 100%;
    height: ${props => (props.$isCollapsed ? 0 : '108px')};
    visibility: ${props => (props.$isCollapsed ? 'hidden' : 'auto')};
  }
`;

const HamburgerButton = styled.button`
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: 4px;
  width: 56px;
  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    display: none;
  }
  img {
    height: 30px;
  }
`;

const NavTools = styled.div`
  display: flex;
`;

const MainLinkContainer = styled.ul`
  display: flex;
  align-items: center;
  flex-grow: 4;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    height: 64px;
    position: relative;
    margin: 0 10px;
  }
`;

const MainLink = styled(NavLink)`
  display: block;
  margin-top: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${props => props.theme.colors.loblolly};
  &.active {
    color: ${props => props.theme.colors.curiousBlue};
  }
  &.active + div {
    display: block;
    @media (max-width: ${props => props.theme.breakPoints.lg}) {
      display: none;
    }
  }
`;

const MainLinkPointer = styled.div`
  display: none;
  border-bottom: 10px solid ${props => props.theme.colors.solitude};
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
`;

const ProfileButtonContainer = styled.div`
  display: flex;
  align-items: center;
  .toggle {
    position: relative;
    left: -2px;
  }
`;

const ProfileButton = styled.button`
  display: flex;
  padding: 1px 6px;
`;

const ProfilePopperContent = styled.ul`
  position: relative;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.hawkesBlue};
  border-radius: 5px;
  list-style: none;
  min-width: 12.5rem;
  margin: 0 6px;
  padding: 10px 4px;
  &::before,
  &::after {
    border: solid transparent;
    content: ' ';
    width: 0;
    height: 0;
    bottom: 100%;
    left: 89%;
    position: absolute;
    pointer-events: none;
  }
  &::before {
    border-width: 13px;
    margin-left: -13px;
    border-bottom-color: ${props => props.theme.colors.hawkesBlue};
  }
  &::after {
    border-width: 11px;
    margin-left: -11px;
    border-bottom-color: ${props => props.theme.colors.white};
  }
  li {
    padding: 6px 18px;
  }
  li a,
  button,
  span {
    text-transform: uppercase;
    font-weight: 900;
    font-family: Brandon;
    border: none;
    background: none;
    color: ${props => props.theme.colors.loblolly};
  }
  li span {
    color: ${props => props.theme.colors.charcoal};
  }
  hr {
    border: none;
    background-color: ${props => props.theme.colors.hawkesBlue};
    height: 1px;
  }
`;

export const NavBar = () => {
  const { user, setUser, getString } = useUserContext();
  const navigate = useNavigate();
  const [profilePopperAnchor, setProfilePopperAnchor] = useState<HTMLElement>();
  const isProfilePopperOpen = Boolean(profilePopperAnchor);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [searchResults, setSearchResults] = useState(['']);

  const [users, setUsers] = useState<User[]>([]);
  const selectPatient = (key: number) => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'navigation_header_bar',
      eventAct: 'click',
      eventLbl: 'search_' + key,
    });
    navigate(`/patient/${users[key].id}`);
    window.location.reload();
  };

  const patientSearchCallback = useCallback((text: string) => {
    if (!text || text.length == 0) {
      return;
    }
    const apiOutputNames: string[] = [];
    axios
      .get(Endpoints.patients(), {
        params: {
          query: text,
        },
      })
      .then(response => {
        setUsers(response.data.users);
        response.data.users.map((fills: User) => {
          apiOutputNames.push(fills.lastName + ', ' + fills.firstName);
        });
        setSearchResults(apiOutputNames);
      })
      .catch(error => {
        ErrorToast(error);
      });
  }, []);

  return (
    <NavContainer>
      <NavContentContainer>
        <LogoLink
          to={{ pathname: !user?.superAdmin ? '/patient' : '/hcp/users' }}>
          <img src={logoImage} alt='logo' />
        </LogoLink>
        <HamburgerButton onClick={() => setIsNavCollapsed(!isNavCollapsed)}>
          <img src={hamburgerImage} alt='toggle' />
        </HamburgerButton>
        <NavCollapsable $isCollapsed={isNavCollapsed}>
          <MainLinkContainer>
            {!user?.superAdmin && (
              <li>
                <MainLink to={{ pathname: '/patient' }}>Patients</MainLink>
                <MainLinkPointer />
              </li>
            )}
            <li>
              <MainLink to={{ pathname: '/hcp' }}>Admin</MainLink>
              <MainLinkPointer />
            </li>
          </MainLinkContainer>
          <NavTools>
            {!user?.superAdmin && (
              <SearchField
                searchStyle='default'
                clickEvent={selectPatient}
                handleSearchCallback={patientSearchCallback}
                output={searchResults}
                placeholder={getString('searchComponent.placeholder.search')}
              />
            )}
            <ProfileButtonContainer>
              <ProfileButton
                onClick={e => {
                  setProfilePopperAnchor(
                    !profilePopperAnchor ? e.currentTarget : undefined
                  );
                }}>
                <img src={updateProfileImage} alt='update profile' />
              </ProfileButton>
              <img
                className='toggle'
                alt='toggle'
                src={
                  isProfilePopperOpen
                    ? triangleExpandedImage
                    : triangleCollapsedImage
                }
              />
            </ProfileButtonContainer>
            <Popper
              open={isProfilePopperOpen}
              anchorEl={profilePopperAnchor}
              placement='bottom-end'
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [12, 14],
                  },
                },
              ]}>
              <ClickAwayListener
                onClickAway={() => setProfilePopperAnchor(undefined)}>
                <ProfilePopperContent>
                  <li>
                    <span>{[user?.firstName, user?.lastName].join(' ')}</span>
                  </li>
                  <li>
                    <Link to={{ pathname: '/hcp/profile' }}>
                      {getString('profileDropdownComponent.content.myProfile')}
                    </Link>
                  </li>
                  <hr />
                  <li>
                    <button
                      onClick={() => {
                        setUser();
                        navigate('/login');
                      }}>
                      {getString('profileDropdownComponent.content.logOut')}
                    </button>
                  </li>
                </ProfilePopperContent>
              </ClickAwayListener>
            </Popper>
          </NavTools>
        </NavCollapsable>
      </NavContentContainer>
    </NavContainer>
  );
};

export default NavBar;
