import styled from 'styled-components';
import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import moment from 'moment';
import { useUserContext } from '../../context/UserContext';

interface TimeInputProps {
  setIsValid: (isValid: boolean) => void;
  valueCallback?: (value: string) => void;
  defaultValue?: string;
  startDateValue: Date | undefined;
  startTimeValue?: string;
  isEndtime?: boolean;
  name?: string;
  id?: string;
  onClick?: () => void;
}

enum errorTypes {
  none = 'none',
  isRequired = 'required',
  isInvalidFormat = 'invalid12HourFormat',
  isInPast = 'appointmentStartTimeInThePast',
  isBeforeStartTime = 'endTimeBeforeStartTime',
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 202px;
`;

const Input = styled.input`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-size: 16px;
  height: 26px;
  padding: 10px;
  width: 180px;
  text-align: left;
  font-family: Brandon;

  border-radius: 4px 0 0 4px;

  &.invalid {
    border: 1px solid ${props => props.theme.colors.mangoTango};
  }

  &.end-time {
    border-radius: 0 4px 4px 0;
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const ErrorText = styled.p`
  color: ${props => props.theme.colors.mangoTango};
  text-align: left;
  font-weight: 500;
  margin: 0;
`;

const timeRegex = /^((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/;
const timeInputMask = /^(([1_][0-2_]|0?[1-9_]):([0-5_][0-9_]) ?([AaPp_][Mm]))$/;
const TimeInput = (props: TimeInputProps) => {
  const [value, setValue] = useState<string>(
    props.defaultValue?.toUpperCase() || ''
  );
  const [hasBeenTouched, setHasBeenTouched] = useState(false);
  const { getString } = useUserContext();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [errorType, setErrorType] = useState(errorTypes.none);
  const [cursor, setCursor] = useState<number>();
  const inputRef = useRef<HTMLInputElement>(null);
  const inputWasAdditive = useRef<boolean | null>(null);
  const inputWasValid = useRef<boolean>(false);

  useEffect(() => {
    props.setIsValid(isValid);
  }, [isValid, props]);

  // update the cursor position
  useLayoutEffect(() => {
    const input = inputRef.current;
    if (input && inputWasValid) {
      if (cursor == 2 && inputWasAdditive.current) {
        input.setSelectionRange(3, 3);
      } else if (cursor == 1 && value[1] !== '_' && inputWasAdditive.current) {
        input.setSelectionRange(3, 3);
      } else if (cursor == 3 && !inputWasAdditive.current) {
        input.setSelectionRange(2, 2);
      } else if (cursor == 5 && inputWasAdditive.current) {
        input.setSelectionRange(6, 6);
      } else if (cursor == 5 && !inputWasAdditive.current) {
        input.setSelectionRange(4, 4);
      } else if (cursor == 6 && !inputWasAdditive.current) {
        input.setSelectionRange(5, 5);
      } else {
        input.setSelectionRange(cursor ?? 0, cursor ?? 0);
      }
    }
  }, [inputRef, cursor, value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputWasValid.current = false;
    let inputValue = e.target.value;

    // set what the input type was
    if (inputValue.length < value.length) {
      // last input removed item(s) from the string
      inputWasAdditive.current = false;
    } else if (inputValue.length > value.length) {
      // last input added item(s) to the string
      inputWasAdditive.current = true;
    } else {
      // last input didn't change string value
      inputWasAdditive.current = null;
    }

    // if it's not replacing a static element of the array
    if (inputValue.length > value.length) {
      if (
        inputValue[(e.target.selectionStart || 0) + 1] != ':' ||
        inputValue[(e.target.selectionStart || 0) + 1] != ' ' ||
        inputValue[(e.target.selectionStart || 0) + 1] != 'M'
      ) {
        inputValue =
          inputValue.substring(0, e.target.selectionStart || 0) +
          inputValue.substring((e.target.selectionEnd || 0) + 1);
      }
    }

    // Turn 2_: into 02:, turns 1:: into 01:
    if (
      parseInt(inputValue.substring(0, 1), 10) > 1 ||
      inputValue.substring(1, 1) === ':'
    ) {
      inputValue = '0' + inputValue.substring(0, 1) + inputValue.substring(3);
      setCursor(3);
    }

    // Don't accept 00:
    if (inputValue.substring(0, 2) === '00') {
      e.preventDefault();
      return false;
    }
    // append the format string to the rest of the input
    const regexList = [/[\d_]/, /[\d_]/, /[\d_]/, /[\d_]/, /[aApP_]/];
    const outputList = Array<string | null>();

    //replace invalid characters with _
    regexList.some(regex => {
      const match = inputValue.match(regex);
      outputList.push(match?.[0] ?? '_');

      if (match?.index !== undefined) {
        inputValue = inputValue.substring(match?.index + 1);
      }
    });

    // calculate the difference between this string and the last
    let difference = [];
    const filteredValue = value.split('').filter(x => /\d|_|[aApP]/.test(x));
    if (inputWasAdditive.current) {
      difference = outputList.filter(x => {
        const val = !filteredValue.includes(x ?? '');
        const index = filteredValue.indexOf(x || '');
        if (index > -1) {
          // only splice array when item is found
          filteredValue.splice(index, 1); // 2nd parameter means remove one item only
        }
        return val;
      });
    } else {
      difference = filteredValue.filter(x => {
        const val = !outputList.includes(x);
        const index = filteredValue.indexOf(x || '');
        if (index > -1) {
          // only splice array when item is found
          filteredValue.splice(index, 1); // 2nd parameter means remove one item only
        }
        return val;
      });
    }

    //TODO: Fix backspacing code here:
    if (
      !inputWasAdditive.current &&
      (difference[0] == value[0] || difference[0] == value[1])
    ) {
      if (difference[0] == value[0]) {
        outputList.splice(0, 0, '_');
        outputList.splice(outputList.length - 2, 1);
      } else {
        outputList.splice(1, 0, '_');
        outputList.splice(outputList.length - 2, 1);
      }
    }
    // override the input format with correct format
    inputValue = `${outputList[0]}${outputList[1]}:${outputList[2]}${outputList[3]} ${outputList[4]}M`;
    // if the time indicator (a/p) is not _, set it to uppercase
    if (inputValue.substring(6, 7) !== '_') {
      inputValue =
        inputValue.substring(0, 6) +
        inputValue.substring(6, 7).toUpperCase() +
        inputValue.substring(7);
    }

    // if the input string is valid for the time mask, set the string, otherwise return false
    if (inputValue.match(timeInputMask) && inputValue != value) {
      setValue(inputValue);
      setCursor(e.target.selectionStart || 0);
      inputWasValid.current = true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (props.isEndtime && value == '') return;
    // Check correct time format and split into components
    let time: Array<string> = value
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [value];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = (+time[0] % 12 || 12).toString(); // Adjust hours
    }
    const inputValue = time.join(''); // adjusted time

    //does the input pass the regex (in for of H(0-1)H(0-9):M(0-5)M(0-9) [Pp/Aa]M)
    if (timeRegex.test(inputValue)) {
      //is the appointment date not after today
      if (
        !moment(props.startDateValue ?? moment().startOf('D')).isAfter(moment())
      ) {
        if (moment(inputValue, 'hh:mm a').isBefore(moment.now())) {
          setIsValid(false);
          setErrorType(errorTypes.isInPast);
          return;
        }
        // is this the end time input and is the end time before start time
      }
      if (
        props.startTimeValue &&
        props.isEndtime &&
        moment(props.startTimeValue, 'hh:mm a').isAfter(
          moment(inputValue, 'hh:mm a')
        )
      ) {
        setIsValid(false);
        setErrorType(errorTypes.isBeforeStartTime);
        return;
      }
      setIsValid(true);
      setErrorType(errorTypes.none);
    } else {
      if (inputValue.length == 0 && hasBeenTouched && !props.isEndtime) {
        setIsValid(false);
        setErrorType(errorTypes.isRequired);
      } else if (hasBeenTouched && value != '') {
        setIsValid(false);
        setErrorType(errorTypes.isInvalidFormat);
      }
    }

    props.valueCallback?.(inputValue);
    setValue(inputValue);
  }, [value, props.startTimeValue, props.isEndtime, props, hasBeenTouched]);

  // handle focus and blur are used to set the input type
  // this is so we can have a placeholder
  // this solution is kinda hacky but it mostly works
  const handleFocus = () => {
    setHasBeenTouched(true);
  };

  return (
    <InputContainer>
      <Input
        className={`time-input ${isValid ? '' : 'invalid'} ${
          props.isEndtime ? 'end-time' : ''
        }`}
        id={props.id}
        placeholder={getString(
          `appointmentModalComponent.placeholders.${
            props.isEndtime ? 'endTime' : 'startTime'
          }`
        )}
        value={value}
        type='text'
        name={props.name}
        data-testid={props.isEndtime ? 'end-time' : 'start-time'}
        onChange={handleChange}
        onFocus={handleFocus}
        onClick={() => props.onClick?.()}
        ref={inputRef}
      />
      {isValid ? null : (
        <ErrorText data-testid='error-message'>
          {getString(`controlMessageComponent.message.${errorType.valueOf()}`)}
        </ErrorText>
      )}
    </InputContainer>
  );
};

export { TimeInput };
