import React from 'react';
import styled from 'styled-components';
import { Adherence, ScheduledAdherence } from '../../../models';
import useAdherenceCalendar from '../useAdherenceCalendar';

const Container = styled.div`
  display: flex;
  min-height: 140px;
`;

const Table = styled.table`
  align-self: center;
  tr th span {
    text-align: center;
    color: ${props => props.theme.colors.loblolly};
    font-weight: 800;
  }
  tr td {
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    @media (max-width: ${props => props.theme.breakPoints.md}) {
      width: 18px;
      height: 20px;
      border-radius: 4px;
    }
    &.no-data {
      background-color: ${props => props.theme.colors.solitude};
    }
    &.full-adherence {
      background-color: ${props => props.theme.colors.mossGreen};
    }
    &.some-adherence {
      background-color: ${props => props.theme.colors.texasRose};
    }
    &.no-adherence {
      background-color: ${props => props.theme.colors.amaranth};
    }
  }
`;

type MedicationCalendarProps = {
  calendar?: Record<string, Adherence | ScheduledAdherence>;
};

export const MedicationCalendar = ({
  calendar = {},
}: MedicationCalendarProps) => {
  const { weeks, getAdherenceLevel, dayOfWeek } = useAdherenceCalendar({
    calendar,
  });

  return (
    <Container>
      {weeks.length && weeks[0].length == 7 && (
        <Table>
          <thead>
            <tr>
              {weeks[0].map((date, dayIndex) => (
                <th key={date} data-testid='dayOfWeek'>
                  <span>{dayOfWeek(dayIndex)}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeks.map((week, weekIndex) => (
              <tr key={weekIndex} data-testid='week'>
                {week.map((date, dayIndex) => (
                  <td
                    key={dayIndex}
                    className={getAdherenceLevel(date)}
                    data-testid='day'
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default MedicationCalendar;
