import styled from 'styled-components';

export const ResponsiveContainer = styled.div`
  margin: 0 auto;
  padding: 0 16px;
  @media (min-width: ${props => props.theme.breakPoints.sm}) {
    max-width: ${props => props.theme.containerMaxWidths.sm};
  }
  @media (min-width: ${props => props.theme.breakPoints.md}) {
    max-width: ${props => props.theme.containerMaxWidths.md};
  }
  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    max-width: ${props => props.theme.containerMaxWidths.lg};
  }
  @media (min-width: ${props => props.theme.breakPoints.xl}) {
    max-width: ${props => props.theme.containerMaxWidths.xl};
  }
`;

export default ResponsiveContainer;
