import React, { FocusEvent, useState } from 'react';
import { Box, Tab, Tabs, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { getString } from '../../../context/UserContext';
import { ErrorToast } from '../../shared/ToastrNotifications';
import BaseModal from '../../shared/modals/BaseModal';

const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    display: none;
  }
  & .MuiTabs-flexContainer {
    border-radius: 7px;
    overflow: hidden;
  }
`;

const StyledTab = styled(Tab)`
  && {
    text-transform: none;
    font-family: 'Brandon';
    background: ${props => props.theme.colors.aliceBlue};
    color: ${props => props.theme.colors.charcoal};
    height: 42px;
    &.Mui-selected {
      border-bottom: 0;
      background: ${props => props.theme.colors.cerulean};
      color: ${props => props.theme.colors.white};
    }
  }
`;

const Input = styled(TextField)`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 10px !important;
  margin: 0 4px !important;
  width: 60px;

  & .MuiInputBase-input {
    height: 0;
    color: ${props => props.theme.colors.charcoal};
    font-size: 16px;
    font-weight: 600;
    font-size: 16px;
  }

  .MuiOutlinedInput-root {
    fieldset {
      border: 1px solid ${props => props.theme.colors.mystic};
      border-radius: 4px;
    }
    &:hover fieldset {
      border: 1px solid ${props => props.theme.colors.curiousBlue};
    }
    &.Mui-focused fieldset {
      border: 1px solid ${props => props.theme.colors.curiousBlue};
    }
    &.Mui-error fieldset {
      border-color: ${props => props.theme.colors.mangoTango};
      &:hover {
        border: 1px solid ${props => props.theme.colors.curiousBlue};
      }
    }
  }
  .MuiFormHelperText-root.Mui-error {
    color: ${props => props.theme.colors.mangoTango};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    font-family: 'Brandon';
    width: max-content;
    margin-left: -40px;
  }
`;

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin: auto;
  gap: 16px;
`;

const Button = styled.button`
  background: ${props => props.theme.colors.white};
  border: 0;
  text-transform: uppercase;
  padding: '1px 6px';
`;

const DoneButton = styled(Button)`
  color: ${props => props.theme.colors.cerulean};
  font-weight: 700;
`;

const CheckboxContainer = styled.label<{ checked: boolean }>`
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: ${props =>
    props.checked ? props.theme.colors.cerulean : 'transparent'};
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

const CheckboxLabel = styled.span<{ checked: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: 700;
  padding: '4px';
  margin-top: 4px;
  color: ${props =>
    props.checked ? props.theme.colors.white : props.theme.colors.charcoal};
`;

interface FrequencyModalProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  updateCustomFrequency: (
    type: number,
    days: string,
    weekDays: string[]
  ) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ py: 3, textAlign: 'left' }}>
          <Typography component={'div'} sx={{ fontFamily: 'Brandon' }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const CustomFrequencyModal = (props: FrequencyModalProps) => {
  const [tab, setTab] = useState(0);
  const [invalidDays, setInvalidDays] = useState(false);
  const [invalidWeek, setInvalidWeek] = useState(false);
  const [days, setDays] = useState('');
  const [weeks, setWeeks] = useState('');
  const [weekDays, setWeekDays] = useState<string[]>([]);

  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    setTab(0);
    setDays('');
    setWeeks('');
    setWeekDays([]);
    props.setModalIsOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setDays('');
    setWeeks('');
    setWeekDays([]);
    setTab(newValue);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setInvalidDays(false);
    setInvalidWeek(false);
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case 'days':
        setInvalidDays(value === '' ? true : false);
        setDays(value);
        break;
      case 'weeks':
        setInvalidWeek(value === '' ? true : false);
        setWeeks(value);
        break;
      default:
        return;
    }
  };

  const handleWeekDaysSelection = (value: string) => {
    // Check if the value is already in the array
    const isSelected = weekDays.includes(value);

    // If it's selected, remove it; if not, add it to the array
    setWeekDays(prevSelected => {
      if (isSelected) {
        return prevSelected.filter(item => item !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const getDayCheckbox = (name: string) => {
    const lowerCaseName = name.toLowerCase();
    return (
      <CheckboxContainer
        checked={weekDays.includes(lowerCaseName) ? true : false}>
        <HiddenCheckbox
          name={lowerCaseName}
          value={lowerCaseName}
          checked={weekDays.includes(lowerCaseName) ? true : false}
          onChange={() => handleWeekDaysSelection(lowerCaseName)}
        />
        <CheckboxLabel
          checked={weekDays.includes(lowerCaseName) ? true : false}>
          {name}
        </CheckboxLabel>
      </CheckboxContainer>
    );
  };

  const handleSubmit = () => {
    if (
      (tab === 0 && days === '') ||
      (tab === 1 && (weeks === '' || !weekDays.length))
    ) {
      ErrorToast(
        getString(
          'toastrMessages.medicationModalComponent.pleaseCompleteTheForm'
        )
      );
      return;
    }
    props.updateCustomFrequency(tab, tab === 0 ? days : weeks, weekDays);
    handleClose();
  };

  return (
    <BaseModal
      onCloseButtonClicked={handleClose}
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}>
      <Box>
        <StyledTabs
          value={tab}
          onChange={handleTabChange}
          aria-label='day tabs'
          variant='fullWidth'>
          <StyledTab
            label={getString('customScheduleModalComponent.button.daily')}
            {...a11yProps(0)}
          />
          <StyledTab
            label={getString('customScheduleModalComponent.button.weekly')}
            {...a11yProps(1)}
          />
        </StyledTabs>
        <CustomTabPanel value={tab} index={0}>
          {getString('customScheduleModalComponent.instructions.every')}
          <Input
            variant='outlined'
            error={invalidDays}
            helperText={
              invalidDays
                ? getString('controlMessageComponent.message.required')
                : ''
            }
            id='every-day-input'
            type='number'
            name='days'
            inputProps={{
              min: 0,
              step: 1,
            }}
            value={days}
            onBlur={handleBlur}
            onChange={handleBlur}
          />
          {getString('customScheduleModalComponent.instructions.days')}
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          {getString('customScheduleModalComponent.instructions.every')}
          <Input
            value={weeks}
            variant='outlined'
            error={invalidWeek}
            helperText={
              invalidWeek
                ? getString('controlMessageComponent.message.required')
                : ''
            }
            id='every-week-input'
            type='number'
            name='weeks'
            inputProps={{
              min: 0,
              step: 1,
            }}
            onBlur={handleBlur}
            onChange={handleBlur}
          />
          {getString('customScheduleModalComponent.instructions.weeksOn')}

          <Box
            sx={{ mt: '40px', display: 'flex', gap: '4px' }}
            component={'div'}>
            <>
              {getDayCheckbox(
                getString('customScheduleModalComponent.abbreviation.sunday')
              )}
              {getDayCheckbox(
                getString('customScheduleModalComponent.abbreviation.monday')
              )}
              {getDayCheckbox(
                getString('customScheduleModalComponent.abbreviation.tuesday')
              )}
              {getDayCheckbox(
                getString('customScheduleModalComponent.abbreviation.wednesday')
              )}
              {getDayCheckbox(
                getString('customScheduleModalComponent.abbreviation.thursday')
              )}
              {getDayCheckbox(
                getString('customScheduleModalComponent.abbreviation.friday')
              )}
              {getDayCheckbox(
                getString('customScheduleModalComponent.abbreviation.saturday')
              )}
            </>
          </Box>
        </CustomTabPanel>
      </Box>
      <ModalButtonContainer>
        <Button onClick={handleClose}>Cancel</Button>
        <DoneButton onClick={handleSubmit}>Done</DoneButton>
      </ModalButtonContainer>
    </BaseModal>
  );
};
export { CustomFrequencyModal };
