import styled from 'styled-components';
import theme from '../../../assets/theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    color: ${props => props.theme.colors.charcoal};
    font-weight: 900;
    font-size: 20px;
  }
`;

type CircularProgressProps = {
  percentage: number;
};

const CircularProgress = (props: CircularProgressProps) => {
  const arc = props.percentage * 250;
  return (
    <Container>
      <svg
        preserveAspectRatio='none'
        width={60}
        version='1.1'
        viewBox='0 0 100 100'
        xmlns='http://www.w3.org/2000/svg'>
        <circle
          cx='150'
          cy='50'
          fill='none'
          r='40'
          stroke={theme.colors.athensGray}
          strokeLinecap='round'
          strokeWidth='20'
          transform='rotate(-90,100,100)'
        />
        {props.percentage > 0 && (
          <circle
            cx='150'
            cy='50'
            r='40'
            fill='none'
            stroke={theme.colors.charcoal}
            strokeLinecap='round'
            strokeWidth='20'
            strokeDasharray={`${arc},20000`}
            transform='rotate(-90,100,100)'
          />
        )}
      </svg>
      <span>{Math.round(props.percentage * 100)}%</span>
    </Container>
  );
};

export default CircularProgress;
