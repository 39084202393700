import { styled } from 'styled-components';
import { Card } from '../detail/Card';

export const FlexCard = styled(Card)`
  flex: 0 0 48%;
  height: fit-content;
`;

export const Content = styled.div`
  border-top: 1px solid ${props => props.theme.colors.shark};
  margin: 0 15px;
  padding: 15px 0;
  display: flex;
  gap: 5px;
  overflow: hidden;

  div:first-child {
    flex: 0 0 84%;
    max-width: 84%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div.actions {
    flex: 0 0 15%;
    text-align: right;

    button:first-child {
      margin-right: 10px;
    }
  }
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: ${props => props.theme.colors.shark};
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SubTitle = styled(Title)`
  font-weight: 400;
`;
