import BaseModal from '../../../shared/modals/BaseModal';
import axios from 'axios';
import styled from 'styled-components';
import { useUserContext } from '../../../../context/UserContext';
import { Endpoints } from '../../../../api/endpoints';
import { ErrorToast } from '../../../shared/ToastrNotifications';
import { GenericButton } from '../../../shared/modals/BaseModalStyle';
import React, { useState, useCallback } from 'react';

import RemoveItem from '../../../../assets/images/remove-item.svg';
import SearchField from '../../../shared/SearchField';
interface TagModalProps {
  patientId: number;
  patientTags: string[];
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  children?: React.ReactNode;
  updateTags: (newTags: string[]) => void;
}

const ModalTitle = styled.h1`
  text-transform: uppercase;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 900;
  font-size: 20px;
  letter-spacing: 2.73px;
  margin: 0;
  margin-top: -20px;
  padding-bottom: 50px;
`;
const NewButton = styled(GenericButton)`
  font-weight: 900;
  text-transform: uppercase;
`;
const FieldRow = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;
const Tag = styled.span`
  background: ${props => props.theme.colors.athensGray};
  border-radius: 5px;
  display: inline-block;
  width: auto;
  margin: 0 4px 8px 0;
  padding: 4px 10px;
  word-break: break-word;
  background: ${props => props.theme.colors.athensGray};
`;

const CloseTag = styled.button`
  width: 16px;
  height: 16px;
  position: relative;
  left: 6px;
  top: 6px;

  background: transparent url(${RemoveItem}) no-repeat;
`;

const SubmitContainer = styled.div`
  margin-top: 90px;
  margin-bottom: 20px;
`;

const TagContainer = styled.div`
  text-align: left;
  margin-left: 20px;
  margin-top: 30px;
`;
export const TagModal = (props: TagModalProps) => {
  const [searchResults, setSearchResults] = useState(['']);
  const [tagsUsed, setTagsUsed] = useState<string[]>(props.patientTags);
  const { getString } = useUserContext();
  const selectTag = (key: number) => {
    const adjustedTag = searchResults[key].replace(
      getString('editTagsModalComponent.tooltip.createNewTag'),
      ''
    );
    setTagsUsed(
      [...tagsUsed, adjustedTag].sort((a, b) =>
        a.localeCompare(b, undefined, { sensitivity: 'case' })
      )
    );
  };
  const handleTagClose = (tag: string) => {
    setTagsUsed(tagsUsed => {
      return tagsUsed.filter(tags => tags !== tag);
    });
  };

  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    props.setModalIsOpen(false);
  };

  const handleSubmit = () => {
    axios
      .put(Endpoints.patientTags(props.patientId), { tags: tagsUsed })
      .then(() => {
        props.updateTags(tagsUsed);
      })
      .catch(error => {
        ErrorToast(error);
      })
      .finally(() => {
        props.setModalIsOpen(false);
      });
  };

  const tagCallback = useCallback(
    (text: string) => {
      if (!text || text.length == 0) {
        return;
      }
      const tagOutput: string[] = [];
      axios
        .get(Endpoints.tags, {
          params: {
            q: text,
          },
        })
        .then(response => {
          response.data.tags.map((fills: string) => {
            if (!tagsUsed.includes(fills)) {
              tagOutput.push(fills);
            }
          });

          if (!tagOutput.includes(text) && !tagsUsed.includes(text)) {
            tagOutput.push(
              text + getString('editTagsModalComponent.tooltip.createNewTag')
            );
          }
          if (tagOutput.length > 0) {
            setSearchResults(tagOutput);
          }
        })
        .catch(error => {
          ErrorToast(error);
        });
    },
    [getString, tagsUsed]
  );

  return (
    <BaseModal
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}>
      <ModalTitle>
        {getString('editTagsModalComponent.title.editTags')}
      </ModalTitle>
      <FieldRow>
        <SearchField
          searchStyle='tagSelect'
          placeholder={getString(
            'editTagsModalComponent.instructions.placeholder'
          )}
          output={searchResults}
          hasClearField={true}
          clickEvent={selectTag}
          handleSearchCallback={tagCallback}
        />
      </FieldRow>
      <FieldRow>
        <TagContainer>
          {tagsUsed?.map((tag, index) => {
            return (
              <Tag data-testid={'tag: ' + tag} key={index}>
                {tag}
                <CloseTag
                  data-testid={'close: ' + tag}
                  onClick={() => {
                    handleTagClose(tag);
                  }}
                />
              </Tag>
            );
          })}
        </TagContainer>
      </FieldRow>
      <SubmitContainer>
        <NewButton onClick={handleSubmit} data-testid={'Save'}>
          {getString('form.button.save')}
        </NewButton>
      </SubmitContainer>
    </BaseModal>
  );
};
export default TagModal;
