import { useState } from 'react';
import styled from 'styled-components';
import { Encouragement } from '../../../models';

const Container = styled.div<{ $isExpanded: boolean }>`
  transition: 0.2s ease-in-out;
  max-height: ${props => (props.$isExpanded ? '600px' : 0)};
  overflow: ${props => (props.$isExpanded ? 'auto' : 'hidden')};
  text-align: center;
  margin: ${props => (props.$isExpanded ? '15px 0' : 0)};
  div.record {
    display: inline-block;
    color: ${props => props.theme.colors.encouragementLight};
    font-weight: 100;
    line-height: 1.2;
    padding: 0.5% 5%;
    &.priority-1 {
      color: ${props => props.theme.colors.black};
      font-weight: 900;
      font-size: 300%;
      letter-spacing: 6px;
    }
    &.priority-2 {
      color: ${props => props.theme.colors.encouragementDark};
      font-weight: 500;
      font-size: 200%;
      letter-spacing: 5px;
    }
    &.priority-3 {
      color: ${props => props.theme.colors.encouragementLight};
      font-weight: 300;
      font-size: 175%;
      letter-spacing: 5px;
    }
    &.priority-4 {
      color: ${props => props.theme.colors.encouragementLight};
      font-weight: 100;
      font-size: 150%;
      letter-spacing: 5px;
    }
    // "Randomize" padding to create a sporatic display
    &:nth-of-type(3n-1) {
      padding-left: 10%;
      padding-right: 2%;
    }
    &:nth-of-type(4n + 3) {
      padding-right: 20%;
      padding-left: 1%;
    }
    &:nth-of-type(5n-2) {
      padding-right: 15%;
    }
  }
  @media (max-width: ${props => props.theme.breakPoints.lg}) {
    div.record {
      font-size: 70%;
      &.priority-1 {
        font-size: 200%;
      }
      &.priority-2 {
        font-size: 140%;
      }
      &.priority-3 {
        font-size: 100%;
      }
      &.priority-4 {
        font-size: 75%;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakPoints.md}) {
    div.record {
      font-size: 65%;
      &.priority-1 {
        font-size: 150%;
      }
      &.priority-2 {
        font-size: 120%;
      }
      &.priority-3 {
        font-size: 115%;
      }
      &.priority-4 {
        font-size: 75%;
      }
    }
  }
`;

type ActivityEncouragementProps = {
  records: Encouragement[];
  isExpanded: boolean;
};

const ActivityEncouragement = ({
  records,
  isExpanded,
}: ActivityEncouragementProps) => {
  type Item = {
    label: string;
    priority: number;
    order: number;
  };

  const [items] = useState<Item[]>(() => {
    // returns a map of labels where value is the instance count
    const uniques = (() => {
      const map = new Map<string, number>();
      records.forEach(r => {
        r.selection?.forEach(s => {
          const key = s.label;
          const value = map.get(key) || 0;
          map.set(key, value + 1);
        });
      });
      return [...map.entries()].sort((a, b) => b[1] - a[1]);
    })();

    if (!uniques.length) {
      return [];
    }
    // create the order that the word cloud should go by
    // largest numbers are in the center and adjacent values decrement
    const orders = (() => {
      const result: number[] = [];
      const center = Math.ceil(uniques.length / 2);
      result.push(center);
      for (let i = 1; i < center; i++) {
        result.push(center - i);
        result.push(center + i);
      }
      return result;
    })();

    // assigns priorities, orders, and formatting to items which determines where and how items are displayed
    // priority of 1 is highest and 5 is lowest
    // ordering is specified so the highest priority values are in the center
    let count = uniques[0][1];
    let priority = 1;
    return uniques
      .map(([label, labelCount], index) => {
        if (labelCount !== count) {
          count = labelCount;
          if (priority !== 5) {
            priority++;
          }
        }
        return {
          label: label.replace(/\//g, ' / '),
          priority,
          order: orders[index],
        };
      })
      .sort((a, b) => b.order - a.order);
  });

  return (
    <Container $isExpanded={isExpanded}>
      {items.map((item, index) => (
        <div key={index} className={`record priority-${item.priority}`}>
          {item.label}
        </div>
      ))}
    </Container>
  );
};

export default ActivityEncouragement;
