import { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../assets/theme';
import axios from 'axios';
import { Endpoints } from '../../../api/endpoints';
import { format, parseISO } from 'date-fns';
import {
  MedicationListResponse,
  MedicationListItem,
  MedicationDoseAdherence,
} from '../../../api/models';
import { useUserContext } from '../../../context/UserContext';
import { Card, CardHeader, NoInfoBaseContainer } from './Card';
import CircularProgress from './CircularProgress';
import ProgressBar from './ProgressBar';
import MedicationCalendar from './MedicationCalendar';
import Pager from '../Pager';
import Loader from '../../shared/Loader';
import { ErrorToast } from '../../shared/ToastrNotifications';
import noMedicationsImage from '../../../assets/images/no_medications_icon.svg';
import arrowExpandedImage from '../../../assets/images/arrow_expanded.svg';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 255px;
`;

const NoInfoContainer = styled(NoInfoBaseContainer)`
  flex-grow: 1;
  justify-content: center;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  padding: 0 15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  min-width: 0;
`;

const Overall = styled(Row)`
  display: flex;
  height: 90px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.athensGray};
  border-bottom: 1px solid ${props => props.theme.colors.athensGray};
  p {
    flex-basis: 60%;
    text-transform: uppercase;
    font-weight: 600;
    color: ${props => props.theme.colors.loblolly};
    margin-left: 15px;
  }
  div {
    margin-right: 15px;
  }
`;

const MedicationListContainer = styled(Column)`
  flex-basis: 60%;
  max-width: 60%;
  border-right: 1px solid ${props => props.theme.colors.athensGray};
  .pager {
    align-self: flex-end;
    margin: 15px;
  }
`;

const MedicationList = styled.div`
  display: flex;
  flex-direction: column;
  button {
    position: relative;
    flex-grow: 1;
    &:hover,
    &.selected {
      background-color: ${props => props.theme.colors.athensGray};
    }
    div.container {
      display: flex;
      padding: 8px 0;
      margin: 0 15px;
      gap: 15px;
      align-items: center;
      border-bottom: 1px solid ${props => props.theme.colors.athensGray};
      @media (max-width: ${props => props.theme.breakPoints.sm}) {
        flex-direction: column;
        align-items: stretch;
        gap: 5px;
      }
      span {
        display: block;
        text-align: left;
      }
      span.gray {
        color: ${props => props.theme.colors.loblolly};
        font-weight: 500;
      }
      span.inactive {
        font-weight: 700;
        color: ${props => props.theme.colors.vividRed};
      }
      span.uppercase {
        text-transform: uppercase;
      }
      div.strength {
        position: relative;
        flex-direction: column;
        @media (min-width: ${props => props.theme.breakPoints.sm}) {
          min-width: 0;
          flex-basis: 60%;
          max-width: 60%;
        }
        span.primary {
          color: ${props => props.theme.colors.charcoal};
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @media (max-width: ${props => props.theme.breakPoints.sm}) {
            white-space: normal;
          }
        }
        p.tooltip {
          position: absolute;
          top: 100%;
          width: 90%;
          display: none;
          word-wrap: break-word;
          color: ${props => props.theme.colors.white};
          background-color: ${props => props.theme.colors.charcoal};
          font-weight: 500;
          padding: 5px 0;
          margin: 0;
          border-radius: 6px;
          text-align: center;
          z-index: 1;
        }
        &:hover {
          .tooltip {
            display: block;
          }
        }
      }
      div.progress {
        display: flex;
        flex-direction: column;
        @media (min-width: ${props => props.theme.breakPoints.sm}) {
          flex-basis: 38%;
          max-width: 38%;
        }
      }
    }
    &:last-child div.container {
      border-bottom: none;
    }
    div.arrow {
      position: absolute;
      top: calc(50% - 12px);
      right: -1px;
      display: none;
      border-bottom: 12px solid transparent;
      border-right: 12px solid ${props => props.theme.colors.white};
      border-top: 12px solid transparent;
      width: 0;
      height: 0;
      z-index: 1;
      &.selected {
        display: block;
      }
    }
  }
`;

const AdherenceContainer = styled(Column)`
  justify-content: flex-start;
  flex-basis: 40%;
  max-width: 40%;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
`;

const Dropdown = styled.select`
  width: 100%;
  padding: 0 26px;
  margin-bottom: 4px;
  appearance: none;
  background: url(${arrowExpandedImage}) no-repeat 95% 50%;
  color: ${props => props.theme.colors.charcoal};
  font-size: 16px;
  font-family: Brandon, Helvetica, Arial, sans-serif;
  border: 0;
  cursor: pointer;
  &.primary {
    font-weight: 600;
  }
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    font-size: 14px;
    padding: 0 14px;
  }
`;

const AdherenceLegend = styled(Row)`
  justify-content: space-evenly;
  flex-wrap: wrap;
  border-top: 1px solid ${props => props.theme.colors.athensGray};
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    flex-direction: column;
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    margin: 12px -6px;
    @media (min-width: ${props => props.theme.breakPoints.sm}) {
      flex-basis: 25%;
      max-width: 25%;
    }
    @media (max-width: ${props => props.theme.breakPoints.sm}) {
      margin: 6px 0;
    }
    div {
      width: 15px;
      height: 15px;
      border-radius: 3px;
    }
    span {
      color: ${props => props.theme.colors.stoneGray};
      text-transform: uppercase;
      text-align: center;
      font-weight: 700;
      line-height: 18px;
      @media (max-width: ${props => props.theme.breakPoints.lg}) {
        font-size: 14px;
      }
    }
  }
`;

type MedicationsProps = {
  patientId: number;
  showOnPrint?: boolean;
};

const Medications = ({ patientId, showOnPrint = true }: MedicationsProps) => {
  const { getString } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [listResponse, setListResponse] = useState<MedicationListResponse>();

  // medications
  const [items, setItems] = useState<MedicationListItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<MedicationListItem>();
  const [page, setPage] = useState(1);
  const itemsPerPage = 4;

  // dose adherence calendars
  const [doses, setDoses] = useState<MedicationDoseAdherence[]>([]);
  const [selectedDose, setSelectedDose] = useState<MedicationDoseAdherence>();

  // load the medication list
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(Endpoints.medicationList(patientId), {
        params: {
          calendarDaysBack: 28,
          percentageDaysBack: 30,
          currentDateTime: format(new Date(), `yyyy-MM-dd'T'HH:mm:ssxxx`),
        },
      })
      .then(response => {
        setListResponse(response.data);
      })
      .catch(error => {
        ErrorToast(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [patientId, selectedItem]);

  // load the medication adherence calendars
  useEffect(() => {
    setDoses([]);
    setSelectedDose(undefined);
    if (!selectedItem) {
      return;
    }

    axios
      .get(
        Endpoints.medicationAdherence(patientId, selectedItem.medication.id),
        {
          params: {
            calendarDaysBack: 28,
            currentDateTime: format(new Date(), `yyyy-MM-dd'T'HH:mm:ssxxx`),
          },
        }
      )
      .then(response => {
        const doses: MedicationDoseAdherence[] = response.data;
        // reorder doses to match ui spec
        const nextDoses = [
          doses.find(d => d.doseType === 'overall'),
          doses.find(d => d.doseType === 'asNeeded'),
          ...doses.filter(d => d.doseType === 'scheduled'),
        ];
        setDoses(nextDoses.filter(d => !!d) as MedicationDoseAdherence[]);
      })
      .catch(error => {
        ErrorToast(error);
      });
  }, [patientId, selectedItem, getString]);

  useEffect(() => {
    const allItems = listResponse?.medicationList ?? [];
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setItems(allItems.slice(startIndex, endIndex));
  }, [listResponse, page]);

  const truncateString = (value: string) => {
    const maxLength = 27;
    return value.length <= maxLength
      ? value
      : value.slice(0, maxLength) + '...';
  };

  const adherenceLegendItems = [
    {
      key: 'form.adherence.noData',
      color: theme.colors.athensGray,
    },
    {
      key: 'form.adherence.missedAll',
      color: theme.colors.amaranth,
    },
    {
      key: 'form.adherence.missedSome',
      color: theme.colors.texasRose,
    },
    {
      key: 'form.adherence.tookAll',
      color: theme.colors.mossGreen,
    },
  ];

  return (
    <Card className={showOnPrint ? '' : 'do-not-print'}>
      <CardHeader>
        <h3>{getString('medicationCardComponent.title.medications')}</h3>
      </CardHeader>
      <Container>
        {isLoading ? (
          <Loader />
        ) : !listResponse?.medicationList.length ? (
          <NoInfoContainer>
            <img src={noMedicationsImage} alt='no medications' />
            <h1>{getString('medicationCardComponent.title.noMedications')}</h1>
            <p>
              {getString('medicationCardComponent.instructions.noMedications')}
            </p>
          </NoInfoContainer>
        ) : (
          <ContentContainer>
            <Overall>
              <p>
                {getString(
                  'medicationCardComponent.instructions.overallMedAdherence'
                )}
              </p>
              <CircularProgress percentage={listResponse?.percentage ?? 0} />
            </Overall>
            <Row>
              <MedicationListContainer>
                <MedicationList data-testid='medications-list'>
                  {items.map(item => {
                    const med = item.medication;
                    const isSelected = med.id === selectedItem?.medication.id;
                    const adherence = item.adherence ?? 0;
                    const isMedInactive = item.medication
                      .versionEffectiveEndDateTime
                      ? format(
                          new Date(item.medication.versionEffectiveEndDateTime),
                          "yyyy-MM-dd'T'"
                        ) <= format(new Date(), "yyyy-MM-dd'T'")
                      : false;
                    return (
                      <button
                        key={med.id}
                        onClick={() => {
                          const nextItem = !isSelected ? item : undefined;
                          setSelectedItem(nextItem);
                        }}
                        className={isSelected ? 'selected' : ''}>
                        <div className='container'>
                          <div className='strength'>
                            <span className='primary'>{med.name}</span>
                            <span className='gray'>
                              {med.strength} {med.form}
                            </span>
                            {med.suspended && (
                              <span className='inactive'>
                                {`${getString(
                                  'medicationModalComponent.label.suspended'
                                )} - ${format(
                                  parseISO(med.lastModifiedDateTime),
                                  'MMM d, y'
                                )}`}
                              </span>
                            )}
                            <p className='tooltip'>
                              {med.strength} {med.form}
                            </p>
                            {isMedInactive &&
                              !med.suspended &&
                              med?.versionEffectiveEndDateTime && (
                                <span className='inactive'>
                                  {`${getString(
                                    'medicationModalComponent.label.inactive'
                                  )} - ${format(
                                    parseISO(med.versionEffectiveEndDateTime),
                                    'MMM d, y'
                                  )}`}
                                </span>
                              )}
                          </div>
                          <div className='progress'>
                            {!med.asNeeded ? (
                              <>
                                <ProgressBar
                                  percentage={adherence}
                                  borderSize={0.5}
                                />
                                <span>{Math.ceil(adherence * 100)}%</span>
                              </>
                            ) : (
                              <span className='gray uppercase'>
                                {getString(
                                  'medicationCardComponent.instructions.asNeeded'
                                )}
                              </span>
                            )}
                          </div>
                          <div
                            className={`arrow ${isSelected && 'selected'}`}
                          />
                        </div>
                      </button>
                    );
                  })}
                </MedicationList>
                <Pager
                  className='pager'
                  currentPage={page}
                  itemsPerPage={itemsPerPage}
                  totalItems={listResponse?.medicationList.length}
                  onPageChange={next => setPage(next)}
                />
              </MedicationListContainer>
              <AdherenceContainer>
                <Dropdown
                  className='primary'
                  data-testid='select-medication'
                  value={selectedItem?.medication.id ?? 0}
                  onChange={e => {
                    const index = e.target.selectedIndex;
                    setSelectedItem(index ? items[index - 1] : undefined);
                  }}>
                  <option value='0'>
                    {getString(
                      'medicationCardComponent.calendar.allMedications'
                    )}
                  </option>
                  {listResponse?.medicationList.map(item => {
                    const med = item.medication;
                    return (
                      <option key={med.id} value={med.id}>
                        {truncateString(
                          `${med.name} (${
                            med.strength ? med.strength : med.form
                          })`
                        )}
                      </option>
                    );
                  })}
                </Dropdown>
                {selectedItem && doses.length > 0 && (
                  <Dropdown
                    data-testid='select-dose'
                    onChange={e => {
                      const index = e.target.selectedIndex;
                      setSelectedDose(doses[index]);
                    }}>
                    {doses.map(dose => {
                      const key = dose.doseType + dose.doseTime;
                      const label = (() => {
                        switch (dose.doseType) {
                          case 'overall':
                            return getString(
                              'medicationCardComponent.calendar.allDoses'
                            );
                          case 'asNeeded':
                            return getString(
                              'medicationCardComponent.calendar.asNeeded'
                            );
                          case 'scheduled':
                            return dose.doseTime;
                        }
                      })();
                      return <option key={key}>{label}</option>;
                    })}
                  </Dropdown>
                )}
                <MedicationCalendar
                  calendar={(() => {
                    if (!selectedItem || doses.length === 0) {
                      // display the overall calendar
                      return listResponse?.calendar;
                    } else if (
                      selectedItem &&
                      !selectedDose &&
                      doses.length > 0
                    ) {
                      // display calendar for selected medication
                      return doses[0].calendar;
                    } else if (selectedDose) {
                      // display asNeeded or scheduled calendar
                      return selectedDose.calendar;
                    } else {
                      return {};
                    }
                  })()}
                />
                <AdherenceLegend>
                  {adherenceLegendItems.map(item => (
                    <div key={item.key} className='item'>
                      <div style={{ backgroundColor: item.color }} />
                      <span>{getString(item.key)}</span>
                    </div>
                  ))}
                </AdherenceLegend>
              </AdherenceContainer>
            </Row>
          </ContentContainer>
        )}
      </Container>
    </Card>
  );
};

export default Medications;
