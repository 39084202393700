import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './assets/theme';
import { APIProvider } from './context/APIContext';
import { UserProvider } from './context/UserContext';
import Login from './components/auth/Login';
import ErrorPage from './components/shared/ErrorPage';
import PatientList from './components/patient/PatientList';
import HCPList from './components/hcp/HCPList';
import HCPProfile from './components/hcp/HCPProfile';
import { ForgotPassword } from './components/auth/ForgotPassword';
import PatientDetail from './components/patient/PatientDetail';
import CustomizeCareModule from './components/patient/caremodule/CustomizeCareModule';
import HCPActivation from './components/hcp/HCPActivation';
import ResetPassword from './components/auth/ResetPassword';

export const App = () => {
  return (
    <BrowserRouter basename='/'>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <APIProvider>
            <Routes>
              <Route
                path='/'
                element={<Navigate to='login' />}
                errorElement={<ErrorPage />}
              />
              <Route path='login' element={<Login />} />
              <Route
                path='forgotpassword/:passwordExpired'
                element={<ForgotPassword />}
              />
              <Route path='setPassword' element={<HCPActivation />} />
              <Route path='newpassword' element={<ResetPassword />} />
              <Route
                path='patient'
                element={<Navigate to='/patient/page/1' />}
              />
              <Route path='patient/page/:page' element={<PatientList />} />
              <Route path='hcp' element={<Navigate to='/hcp/users' />} />
              <Route path='hcp/users' element={<HCPList />} />
              <Route path='hcp/sites' element={<HCPList />} />
              <Route path='hcp/organizations' element={<HCPList />} />
              <Route path='hcp/profile' element={<HCPProfile />} />
              <Route path='patient/:id' element={<PatientDetail />} />
              <Route
                path='patient/:id/customizeModule'
                element={<CustomizeCareModule />}
              />
            </Routes>
          </APIProvider>
        </UserProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
