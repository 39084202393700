import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Endpoints } from './../../api/endpoints';
import { getString } from '../../context/UserContext';

const Link = styled.a`
  color: ${props => props.theme.colors.pacificBlue};
  &:hover {
    text-decoration: underline;
  }
`;

const LegalDoc = (props: { docKey: string }) => {
  const [docUrl, setDocUrl] = useState('');

  useEffect(() => {
    axios
      .get(Endpoints.document, {
        params: {
          product: 'Care4Today',
          key: props.docKey,
        },
      })
      .then(response => {
        setDocUrl(response.data?.[0].url);
      });
  }, [props.docKey]);

  return (
    <>
      {docUrl && (
        <Link target='_blank' rel='noreferrer' href={docUrl}>
          {getString('hcpModalComponent.form.privacyPolicy')}
        </Link>
      )}
    </>
  );
};

export default LegalDoc;
