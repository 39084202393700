import { useEffect, useState } from 'react';
import styled from 'styled-components';
import leftImage from '../../assets/images/cal_arrow_left.svg';
import rightImage from '../../assets/images/cal_arrow_right.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  span {
    font-weight: 400;
    color: ${props => props.theme.colors.loblolly};
    margin-right: 10px;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.athensGray};
    border-radius: 10px;
    &:first-of-type {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &.disabled {
      cursor: default;
      opacity: 0.5;
    }
  }
`;

type Range = {
  lowerBound: number;
  upperBound: number;
};

type PagerProps = {
  className?: string;
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  onPageChange?: (page: number, range: Range) => void;
};

export const Pager = ({
  className,
  currentPage = 1,
  itemsPerPage,
  totalItems,
  onPageChange,
}: PagerProps) => {
  const [page, setPage] = useState(currentPage);

  const getRange = (forPage: number) => ({
    lowerBound: totalItems === 0 ? 0 : (forPage - 1) * itemsPerPage + 1,
    upperBound: Math.min(forPage * itemsPerPage, totalItems),
  });

  const maxPage = Math.max(Math.ceil(totalItems / itemsPerPage), 1);
  const range = getRange(page);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  return (
    <Container className={className}>
      <span>{`${range.lowerBound} - ${range.upperBound} OF ${totalItems}`}</span>
      <button
        className={page <= 1 ? 'disabled' : ''}
        disabled={page <= 1}
        aria-label='previous-page'
        onClick={() => {
          const prevPage = page - 1;
          const prevRange = getRange(prevPage);
          setPage(prevPage);
          onPageChange?.(prevPage, prevRange);
        }}>
        <img src={leftImage} alt='previous page' />
      </button>
      <button
        className={page >= maxPage ? 'disabled' : ''}
        disabled={page >= maxPage}
        aria-label='next-page'
        onClick={() => {
          const nextPage = page + 1;
          const nextRange = getRange(nextPage);
          setPage(nextPage);
          onPageChange?.(nextPage, nextRange);
        }}>
        <img src={rightImage} alt='next page' />
      </button>
    </Container>
  );
};

export default Pager;
