import React from 'react';
import loadingImg from '../../assets/images/loading.svg';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

const Loader = () => {
  return (
    <Container>
      <img src={loadingImg} alt='Loading...' />
    </Container>
  );
};

export default Loader;
