import add from '../../../assets/images/add.svg';
import trash from '../../../assets/images/delete.svg';
import pencil from '../../../assets/images/hcp-list/pencil_sans_circle.svg';
import { useUserContext } from '../../../context/UserContext';
import { CardHeader } from '../detail/Card';
import { Content, FlexCard, SubTitle, Title } from './ModuleStyle';
import { DeleteMedicationModal } from '../modals/MedicationModal';
import { useEffect, useState } from 'react';
import { logGAEvent } from '../../../shared/services/googleAnalyticsService';
import { BuildParams } from '../../../context/BuildParams';
import { Endpoints } from '../../../api/endpoints';
import { useParams } from 'react-router';
import { Medication } from '../../../models';
import axios from 'axios';
import { Tooltip } from '@mui/material';
import { ErrorToast } from '../../shared/ToastrNotifications';
import ManageMedicationModal from '../modals/ManageMedicationModal';

enum ModalType {
  none,
  manageMedication,
  deleteMedication,
}

const MedicationModule = () => {
  const { getString } = useUserContext();
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [patientId] = useState(() => parseInt(id || ''));
  const [medications, setMedications] = useState<Medication[]>([]);
  const [modalType, setModalType] = useState<ModalType>(ModalType.none);
  const [selectedMedication, setSelectedMedication] = useState<Medication>();
  const [refetchMedications, setRefetchMedications] = useState(false);

  useEffect(() => {
    axios
      .get(Endpoints.medication(patientId), {
        params: {
          adherencePercentageDays: 30,
        },
      })
      .then(response => {
        setMedications(response?.data);
      })
      .catch(error => {
        ErrorToast(error);
      });
    setRefetchMedications(false);
  }, [refetchMedications, patientId]);

  useEffect(() => {
    if (!open) {
      setSelectedMedication(undefined);
    }
  }, [open]);

  const handleDelete = (medication: Medication) => {
    if (!medication) return null;

    setSelectedMedication(medication);
    setModalType(ModalType.deleteMedication);
    setOpen(true);
  };

  const handleEdit = (medication: Medication) => {
    if (!medication) return null;

    setSelectedMedication(medication);
    setModalType(ModalType.manageMedication);
    setOpen(true);
  };

  const addMedication = () => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'medications',
      eventAct: 'click',
      eventLbl: 'add_medication',
    });
    setSelectedMedication(undefined);
    setModalType(ModalType.manageMedication);
    setOpen(true);
  };

  const activeModal = () => {
    if (medications === null) return null;

    switch (modalType) {
      case ModalType.manageMedication:
        return (
          <ManageMedicationModal
            modalIsOpen={open}
            setModalIsOpen={setOpen}
            refreshTable={() => setRefetchMedications(true)}
            medication={selectedMedication}
          />
        );
      case ModalType.deleteMedication:
        if (BuildParams.confirm_delete_medication_modal_enabled) {
          return (
            <DeleteMedicationModal
              modalIsOpen={open}
              setModalIsOpen={setOpen}
              medication={selectedMedication}
              refetchMedications={() => setRefetchMedications(true)}
            />
          );
        }
        break;
      default:
        return null;
    }
  };

  return (
    <FlexCard>
      {activeModal()}
      <CardHeader>
        <h3>{getString('moduleCustomizationComponent.title.medications')}</h3>
        {BuildParams.care_plan_add_medication_enabled && (
          <div className='actions'>
            <button onClick={addMedication}>
              <img
                alt='add medication'
                id='add-medication-customization-img'
                src={add}
              />
            </button>
          </div>
        )}
      </CardHeader>
      {medications?.length > 0
        ? medications.map(medication => (
            <Content key={medication.id}>
              <div>
                <Title>{medication.name}</Title>
                <Tooltip
                  title={[medication.strength, medication.form].join(' ')}
                  arrow
                  placement='top'>
                  <SubTitle>
                    {[medication.strength, medication.form].join(' ')}
                  </SubTitle>
                </Tooltip>
              </div>
              <div className='actions'>
                <button onClick={() => handleEdit(medication)}>
                  <img
                    alt='edit medication'
                    id='edit-medication'
                    src={pencil}
                  />
                </button>
                <button onClick={() => handleDelete(medication)}>
                  <img
                    alt='delete medication'
                    id='delete-medication'
                    src={trash}
                  />
                </button>
              </div>
            </Content>
          ))
        : null}
    </FlexCard>
  );
};

export default MedicationModule;
