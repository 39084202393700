import styled from 'styled-components';
import { useUserContext } from '../../../context/UserContext';
import noAppointments from '../../../assets/images/no_appointments_icon.svg';
import listAppointments from '../../../assets/images/log.svg';
import addAppointments from '../../../assets/images/add.svg';
import appointmentIcon from '../../../assets/images/appointment.svg';
import { Card, CardHeader, NoInfoBaseContainer } from './Card';
import { ViewAppointmentsModal } from '../modals/AppointmentListModal';
import Loader from '../../shared/Loader';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { AppointmentDetails } from '../../../models';
import { Endpoints } from '../../../api/endpoints';
import format from 'date-fns/format';
import { AddAppointmentModal } from '../modals/AddAppointmentModal';
import { logGAEvent } from '../../../shared/services/googleAnalyticsService';
import { ErrorToast } from '../../shared/ToastrNotifications';
import { formatISO } from 'date-fns';

enum ModalType {
  none,
  viewAppointments,
  addAppointment,
}

const NoAppointment = styled(NoInfoBaseContainer)`
  flex: 1;
  background-image: url(${noAppointments});
`;

const Header = styled(CardHeader)``;

const Container = styled.div``;

const AppointmentBody = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 105px;
  width: 100%;
  margin: 0 16px;
  border-top: 1px solid ${props => props.theme.colors.athensGray};

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    flex-direction: column;
  }
`;
const AppointmentInfoBlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: space-evenly;
  width: 40%;

  &:first-child {
    border-right: 1px solid ${props => props.theme.colors.athensGray};
  }
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    flex-direction: column;
    align-self: center;
    border: none;
    &:first-child {
      border-right: none;
    }
    padding: 0;
  }
`;
const AppointmentInfoBlock = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1 1 0px;
  width: 0;
  overflow-x: hidden;

  padding: 0 16px;
  border-right: 1px solid ${props => props.theme.colors.athensGray};

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
    border-right: none;
  }

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    padding: 0;
    width: auto;
    border-right: none;
    flex: 1;
  }
`;
const MetricValue = styled.span`
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  &.attended {
    color: ${props => props.theme.colors.mossGreen};
  }
  &.missed {
    color: ${props => props.theme.colors.amaranth};
  }
  &.next-days {
    color: ${props => props.theme.colors.shark};
  }
`;
const MetricLabel = styled.span`
  color: ${props => props.theme.colors.charcoal};
  font-size: 16px;
  font-weight: 600;
  opacity: 0.6;
  text-transform: uppercase;
`;

const AppointmentScheduleContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  width: 60%;

  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    flex-direction: column;
  }
`;

const AppointmentScheduleBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  flex: 1;
  &:first-child {
    border-right: 1px solid ${props => props.theme.colors.athensGray};
    padding-right: 0;
  }
  @media (max-width: ${props => props.theme.breakPoints.sm}) {
    &:first-child {
      border-right: none;
    }
  }
`;
const AppointmentScheduleTitle = styled.label`
  color: ${props => props.theme.colors.charcoal};
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
`;
const AppointmentScheduleIcon = styled.img`
  width: 22px;
  height: 22px;
  padding-right: 16px;
  align-self: center;
`;
const AppointmentScheduleOuterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const AppointmentScheduleInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const AppointmentScheduleName = styled.p`
  color: ${props => props.theme.colors.charcoal};
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const AppointmentScheduleDetails = styled.p`
  color: ${props => props.theme.colors.loblolly};
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

interface AppointmentsProps {
  patientId: number;
  showOnPrint?: boolean;
}

const Appointments = ({ patientId, showOnPrint = true }: AppointmentsProps) => {
  const { getString } = useUserContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.none);
  const [appointmentData, setAppointmentData] = useState<AppointmentDetails>();
  const [appointmentDataLoading, setAppointmentDataLoading] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const gaEvent = (value: string) => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'appointments',
      eventAct: 'click',
      eventLbl: value,
    });
  };

  const handleOpenAppointmentsModal = () => {
    gaEvent('appointments_list');
    setModalType(ModalType.viewAppointments);
    setModalIsOpen(true);
  };
  const handleOpenAddAppointmentModal = () => {
    gaEvent('add_appointment');
    setModalType(ModalType.addAppointment);
    setModalIsOpen(true);
  };

  const hasNoAppointmentData = () => {
    if (appointmentData == null || appointmentData == undefined) {
      return true;
    }

    if (
      appointmentData.attended === 0 &&
      appointmentData.next30Days === 0 &&
      appointmentData.skipped === 0 &&
      appointmentData.mostRecent === null &&
      appointmentData.upcoming === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setAppointmentDataLoading(true);
    axios
      .get(Endpoints.appointmentDetails(patientId), {params: {
        currentTime: formatISO(new Date()),
      }})
      .then(response => {
        setAppointmentData(response.data);
      })
      .catch(error => {
        ErrorToast(error);
      })
      .finally(() => {
        setAppointmentDataLoading(false);
      });
  }, [patientId, refreshTable]);

  const activeModal = () => {
    switch (modalType) {
      case ModalType.addAppointment:
        return (
          <AddAppointmentModal
            setModalIsOpen={isOpen => {
              setModalIsOpen(isOpen);
              if (!isOpen) {
                setModalType(ModalType.none);
              }
            }}
            modalIsOpen={modalIsOpen}
            patientId={patientId}
            refreshTable={() => setRefreshTable(!refreshTable)}
          />
        );
      case ModalType.viewAppointments:
        return (
          <ViewAppointmentsModal
            setModalIsOpen={isOpen => setModalIsOpen(isOpen)}
            modalIsOpen={modalIsOpen}
            patientId={patientId}
          />
        );
      case ModalType.none:
        return null;
    }
  };

  return (
    <Card className={showOnPrint ? '' : 'do-not-print'}>
      {activeModal()}
      <Header>
        <h3>{getString('appointmentCardComponent.title.appointment')}</h3>
        <div className='actions'>
          <button
            onClick={handleOpenAppointmentsModal}
            data-testid='view-modal-button'>
            <img
              alt='open appointment list'
              id='patient-appointments-list-img'
              src={listAppointments}
            />
          </button>
          <button
            onClick={handleOpenAddAppointmentModal}
            data-testid='add-modal-button'>
            <img
              alt='add appointment'
              id='create-patient-appointment-img'
              src={addAppointments}
            />
          </button>
        </div>
      </Header>
      <Container>
        {appointmentDataLoading ? (
          <Loader />
        ) : hasNoAppointmentData() ? (
          <NoAppointment>
            <h1 data-testid='no-appointments'>
              {getString('appointmentCardComponent.title.noAppointment')}
            </h1>
            <p>
              {getString(
                'appointmentCardComponent.instructions.addAppointment'
              )}{' '}
              + {getString('appointmentCardComponent.instructions.button')}
            </p>
          </NoAppointment>
        ) : (
          <AppointmentBody>
            <AppointmentInfoBlockContainer>
              <AppointmentInfoBlock>
                <MetricValue className='attended' data-testid='attended'>
                  {appointmentData?.attended}
                </MetricValue>
                <MetricLabel>
                  {getString('appointmentCardComponent.label.attended')}
                </MetricLabel>
              </AppointmentInfoBlock>
              <AppointmentInfoBlock>
                <MetricValue className='missed' data-testid='missed'>
                  {appointmentData?.skipped}
                </MetricValue>
                <MetricLabel>
                  {getString('appointmentCardComponent.label.missed')}
                </MetricLabel>
              </AppointmentInfoBlock>
              <AppointmentInfoBlock>
                <MetricValue className='next-days' data-testid='next-30'>
                  {appointmentData?.next30Days}
                </MetricValue>
                <MetricLabel>
                  {getString('appointmentCardComponent.label.next30Days')}
                </MetricLabel>
              </AppointmentInfoBlock>
            </AppointmentInfoBlockContainer>
            <AppointmentScheduleContainer>
              <AppointmentScheduleBlock>
                {appointmentData?.mostRecent ? (
                  <>
                    <AppointmentScheduleTitle>
                      {getString('appointmentCardComponent.label.mostRecent')}
                    </AppointmentScheduleTitle>
                    <AppointmentScheduleOuterContainer>
                      <AppointmentScheduleIcon src={appointmentIcon} alt='' />
                      <AppointmentScheduleInnerContainer>
                        <AppointmentScheduleName>
                          {appointmentData?.mostRecent?.appointment.title}
                        </AppointmentScheduleName>
                        <AppointmentScheduleDetails>
                          {appointmentData?.mostRecent?.appointment
                                    ?.occurrenceDateTime
                            ? format(
                                new Date(
                                  appointmentData?.mostRecent?.appointment
                                    ?.occurrenceDateTime
                                ),
                                'hh:mm aaa | E, M/d'
                              )
                            : ''}
                        </AppointmentScheduleDetails>
                      </AppointmentScheduleInnerContainer>
                    </AppointmentScheduleOuterContainer>
                  </>
                ) : null}
              </AppointmentScheduleBlock>
              {appointmentData?.upcoming ? (
                <>
                  <AppointmentScheduleBlock>
                    <AppointmentScheduleTitle>
                      {getString('appointmentCardComponent.label.upcoming')}
                    </AppointmentScheduleTitle>
                    <AppointmentScheduleOuterContainer>
                      <AppointmentScheduleIcon src={appointmentIcon} alt='' />
                      <AppointmentScheduleInnerContainer>
                        <AppointmentScheduleName>
                          {appointmentData?.upcoming?.title}
                        </AppointmentScheduleName>
                        <AppointmentScheduleDetails>
                          {appointmentData?.upcoming?.occurrence
                            ? format(
                                new Date(
                                  appointmentData?.upcoming?.occurrence
                                ),
                                'hh:mm aaa | E, M/d'
                              )
                            : ''}
                        </AppointmentScheduleDetails>
                      </AppointmentScheduleInnerContainer>
                    </AppointmentScheduleOuterContainer>
                  </AppointmentScheduleBlock>
                </>
              ) : null}
            </AppointmentScheduleContainer>
          </AppointmentBody>
        )}
      </Container>
    </Card>
  );
};

export default Appointments;
